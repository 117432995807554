import React, { Component } from "react";
import Modal from "react-responsive-modal";

import { connect } from "react-redux";
import { withTranslation, Trans } from 'react-i18next';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DatePicker from "react-datepicker";
import {Row, Col} from "react-bootstrap"
import Select from 'react-select'

import countryList from 'react-select-country-list'
import moment from "moment";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {get} from "services/storage";
import {levels_api_list, schools_api_list, application_file_api_store} from 'services/cms/api';
import i18nCountries from "i18n-iso-countries";
import fr from "i18n-iso-countries/langs/fr.json";

// Charger la traduction en français
i18nCountries.registerLocale(fr);

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 26,
    minHeight: 26,
    backgroundColor: "rgba(245, 248, 249, 0.3)"
  }),
  input: (provided) => ({
    ...provided,
    height: 20
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }), 
}

class ModalParentCandidate extends Component {
  constructor(props){
    super(props);
    this.scholar_year_id = get('current_year')? get('current_year').id:false;
    this.state = {
      success: false,
      selectedValue: "a",
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      errorCaptcha: false,
      errorGlobal: "",
      server_errors: {},
      openconnexionModal: false,
      phone_number: "",
      is_email: true,
      first:this.props.first?false:true,
      levels: props.levels || [],
      schools: props.school ? [props.school] : []
    };
    this.countries = countryList().getData();
    this.countries = this.countries.filter(op => {
      return op.value != "EH";
    });
  }

  componentDidMount(){
    if(this.state.levels.length == 0)
      levels_api_list().then(res => this.setState({levels:res.levels}));
    if(this.state.schools.length == 0)
      schools_api_list().then(res => this.setState({schools:res.schools}));
  }

  getValidationSchema = () => {
    const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/;
    var shape = {
      first_name_parent: Yup.string()
        .required(this.props.t("First name required")),
      last_name_parent: Yup.string()
        .required(this.props.t("Last Name required")),
      first_name: Yup.string()
        .required(this.props.t("First name required")),
      last_name: Yup.string()
        .required(this.props.t("Last Name required")),
      mail_parent: Yup.string()
        .email(this.props.t("Email format incorrect"))
        .required(this.props.t("Email required")+"!"),
      phone_parent: Yup.string()
        .matches(phoneRegExp, this.props.t('Phone format incorrect'))
        .required(this.props.t("Phone is required")+"!"),
      // mail_parent: Yup.string()
      //   .email(this.props.t("Email format incorrect"))
      //   .when('phone_parent', {
      //       is: (phone_parent) => !phone_parent,
      //       then: Yup.string().required(this.props.t("Email or phone is required")+"!")
      //   }),
      // phone_parent: Yup.string()
      //   .matches(phoneRegExp, this.props.t('Phone format incorrect'))
      //   //.phone("Phone format incorrect")
      //   .when('mail_parent', {
      //       is: (mail_parent) => !mail_parent,
      //       then: Yup.string().required(this.props.t("Email or phone is required")+"!")
      //   }),
      school: Yup.string()
        .required(this.props.t("School required")),   
      level: Yup.string()
        .required(this.props.t("Level required"))
    };
    if(!this.props.school){
      shape.captcha = Yup.string().required(this.props.t("Captcha required"));
    }
    return Yup.object().shape(shape, ['mail_parent', 'phone_parent']);
  };

  // handleOpenSuccess = (phone_number) => {
  //   if(phone_number){
  //     this.setState({
  //       success: true,
  //       phone_number: phone_number,
  //       is_email: false
  //     });
  //   }
  //   else{
  //     this.setState({
  //       success: true,
  //       is_email: true
  //     });
  //   }
  // };

  // handleCloseSuccess = () => {
  //   this.setState({
  //     success: false
  //   });
  // };

  handleSubmit = (form) => {
    if(this.state.first){
      this.setState({first:false})
    }
    else{
      if (this.props.school || form.captcha == this.state.captcha.min + this.state.captcha.max) {
        var register = {
          last_name_parent : form.last_name_parent,
          first_name_parent : form.first_name_parent,
          last_name_parent2 : form.last_name_parent2,
          first_name_parent2 : form.first_name_parent2,
          phone_parent : form.phone_parent,
          phone_parent2:form.phone_parent2,
          mail_parent : form.mail_parent,
          mail_parent2 : form.mail_parent2,
          last_name : form.last_name,
          first_name : form.first_name,
          birthday : form.birthday,
          address : form.address,
          gender : form.gender,
          last_level : form.last_level ,
          level : form.level,
          last_school : form.last_school,
          school : form.school,
          by_school: !!this.props.school,
          notes: "",
          remarque:form.remarque,
          cin_par1:form.cin_par1,
          cin_par2:form.cin_par2,
          profes_par1:form.profes_par1,
          profes_par2:form.profes_par2,
          handi:form.handi,
          situ_fam:form.situ_fam,
          code_student:form.code_student,
          know:form.know,

        }
        if (this.scholar_year_id){
          register.scholar_year = this.scholar_year_id
        }
  
        if(this.props.condidature){
          register.id = this.props.condidature.id;
        }
        // if(this.props.first){
        //   register.action="update"
        // }
        this.setState({server_errors: {}});
        var libraries = get('libraries');
        var school = get('school');

        if(school)
          libraries = get('libraries_' + school.id);
        if(libraries){
          register.course_key=libraries.mission.course_key
          application_file_api_store(register).then(res => {
            if(this.props.first){
              this.props.handleignoreform()
            }else{
              this.props.success(res.app, !!this.props.condidature);
            }
            
          })
        }
      } else {
        this.setState({
          errorCaptcha: true,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      }

    }
    
  };

  render_levels(name, value, setFieldValue){
    const {levels} = this.state;
    var options_level = levels.map(level => ({value:level.level_id,label:level.name}));
    var default_level = this.props.condidature ? options_level.filter(le_op => le_op.value == this.props.condidature.level_id)[0] : undefined;
    return <Select 
      styles={customStyles}
      maxMenuHeight={150}
      value={default_level}
      className="connexion-custom-select"
      options={options_level}
      onChange={(evt) => setFieldValue(name, evt.value)}/>

    // <div className="select">
    //   <select name={name}
    //     value={value}
    //     onChange={handleChange}>
    //     <option key={''} value={""}>{ this.props.t('Choose a level') }</option>
    //     {levels.map(level => (
    //       <option key={level.level_id} value={level.level_id}>{level.name}</option>
    //     ))}  
    //   </select>
    //   <i className="fa fa-chevron-circle-down" aria-hidden="true" />
    // </div>
  }

  render() {
    const {server_errors, schools} = this.state;
    const {condidature} = this.props;
    var schools_options = schools.map(sch => ({value:sch.id, label:sch.name}));
     /* TODO: TRANSLATIONS */
    var initialValues = {
      last_name_parent : condidature ? condidature.parent.last_name : "",
      first_name_parent : condidature ? condidature.parent.first_name : "",

      first_name_parent2 : condidature ? condidature.parent2?condidature.parent2.first_name : "":"",
      last_name_parent2 : condidature ? condidature.parent2?condidature.parent2.last_name : "":"",
      code_student: condidature ?condidature.code:"",
      phone_parent2 :condidature ? condidature.parent2?condidature.parent2.phone : "":"",
      mail_parent2: condidature ? condidature.parent2?condidature.parent2.email : "":"",
      handi:"",
      situ_fam:"",
      remarque: condidature ?condidature.remark:"",
      know:condidature ?condidature.know:"",
      profes_par1:"",
      profes_par2:"",

      phone_parent : condidature ? condidature.parent.phone : "",
      mail_parent : condidature ? condidature.parent.email : "",
      last_name : condidature ? condidature.last_name : "",
      first_name : condidature ? condidature.first_name : "",
      birthday : condidature ? condidature.birthday : "",
      address : condidature ? condidature.address : "",
      gender :condidature ? condidature.gender : "",
      last_level : condidature ? condidature.last_level : "" ,
      level : condidature ? condidature.level_id : "",
      last_school : condidature ? condidature.last_school : "",
      school : this.props.school ? this.props.school.id : "",
      notes: "",
      captcha: ""
    };
    var default_school = schools_options.filter(sc_op => sc_op.value == initialValues.school)[0];
    const next_scolar_year_year = moment().month() > 9 ? moment().year() + 1 : moment().year();
    return (
      <>
      <Modal
        let checked = {false}
        open={this.props.open}
        onClose={evt => {}}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white",dialogCandidate: "white" },
          modal: {
            dialog: "white",
            dialogConnexion: "white",
            dialogAddSchool: "white",
            // dialogRegister: "white"
            dialogCandid:"white"
          }
        }}
      >
        <div className="modalConnexion modalform">
          <div className="modalConnexionTop">
            <h2>{ this.props.t('Registration') + " " + next_scolar_year_year + "/" + (next_scolar_year_year + 1) }</h2>
            <span
              className="schoolClose"
              onClick={this.props.hide}
            >
              &times;
            </span>
            <div className="modalConnexionForm">
              <div className="connexionLigne">
                <div className="connexionW100">
                  {this.state.errorGlobal && (
                    <div className="textErrors">{this.state.errorGlobal}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={this.getValidationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values, actions);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue
            }) => {
              return (
                this.state.first ?
                <Form autoComplete="off">
                  <div className="modalConnexionForm">
                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent First Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.first_name_parent &&
                            "textInputError"}`}
                          name="first_name_parent"
                          value={values.first_name_parent}
                          onChange={handleChange}
                        />
                        {errors.first_name_parent && touched.first_name_parent ? (
                          <div className="textErrors">{errors.first_name_parent}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent Last Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.last_name_parent &&
                            "textInputError"}`}
                          name="last_name_parent"
                          value={values.last_name_parent}
                          onChange={handleChange}
                        />
                        {errors.last_name_parent && touched.last_name_parent ? (
                          <div className="textErrors">{errors.last_name_parent}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent Email') }*</label>
                        <input type="text"
                          className={`textInput ${errors.mail_parent &&
                            "textInputError"}`}
                          name="mail_parent"
                          value={values.mail_parent}
                          onChange={handleChange}
                        />
                        {errors.mail_parent && touched.mail_parent ? (
                          <div className="textErrors">{errors.mail_parent}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent phone') }*</label>
                        <IntlTelInput inputClassName={ ((('phone_parent' in server_errors) || (errors.phone_parent && touched.phone_parent))?"textInputError":"")}
                          preferredCountries={['ma']} 
                          defaultValue={(values.phone_parent)?values.phone_parent:''}
                          onPhoneNumberChange={(status, value, countryData, number, id) => {
                              setFieldValue('phone_parent', number);
                          }}/>
                        {(('phone_parent' in server_errors) || (errors.phone_parent && (touched.phone_parent || touched.phone_parent))) &&
                          <div className="textErrors">{server_errors.phone_parent || errors.phone_parent}</div>
                        }
                      </div>   
                    </div>
                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Child First Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.first_name &&
                            "textInputError"}`}
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name ? (
                          <div className="textErrors">{errors.first_name}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Child Last Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.last_name &&
                            "textInputError"}`}
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name ? (
                          <div className="textErrors">{errors.last_name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Birthday') }</label>
                        <DatePicker
                          name='birthday'
                          selected={values.birthday}
                          onChange={(date) => setFieldValue('birthday',date)}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/MM/yyyy"
                        />
                      </div>
                        <div className="col-12 col-md-6">
                          <label>{ this.props.t('Address') }</label>
                          <textarea name="address" 
                            value={values.address}
                            onChange={handleChange}>
                          </textarea>
                      </div>
                    </div>

                    <div className="row connexionLigne">
                      <div className="col-12 col-md-12">
                        <label>{ this.props.t('Previous School') }</label>
                        <input type="text"
                          className="textInput"
                          name="last_school"
                          value={values.last_school}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="col-12 col-md-6">
                        <label>{ this.props.t('Previous Level') }</label>
                        {this.render_levels('last_level',values.last_level, setFieldValue)}
                      </div> */}
                    </div>

                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('School') }*</label>
                        <Select 
                          styles={customStyles}
                          maxMenuHeight={150}
                          className="connexion-custom-select"
                          value={default_school}
                          options={schools.map(sch => ({value:sch.id, label:sch.name}))}
                          onChange={(evt) => setFieldValue('school',evt.value)}
                          isDisabled={!!this.props.school}
                        />
                        {/* <div className="select">
                          
                          <select name='school' 
                          value={values.school}
                          onChange={handleChange}>
                            <option key={''} value={""}>{ this.props.t('Choose a school') }</option>
                            {schools.map(school => (
                              <option key={school.id} value={school.id}>{school.name}</option>
                            ))}  
                          </select>
                          <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                        </div> */}
                        {errors.school && touched.school ? (
                          <div className="textErrors">{errors.school}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Niveau Ciblé') }*</label>
                        {this.render_levels('level',values.level,setFieldValue)}
                        {errors.level && touched.level ? (
                          <div className="textErrors">{errors.level}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row connexionLigne">
                      <div className="col-12">
                        <label>{ this.props.t('Gender') } </label>
                        <div className="gender">
                          <div className="genderMale">
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={values.gender === "male" || values.gender === "m"}
                              onChange={() => setFieldValue("gender", "male")}
                            />
                            <span /> <b>{ this.props.t('Male') }</b>
                          </div>

                          <div className="genderFemale">
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={values.gender === "female" || values.gender === "f"}
                              onChange={() => setFieldValue("gender", "female")}
                            />
                            <span /> <b>{ this.props.t('Female') }</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.props.school &&
                    <div className="row connexionLigne">
                      <div className="col-12">
                        <div className="captcha">
                          <p className="pCaptcha">
                            {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                          </p>
                          <input
                            name="captcha"
                            type="text"
                            value={values.captcha}
                            onChange={handleChange}
                            className="captchaInput"
                          />
                        </div>
                        {this.state.errorCaptcha && (
                          <div className="textErrors">{ this.props.t('Captcha Invalid') }</div>
                        )}
                        {errors.captcha && touched.captcha ? (
                          <div className="textErrors">{errors.captcha}</div>
                        ) : null}
                      </div>
                    </div>
                    }

                    <div className="connexionLigne w12">
                      {/* <a onClick={this.handleAlreadyHaveAnAccount}>{ this.props.t('Already have an account?') }</a> */}
                      <button type="submit" className="add" >{ this.props.t('next') }</button>

                    </div>
                  </div>
                </Form>
              :<Form autoComplete="off">
              <div className="modalConnexionForm">
              <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent 2 First Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.first_name_parent2 &&
                            "textInputError"}`}
                          name="first_name_parent2"
                          value={values.first_name_parent2}
                          onChange={handleChange}
                        />
                        {errors.first_name_parent2 && touched.first_name_parent2 ? (
                          <div className="textErrors">{errors.first_name_parent2}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent 2 Last Name') }*</label>
                        <input type="text"
                          className={`textInput ${errors.last_name_parent2 &&
                            "textInputError"}`}
                          name="last_name_parent2"
                          value={values.last_name_parent2}
                          onChange={handleChange}
                        />
                        {errors.last_name_parent2 && touched.last_name_parent2 ? (
                          <div className="textErrors">{errors.last_name_parent2}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row connexionLigne">
                      <div className="col-12 col-md-6">
                        <label>{ this.props.t('Parent 2 Email') }</label>
                        <input type="text"
                          className={`textInput ${errors.mail_parent2 &&
                            "textInputError"}`}
                          name="mail_parent2"
                          value={values.mail_parent2}
                          onChange={handleChange}
                        />
                        {errors.mail_parent2 && touched.mail_parent2 ? (
                          <div className="textErrors">{errors.mail_parent2}</div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-6">
                      {false && values.phone_parent2}
                        <label>{ this.props.t('Parent 2 phone') }</label>
                        <IntlTelInput inputClassName={ ((('phone_parent2' in server_errors) || (errors.phone_parent2 && touched.phone_parent2))?"textInputError":"")}
                          preferredCountries={['ma']} 
                          name="phone_parent2"
                          defaultValue={values.phone_parent2}
                          onPhoneNumberChange={(status, value_, countryData, number_, id_) => {
                              setFieldValue('phone_parent2', number_);
                          }}/>
                        {(('phone_parent2' in server_errors) || (errors.phone_parent2 && (touched.phone_parent2 || touched.phone_parent2))) &&
                          <div className="textErrors">{server_errors.phone_parent2 || errors.phone_parent2}</div>
                        }
                      </div>   
                    </div>


                <div className="row connexionLigne">
                  <div className="col-12 col-md-6">
                    <label>{ this.props.t('Code Massar') }</label>
                    <input type="text"
                      className={`textInput ${errors.code_student &&
                        "textInputError"}`}
                      name="code_student"
                      value={values.code_student}
                      onChange={handleChange}
                    />
                    {errors.code_student && touched.code_student ? (
                      <div className="textErrors">{errors.code_student}</div>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                   
                
                    <label>{ this.props.t('Handicap') } </label>
                    <div className="gender">
                      <div className="choiseSituation">
                        <input
                          type="radio"
                          name="handi"
                          value="yes_handi"
                          checked={values.handi === "yes_handi" || values.handi === "yes_handi"}
                          onChange={() => setFieldValue("handi", "yes_handi")}
                        />
                        <span /> <b>{ this.props.t('yes') }</b>
                      </div>

                      <div className="choiseSituation">
                        <input
                          type="radio"
                          name="handi"
                          value="no_handi"
                          checked={values.handi === "no_handi" || values.handi === "no_handi"}
                          onChange={() => setFieldValue("handi", "no_handi")}
                        />
                        <span /> <b>{ this.props.t('no') }</b>
                      </div>
                    </div>
                 




                  </div>
                </div>
                
                <div className="row connexionLigne">
                  <div className="col-12 col-md-6">
                    <label>{ this.props.t('CIN parent1') }</label>
                    <input type="text"
                      className={`textInput ${errors.cin_par1 &&
                        "textInputError"}`}
                      name="cin_par1"
                      value={values.cin_par1}
                      onChange={handleChange}
                    />
                    {errors.cin_par1 && touched.cin_par1 ? (
                      <div className="textErrors">{errors.cin_par1}</div>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label>{ this.props.t('CIN parent2') }</label>
                    <input type="text"
                      className={`textInput ${errors.cin_par2 &&
                        "textInputError"}`}
                      name="cin_par2"
                      value={values.cin_par2}
                      onChange={handleChange}
                    />
                    {errors.cin_par2 && touched.cin_par2 ? (
                      <div className="textErrors">{errors.cin_par2}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row connexionLigne">
                  <div className="col-12 col-md-6">
                    <label>{ this.props.t('profession parent1') }</label>
                    <input type="text"
                      className={`textInput ${errors.profes_par1 &&
                        "textInputError"}`}
                      name="profes_par1"
                      value={values.profes_par1}
                      onChange={handleChange}
                    />
                    {errors.profes_par1 && touched.profes_par1 ? (
                      <div className="textErrors">{errors.profes_par1}</div>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-6">
                    <label>{ this.props.t('profession parent2') }</label>
                    <input type="text"
                      className={`textInput ${errors.profes_par2 &&
                        "textInputError"}`}
                      name="profes_par2"
                      value={values.profes_par2}
                      onChange={handleChange}
                    />
                    {errors.profes_par2 && touched.profes_par2 ? (
                      <div className="textErrors">{errors.profes_par2}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row connexionLigne">
                  <div className="col-12 col-md-12">
                    <label>{ this.props.t('Remarques') }</label>
                    <textarea  type="text"
                      className={`textInput ${errors.remarque &&
                        "textInputError"}`}
                      name="remarque"
                      value={values.remarque}
                      onChange={handleChange}
                    />
                    {errors.remarque && touched.remarque ? (
                      <div className="textErrors">{errors.remarque}</div>
                    ) : null}
                  </div>
                    
                </div>
                
                <div className="row connexionLigne">
                  <div className="col-12 col-md-12">
                    <label>{ this.props.t('Comment avez-vous connu notre établissement') }?</label>
                    <input  type="text"
                      className={`textInput ${errors.know &&
                        "textInputError"}`}
                      name="know"
                      value={values.know}
                      onChange={handleChange}
                    />
                    {errors.know && touched.know ? (
                      <div className="textErrors">{errors.know}</div>
                    ) : null}
                  </div>
                    
                </div>

                <div className="row connexionLigne">
                <div className="col-12">
                   
                
                   <label>{ this.props.t('Situation familiale') } </label>
                   <div className="gender">
                     <div className="choiseSituation">
                       <input
                         type="radio"
                         name="situ_fam"
                         value="sit_mar"
                         checked={values.situ_fam === "sit_mar" || values.situ_fam === "sit_mar"}
                         onChange={() => setFieldValue("situ_fam", "sit_mar")}
                       />
                       <span /> <b>{ this.props.t('Marié(e)') }</b>
                     </div>

                     <div className="choiseSituation">
                       <input
                         type="radio"
                         name="situ_fam"
                         value="sit_div"
                         checked={values.situ_fam === "sit_div" || values.situ_fam === "sit_div"}
                         onChange={() => setFieldValue("situ_fam", "sit_div")}
                       />
                       <span /> <b>{ this.props.t('Divorcé(e)') }</b>
                     </div>
                     <div className="choiseSituation">
                       <input
                         type="radio"
                         name="situ_fam"
                         value="sit_veu"
                         checked={values.situ_fam === "sit_veu" || values.situ_fam === "sit_veu"}
                         onChange={() => setFieldValue("situ_fam", "sit_veu")}
                       />
                       <span /> <b>{ this.props.t('Veuf(ve)') }</b>
                     </div>
                   </div>
                




                 </div>
                </div>
               

                

         

             
                {!this.props.school &&
                <div className="row connexionLigne">
                  <div className="col-12">
                    <div className="captcha">
                      <p className="pCaptcha">
                        {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                      </p>
                      <input
                        name="captcha"
                        type="text"
                        value={values.captcha}
                        onChange={handleChange}
                        className="captchaInput"
                      />
                    </div>
                    {this.state.errorCaptcha && (
                      <div className="textErrors">{ this.props.t('Captcha Invalid') }</div>
                    )}
                    {errors.captcha && touched.captcha ? (
                      <div className="textErrors">{errors.captcha}</div>
                    ) : null}
                  </div>
                </div>
                }

                <div className="connexionLigne w12">
                  {/* <a onClick={this.handleAlreadyHaveAnAccount}>{ this.props.t('Already have an account?') }</a> */}
                  <button style={{marginRight:'5px'}} type="submit" className="add" >{ this.props.t('valider') }</button>
                  {this.props.first && <button  style={{marginRight:'5px'}}  onClick={() => this.props.handleignoreform()} className="add" >{ this.props.t('Ignorer') }</button> }
                   {/* <button  style={{marginRight:'5px'}}  onClick={() => this.props.handleignoreform()} className="add" >{ this.props.t('Ignorer') }</button> */}

                </div>
              </div>
            </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withTranslation('translations')(ModalParentCandidate));

