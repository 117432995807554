import React, { Component } from "react";

import MediaQuery from "react-responsive";
import { get } from "../services/storage";
import { check_ip_address_access } from "../services/cms/api";

export default class NotAuthorizedComponent extends Component {

  constructor(props) {
    super(props);
    
    this.user = get('session_user');
    this.school = get('school');

    this.state = {
      currentIP: ""
    };

    this.check_current_ip();
    this.useIPChangeListener()
  }

  componentWillUnmount(){
    
    clearInterval(this.ip_interval)
}

  check_current_ip = async () => {
    try {
      const response = await fetch("https://checkip.amazonaws.com");
      const ip = await response.text();
      const newIP = ip.trim();
      // console.log('3333', this.state.currentIP, newIP)
      if (this.state.currentIP !== newIP) {
        console.log("L'adresse IP a changé :", newIP);
        this.check_ip_address_access(); // Déclenche l'événement
        this.setState({
          currentIP: newIP
        })
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'IP :", error);
    }
  }
  useIPChangeListener = () => {
    // console.log('1111')
    if(this.school && this.school.ip_address && this.user.profile.is_assistant){
      this.ip_interval = setInterval(async () => {
        this.check_current_ip()
      }, 5000)
    }
  }

  check_ip_address_access = () => {
    if(this.school && this.school.ip_address && this.user.profile.is_assistant){
      let data = {
        school_id: this.school.id
      }
      check_ip_address_access(data).then(res => {
        if(res.authorized){
          // global.authorized_access = false;
          this.props.history.push('/');
        }
          
        // this.setState({ip_address_authorized: res.authorized})
      })
    }
  }

  render() {
    return <div className="d-flex flex-column justify-content-center align-items-center" style={{height:'100vh'}}>
        <MediaQuery query="(min-width: 500px)">
            <img src={global.logo} alt="Logo" style={{width:'500px'}}/>
        </MediaQuery>
        <MediaQuery query="(max-width: 500px)">
            <img src={global.logo} alt="Logo" style={{width:'300px'}}/>
        </MediaQuery>
        <h1 style={{color: "#d7523e"}}>
            Adresse IP incorrecte
        </h1>
      </div>;
  }
}
