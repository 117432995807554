import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
import { unconnected_api_get, log_js_error} from "services/cms/api";
import axios from "axios";
const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const base_url = process.env.REACT_APP_URL_CMS;
class LayoutHomeSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    this.state = {
    };
  }

  componentWillReceiveProps(props){
  }

  componentDidCatch(error, info) {
  }

  componentDidMount(){
  }


  render() {
    const { Layout, Component } = this.props;
  return (
      <Layout 
        {...this.props}
      >
        <Component
          {...this.props}
          global_data={this.state}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutHomeSystem);
