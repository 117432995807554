import logo from "../../assets/img/logo.svg";
import logo_laraki from "../../assets/img/logo_laraki.png";
import iconConnexion from "../../assets/img/login_icon.svg";
import slider from "../../assets/img/first-page-image.jpg";
import slider2 from "../../assets/img/img-children.jpg";
import slider3 from "../../assets/img/children2.jpg";
import gameboy from "../../assets/img/gameboy.jpg";
import viewAll from "../../assets/img/view-all-arrow.svg";
import childSlider1 from "../../assets/img/image-slider01.jpg";
import childSlider2 from "../../assets/img/image-slider02.jpg";
import childSlider3 from "../../assets/img/image-slider03.jpg";

import logoFooter from "../../assets/img/logo-footer.svg";
import feedImage from "../../assets/img/img-children.jpg";
import imgSignin from "../../assets/img/sign-in-kids.png";
import registerKids from "../../assets/img/register-kids.png";
import editIcon from "../../assets/img/editIcon.png";

import chimie from "../../assets/img/Chimie.gif";
import geographie from "../../assets/img/Geography.gif";
import physique from "../../assets/img/Physics.gif";
import english from "../../assets/img/English.gif";
import frensh from "../../assets/img/French.gif";
import history from "../../assets/img/History.gif";
import maths from "../../assets/img/Maths.gif";
import sciences from "../../assets/img/Sciences.gif";
import arabic from "../../assets/img/Arabic.gif";
import iconUser from "../../assets/img/user.png";
import iconGroup from "../../assets/img/group.png";
import Group from "../../assets/img/chat_bot.svg";
import NewsFeed from "../../assets/img/shape-news-feed.svg";
import ArrowNews from "../../assets/img/news_arrow.svg";

import Arrowback from "../../assets/img/arrow-back.svg";
import iconClose from "../../assets/img/close-calc-icon.png";
import kidSlide1 from "../../assets/img/Caroussels/Kids/1.png"
import kidSlide2 from "../../assets/img/Caroussels/Kids/2.png"
import kidSlide3 from "../../assets/img/Caroussels/Kids/3.png"
import kidSlide4 from "../../assets/img/Caroussels/Kids/4.png"
import kidSlide5 from "../../assets/img/Caroussels/Kids/5.png"



import logo_landing from "../../assets/img/logo_landing.jpg"
import logo_landing_2x from "../../assets/img/logo_landing@2x.jpg"
import logo_landing_3x from "../../assets/img/logo_landing@3x.jpg"
import bg_connexion from "../../assets/img/bg_connexion.png"
import icon_cadenat from "../../assets/img/icon_cadenat.png"
import icon_lng from "../../assets/img/icon_lng.png"
import items_imgs from "../../assets/img/items_imgs.png"
import background from "../../assets/img/background.png"
import boy_laptop from "../../assets/img/boy-laptop.png"
import parents_slide_1 from "../../assets/img/parents_slide_1.png"
import professors_slide_1 from "../../assets/img/professors_slide_1.png"
import schools_slide_1 from "../../assets/img/schools_slide_1.png"
import boy_laptop_mobile from "../../assets/img/boy-laptop_mobile.png"
import parents_slide_1_mobile from "../../assets/img/parents_slide_1_mobile.png"
import professors_slide_1_mobile from "../../assets/img/professors_slide_1_mobile.png"
import schools_slide_1_mobile from "../../assets/img/schools_slide_1_mobile.png"
import school_slide_mobile_2 from "../../assets/img/school_slide_mobile_2.png"
import parent_slide_mobile_2 from "../../assets/img/parent_slide_mobile_2.png"
import professor_slide_mobile_2 from "../../assets/img/professor_slide_mobile_2.png"
import students_slide_mobile_2 from "../../assets/img/students_slide_mobile_2.png"
import arrow_to_right from "../../assets/img/arrow_to_right.png"
import bg_btn_orange from "../../assets/img/bg_btn_orange.png"
import bg_btn_white from "../../assets/img/bg_btn_white.png"
import bg_btn_orange_shadow from "../../assets/img/bg_btn_orange_shadow.png"
import bg_btn_shadow2 from "../../assets/img/bg_btn_shadow2.png"
import bg_btn_orange_shadow2 from "../../assets/img/bg_btn_orange_shadow2.png"
import top_left_2 from "../../assets/img/top_left_2.png"
import bg_lines from "../../assets/img/bg-lines.png"
import bg_object from "../../assets/img/bg_object.png"
import bg_lines2 from "../../assets/img/bg-lines-2.png"
import app_store_button from "../../assets/img/app-store-button.png"
import google_play_button from "../../assets/img/google-play-button.png"
import imqge from "../../assets/img/imqge.png"
import bg from "../../assets/img/bg.png"
import pedagogie_interactif from "../../assets/img/pedagogie_interactif.png"
import vie_scolaire from "../../assets/img/vie_scolaire.png"
import communication from "../../assets/img/communication.png"
import finance from "../../assets/img/finance.png"
import service from "../../assets/img/service.png"
import for_schools from "../../assets/img/for_schools.png"
import for_professors from "../../assets/img/for_professors.png"
import for_parents from "../../assets/img/for_parents.png"
import for_students from "../../assets/img/for_students.png"
import bg_5 from "../../assets/img/bg_5.png"
import exercises_chiffre_bg from "../../assets/img/exercises_chiffre_bg.png"
import students_chiffre_bg from "../../assets/img/students_chiffre_bg.png"
import schools_chiffre_bg from "../../assets/img/schools_chiffre_bg.png"
import exercises_chiffre_icon from "../../assets/img/exercises_chiffre_icon.png"
import students_chiffre_icon from "../../assets/img/students_chiffre_icon.png"
import schools_chiffre_icon from "../../assets/img/schools_chiffre_icon.png"
import ressources_bg from "../../assets/img/ressources_bg.png"
import card_image_1 from "../../assets/img/card_image_1.png"
import card_image_2 from "../../assets/img/card_image_2.png"
import card_image_3 from "../../assets/img/card_image_3.jpg"
// import labtop_gif from "../../assets/img/labtop_gif.gif"
import labtop_gif from "../../assets/img/labtop_gif.png"
// import mobiles_gif from "../../assets/img/mobiles_gif.gif"
import mobiles_gif from "../../assets/img/mobiles_gif.png"
import qr_design from "../../assets/img/qr-design.png"
import espace_parents from "../../assets/img/espace_parents.png"
import espace_professors from "../../assets/img/espace_professors.png"
import espace_students from "../../assets/img/espace_students.png"
import espace_vie_scolaire from "../../assets/img/espace_vie_scolaire.png"
import bg_img_p8 from "../../assets/img/bg_img_p8.png"
import bg_img_p8_1 from "../../assets/img/bg_img_p8_1.png"
import bg_img_p8_2 from "../../assets/img/bg_img_p8_2.png"
import login_bg from "../../assets/img/login_bg.png"
import bg_9_lines from "../../assets/img/bg_p9_2.png"
import bg_9_image from "../../assets/img/img-kid.png"
import kr_ellipse from "../../assets/img/kr-ellipse.png"
import km_ellipse from "../../assets/img/km-ellipse.png"
import kr_phone from "../../assets/img/kr_phone.png"
import km_phone from "../../assets/img/km_phone.png"
import km_qr_design from "../../assets/img/km-qr-design.png"
import kr_qr_design from "../../assets/img/kr-qr-design.png"
import logo_footer from "../../assets/img/logo_footer.png"
import facebook_icon from "../../assets/img/fb.png"
import instagram_icon from "../../assets/img/in.png"
import linkedin_icon from "../../assets/img/li.png"
import whatsapp_icon from "../../assets/img/wt.png"
import address_icon from "../../assets/img/address.png"
import phone_icon from "../../assets/img/phone.png"
import email_icon from "../../assets/img/email.png"


export const images = {
  logo,
  logo_laraki,
  iconConnexion,
  slider,
  slider2,
  slider3,
  gameboy,
  viewAll,
  childSlider1,
  childSlider2,
  childSlider3,
  logoFooter,
  feedImage,
  imgSignin,
  registerKids,
  editIcon,
  chimie,
  geographie,
  physique,
  english,
  frensh,
  history,
  maths,
  sciences,
  arabic,
  iconUser,
  iconGroup,
  Group,
  NewsFeed,
  ArrowNews,
  Arrowback,
  iconClose,
  kidSlide1,
  kidSlide2,
  kidSlide3,
  kidSlide4,
  kidSlide5,
  logo_landing,
  logo_landing_2x,
  logo_landing_3x,
  bg_connexion,
  icon_cadenat,
  icon_lng,
  items_imgs,
  background,
  boy_laptop,
  schools_slide_1,
  parents_slide_1,
  professors_slide_1,
  boy_laptop_mobile,
  schools_slide_1_mobile,
  school_slide_mobile_2,
  professor_slide_mobile_2,
  parent_slide_mobile_2,
  students_slide_mobile_2,
  parents_slide_1_mobile,
  professors_slide_1_mobile,
  arrow_to_right,
  bg_btn_orange,
  bg_btn_white,
  bg_btn_orange_shadow,
  bg_btn_shadow2,
  bg_btn_orange_shadow2,
  top_left_2,
  bg_lines,
  bg_object,
  bg_lines2,
  app_store_button,
  google_play_button,
  imqge,
  bg,
  pedagogie_interactif,
  vie_scolaire,
  communication,
  finance,
  service,
  for_schools,
  for_professors,
  for_parents,
  for_students,
  bg_5,
  exercises_chiffre_bg,
  students_chiffre_bg,
  schools_chiffre_bg,
  exercises_chiffre_icon,
  students_chiffre_icon,
  schools_chiffre_icon,
  ressources_bg,
  card_image_1,
  card_image_2,
  card_image_3,
  labtop_gif,
  mobiles_gif,
  qr_design,
  espace_parents,
  espace_professors,
  espace_students,
  espace_vie_scolaire,
  bg_img_p8,
  bg_img_p8_1,
  bg_img_p8_2,
  login_bg,
  bg_9_lines,
  bg_9_image,
  kr_ellipse,
  km_ellipse,
  kr_phone,
  km_phone,
  kr_qr_design,
  km_qr_design,
  facebook_icon,
  instagram_icon,
  linkedin_icon,
  whatsapp_icon,
  logo_footer,
  address_icon,
  phone_icon,
  email_icon,
};

export const messages = {
  button: "REGISTER",
  labelNom: "Nom",
  labelNom: "Prénom"
};

export const form = {
  nom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "nom"
  },
  prenom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "prenom"
  }
};
