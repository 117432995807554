import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../../assets/variables/colors";
import { images } from "./_resources";
import ModalSigninDesktop from "../children/home/components/ModalSigninDesktop";
import ModalRegisterDesktop from "../children/home/components/ModalRegisterDesktop";
import ModalSignInRegisterMobile from "../children/home/components/ModalSignInRegisterMobile";
//import CommonModalConnexion from "../professor/home/components/ModalConnexion";
import CommonModalConnexion from "../professor/home/components/CommonModalConnexion";
import ModalRegisterGlobal from "components/ModalRegisterGlobal";
import ModalSchoolRegister from "../school/components/ModalSchoolRegister";
import ModalSignInMobile from "../professor/home/components/ModalSignInMobile";
import ModalSchoolMobile from "../school/components/ModalSchoolMobile";
import ModalParentMobile from "../parents/components/ModalParentMobile";
import ModalPasswordChanged from "../professor/home/components/ModalPasswordChanged";
import ModalTermsAndCondition from "../professor/home/components/ModalTermsAndCondition";
import ModalCompteSuccess from "../children/home/components/ModalCompteSuccess";
import { get, set } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';
import FonctionalityNotExistModal from "../parents/dashboard/components/FonctionalityNotExistModal";

class HomeHeader extends Component {
  constructor(props){
    super(props);
    this.is_sanadi_logo = window.location.hostname == "sanadidev.koolskools.com";
    this.is_laraki_logo = window.location.hostname == "preprod.koolskools.com";
    this.device_width = window.innerWidth;
    this.state = {
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      openChildrenModalConnexion: (this.props.openConnexion || (this.props.hash && this.props.hash==="#klogin")) && this.props.page === "children",
      hash:this.props.hash && this.props.hash,
      register: false,
      page: props.page,
      registerChild: false,
      openFonctionalityNotExistModal: false,
      openCommonConnexionModal: (this.props.openModalConnexion || (this.props.location.state &&
        this.props.location.state.signin_mode))&& ["professor", "school", "parent"].indexOf(this.props.pages) >= 0,
      openModalTerms:false,
      openSuccessRegister:false,
      messageSuccess: this.props.pages === "children" ? "" : this.props.t("An email has been sent to your address to activate your account"),
      with_phone: false,
      phone_number: "",
      // openRegisterProfessor:
      //   (this.props.openRegister ||
      //   (this.props.location.state &&
      //   this.props.location.state.register_mode)) &&
      //   this.props.pages === "professor"
      //     ? true
      //     : false,
      openRegisterSchool: this.props.openRegister && this.props.pages === "school",
      // openRegisterParent: (this.props.openRegister && this.props.pages === "parent") || !!this.props.email,
      openRegister: props.openRegister && this.props.pages !== "school",
      popupSignin: {
        popup: (this.props.openConnexion || (this.props.hash && this.props.hash==="#klogin")) && this.props.pages === "children",
        signin: true
      },
      openParentMobile: {
        popup:
          (this.props.openRegister && this.props.pages === "parent") ||
          !!this.props.email ||
          (!!this.props.username && this.props.pages === "parent") ||
          false,
        signin:
          !!this.props.username && this.props.pages === "parent" ? true : false
      },
      openProfessorMobile: {
        popup:
          (this.props.openRegister || 
          ((this.props.openConnexion || !!this.props.username ||
          (this.props.location.state &&
          this.props.location.state.register_mode))) &&
          this.props.pages === "professor")
            ? true
            : false,
        signin: (this.props.openConnexion || !!this.props.username) &&
            this.props.pages === "professor" ? true : false
      },
      openSchoolMobile: {
        popup: this.props.openRegister && this.props.pages === "school",
        signin:
          !!this.props.username && this.props.pages === "school" ? true : false
      },
      default_username: this.props.username || "",
      account_activated: this.props.account_activated,
      default_email: this.props.email,
      key_inv: this.props.key_inv
    };
  }

  componentWillReceiveProps(newProps){
    this.setState({
      openChildrenModalConnexion:(newProps.openConnexion || (newProps.hash && newProps.hash==="#klogin")) && newProps.pages === "children",
      openCommonConnexionModal: (newProps.openModalConnexion)&& ["professor", "school", "parent"].indexOf(newProps.pages) >= 0,
      hash:newProps.hash && newProps.hash,
      popupSignin: {
        popup: (newProps.openConnexion || (newProps.hash && newProps.hash==="#klogin"))  && newProps.pages === "children",
        signin: true
      },
      openProfessorMobile: {
        popup:
          (newProps.openRegister || 
          ((newProps.openConnexion || !!newProps.username ||
          (newProps.location.state &&
          newProps.location.state.register_mode)))) &&
          newProps.pages === "professor"
            ? true
            : false,
        signin: (newProps.openConnexion || !!newProps.username) &&
        newProps.pages === "professor" ? true : false
      },
      page: newProps.page,
      openSchoolMobile: {
        popup: (newProps.openConnexion || newProps.openRegister) && newProps.pages === "school",
        signin: (newProps.openConnexion || !!newProps.username)
          && newProps.pages === "school" ? true : false
      },
      openParentMobile: {
        popup:
          (newProps.openRegister && newProps.pages === "parent") ||
          !!newProps.email ||
          ((!!newProps.username || newProps.openConnexion) && newProps.pages === "parent") ||
          false,
        signin: (!!newProps.username || newProps.openConnexion) &&
        newProps.pages === "parent" ? true : false
      },
      // openRegisterProfessor:
      //   (newProps.openRegister ||
      //   (newProps.location.state &&
      //     newProps.location.state.register_mode)) &&
      //     newProps.pages === "professor"
      //     ? true
      //     : false,
      openRegisterSchool: newProps.openRegister && newProps.pages === "school",
      // openRegisterParent: (newProps.openRegister && newProps.pages === "parent") || !!newProps.email,
      openRegister: newProps.openRegister && newProps.pages !== "school",
      reset_password_response: newProps.reset_password_response,
      reset_password_error: newProps.reset_password_error,
    })
  }

  handleOpenRegisterProfessor = () => {
    this.setState({ openRegisterProfessor: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterProfessor = () => {
    this.setState({ openRegisterProfessor: false });
    this.props.handleCloseRegister();
  };

  // for school
  handleOpenRegisterSchool = () => {
    this.setState({ openRegisterSchool: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterSchool = () => {
    this.setState({ openRegisterSchool: false });
    this.props.handleCloseRegister();
  };

  handleOpenRegisterParent = () => {
    this.setState({ openRegisterParent: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterParent = () => {
    this.setState({ openRegisterParent: false });
    this.props.handleCloseRegister();
  };

  onCloseModalRegisterChild = () => {
    this.setState({ registerChild: false, openFonctionalityNotExistModal: false });
  };

  onOpenModalRegisterChild = () => {
    // this.setState({ registerChild: false, openFonctionalityNotExistModal: true  });
    this.setState({ openRegister:true  });
  };

  onOpenModal = () => {
    this.setState({ openChildrenModalConnexion: true });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  onCloseModal = () => {
    this.setState({ openChildrenModalConnexion: false });
    this.props.handleCloseConnexion();
  };

  handleOpenSigninMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: true
      },
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleOpenRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: false
      },
    });
  };

  handleCloseRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: false,
        signin: true
      }
    });
    this.props.handleCloseConnexion();
  };

  handleOpenCnxConnexion = () => {
    this.setState({
      openCommonConnexionModal: true
    });
    if(this.props.handleOpenModalConnexion){
      this.props.handleOpenModalConnexion();
    }
  };

  handleCloseCnxConnexion = () => {
    this.setState({
      openCommonConnexionModal: false,
      openChildrenModalConnexion: false,
      default_username: "",
      account_activated: null,
      default_email: "",
      key_inv: ""
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: true,
        signin: false
      },
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: true,
        signin: true
      },
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: false,
        signin: false
      },
      default_username: "",
      account_activated: null,
      default_email: "",
      key_inv: ""
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: true,
        signin: false
      },
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: true,
        signin: true
      },
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: false,
        signin: false
      }
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: true,
        signin: false
      },
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: true,
        signin: true
      },
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: false,
        signin: false
      }
    });
    this.props.handleCloseConnexion();
  };
  changeLanguage = (new_language) => {
    return;
    set("current_language", new_language);
    this.props.i18n.changeLanguage(new_language);
  }

  updateDimensions = () => {
    this.device_width = window.innerWidth;
    this.setState({
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render_navigation = () => {
    var {is_mobile, is_tablete} = this.state;
    return <div className={is_mobile ? "col-12 navigation" : "col-sm-8 d-none d-sm-block navigation"} style={{zIndex: 1}}>
      <nav>
        <ul>
          <li>
            <Link
              to="/home/school"
              className={
                this.state.page === "school" ? "active" : ""
              }
            >
              <span>{ this.props.t('Schools') }</span>
              <span className="icon_round"></span>
              <span className="icon_round_hidden"></span>
            </Link>
          </li>
          <li>
            <Link
              to="/home/professor"
              className={
                this.state.page === "professors" ? "active" : ""
              }
            >
              <span>{ this.props.t('Professors') }</span>
              <span className="icon_round"></span>
              <span className="icon_round_hidden"></span>
            </Link>
          </li>
          <li>
            <Link
              to="/home/parent"
              className={
                this.state.page === "parents" ? "active" : ""
              }
            >
              <span>{ this.props.t('Parents') }</span>
              <span className="icon_round"></span>
              <span className="icon_round_hidden"></span>
            </Link>
          </li>
          <li>
            <Link
              to="/home/children"
              className={
                this.state.page === "children" ? "active" : ""
              }
            >
              <span>{ this.props.t('Students') }</span>
              <span className="icon_round"></span>
              <span className="icon_round_hidden"></span>
            </Link>
          </li>
          {!(is_mobile) &&
            <>{this.render_language_select()}</>
          }
        </ul>
      </nav>
      {/* {(!(is_mobile)) &&
        <>{this.render_language_select()}</>
      } */}
    </div>
  }
  render_language_select = () => {
    var {is_mobile, is_tablete} = this.state;
    const current_language = get("current_language");
    return <div className="lang">
      <div className={"lng en " + (current_language === "en" ? "active" : "")} onClick={() => this.changeLanguage("en")}>
        <span >{this.props.t("EN")}</span>
        <span className="selected_lng" style={{left: "-1.4vw"}}>
          <img src={images.icon_lng}/>
          <span className="txt">{this.props.t("EN")}</span>
        </span>
      </div>
      <div className={"lng ar " + (current_language === "ar" ? "active" : "")} onClick={() => this.changeLanguage("ar")}>
        <span>{this.props.t("AR")}</span>
        <span className="selected_lng" style={{left: "-0.8vw"}}>
          <img src={images.icon_lng}/>
          <span className="txt">{this.props.t("AR")}</span>
        </span>
      </div>
      <div className={"lng fr " + (current_language === "fr" ? "active" : "")} onClick={() => this.changeLanguage("fr")}>
        <span>{this.props.t("FR")}</span>
        <span className="selected_lng" style={{left: "0"}}>
          <img src={images.icon_lng}/>
          <span className="txt">{this.props.t("FR")}</span>
        </span>
      </div>
    </div>
  }

  render() {
    const {openRegister, is_mobile, is_tablete} = this.state;
    const current_language = get("current_language");
    return (
      <>
        <HeaderStyle className={is_mobile ? " mobile " : is_tablete ? " tablete " : "web"}>
          <div className="container">
            <div className="row" style={{justifyContent: "space-between"}}>
              <div className={is_mobile ? "col-4 logo" : "col-sm-2 col-8 logo"}>
                <img src={images.logo_landing} className="img-fluid" />
              </div>
              {!(is_mobile) &&
              <>
                {this.render_navigation()}
              </>
              }
              <div className={is_mobile ? "col-8 connexion" : "col-sm-2 col-4 connexion"}>
                {(is_mobile) &&
                  <>{this.render_language_select()}</>
                }
                <div className="connexion_btn" onClick={() =>
                  this.state.page === "children"
                    ? this.onOpenModal()
                    : this.handleOpenCnxConnexion()
                }>
                  <div>
                    <img src={images.icon_cadenat} />
                    <span>{ this.props.t('Sign in') }</span>
                  </div>
                </div>
              </div>
            </div>
            {(is_mobile) &&
              <div className="row">
                {this.render_navigation()}
              </div>
            }
          </div>
        </HeaderStyle>

        {(!!this.state.default_username || this.state.openCommonConnexionModal || this.state.openChildrenModalConnexion) &&
            <CommonModalConnexion
              pages={this.props.pages}
              username={this.state.default_username}
              account_activated={this.state.account_activated}
              key_inv={this.state.key_inv}
              openProfessor={
              !!this.state.default_username || this.state.openCommonConnexionModal || this.state.openChildrenModalConnexion
              }
              handleCloseCnxConnexion={this.handleCloseCnxConnexion}
            />
          }
          <FonctionalityNotExistModal
            open={this.state.openFonctionalityNotExistModal}
            handleCloseModal={this.onCloseModalRegisterChild}
            addedMessage={this.props.t("Veuillez demander vos identifiants au niveau de votre école")+"."}
          />
        {/* <MediaQuery query="(min-width: 1200px)"> */}
          {/* <ModalSigninDesktop
            open={this.state.openChildrenModalConnexion}
            onCloseModal={this.onCloseModal}
            onOpenModal={this.onOpenModal}
          /> */}
          {/* <ModalRegisterDesktop
            register={this.state.registerChild}
            onCloseModalRegister={this.onCloseModalRegisterChild}
          /> */}


          {/* <ModalSchoolRegister
            openRegisterSchool={this.state.openRegisterSchool}
            handleCloseRegisterSchool={this.handleCloseRegisterSchool}
          /> */}
          {/* {openRegister &&
            <ModalRegisterGlobal
              open={openRegister}
              pages={this.props.pages}
              handleCloseRegister={() => this.setState({openRegister:false})}
              handleOpenTermsAndCondition={() => {
                this.setState({openModalTerms:true})
              }}
              handleOpenSuccess={(message) => this.setState({
                openSuccessRegister:true,
                messageSuccess:message
              })}
            />
          } */}

        {/* </MediaQuery> */}
        {/* <MediaQuery query="(max-width: 1199.98px)"> */}

          {/* <ModalSignInRegisterMobile
            popupSignin={this.state.popupSignin}
            username={this.state.default_username}
            account_activated={this.state.account_activated}
            handleOpenSigninMobile={this.handleOpenSigninMobile}
            handleOpenRegisterMobile={this.handleOpenRegisterMobile}
            handleCloseRegisterMobile={this.handleCloseRegisterMobile}
          /> */}

          {/* <ModalSchoolMobile
            openSchoolMobile={ this.state.openSchoolMobile}
            username={this.state.default_username}
            account_activated={this.state.account_activated}
            handleOpenSignupSchoolMobile={this.handleOpenSignupSchoolMobile}
            handleOpenSigninSchoolMobile={this.handleOpenSigninSchoolMobile}
            handleCloseSchoolMobile={this.handleCloseSchoolMobile}
          /> */}

          {/* <ModalParentMobile
            openParentMobile={ this.state.openParentMobile}
            username={this.state.default_username}
            email={this.state.default_email}
            key_inv={this.state.key_inv}
            account_activated={this.state.account_activated}
            handleOpenSignupParentMobile={this.handleOpenSignupParentMobile}
            handleOpenSigninParentMobile={this.handleOpenSigninParentMobile}
            handleCloseParentMobile={this.handleCloseParentMobile}
            handleOpenTermsAndCondition={() => {
              this.setState({openModalTerms:true})
            }}
            handleOpenSuccess={(message) => this.setState({
              openSuccessRegister:true,
              messageSuccess:message
            })}
          /> */}
          {/* {(this.state.openProfessorMobile && this.state.openProfessorMobile.popup) &&
            <ModalSignInMobile
              username={this.state.default_username}
              account_activated={this.state.account_activated}
              openProfessorMobile={this.state.openProfessorMobile}
              handleOpenSignupProfessorMobile={this.handleOpenSignupProfessorMobile}
              handleOpenSigninProfessorMobile={this.handleOpenSigninProfessorMobile}
              handleOpenSuccess={(message, with_phone, phone_number) => this.setState({
                openSuccessRegister:true,
                messageSuccess:message || this.state.messageSuccess,
                with_phone: with_phone,
                phone_number: phone_number
              })}
              handleOpenTermsAndCondition={() => {
                this.setState({openModalTerms:true})
              }}
              handleCloseProfessorMobile={this.handleCloseProfessorMobile}
              schoolProfessorKey={
                (this.props.location.state && this.props.location.state.key)
                ? this.props.location.state.key
                  : false
                  }
              professor={
                (this.props.location.state && this.props.location.state.professor)
                ? this.props.location.state.professor
                : false
                }
            />
          } */}
        {/* </MediaQuery> */}

        <ModalPasswordChanged
          success={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.password_changed
          }
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleClose={() => {
            console.log(this.state)
            this.setState({reset_password_response: "", reset_password_error: ""});
            const state = { ...this.props.history.location.state };
            delete state.password_changed;
            this.props.history.replace({
              ...this.props.history.location,
              state
            });
          }}
        />
        {this.state.openSuccessRegister &&
          <ModalCompteSuccess success={this.state.openSuccessRegister}
              handleCloseSuccess={() => this.setState({
                                        openSuccessRegister:false,
                                        messageSuccess:this.props.pages === "children" ? "" : this.props.t("An email has been sent to your address to activate your account")
                                      })}
              with_phone={this.state.with_phone}
              phone_number={this.state.phone_number}
              message={this.state.messageSuccess}/>
        }



        <ModalTermsAndCondition
          success={this.state.openModalTerms}
          handleClose={() => this.setState({openModalTerms:false})}
        />
      </>
    );
  }
}

const HeaderStyle = styled.header`
  width: 100%;
  background: transparent;
  padding: 0 12%;
  .container{
    width: 100%;
    margin: 0;
    max-width: unset;
    padding-right: 0;
    .row{
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .logo{
        padding: 15px;
        img{
          width: 181px;
          height: 116px;
          margin: 0;
          padding-left: 0;
          object-fit: contain;
        }
      }
      .connexion{
        padding-right: 0;
        .connexion_btn{
          background-image: url(${images.bg_connexion});
          background-size: 100% 100%;
          width: 100%;
          height: 114px;
          margin: 0 0 47px 0;
          padding: 26px 32px 25px 31px;
          padding: 26px 2vw;
          object-fit: contain;
          cursor: pointer;
          >div{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img{
              width: 28px;
              height: 35px;
              margin: 0 33px;
              object-fit: contain;
              margin: auto;
            }
            span{
              width: 94px;
              height: 12px;
              margin: 0px 0 0;
              font-family: QuicksandLight;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: #042a45;
              margin: auto;
            }
          }
        }
      }
      .navigation {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        padding: 0 1.68vw;
        text-align: unset;
        .lang {
          width: 11.7vw;
          height: 3.41vw;
          right: 0vw;
          top: -0.6vw;
          margin: 3.53vw 4.53vw 3.53vw 0;
          padding: 1vw 1.12vw 1.4vw 1.12vw;
          object-fit: contain;
          border-radius: 1.7vw;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .lng{
            height: 16px;
            margin: 0 1.96vw 0 0;
            font-family: QuicksandLight;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.1;
            letter-spacing: normal;
            text-align: left;
            color: #ffa827;
            position: relative;
            .selected_lng{
              display: none;
              position: absolute;
              width: 4.03vw;
              height: 140px;
              margin: 0;
              padding: 1vw 1vw 2vw;
              object-fit: contain;
              background-color: #ffa827;
              border-radius: 0 0 2.15vw 2.15vw;
              justify-content: center;
              flex-direction: column;
              bottom: -40px;
              left: -20px;
              >img{
                width: 20px;
                height: 25px;
                margin: 0 auto 14px;
                object-fit: contain;
              }
              >.txt{
                width: 24px;
                height: 16px;
                margin: 14px 0 0;
                margin-top: 0;
                font-family: QuicksandLight;
                font-size: 1.5vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.85;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                width: 100%;
                text-align: center;
              }
            }
            >span:not(.selected_lng){
              width: 24px;
              height: 16px;
              margin: 14px 0 0;
              margin-top: 0;
              font-family: QuicksandLight;
              font-size: 1.5vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              width: 100%;
              text-align: center;
              display: block;
            }
            &.active{
              .selected_lng{
                display: flex;
              }
            }
          }
        }
        nav {
          opacity: 1;
          border-radius: 17.5px;
          background-color: transparent;
          display: inline-block;
          padding: 2px 3px;
          margin-top: 0px;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          ul {
            padding: 0;
            margin: 0;
            li {
              display: inline-flex;
              margin: 0;
              &:first-child {
                margin-left: 0;
              }
              a {
                cursor: pointer;
                background: unset;
                .icon_round{
                  display: none;
                  width: 10px;
                  height: 10px;
                  object-fit: contain;
                  background-color: #64c2be;
                  margin: auto;
                  border-radius: 50%;
                }
                .icon_round_hidden{
                  display: none;
                  width: 10px;
                  height: 10px;
                  object-fit: contain;
                  background-color: transparent;
                  margin: auto;
                  border-radius: 50%;
                }
                span:not(.icon_round):not(.icon_round_hidden){
                  width: 71px;
                  height: 23px;
                  margin: 57px 13px 9px 15px;
                  font-family: QuicksandLight;
                  font-size: 1.6vw;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.53;
                  letter-spacing: normal;
                  text-align: left;
                  color: #6e7b81;
                  margin: 0;
                  text-transform: none;
                }
                &:hover{
                  background: unset;
                }
    
                &.active {
                  background: unset;
                  .icon_round{
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .signin {
    text-align: right;
    margin: 15px 0 0;
    display:flex;
    justify-content:flex-end;
    li {
      display: inline-block;
      text-align: center;
      a {
        opacity: 0.9;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2.5px;
        text-align: right;
        color: ${colors.white};
        cursor: pointer;
      }
      &:nth-child(2) {
        margin-left: 15px;
        padding-left: 15px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -3px;
          width: 4px;
          top: 3px;
          bottom: 3px;
          background: #3eb6ac;
          border-radius: 5px;
        }
      }
    }
  }

  &.web{
    .container{
      .row{
        .connexion{
          padding-right: 2vw;
          .connexion_btn{
            padding-left: 0;
            padding-right: 0;
          }
        }
        .navigation {
          .lang {
            .lng{
              .selected_lng{
                >img{
                }
                >.txt{
                }
              }
              &.active{
                .selected_lng{
                }
              }
            }
          }
          nav {
            ul {
              position: relative;
              width: 100%;
              li {
              }
              .lang{
                top: 0;
                right: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .signin {
      text-align: right;
      margin: 15px 0 0;
      display:flex;
      justify-content:flex-end;
      li {
        display: inline-block;
        text-align: center;
        a {
          opacity: 0.9;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2.5px;
          text-align: right;
          color: ${colors.white};
          cursor: pointer;
        }
        &:nth-child(2) {
          margin-left: 15px;
          padding-left: 15px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: -3px;
            width: 4px;
            top: 3px;
            bottom: 3px;
            background: #3eb6ac;
            border-radius: 5px;
          }
        }
      }
    }
  }
  &.tablete{
    width: 100%;
    background: transparent;
    padding: 0 7vw;
    .container{
      width: 100%;
      margin: 0;
      max-width: unset;
      padding-right: 0;
      padding-left: 0;
      >.row{
        height: 11vw;
        >div{
          height: 11vw;
        }
      }
      .row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        .logo{
          padding: 1.5vw;
          img{
            width: 12.5vw;
            height: 8vw;
            margin: 0;
            padding-left: 0;
            object-fit: contain;
          }
        }
        .connexion{
          padding-right: 2vw;
          .connexion_btn{
            background-image: url(${images.bg_connexion});
            background-size: 100% 100%;
            max-width: 100%;
            width: 10vw;
            height: 8vw;
            float: right;
            margin: 0 0 47px 0;
            padding: 26px 32px 25px 31px;
            padding: 26px 1vw;
            object-fit: contain;
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;
            >div{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              img{
                width: 2vw;
                height: 2.5vw;
                margin: 0 2.35vw;
                object-fit: contain;
                margin: auto;
              }
              span{
                width: max-content;
                height: 12px;
                margin: 0px 0 0;
                font-family: QuicksandLight;
                font-size: 1vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #042a45;
                margin: auto;
              }
            }
          }
        }
        .navigation {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 30px;
          padding: 0 1.68vw;
          text-align: unset;
          .lang {
            width: 11.7vw;
            height: 3.41vw;
            margin: 3.53vw 7.53vw 3.53vw 0;
            padding: 1vw 1.12vw 1.4vw 1.12vw;
            position: absolute;
            right: -6.28vw;
            top: -1vw;
            object-fit: contain;
            border-radius: 1.7vw;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .lng{
              height: 16px;
              margin: 0 1.96vw 0 0;
              font-family: QuicksandLight;
              font-size: 1.2vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.1;
              letter-spacing: normal;
              text-align: left;
              color: #ffa827;
              position: relative;
              .selected_lng{
                display: none;
                position: absolute;
                width: 4.03vw;
                height: 8vw;
                margin: 0;
                padding: 1vw 1vw 2vw;
                object-fit: contain;
                background-color: #ffa827;
                border-radius: 0 0 2.15vw 2.15vw;
                justify-content: center;
                flex-direction: column;
                bottom: -2.5vw;
                left: -20px;
                >img{
                  width: 2vw;
                  height: 2vw;
                  margin: 0 auto 14px;
                  object-fit: contain;
                }
                >.txt{
                  width: 24px;
                  height: 2vw;
                  margin: 14px 0 0;
                  margin-top: -0.8vw;
                  font-family: QuicksandLight;
                  font-size: 1.2vw;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  text-align: left;
                  color: #fff;
                  width: 100%;
                  text-align: center;
                }
              }
              &.active{
                .selected_lng{
                  display: flex;
                }
              }
            }
          }
          nav {
            opacity: 1;
            border-radius: 17.5px;
            background-color: transparent;
            display: inline-block;
            padding: 2px 3px;
            margin-top: 0px;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: -1vw;
            ul {
              position: relative;
              width: 100%;
              padding: 0;
              margin: 0;
              .lang{
                top: 4px;
                right: 0;
                margin: 0;
                height: 4vw;
                .selected_lng{
                  justify-content: space-between !important;
                  padding-bottom: 2vw !important;
                  padding-top: 1vw !important;
                  img{
                    margin: 0 auto 0 !important;
                  }
                  .txt{
                    margin-top: unset !important;
                    font-size: 1.4vw !important;
                  }
                }
              }
              li {
                display: inline-flex;
                margin: 0;
                &:first-child {
                  margin-left: 0;
                }
                a {
                  cursor: pointer;
                  background: unset;
                  .icon_round{
                    display: none;
                    width: 10px;
                    height: 10px;
                    object-fit: contain;
                    background-color: #64c2be;
                    margin: auto;
                    border-radius: 50%;
                  }
                  span:not(.icon_round):not(.icon_round_hidden){
                    width: 71px;
                    height: 23px;
                    margin: 57px 13px 9px 15px;
                    font-family: QuicksandLight;
                    font-size: 2vw;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.53;
                    letter-spacing: normal;
                    text-align: left;
                    color: #6e7b81;
                    margin: 0;
                    text-transform: none;
                  }
                  &:hover{
                    background: unset;
                  }
      
                  &.active {
                    background: unset;
                    .icon_round{
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .signin {
      text-align: right;
      margin: 15px 0 0;
      display:flex;
      justify-content:flex-end;
      li {
        display: inline-block;
        text-align: center;
        a {
          opacity: 0.9;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2.5px;
          text-align: right;
          color: ${colors.white};
          cursor: pointer;
        }
        &:nth-child(2) {
          margin-left: 15px;
          padding-left: 15px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: -3px;
            width: 4px;
            top: 3px;
            bottom: 3px;
            background: #3eb6ac;
            border-radius: 5px;
          }
        }
      }
    }
  }

  &.mobile{
    padding: 0 0;
    .container{
      padding: 0 3.8vw;
      .row{
        margin: 0;
        .logo{
          width: 21.4vw;
          max-width: 21.4vw;
          padding-right: 0;
          padding-left: 0;
          paddind-top: 13px;
          padding-bottom: 0;
          img{
            width: 25vw;
            height: 15.8vw;
            max-width: 25vw;
          }
        }
        .connexion{
          display: flex;
          padding: 4vw 2vw;
          justify-content: end;
          padding-right: 0.9vw;
          padding-top: 0;
          width: 71vw;
          min-width: 71vw;
          padding-bottom: 0;
          .connexion_btn{
            width: 30.8vw;
            height: 22.2vw
            margin-left: 0px;
            margin: 0;
            >div{
              img{
                width: 6.3vw;
                height: 6.9vw;
                margin-top: 1vw;
              }
              span{
                width: unset;
                font-size: 2.9vw;
                margin: 0 auto;
                height: unset;
                font-weight: bold;
              }
            }
          }
          .lang {
            width: 27vw;
            height: 7.41vw;
            margin: 5.53vw 4.7vw 3.53vw 0;
            padding: 1.12vw 0.5vw 1.4vw 0vw;
            object-fit: contain;
            border-radius: 3.7vw;
            background-color: #fff;
            display: flex;
            justify-content: center;
            cursor: pointer;
            position: relative;
            top: unset;
            right: unset;
            margin-top: 7.63vw
            .lng{
              height: unset;
              margin: 0 1.96vw 0 0;
              font-family: QuicksandLight;
              font-size: 3vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.1;
              letter-spacing: normal;
              text-align: left;
              color: #ffa827;
              position: relative;
              width: 33%;
              margin: 0;
              text-align: center;
              padding-top: 1vw;
              .selected_lng{
                display: none;
                position: absolute;
                width: 11vw;
                height: 18vw;
                margin: 0;
                padding: 1vw 1vw 2vw;
                object-fit: contain;
                background-color: #ffa827;
                border-radius: 0 0 6vw 6vw;
                justify-content: center;
                flex-direction: column;
                top: -8.75vw;
                bottom: unset;
                left: -0.25vw;
                right: 0;
                >img{
                  width: 3vw;
                  height: 3.8vw;
                  margin: 0 auto 2.1vw;
                  object-fit: contain;
                  position: absolute;
                  left: 50%;
                  top: 33%;
                  transform: translate(-50%, -50%);
                }
                >.txt{
                  width: 24px;
                  height: 16px;
                  margin: 2.1vw 0 0;
                  margin-top: 0;
                  font-family: QuicksandLight;
                  font-size: 3.2vw;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.85;
                  letter-spacing: normal;
                  text-align: left;
                  color: #fff;
                  width: 100%;
                  text-align: center;
                  position: absolute;
                  left: 50%;
                  top: 52%;
                  transform: translate(-50%, -50%);
                }
              }
              &.active{
                .selected_lng{
                  display: flex;
                }
              }
            }
          }
        }
        .navigation{
          padding: 0 1.2vw;
          margin-top: 3px;
          nav{
            width: 100%;
            ul{
              display: flex;
              li{
                margin: auto;
                a{
                  border: unset;
                  text-align: center;
                  span{
                    margin-top: -5px !important;
                    &:not(.icon_round):not(.icon_round_hidden){
                      font-size: 3.6vw;
                      margin-bottom: 2px;
                      display: block;
                      width: max-content;
                    }
                    &.icon_round{
                      width: 7px;
                      height: 7px;
                    }
                  }
                  &:not(.active) {
                    .icon_round_hidden{
                      display: block;
                    }
                  }
                  &.active {
                    span{
                      color: #042a45;
                    }
                  }
                }
              }
            }
          }
        }
        
      }
    }
  }
  
`;

export default withTranslation('translations')(HomeHeader);