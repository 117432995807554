import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { loginProfessor } from "services/actions";

import { resendActivationData } from "services/api";
import { globalData } from "services/cms/actions";
import { withTranslation, Trans } from 'react-i18next';
import {clear} from "services/storage";

import { set, get } from "services/storage";
import { images } from "../../../home/_resources";

import ModalPassword from "../../../children/home/components/ModalPassword"
import ModalCompteSuccess from "../../../children/home/components/ModalCompteSuccess";
import LdsSpinner from 'components/LdsSpinner';

class CommonModalConnexion extends Component {
  constructor(props){
    super(props);
    this.device_width = window.innerWidth;
    this.state = {
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      open: false,
      success: false,
      messageSuccess: "",
      resend_activation_data: "",
      resend_activation_data_message: "",
      username: "",
      key_inv: this.props.key_inv,
      errorGlobal:"",
      isSubmitting: false
    };
  }

  handleCloseCnxConnexion = () => {
    this.props.handleCloseCnxConnexion();
  };

  handlePasswordReseted = (message) => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(this.props.t("Username required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required"))
    });
  };

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handleResendActivationData = () => {
    
    let account;
    account = {
      username: this.state.username
    };
    resendActivationData(account)
    .then(data => {
      this.setState({resend_activation_data_message: data.message});
    })
    .catch(err => {
      this.setState(state => ({
        errorGlobal: err.message
      }));
    });
  };

  handleSubmit = form => {
    let loginprof = this.props.addedData || {};
    if(this.props.addedData){
      loginprof.added_action = "add_school";
    }
    loginprof.username = form.username;
    loginprof.password = form.password;
    loginprof.page_front = this.props.pages;
    
    var lev = get("level_groups");
    var current_language = get("current_language"),
      device_token = get("device_token");
    clear();
    set("device_token", device_token);
    set("level_groups", lev);
    set("current_language", current_language);
    return this.props
      .dispatch(loginProfessor(loginprof))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(this.props.pages === "school"){
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else{
              if(!user.profile.is_school_admin)
                this.props.dispatch(globalData({}));
              this.props.history.push("/");
            }
          }else if (this.props.pages === "parent") {
            if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0 || user.account_type.indexOf('pa') != -1){
              this.props.history.push("/parents/dashboard");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else{
              if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
                this.props.dispatch(globalData({}));
                this.props.history.push("/parents/dashboard");
                // else
                //   this.props.history.push("/parents/candidatures");
              }
              else
                this.props.history.push("/");
            }
          }else if (this.props.pages === "professor") {
            if(['pr', 'pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){

              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
              this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
              this.props.history.push("/parents/dashboard");
            }
            else{
              this.props.history.push("/");
            }
          } else {
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){

              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
              this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else
              this.props.history.push("/");
          }
        }
        
      })
      .catch(err => {
        var message = err.message || "";
        var resend_activation_data = message.indexOf("mail_not_yet_verified") !== -1 ? "mail" :(message.indexOf("phone_not_yet_verified") !== -1 ? "phone" : "");
        this.setState(state => ({
          errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
          resend_activation_data: resend_activation_data,
          resend_activation_data_message: "",
          username: form.username,
          isSubmitting: false
        }));
      });
  }

  render() {
         /* TODO: TRANSLATIONS */

    const {resend_activation_data, resend_activation_data_message, isSubmitting} = this.state;
    return (
        <>
      <Modal
        open={this.props.openProfessor}
        onClose={this.handleCloseCnxConnexion}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white" },
          modal: { dialog: "white", dialogConnexion: "white", dialogCommonConnexion: "white", web: !(this.state.is_tablete || this.state.is_mobile) ? "white" : "", tablete: this.state.is_tablete && !this.state.is_mobile ? "white" : "", mobile: this.state.is_mobile ? "white" : "" }
        }}
      >
        <div className={"row " + (this.state.is_mobile ? " mobile " : this.state.is_tablete ? " tablete " : "web")}>
          <div className="col col-md-6 form_">
            <div className="connexion_form">
              <h2>{ this.props.t('Sign in') }</h2>
              {/* {this.props.account_activated &&
                <div className="account_activated">Bienvenue sur Koolskools. Votre compte a été activé, vous pouvez maintenant accéder à votre espace en saisissant votre mot de passe.</div>
              } */}
              <Formik
                initialValues={{
                  username: this.props.username || "",
                  password: ""
                }}
                validationSchema={this.getValidationSchema}
                onSubmit={values => {
                  this.setState({
                    isSubmitting:true
                  })
                  this.handleSubmit(values);
                }}
              > 
                {({ errors, touched, values, handleChange }) => {
                  return (<Form>
                    {this.state.errorGlobal && (                    
                      <div className="textErrors" style={{color: "red", paddingBottom: 10}}>{this.props.t(this.state.errorGlobal)}</div>
                    )}
                    <span
                      className="schoolClose hidden"
                      onClick={() => this.handleCloseCnxConnexion()}
                    >
                    &times;
                  </span>
                  <div className="modalConnexionForm">
                    <div className="connexionLigne">
                      <div className="connexionW100">
                        <label className="hidden">{ this.props.t('Enter your ID') }</label>
                        <input type="text"
                          className={`textInput ${errors.username && "textInputError"}`}
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          placeholder={ this.props.t('Enter your ID') }
                        />
                        {errors.username && touched.username ? (
                          <div className="textErrors">{errors.username}</div>
                        ) : null}
                      </div>
                      <div className="connexionW100">
                        <label className="hidden">{ this.props.t('Password') }</label>
                        <input type="password"
                          className={`textInput ${errors.password && "textInputError"}`}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          placeholder={ this.props.t('Password') }
                        />
                        {errors.password && touched.password ? (
                          <div className="textErrors">{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="connexionLigne w12 justify-content-between">

                      <a onClick={() => this.onOpenModalPassword()} className="forgot" style={{float:'none'}}>
                        { this.props.t('FORGOT PASSWORD?') }
                      </a>
                      <div className=' connexion_button'>
                        {isSubmitting && <LdsSpinner type='relative'/>}
                        {/* <button type="submit"  className="home_page_btn_connexion" style={{backgroundImage: `url(${images.bg_btn_orange_shadow})`}} onClick={() => this.props.handleOpenConnexion()}>
                          <img src={images.bg_btn_orange} />
                          <span>{ this.props.t('Validate') } </span>
                        </button> */}
                        <button type="submit" className="signin home_btn_connexion" style={{float:'none'}} disabled={isSubmitting}>
                        { this.props.t('Validate') }
                        </button>
                      </div>
                    </div>
                    {resend_activation_data &&
                    <>
                      <div className="connexionLigne w12 resend_activation_data">
                        Resend {resend_activation_data == "mail" ? "link " : "code "} activation?
                        <button className="resend_activation_data_btn" onClick={this.handleResendActivationData}>{ this.props.t('Resend') }</button>
                      </div>
                      {resend_activation_data_message &&
                        <div className="resend_activation_data_message">
                          {resend_activation_data_message}
                        </div>
                      }
                    </>
                    }
                  </div>
                </Form>);
                }}
              </Formik>
            </div>
          </div>
          <div className="col col-md-6">
          </div>
        </div>
      </Modal>
          <ModalPassword 
            open={this.state.open} 
            pages={this.props.pages} 
            onCloseModalPassword={this.onCloseModalPassword}
            handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
          <ModalCompteSuccess
            success={this.state.success}
            message={this.state.messageSuccess}
            handleCloseSuccess={this.handleCloseSuccess}
          />
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(CommonModalConnexion)));
