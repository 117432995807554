import React, { Component } from "react";
import styled from "styled-components";

import { images } from "./_resources";
import HomeHeader from "./HomeHeader";
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { levelGroupsAction } from "services/actions";

import {forgotPassword} from "services/api";
class LayoutHome extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    var openConnexion = false;
    var openModalConnexion = false;
    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
    this.device_width = window.innerWidth;
    this.state = {
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      openConnexion:openConnexion,
      openModalConnexion:openModalConnexion,
      openRegister: false
    };
    this.homeRef = React.createRef();
    this.params = queryString.parse(this.props.location.search);
  }

  updateDimensions = () => {
    this.device_width = window.innerWidth;
    this.setState({
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      clientWidth: this.homeRef.current.clientWidth,
      clientHeight: this.homeRef.current.clientHeight,
    });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
    // this.props.dispatch(levelGroupsAction());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    var {is_mobile, is_tablete} = this.state;
    return (
      <LayoutHomeStyle className={is_mobile ? " mobile " : is_tablete ? " tablete " : ""} ref={this.homeRef}>
        <div className="background_top_right"></div>
        <div className="img_top_right">
          <img src={images.top_left_2}/>
        </div>
        <div className="background_left"></div>
        <div className="background_right"></div>
        <div className="object_left">
          <img src={images.bg_object}/>
        </div>
        <div className="lines_left">
          <img src={images.bg_lines}/>
        </div>
        <div className="lines_right">
          <img src={images.bg_lines2}/>
        </div>
        <HomeHeader
          username={this.params.username}
          account_activated={this.params.account_activated && this.params.account_activated === "true"} 
          openConnexion={this.state.openConnexion}
          openModalConnexion={this.state.openModalConnexion}
          openRegister={this.state.openRegister}
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleCloseConnexion={() => this.setState({openModalConnexion: false, openConnexion:false, openRegister: false, reset_password_response: "", reset_password_error:""})}
          handleCloseModalConnexion={() => this.setState({openModalConnexion:false, openRegister: false, reset_password_response: "", reset_password_error:""})}
          handleOpenConnexion={()  => this.setState({openConnexion:true, openRegister: false})}
          handleOpenModalConnexion={()  => this.setState({openModalConnexion:true, openRegister: false})}
          handleCloseRegister={() => this.setState({openRegister:false, openConnexion:false})}
          handleOpenRegister={()  => this.setState({openRegister:true,openConnexion:false})}
          {...this.props}/>
        <>
            {React.Children.map(this.props.children, el =>
              React.cloneElement(el, { 
                pages: this.props.pages,
                username: this.params.username,
                account_activated: this.params.account_activated && this.params.account_activated === "true",
                openModalConnexion: this.state.openModalConnexion,
                openConnexion: this.state.openConnexion,
                openRegister: this.state.openRegister,
                reset_password_response: this.state.reset_password_response,
                reset_password_error: this.state.reset_password_error,
                handleCloseConnexion: () => this.setState({openConnexion:false, openRegister: false, reset_password_response: "", reset_password_error:""}),
                handleOpenConnexion: ()  => {
                  this.setState({
                    openConnexion:this.props.pages == "children",
                    openModalConnexion:this.props.pages != "children",
                    openRegister: false
                  })
                },
                handleCloseRegister: () => this.setState({openRegister:false, openConnexion:false}),
                handleOpenRegister: ()  => this.setState({openRegister:true,openConnexion:false}),
              })
            )}
        </>
      </LayoutHomeStyle>
    );
  }
}

const LayoutHomeStyle = styled.div`
  width: 100%;
  // height: 9400px;
  background-color: #fff;
  padding: 0 0px;
  position: relative;
  overflow: hidden;
  section {
    margin-bottom: 100px;
  }
  .background_top_right{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 674px;
    margin: 0 0 30% 6%;
    padding: 0 9% 50% 32%;
    opacity: 0.75;
    background-image: radial-gradient(circle at 100% 0%, #64c2bea6 20%, #fff 70%);
  }
  .img_top_right{
    position: absolute;
    right: 0;
    top: 0;
    width: 8%;
    margin: 54px 3% 0px 0.7%;
    object-fit: contain;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .object_left{
    position: absolute;
    left: 13%;
    top: 880px;
    width: 35%;
    // height: 727px;
    display: none;
    margin: 0;
    padding: 0;
    object-fit: contain;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .lines_left{
    position: absolute;
    left: 0;
    top: 864px;
    width: 37%;
    // height: 689px;
    margin: 0;
    padding: 0;
    object-fit: contain;
    display: none;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .lines_right{
    position: absolute;
    right: -270px;
    right: -19%;
    top: 993px;
    width: 705px;
    width: 50%;
    // height: 689px;
    margin: 0;
    padding: 0;
    object-fit: contain;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .background_left{
    position: absolute;
    left: 0;
    top: 50px;
    width: 576px;
    height: 1021px;
    margin: 216px 4px 34px 0;
    padding: 156px 58px 680px 230px;
    opacity: 0.75;
    background-image: radial-gradient(circle at 0% 50%,#ffa827d9 4%,#fff 60%);
  }
  .background_right{
    position: absolute;
    right: 0;
    top: 563px;
    width: 30%;
    // height: 1206px;
    margin: 216px 4px 34px 0;
    padding: 156px 58px 680px 230px;
    opacity: 0.5;
    background-image: radial-gradient(circle at 100% 50%,#d33f29 14%,#fff 60%);
  }
  &.tablete{
    width: 100%;
    // height: 9400px;
    background-color: #fff;
    padding: 0 0px;
    position: relative;
    overflow: hidden;
    section {
      margin-bottom: 100px;
    }
    .background_top_right{
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 674px;
      margin: 0 0 30% 6%;
      padding: 0 9% 50% 32%;
      opacity: 0.75;
      background-image: radial-gradient(circle at 100% 0%,#64c2bea6 4%,#fff 52%);
    }
    .img_top_right{
      position: absolute;
      right: -6%;
      top: 0;
      width: 8%;
      margin: 54px 3% 0px 0.7%;
      object-fit: contain;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .object_left{
      position: absolute;
      left: 13%;
      top: 880px;
      width: 35%;
      // height: 727px;
      display: none;
      margin: 0;
      padding: 0;
      object-fit: contain;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .lines_left{
      position: absolute;
      left: 0;
      top: 864px;
      width: 37%;
      // height: 689px;
      margin: 0;
      padding: 0;
      object-fit: contain;
      display: none;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .lines_right{
      position: absolute;
      right: -270px;
      right: -19%;
      top: 44vw;
      width: 705px;
      width: 70%;
      margin: 0;
      padding: 0;
      object-fit: contain;
      margin: 17vw 0px 3vw 0;
      padding: 7vw 4vw 60vw 20vw;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .background_left{
      position: absolute;
      left: 0;
      top: -12vw;
      width: 50vw;
      height: 80vw;
      margin: 216px 4px 34px 0;
      padding: 156px 58px 680px 230px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 0% 50%,#ffa827d9 4%,#fff 60%);
    }
    .background_right{
      position: absolute;
      right: 0;
      top: 44vw;
      width: 30%;
      margin: 17vw 0px 3vw 0;
      padding: 7vw 4vw 60vw 20vw;
      opacity: 0.5;
      background-image: radial-gradient(circle at 100% 50%,#d33f29 14%,#fff 60%);
    }
  }
  &.mobile{
    .img_top_right{
      display: none;
    }
    .background_top_right{
      width: 91%;
      background-image: radial-gradient(circle at 250% 0%,#30bfb9c7 47%,#fff0 73%);
    }
    .background_left{
      width: 100%;
      top: -15vh;
      left: -50%;
      height: 130vh;
      margin: 216px 4px 34px 0;
      padding: 156px 50vw 60vw 0;
      background-image: radial-gradient(circle at 26% 50%,#ffa827b0 25%,#fff0 47%);
    }
    .background_right{
      width: 100%;
      top: 60vh;
      margin: 0 4px 34px 0;
      padding: 92px 0 150vw 0;
      background-image: radial-gradient(circle at 100% 48%,#d33f29 14%,#fff0 56%);
      right: -20%;
    }
    .lines_right{
      top: 137vh;
      right: -62%;
      width: 112%;
    }
  }
`;

export default connect()(withRouter(LayoutHome));
