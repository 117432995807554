import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import $ from "jquery";
import { images } from "./_resources";
import { Fade } from "react-slideshow-image";
import Slider from "react-slick";
import { withTranslation, Trans } from 'react-i18next';
import HomeFooter from "./HomeFooter";
import { Modal, Button, Row, Col } from 'react-bootstrap';

import styled from "styled-components";

window.jQuery = $;
const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
  register: "false"
};
const settingsSlide = {
  autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  dotsClass: "dots_classes",
  slidesToScroll: 1
}
const settingsSlideMobile = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  dotsClass: "dots_classes",
  slidesToScroll: 1
}


const StyledViewSpaceModal = styled.div`
  display:flex;
  flex-direction:column;
  max-height:100%;
  height:100%;
  .modal-title{
    text-align: left;
    font-size: 16px;
    color: #1b2437;
    font-weight: 600;
    width: 100%;
  }

  .modal-header {
    padding-left: 50px;
    padding-right: 50px;
    border: 0;
    /*padding-top: 25px;
    padding-bottom: 50px;*/
    position: relative;
    button{
      &.btn-right{
        left: auto;
        right: 0;
      }
      background-image: linear-gradient(45deg, #72dcd6, #3eb6ad);
      box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
      border-radius: 50%;
      opacity: 1;
      color: #fff;
      font-size: 18px;
      font-weight: normal;
      height: 45px;
      width: 45px;
      padding: 0;
      line-height: 40px;
      outline: none;
      cursor: pointer;
      position: absolute;
      left: -1px;
      border: 0;
      top: -1px;
    }
  }

  .modal-footer{
    padding-bottom: 20px;
    margin-top: 10px;
    justify-content: center;
    border: none;
  }

  .modal-body{
    height: calc(100% - 132px);
    padding: 0px 35px 30px;
    padding-bottom: 0;
    overflow-y: auto;
    text-align: center;
    iframe{
      width: 800px;
      max-width: 90%;
      margin: auto;
      height: 98%;
      overflow-y: auto;
      width: max-content;
      border: 0;
    }
    
  }
  .contacts{
    padding: 0 15%;
    width: 100%;
    padding: 70px 5% 70px 4%;
    object-fit: contain;
    border-radius: 9px;
    background-image: linear-gradient(to top, #0da39d, #64c2be);
    height: calc(100% - 85px);
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3eb6ad;
    
    }
    .title{
      font-size: 5.2vw;
      margin: 8vw 0 4vw;
    }
    .values{
      .contact{
        img{
          width: 10vw;
          margin-right: 2vw;
        }
        .vals{
          span{
            font-size: 1vw;
          }
        }
      }
    }
    .contact_values{
      .contact{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;
        img{
          width: 4vw;
          margin-right: 2vw;
        }
        .vals{
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          >*{
            display: block;
            font-family: QuicksandLight;
            font-size: 1vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            width: 100%;
          }
        }
      }
    }
  }
`;

const ViewSpaceModal = (props) => {
  const [show, setShow] = useState(props.show);
  const [space, setSpace] = useState(props.space);
  const contacts = [
    {
      image: images.address_icon,
      values: ["400, bd zerktouni. Casablanca"],
    },
    {
      image: images.phone_icon,
      values: ["+212 522 471 720", "+212 667 947 883", "+212 662 405 854"],
    },
    {
      image: images.email_icon,
      values: ["hello@koolskools.com"],
      is_email: true,
    },
  ]
  useEffect(() => {
    setShow(props.show);
    setSpace(props.space);
  }, [props.show, props.space, props.is_mobile, props.is_tablete])
  return <Modal
    show={show}
    onHide={() => props.onHide()}
    centered
    className={"custom_modal full-width dialogOverlay ZIndex10000000 modal_file_viewer " + (space.is_school ? " school_modal " : "") + (props.is_mobile ? " mobile " : props.is_tablete ? " tablette " : " web ")}
  >
    <StyledViewSpaceModal>
      <Modal.Header>
        <button type="button" className="btn-right" onClick={props.onHide}>
          <span className="fa fa-times"></span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {space.is_school ? 
          <>
            <>
              <h1>Contactez-nous</h1>
              <div className=" contacts">
                <div className="values contact_values">
                  {contacts.map((contact, idx) => {
                    return <div className="contact" key={idx} >
                      <img src={contact.image} />
                      <div className="vals">
                        {contact.values.map((val, idx) => {
                          if(contact.is_email){
                            return <a key={idx} href={`mailto:${val}`}>{val}</a>
                          }
                          return <span key={idx}>{val}</span>
                        })}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </>
          </>
          :
          <>
            <video width="100%" height="95%" autoplay controls controlsList="nodownload"  oncontextmenu="return false;">
              <source src={space.video_url} type="video/mp4" />
            </video>
          </>
        }

      </Modal.Body>
    </StyledViewSpaceModal>
  </Modal>

}

class Chiffre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actual_chiffre: 0,
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      is_mobile: props.is_mobile,
      is_tablete: props.is_tablete,
    };
    this.element = React.createRef();
    // Options for the IntersectionObserver
    this.options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the element is visible
  };
  }

  componentWillReceiveProps(props){
    this.setState({
      is_mobile: props.is_mobile,
      is_tablete: props.is_tablete,
    });
  }

  componentDidMount(){
    // Create a new IntersectionObserver
    const observer = new IntersectionObserver(this.handleIntersect, this.options);

    // Start observing the target element
    observer.observe(this.element.current);
  }

  handleIntersect = (entries, observer) => {
    var this_ = this;
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            // Call the function to animate the number
            this.animateNumber(this_.element.current, 0, this_.props.chiffre.max_chiffre, 2000);
            // Stop observing once the animation has been triggered
            observer.unobserve(entry.target);
        }
    });
}

  animateNumber = (element, start, end, duration) => {
    let range = end - start;
    let current = start;
    let increment = end > start ? 1 : -1;
    let stepTime = Math.abs(Math.floor(duration / range));
    var this_ = this;
    let timer = setInterval(function() {
        current += increment;
        element.textContent = "+" + current + (this_.props.chiffre.without_k ? "" : "K");
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
}

  render() {
    var chiffre = this.props.chiffre;
    var actual_chiffre = this.state.actual_chiffre;
    return (
      <ChiffreStyle className={"chiffre_content " + (this.state.is_mobile ? " mobile " : this.state.is_tablete ? " tablete " : "")} style={{paddingTop: chiffre.paddingTop || 0}}>
        <div className="chiffre">
          <div className="bg_chiffre" style={{backgroundColor: chiffre.background}}>
          </div>
          <div className="bg_image">
            <img src={chiffre.image} />
          </div>
            <div className="text_img">
              <span className="text">
                {chiffre.text}
              </span>
              <span className="img">
                <img src={chiffre.icon} />
              </span>
            </div>
            <div className="chiffre_text" ref={this.element}>
              {actual_chiffre}
            </div>
        </div>
      </ChiffreStyle>
    );
  }
}
class QRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillReceiveProps(props){
  }

  componentDidMount(){
  }

  render() {
    return (
      <QRCodeStyle className="qrcode_content" style={this.props.style || {}}>
        {this.props.text &&
          <div className="text">
            {this.props.text}
          </div>
        }
        <div className="qrcode">
          <img src={this.props.qrcode_img} />
        </div>
      </QRCodeStyle>
    );
  }
}
class HomeComponent extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.device_width = window.innerWidth;
    this.testEspacesRef = React.createRef();
    this.state = {
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      openConnexion:  query.get('login')?true:false,
      openRegister: false,
      page: props.page
    };
    this.homeBodyRef = React.createRef();
    this.students_slides = [
      {
        title: props.t("Transforme ta vie scolaire avec KoolSkools"),
        text1: props.t("Éducation de niveau efficace"),
        text2: props.t("Simplifie, organise et réussis !"),
        image: images.boy_laptop,
        image_mobile: images.students_slide_mobile_2,
      },
    ];
    this.professors_slides = [
      {
        title: props.t("Optimisez votre enseignement avec KoolSkools"),
        text1: props.t("Des outils cloud performants"),
        text2: props.t("simplifiez la gestion de classe et maximisez l'engagement des élèves "),
        image: images.professors_slide_1,
        image_mobile: images.professor_slide_mobile_2,
      },
    ];
    this.parents_slides = [
      {
        title: props.t("Soyez proche de la vie scolaire de votre enfant avec KoolSkools"),
        text1: props.t("Des outils pédagogiques"),
        text2: props.t("Restez informé et connecté en un clin d'œil !"),
        image: images.parents_slide_1,
        image_mobile: images.parent_slide_mobile_2,
      },
    ];
    this.schools_slides = [
      {
        title: props.t("Optimisez la gestion de votre école avec KoolSkools"),
        text1: props.t("Montez dans le train"),
        text2: props.t("simplifiez les processus et libérez du temps pour l'essentiel !"),
        image: images.schools_slide_1,
        image_mobile: images.school_slide_mobile_2,
      },
    ];
    this.fonctionalities = [
      {
        title: props.t("Vie scolaire"),
        class_title: "Vie-scolaire",
        items: [
          props.t("• Inscriptions et pré-inscriptions"), props.t("• Emploi du temps"), props.t("• Répartition des classes"),
          props.t("• Organisation du corps enseignant"), props.t("• Absences et retards"),
          props.t("• Gestion des réclamations"), props.t("• Conseil de classe"),
          props.t("• Bulletins et relevés de notes"), props.t("• Calendrier des contrôles"),
          props.t("• Système de compatibilité avec MASSAR"), props.t("• Référentiel des bulletins de compétences"),
          props.t("• Projets éducatifs avec statistiques"), props.t("• Gestion multi-écoles et multi-cycles"),
        ],
        class_items: "-Emploi-du-temps-Calendrier-des-contrles-Inscriptions-et",
        image: images.vie_scolaire,
        class_img: "icon-1",
        marginTop: 0,
      },
      {
        title: props.t("Communication"),
        class_title: "Communication",
        items: [
          props.t("• Communication avec les parents"),
          props.t("• Messagerie"), props.t("• Galerie photo"), props.t("• Agenda des événements"),
          props.t("• Transmissions"), props.t("• Envoi des SMS"),
        ],
        class_items: "-Messagerie-Galerie-photo-actualit-Agenda-des-vnemen",
        image: images.communication,
        class_img: "icon-1",
        marginTop: 0,
      },
      {
        title: props.t("Pédagogie interactive"),
        class_title: "Pdagogie-interactive",
        items: [
          props.t("• Cahier de textes"), props.t("• Cours en ligne"), props.t("• Exercices interactifs"),
          props.t("• Évaluations en ligne"), props.t("•  Remédiation ciblée"), props.t("• Suivi des devoirs"),
          props.t("• Challenges en ligne"), 
        ],
        class_items: "-Conseil-de-classe-Cahier-de-texte-Suivi-devoirs-Cour",
        image: images.pedagogie_interactif,
        class_img: "icon-1",
        marginTop: 0,
      },
      {
        title: props.t("Finance"),
        class_title: "Finance",
        items: [
          props.t("• Encaissements"), props.t("• Extraction de rapports"), props.t("• État de recouvrement"),
          props.t("• Décaissements"), props.t("• Bons de commandes"), props.t("• Paiement des factures"),
        ],
        class_items: "-Paiement-Facturation-Bons-de-commande-Extraction-de-rap",
        image: images.finance,
        class_img: "icon-1",
        marginTop: "-17vw",
      },
      {
        title: props.t("Services"),
        class_title: "Services",
        items: [
          props.t("• Transport"), props.t("• Cantine / Garde"),
          props.t("• Activités extra-scolaires"), props.t("• Bibliothèque numérique"), props.t("• Bibliothèque école"),
        ],
        class_items: "-Paiement-Facturation-Bons-de-commande-Extraction-de-rap",
        image: images.service,
        class_img: "icon-1",
        marginTop: "-15vw",
      },
    ];
    this.ressources = [
      {
        title: props.t("Pour les écoles"),
        class_title: "Pour-les-coles",
        items: [
          props.t("• Gain de temps et d'efficacité"), props.t("• Suivi en temps réel"), props.t("• Accessibilité cloud 24/24h"),
        ],
        class_items: "-Gain-de-temps-et-defficacit-Suivi-en-temps-rel-Access",
        image: images.for_schools,
        class_img: "icon-1",
      },
      {
        title: props.t("Pour les profs"),
        class_title: "Pour-les-profs",
        items: [
          props.t("• Capter l'attention des élèves"), props.t("• Donner envie d'apprendre"), props.t("• Création et récupération des devoirs dans le cahier de textes"),
          props.t("• Challenges en équipe"), 
        ],
        class_items: "-Capter-lattention-des-lves-Donner-envie-dapprendre-",
        image: images.for_professors,
        class_img: "icon-1",
      },
      {
        title: props.t("Pour les parents"),
        class_title: "Pour-les-parents",
        items: [
          props.t("• Accompagner son enfant dans sa scolarité"), props.t("• Savoir ce qui se passe à l'école et en classe"),
          props.t("• Communiquer avec l'école"), 
        ],
        class_items: "-Accompagner-son-enfant-dans-sa-scolarit-Savoir-ce-qui-se",
        image: images.for_parents,
        class_img: "icon-1",
      },
      {
        title: props.t("Pour les élèves"),
        class_title: "Pour-les-élèves",
        items: [
          props.t("• Auto-corrections"), props.t("• Interface ludique"),
          props.t("• Examens en ligne"), props.t("• Challenges"), 
          props.t("• Cours en live"), 
        ],
        class_items: "-Auto-corrections-Interface-ludique-Examens-en-lignes-C",
        image: images.for_students,
        class_img: "icon-1",
      },
    ]
    this.chiffres = [
      {
        paddingTop: 0,
        text: props.t("Écoles"),
        background: "#ffa827",
        max_chiffre: 200,
        icon: images.schools_chiffre_icon,
        image: images.schools_chiffre_bg,
        without_k: true,
      },
      {
        paddingTop: 100,
        text: props.t("Élèves"),
        background: "#d33f29",
        max_chiffre: 100,
        icon: images.students_chiffre_icon,
        image: images.students_chiffre_bg,
      },
      {
        paddingTop: 200,
        text: props.t("Exercices interactifs"),
        background: "#ffa827",
        max_chiffre: 40,
        icon: images.exercises_chiffre_icon,
        image: images.exercises_chiffre_bg,
      },
    ]
    this.cards = [
      {
        text: props.t("Koolskools déploie la 1re plateforme marocaine"),
        site: "leconomiste.com",
        date: "30 Avril 2020",
        image: images.card_image_1,
        url: "https://www.leconomiste.com/flash-infos/ecole-digitale-koolskools-deploie-la-1re-plateforme-marocaine",
        id: "img_ref1",
      },
      {
        text: props.t("La plateforme éducative Koolskools lève 10 MDH auprès d'Azur Innovation Fund"),
        site: "medias24.com",
        date: "22 Fevrier 2023",
        image: images.card_image_2,
        url: "https://medias24.com/2023/02/22/la-plateforme-educative-koolskools-leve-10-mdh-aupres-dazur-innovation-fund/",
        id: "img_ref2",
      },
      {
        text: props.t("Innov Invest : Témoignage de startup avec koolskools, edtech leader au Maroc"),
        site: "boursenews.com",
        date: "17 Mars 2025",
        image: images.card_image_3,
        url: "https://boursenews.ma/article/venture-capital/startups-koolskools-edtech-leader-Maroc",
        id: "img_ref3",
      },
    ]
    this.espaces = [
      {
        image: images.espace_parents,
        text: props.t("Espace "),
        text2: props.t("PARENTS"),
        link: "/home/parent",
        video_url: "https://s3.eu-west-3.amazonaws.com/web.koolskools/videos/WhatsApp+Video+2025-03-19+at+14.50.30+(1).mp4",
        is_school: false,
        // onclick: () => {
        //   setTimeout(() => {
        //     this.props.history.push('/home/parent');
        //   }, 100);
        // }
      },
      {
        image: images.espace_students,
        text: props.t("Espace "),
        text2: props.t("ÉLÈVES"),
        link: "/",
        video_url: "https://s3.eu-west-3.amazonaws.com/web.koolskools/videos/WhatsApp+Video+2025-03-19+at+14.50.30+(2).mp4",
        is_school: false,
        // onclick: () => {
        //   setTimeout(() => {
        //     this.props.history.push('/');
        //   }, 100);
        // }
      },
      {
        image: images.espace_professors,
        text: props.t("Espace "),
        text2: props.t("PROFESSEURS"),
        link: "/home/professor",
        video_url: "https://s3.eu-west-3.amazonaws.com/web.koolskools/videos/WhatsApp+Video+2025-03-19+at+14.50.30.mp4",
        is_school: false,
        // onclick: () => {
        //   setTimeout(() => {
        //     this.props.history.push('/home/professor');
        //   }, 100);
        // }
      },
      {
        image: images.espace_vie_scolaire,
        text: props.t("Espace "),
        text2: props.t("ÉCOLES"),
        link: "/home/school",
        video_url: "",
        is_school: true,
        // onclick: () => {
        //   setTimeout(() => {
        //     this.props.history.push('/home/school');
        //   }, 100);
        // }
      },
    ]
    if(this.state.is_mobile){
      let first_espace = this.espaces[0];
      this.espaces[0] = {...this.espaces[1]};
      this.espaces[1] = {...first_espace};
    }
    this.products = [
      {
        image: images.kr_phone,
        ellipse: images.kr_ellipse,
        qr_design: images.kr_qr_design,
        text: props.t("Koolskools revisions "),
        text2: props.t("Rejoignez notre application ludique de révision scolaire qui transforme l'apprentissage en une aventure captivante"),
        bg_color: "blue",
        marginLeft: 35,
        qr_text: "",
        link: "",
        // onclick: () => {

        // }
      },
      {
        image: images.km_phone,
        ellipse: images.km_ellipse,
        qr_design: images.km_qr_design,
        text: props.t("Kotchmee"),
        text2: props.t("Notre application éducative révolutionnaire offre des activités interactives et des défis motivants pour un apprentissage ludique et efficace"),
        bg_color: "orange",
        qr_text: "À venir",
        marginLeft: 40,
        link: "",
        // onclick: () => {

        // }
      },
    ]
  }

  componentDidUpdate(){
    
    this.componentDidUpdateFunction();
  }
  componentDidUpdateFunction = () => {
    
    const img_ref1 = document.querySelectorAll(".img_ref1")[0] || document.querySelectorAll(".img_ref2")[0];
    const img_ref3s = document.querySelectorAll(".img_ref3");
    if(img_ref1 && img_ref3s[0]){
      img_ref3s.forEach(img_ref3=> {
        img_ref3.style.height = img_ref1.offsetHeight + "px";
      });
    }
  }

  scrollToTestEspacesDiv = () => {
    if (this.testEspacesRef.current) {
      this.testEspacesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentWillReceiveProps(props){
    this.setState({
      page: props.page
    });
  }

  updateDimensions = () => {
    this.device_width = window.innerWidth;
    this.setState({
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
      clientWidth: this.homeBodyRef.current.clientWidth,
      clientHeight: this.homeBodyRef.current.clientHeight,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const {is_mobile, is_tablete} = this.state;
    var slides = this.state.page == "children" ? this.students_slides : this.state.page == "professors" ? this.professors_slides : this.state.page == "parents" ? this.parents_slides : this.schools_slides;
    return (<>
      <HomeComponentStyle className={"one " + (is_mobile ? " mobile " : is_tablete ? " tablete " : "")} ref={this.homeBodyRef}>
        <div className="common">
          <img src={images.items_imgs} />
          <Fade {...fadeProperties} key={this.state.page}>
            {slides.map((slide, idx) => {
              return <div className="each-fade" key={idx}>
                <div className="image-container">
                  <img src={is_mobile ? slide.image_mobile : slide.image} />
                </div>
                <div className="texts_container">
                  <div className="text1 hidden"><span>{slide.text1}</span></div>
                  <div className="title"><span>{slide.title}</span><img className="hidden" src={images.arrow_to_right} /></div>
                  <div className="text2"><span>{slide.text2}</span></div>
                </div>
              </div>
            })}
          </Fade>
          <div className={"slider-bottom " + (is_mobile && this.props.pages == "parent" ? "add_6p" : "")}>
            <div className="btn_connexion" onClick={() => this.props.handleOpenConnexion()}> 
              <img src={images.bg_btn_orange} />
              <span>{this.props.t('Sign in')}</span>
            </div>
          </div>
          <div className="left_bottom_icon"></div>
        </div>
        <div className="sub_page_2">
          <div className="background_left_p2"></div>
          <div className="title">
            {this.props.t("Koolskools")}
          </div>
          <div className="img_bg">
            <img src={images.labtop_gif} />
          </div>
          <div className="sub_page_content">
            <div className="left">
            </div>
            <div className="right">
              <span class="-propos hidden">
                {this.props.t("à propos")}
              </span>
              <span class="Qui-sommes-nous">
                {this.props.t("Qui sommes-nous?")}
              </span>
              <span class="KoolSkools-est-une-plateforme-ducative-destine-aux-coles-o">
                {this.props.t("KoolSkools est une plateforme éducative conçue pour simplifier et améliorer la gestion de divers aspects de la vie scolaire, tant pour les écoles que pour les parents, les élèves et les enseignants.")}
              </span>
              <span class="-Permettre-une-communication-efficace-avec-les-parents-Gre">
                {this.props.t("• Tableau de bord")}<br/>
                {this.props.t("• Communication efficace avec les parents")}<br/>
                {this.props.t("• Gestion complète de la vie scolaire")}<br/>
                {this.props.t("• Suivi pédagogique des élèves")}<br/>
                {this.props.t("• Gestion financière de l'école")}
              </span>
              <span class="KoolSkools-propose-des-applications-pour-iOS-et-Android-adap">
                {this.props.t("Nos applications mobiles sont disponibles sur APPSTORE et PLAYSTORE, garantissant ainsi un accès facile à nos services pour tous les utilisateurs.")}
              </span>
            </div>

          </div>
        </div>
        <div className="sub_page_3">
          <div className="background_left_p3"></div>
          <div className="lines_left_p3">
            <img src={images.bg_lines}/>
          </div>
          <div className="sub_page_content">
            {is_mobile &&
              <div className="right mobiles_gif">
                <div className="background_right_p3"></div>
                <img src={images.mobiles_gif} />
              </div>
            }
            <div className="left">
              <span class="Vocation hidden">
                {this.props.t("Vocation")}
              </span>
              <span class="Notre-mission">
                {this.props.t("Notre mission")}
              </span>
              <span class="Nous-aspirons-encourager-les-coles-et-les-enseignants-ado">
                {this.props.t("Notre objectif est de simplifier la gestion de votre établissement et de stimuler l'adoption de méthodes pédagogiques innovantes par les écoles et les enseignants, tout en contribuant à l'amélioration générale du paysage éducatif.")} <br/>
                {this.props.t("Nous cherchons à mettre en place des avancées pédagogiques en utilisant les technologies éducatives (Ed-Tech) pour créer un impact positif dans l'éducation.")}
              </span>
            </div>
            {!is_mobile &&
              <div className="right mobiles_gif">
                {/* <QRCode qrcode_img={images.qr_design} text={"SCANNE MOI"} style={{left: "-13%", top: 25}}/> */}
                <img src={images.mobiles_gif} />
              </div>
            }
          </div>
          <div className="buttons">
            <a className="app_store_button" href="https://www.apple.com/fr/app-store/" target={"_blank"}>
              <img src={images.app_store_button} />
            </a>
            <a className="google_play_button" href="https://play.google.com/store/apps" target={"_blank"}>
              <img src={images.google_play_button} />
            </a>
          </div>
        </div>
        <div className="sub_page_4">
          <div className="background_right_p4"></div>
          <div className="sub_page_content">
            <div className="left">
              <div className="img_bg">
                <img src={images.imqge} />
                <span class="Utilit">
                  {this.props.t("Utilité")}
                </span>
                <span class="Pour-tous-les-acteurs">
                  {this.props.t("Pour tous les acteurs")}
                </span>
              </div>
            </div>
            <div className="right">
              <span class="Fonctionnalits">
                {this.props.t("Fonctionnalités")}
              </span>
              <span class="Gestion-360-de-lcole">
                {this.props.t("Gestion 360° de l'école")}
              </span>
              <span class="Plateforme-ducative-pour-les-coles-les-parents-les-lves">
               {this.props.t("Plateforme éducative pour les écoles, les enseignants, les parents et les élèves.")}
              </span>
              <div className="cards_fonctionalities">
                {this.fonctionalities.map((card, idx) => {
                  return <div className="card_fonctionality" key={idx} style={{marginTop: card.marginTop}}>
                    <div className="img">
                      <img src={card.image} />
                    </div>
                    <div className="title_items">
                      <div className="ttle">{card.title}</div>
                      <div className="items">{card.items.map((itm, idx_) => {
                        return <span className="item_content" key={itm}>{itm}<br/></span>
                      })}</div>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="sub_page_5">
          <div className="sub_page_content">
            <div className="common_cards card_ressource">
              <div className="bg_div">
                <span class="Des-ressources-pour-toute-lcole">
                  {this.props.t("Des ressources pour toute l'école")}
                </span>
                <div className="btn_connexion" onClick={() => {
                  this.scrollToTestEspacesDiv();
                }}> 
                  <img src={images.bg_btn_orange_shadow2} />
                  <span>{this.props.t('Découvrez les espaces')}</span>
                </div>
              </div>
            </div>
            {this.ressources.map((ressourse, idx) => {
              return <div className="card_ressource" key={idx}>
                <div className="card_ressource_content">
                  <div className="img">
                    <img src={ressourse.image} />
                  </div>
                  <div className="title_items">
                    <div className="ttle">{ressourse.title}</div>
                    <div className="items">{ressourse.items.map((itm, idx_) => {
                      return <span className="item_content" key={itm}>{itm}<br/></span>
                    })}</div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className={"sub_page_6 " + (is_mobile ? " mobile " : is_tablete ? " tablete " : "web")}>
          <div className="background_right_p6"></div>
          <div className="sub_page_content">
            <div className="title">
              <div className="title1 hidden">{this.props.t("Aperçu Global")}</div>
              <div className="title2">{this.props.t("Chiffres Clés")}</div>
              <div className="title2 hidden"><img src={images.arrow_to_right} /></div>
            </div>
            <div className="chiffres">
              {this.chiffres.map((chiffre, idx) => {
                return <Chiffre key= {idx} chiffre={chiffre} is_mobile={this.state.is_mobile} is_tablete={this.state.is_tablete} />
              })}
            </div>
          </div>
        </div>
        <div className="sub_page_7">
          <div className="sub_page_content">
            <div className="title">
              <div className="title1">{this.props.t("On parle de nous")}</div>
              <div className="title_btn">
                <div className="btn_show_plus hidden" onClick={() => {
                  
                }}> 
                  <img src={images.bg_btn_orange} />
                  <span>{this.props.t('Voir plus')}</span>
                </div>
              </div>
            </div>
            <div className="cards">
              <Slider {...(is_mobile ? settingsSlideMobile : settingsSlide)} beforeChange={(oldIndex, newIndex) => {
                this.current_articles_index = newIndex;
              }}>
                {this.cards.map((card, idx) => {
                  return <div className={"each-fade " + (this.current_articles_index == idx ? " active " : "")} key={idx}>
                    <div className="image-container">
                      <img src={card.image} className={card.id} onLoad={() => {
                        if(card.id != "img_ref3"){
                          this.componentDidUpdateFunction()
                        }
                      }} />
                    </div>
                    <div className="site_date">
                      <div className="text1"><span>{card.site}</span></div>
                      <div className="title"><span>{card.date}</span></div>
                    </div>
                    <div className="texts_container">
                      <div className="text1"><span>{card.text}</span></div>
                    </div>
                    <div className="btn_link"> 
                      <a href={card.url} target="_blank">
                        <img className="orange" src={images.bg_btn_orange} />
                        <img className="white" src={is_mobile ? images.bg_btn_orange : images.bg_btn_white} />
                        <span>{this.props.t('Voir plus')}</span>
                      </a>
                    </div>
                  </div>
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="sub_page_8" ref={this.testEspacesRef}>
          {/* <img className="imb_bg_p8" src={images.bg_img_p8} /> */}
          <img className="bg_img_p8_1" src={images.bg_img_p8_1} />
          <img className="bg_img_p8_2" src={images.bg_img_p8_2} />
          <div className="background_right_p8"></div>
          <div className="sub_page_content">
            <div className="title">
              <div className="title1">{this.props.t("Découvrez les espaces KOOLSKOOLS")}</div>
            </div>
            <div className="espaces">
              {this.espaces.map((espace, idx) => {
                return <div className={"espace "} key={idx}>
                  <div className="image-container">
                    <img src={espace.image} />
                  </div>
                  <div className="btn_link"> 
                    <a href={espace.url} target="_blank" onClick={evt => {
                      if(espace.onclick){
                        evt.stopPropagation();
                        espace.onclick();
                      }
                      else{
                        this.setState({
                          space: espace,
                          view_space: true,
                        })
                      }
                    }}>
                      <img className="orange" src={images.bg_btn_orange} />
                      <div className="text_container">
                        <div className="text1"><span>{espace.text}</span></div>
                        <div className="title"><span>{espace.text2}</span></div>
                      </div>
                    </a>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
        <div className="sub_page_9 hidden">
          <div className="background_left_p9"></div>
          <div className="lines_right_p9">
            <img src={images.bg_9_lines}/>
          </div>
          <div className="bg_9_image">
            <img src={images.bg_9_image}/>
          </div>
          <div className="sub_page_content">
            <div className="products">
              {this.products.map((product, idx) => {
                return <div className={"product "} key={idx} style={{marginLeft: product.marginLeft + "%"}}>
                  <div className={"bg_color " + product.bg_color}></div>
                  <div className="image-container0">
                    <div className="image-container1">
                      <div className="image-container">
                        <img src={product.ellipse} />
                      </div>
                    </div>
                  </div>
                  <div className="texts"> 
                    <div className="title">{product.text}</div>
                    <div className="text">{product.text2}</div>
                    <a href={product.url} target="_blank" onClick={evt => {
                      if(product.onclick){
                        evt.stopPropagation();
                        product.onclick();
                      }
                    }}>
                      <div className="text_container">
                        <div className="text1"><span>{this.props.t("En vavoir plus")}</span></div>
                        <img className="orange" src={images.arrow_to_right} />
                      </div>
                    </a>
                  </div>
                  <QRCode qrcode_img={product.qr_design} text={product.qr_text} style={{left: "calc(100% - 30px)", bottom: "calc(100% - 30px)", boxShadow: "0 0 128.6px 38.4px rgba(0, 0, 0, 0.19)"}}/>
                </div>
              })}
            </div>
          </div>
        </div>
        <HomeFooter />
      </HomeComponentStyle>
      {this.state.view_space &&
        <ViewSpaceModal 
          space={this.state.space}
          onHide={() => {
            this.setState({
              view_space: false,
            })
          }}
          show={this.state.view_space}
          is_mobile={is_mobile}
          is_tablete={is_tablete}
        />
      }
    </>);
  }
}
const QRCodeStyle = styled.div`
  background: #042a45;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  .text{
    padding: 5px;
    text-align: center;
    font-family: SourceSansRoman;
    font-size: 1.28vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    color: #fdfdfd;
  }
  .qrcode{
    width: 190px;
    height: 190px;
    border-radius: 18px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 128.6px 38.4px rgba(0, 0, 0, 0.19);
    img{
      width: 170px;
      height: 170px;
    }
  }
  @media (max-width: 1199px) {
    
  }
`;
const ChiffreStyle = styled.div`
  width: 300px;
  height: 420px;
  position: relative;
  border-radius: 20px;
  background-size: 100% 100%;
  max-width: 100%;
  padding: 0 10px;
  .chiffre{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: relative;
    .bg_chiffre{
      position: absolute;
      left: 0;
      top: -20px;
      height: 50%;
      width: 70%;
      border-radius: 20px;
    }
    .bg_image{
      position: absolute;
      left: 0;
      top: -0;
      height: 100%;
      width: 100%;
      img{
        width: 100%;
      }
    }
    .text_img{
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 0 20px;
      padding-top: 40px;
      .text{
        font-family: QuicksandBold;
        font-size: 1.4vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        width: 40%;
        padding-top: 10px;
      }
      .img{
        width: 100px;
        height: 100px;
        img{
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }
    .chiffre_text{
      text-align: center;
      position: relative;
      margin: 110px 69px 0 0;
      margin-top: 6vw;
      font-family: SourceSansRoman;
      font-size: 4vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      width: 100%;
      padding: 0 10%;
    }
  }
  &.tablete{
    width: 300px;
    height: 420px;
    position: relative;
    border-radius: 20px;
    background-size: 100% 100%;
    max-width: 100%;
    padding: 0 10px;
    .chiffre{
      width: 100%;
      height: 100%;
      border-radius: 20px;
      position: relative;
      .bg_chiffre{
        position: absolute;
        left: 0;
        top: -20px;
        height: 50%;
        width: 70%;
        border-radius: 20px;
      }
      .bg_image{
        position: absolute;
        left: 0;
        top: -0;
        height: 100%;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .text_img{
        position: relative;
        display: flex;
        justify-content: space-around;
        padding: 0 2vw;
        padding-top: 4vw;
        .text{
          font-family: QuicksandBold;
          font-size: 1.6w;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          width: 40%;
          padding-top: 10px;
        }
        .img{
          width: 10vw;
          height: 10vw;
          min-width: 10vw;
          img{
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
      }
      .chiffre_text{
        text-align: center;
        position: relative;
        margin: 110px 69px 0 0;
        margin-top: 6vw;
        font-family: SourceSansRoman;
        font-size: 4vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        width: 100%;
        padding: 0 10%;
      }
    }
  }
  &.mobile{
    width: 75vw;
    .chiffre{
      padding-top: 6vw !important;
      .text_img{
        padding: 0 13px;
        justify-content: space-between;
        .img{
          width: 111px;
          width: 25vw;
          height: 111px;
          height: 25vw;
          margin-right: 2.2vw;
        }
        .text{
          font-size: 8vw;
          line-height: 1.2;
        }
      }
      .bg_img{
        top: 13px;
      }
      .bg_chiffre{
        border-radius: 6vw 9vw;
        width: 61%;
      }
    }
  }
  @media (max-width: 1199px) {
    
  }
`;
const HomeComponentStyle = styled.section`
  height: 100%;
  margin-bottom: 0px !important;
  .common{
    width: calc(100% - 24%);
    height: 31vw;
    margin: auto;
    padding: 0 0 2px;
    object-fit: contain;
    border-radius: 80px;
    background-image: linear-gradient(to top, #64c2be, #cfe4df);
    background-image: url(${images.background});
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    >img{
      width: 424px;
      height: 259px;
      margin: 0 463px 31px 0;
      padding: 0;
      object-fit: contain;
      opacity: 0.5;
      left: 0;
      top: 0;
      position: absolute;
    }
    .left_bottom_icon{
      width: 5.6vw;
      height: 5.6vw;
      margin: 0;
      object-fit: contain;
      border: solid 1.6vw #fff;
      position: absolute;
      left: -7px;
      bottom: -7px;
      background: #64c2be;
      border-radius: 50%;
    }
    >div:not(.left_bottom_icon):not(.slider-bottom){
      padding: 25px;
      height: 101%;
      padding-bottom: 0;
      padding-right: 0;
      position: relative;
      .react-slideshow-container{
        height: 100%;
        .react-slideshow-fade-wrapper{
          height: 100%;
          .react-slideshow-fade-images-wrap{
            max-width: 100%;
            height: 100%;
            >div{
              .each-fade{
                height: 100%;
                padding-bottom: 8vw;
              }
            }
          }
        }
      }
      .image-container{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .texts_container{
        height: 100%;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .text1{
          font-family: QuicksandLight;
          font-size: 1vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
          margin-top: 10px;
          margin-right: 5px;
        }
        .title{
          margin: 40px 81px 17px 5px;
          width: 57%;
          font-family: SourceSansRoman;
          font-size: 2.8vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          -webkit-letter-spacing: normal;
          -moz-letter-spacing: normal;
          -ms-letter-spacing: normal;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
          >img{
            margin-left: 50px;
          }
        }
        .text2{
          margin: 5px 0px 0px 5px;
          font-family: QuicksandLight;
          font-size: 1.4vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          width: 52%;
        }
      }
      .indicators{
        position: absolute;
        bottom: 7.56vw;
        left: 2.8vw;
        z-index: 1;
        display: none;
        >div{
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: white;
          margin: auto;
          margin-right: 10px;
          &.active{
            width: 20px;
            height: 20px;
            background-color: #042a45;
          }
        }
      }
    }
    .slider-bottom{
      position: absolute;
      bottom: 0;
      z-index: 1;
      .btn_connexion{
        background-image: url(${images.bg_btn_orange_shadow});
        background-size: 100% 100%;
        position: relative;
        width: 232px;
        width: 13vw;
        height: 73px;
        height: 4.1vw;
        margin: 38px 5px 48px 51px;
        margin: 0 5px 2.7vw 2.8vw;
        object-fit: contain;
        border-radius: 35.7px;
        cursor: pointer;
        img{
          position: absolute;
          left: -3px;
          top: -3px;
          width: 100%;
          height: 100%;
        }
        span{
          position: absolute;
          width: max-content;
          height: 16px;
          font-family: QuicksandLight;
          font-size: 1.12vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
        }
      }
    }
  }
  .sub_page_2{
    padding: 0 8.4vw;
    position: relative;
    padding-top: 50px;
    z-index: 3;
    .background_left_p2{
      position: absolute;
      left: 0;
      top: -90px;
      width: 30%;
      display: none;
      padding: 156px 58px 680px 230px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 0% 50%,#64C2BE 4%,#fff 60%);
      z-index: 1;
    }
    .img_bg{
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 120px;
      img{
        width: max-content;
        height: max-content;
        width: 95%;
        opacity: 1;
      }
    }
    .title{
      height: 125px;
      margin: 54px 4px 23px 8px;
      object-fit: contain;
      font-family: SourceSansRoman;
      font-size: 9.74vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: normal;
      text-align: left;
      color: #042a45;
      position: relative;
      z-index: 1;
    }
    .sub_page_content{
      display: flex;
      justify-content: space-between;
      .left{
        width: 50%;
        position: relative;
        img{
          position: absolute;
          left: 0;
        }
      }
      .right{
        width: 45%;
        position: relative;
        z-index: 1;
        padding-top: 3vw;
        > span{
          display: block;
        }
        .-propos {
          width: 98px;
          height: 26px;
          margin: 3px 19px 10px 0;
          font-family: QuicksandLight;
          font-size: 1.29vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .Qui-sommes-nous {
          // width: 618px;
          height: 69px;
          font-family: SourceSansRoman;
          margin-bottom: 25px;
          font-size: 4.2vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .KoolSkools-est-une-plateforme-ducative-destine-aux-coles-o {
          
          font-family: QuicksandLight;
          font-size: 1.68vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #4c5d65;
        }
        .-Permettre-une-communication-efficace-avec-les-parents-Gre {
          // width: 590px;
          margin: 32px 0;
          font-family: QuicksandLight;
          font-size: 1.4vw;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          text-align: left;
          color: #4c5d65;
          padding-left: 15px;
        }
        .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap {
          // width: 572px;
          // height: 95px;
          // margin: 15px 266px 52px 2px;
          font-family: QuicksandLight;
          font-size: 1.4vw;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          text-align: left;
          color: #4c5d65;
        }
        
        .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-1 {
          font-weight: bold;
          margin-right: 5px;
        }
        .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-2 {
          margin: 0 5px;
        }
        .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-3 {
          margin-left: 5px;
        }
      }
    }
  }
  .sub_page_3{
    padding: 0 9%;
    position: relative;
    padding-top: 5.6vw;
    z-index: 2;
    .lines_left_p3{
      position: absolute;
      left: -15%;
      top: 150px;
      width: 36%;
      // height: 689px;
      margin: 0;
      padding: 0;
      object-fit: contain;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .background_left_p3{
      position: absolute;
      left: 0;
      top: -90px;
      width: 30%;
      // height: 1021px;
      padding: 156px 58px 680px 230px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 0% 50%,#64C2BE 4%,#fff 60%);
      z-index: 1;
    }
    .sub_page_content{
      display: flex;
      justify-content: space-between;
      position: relative;
      .left{
        width: 45%;
        position: relative;
        padding-top: 100px;
        z-index: 1;
        > span{
          display: block;
        }
        .Vocation {
          width: 96px;
          height: 18px;
          font-family: QuicksandLight;
          font-size: 1.29vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .Notre-mission {
          font-family: SourceSansRoman;
          font-size: 4.76vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .Nous-aspirons-encourager-les-coles-et-les-enseignants-ado {
          margin-top: 20px;
          font-family: QuicksandLight;
          font-size: 1.68vw;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #4c5d65;
        }
      }
      .right{
        width: 50%;
        position: relative;
        .qrcode_content{

        }
        > img{
          position: absolute;
          right: 0;
          width: 200%;
        }
      }
    }
    .buttons{
      display: flex;
      justify-content: flex-start;
      position: relative;
      margin-top: 70px;
      width: 45%;
      z-index: 2;
      a{
        margin-right: 15px;
        img{
          width: 100%;
          height: 72px;
          object-fit: contain;
        }
      }
    }
  }
  .sub_page_4{
    padding: 0 50px;
    position: relative;
    padding-top: 50px;
    .background_right_p4{
      position: absolute;
      right: 0;
      top: 273px;
      width: 201px;
      height: 1021px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 100% 50%,#ffa827 -3%,#fff 35%);
    }
    .sub_page_content{
      display: flex;
      justify-content: space-between;
      position: relative;
      .left{
        width: 40%;
        .img_bg{
          background-image: url(${images.imqge});
          width: 100%;
          height: 1049px;
          margin: 98px 11px 93px 3.3px;
          padding: 0 0 1px;
          object-fit: contain;
          border-radius: 80px;
          background-color: #04041e;
          position: relative;
          img{
            width: 654px;
            height: 1048px;
            padding: 488px 101px 486px 100px;
            object-fit: contain;
            opacity: 0.5;
            display: none;
          }
          span{
            display: block;
          }
        }
        .Utilit {
          font-family: QuicksandLight;
          font-size: 1.88vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          position: absolute;
          left: 50%;
          top: calc(50% - 25px);
          transform: translate(-50%, -50%);
          width: max-content;
          max-width: 100%;
        }
        .Pour-tous-les-acteurs {
          margin: 13px 0 0;
          font-family: SourceSansRoman;
          font-size: 3vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          position: absolute;
          left: 50%;
          top: calc(50% + 5px);
          transform: translate(-50%, -50%);
          width: max-content;
          max-width: 100%;
        }
      }
      .right{
        width: 55%;
        margin-top: 120px;
        > span{
          display: block;
        }
        .Fonctionnalits {
          width: 165px;
          height: 19px;
          margin: 57px 15px 8px 24px;
          font-family: QuicksandLight;
          font-size: 1.29vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
          display: none;
        }
        .Gestion-360-de-lcole {
          height: 63px;
          margin: 8px 13px 43px 23px;
          font-family: SourceSansRoman;
          font-size: 4.2vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .Plateforme-ducative-pour-les-coles-les-parents-les-lves {
          font-family: QuicksandLight;
          font-size: 1.68vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #6e7b81;
          margin-left: 23px;
        }
        .cards_fonctionalities{
          padding: 23px;
          display: flex;
          flex-wrap: wrap;
          .card_fonctionality{
            width: 50%;
            padding: 10px;
            display: flex;
            padding-left: 0;
            margin-left: auto;
            .img{
              width: 6vw;
              img{
                width: 6vw;
              }
            }
            .title_items{
              width: calc(100% - 6vw);
              padding-left: 10px;
              .ttle{
                font-family: QuicksandLight;
                font-size: 1.32vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                margin-bottom: 5px;
              }
              .items{
                font-family: QuicksandLight;
                font-size: 1.23vw;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: left;
                color: #6e7b81;
                .item_content{
                  display: block;
                  text-indent: -0.9vw;
                  padding-left: 1.22vw;
                  font-family: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  .sub_page_5{
    padding: 0 9.4%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 60px;
    background-image: url(${images.bg_5});
    background-size: 100% 100%;
    .sub_page_content{
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;

      .card_ressource{
        width: 33.33%;
        padding: 20px;
        .card_ressource_content{
          background: white;
          height: 100%;
          border-radius: 20px;
          padding: 20px;
          padding-bottom: 40px;
          .img{
            width: 100%;
            img{
              width: 100px;
              margin: 20px 0;
            }
          }
          .title_items{
            width: calc(100%);
            padding-left: 10px;
            .ttle{
              font-family: QuicksandLight;
              font-size: 1.32vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #042a45;
              margin-bottom: 15px;
            }
            .items{
              font-family: QuicksandLight;
              font-size: 1.23vw;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.3;
              letter-spacing: normal;
              text-align: left;
              color: #6e7b81;
              .item_content{
                display: block;
                text-indent: -0.9vw;
                padding-left: 1.22vw;
                font-family: unset;
              }
            }
          }
        }
      }
      .common_cards{
        width: 66.66%;
        .bg_div{
          background-image: url(${images.ressources_bg});
          background-size: 100% 100%;
          height: 465px;
          height: 26vw;
          margin: 0;
          padding: 0 0 1px;
          object-fit: contain;
          border-radius: 80px;
          background-color: #04041e;
          position: relative;
          span{
            display: block;
          }
          .Des-ressources-pour-toute-lcole {
            width: 80%;
            margin: auto;
            padding-top: 10%;
            font-family: SourceSansRoman;
            font-size: 4.2vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }
          .btn_connexion{
            background-image: url(${images.bg_btn_shadow2});
            background-size: 100% 100%;
            position: relative;
            width: 312px;
            width: 17.4vw;
            height: 81px;
            height: 4.5vw;
            margin: 38px 5px 48px 51px;
            margin: 38px 5px 2.68vw 2.85vw;
            margin-left: 10%;
            object-fit: contain;
            border-radius: 1.96vw;
            cursor: pointer;
            img{
              position: absolute;
              left: -3px;
              top: -3px;
              width: 100%;
              height: 100%;
            }
            span{
              position: absolute;
              font-family: QuicksandLight;
              font-size: 1.12vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #042a45;
              width: max-content;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -60%);
            }
          }
        }
      }
    }
  }
  .sub_page_6{
    padding: 0 9%;
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    // height: 800px;
    padding-bottom: 190px;
    padding-bottom: 13vw;
    .background_right_p6{
      position: absolute;
      left: 0;
      top: 150px;
      width: 201px;
      height: 1021px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 0% 50%,#ffa827 7%,#fff 35%);
    }
    .sub_page_content{
      padding: 0 12%;
      position: relative;
      .title{
        position: absolute;
        width: 100%;
        padding: 0 15%;
        left: 0;
        text-align: right;
        >div{
          text-align: right;
        }
        .title1{
          font-family: QuicksandLight;
          font-size: 1vw;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #042a45;
        }
        .title2{
          font-family: SourceSansRoman;
          font-size: 3.64vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: right;
          color: #042a45;
        }
        .title3{
          >img{
            margin-left: 50px;
          }
        }
      }
      .chiffres{
        display: flex;
        justify-content: space-between;
        position: relative;
      }
    }
    &.web{
      padding: 0 10.5%;
      padding-bottom: 18vw;
      padding-top: 3vw;
      .sub_page_content{
        padding: 0 0%;
        .chiffres{
          padding-top: 25px;
          .chiffre_content{
            width: 23vw !important;
            .chiffre{
              .chiffre_text{
                margin-top: 8vw;
                font-size: 6vw;
              }
              .text_img{
                .text{
                  font-size: 2vw;
                }
                img{
                  width: 112px;
                  height: 112px;
                }
              }
            }
          }
        }
      }
    }
  }
  .sub_page_7{
    padding: 0 10%;
    position: relative;
    padding-top: 0;
    padding-bottom: 100px;
    .sub_page_content{
      padding: 0 3%;
      position: relative;
      .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .title1{
          font-family: SourceSansRoman;
          font-size: 3.64vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.08;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .title_btn{
          margin-bottom: 41px;
          .btn_show_plus{
            background-image: url(${images.bg_btn_orange_shadow});
            position: relative;
            width: 232px;
            height: 73px;
            width: 13vw;
            height: 4.1vw;
            object-fit: contain;
            border-radius: 2vw;
            cursor: pointer;
            img{
              position: absolute;
              left: -3px;
              top: -3px;
              width: 100%;
              height: 100%;
            }
            span{
              position: absolute;
              font-family: QuicksandLight;
              font-size: 1.12vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #042a45;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -60%);
            }
          }
        }
      }
      .cards{
        .slick-slider{
          .slick-list{
            .slick-track{
              .slick-slide{
                max-width: 33.33% !important;
                margin: 0;
                background: #fff;
                border-radius: 40px;
                padding: 15px;
                &.slick-active{
                  &.slick-current{
                    background: #64c2be;
                    .site_date{
                      .text1{
                        span{
                          color: #fff;
                        }
                      }
                      .title{
                        span{
                          color: #fff;
                        }
                      }
                    }
                    .texts_container{
                      .text1{
                        span{
                          color: #fff;
                        }
                      }
                    }
                    .btn_link{
                      a{
                        img{
                          &.orange{
                            display: none;
                          }
                          &.white{
                            display: block;
                          }
                        }
                        span{
                          color: #042a45;
                        }
                      }
                    }
                  }
                }
                .each-fade{
                  .image-container{
                    img{
                      width: 100%;
                      border-radius: 40px;
                    }
                  }
                  .site_date{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 20px;
                    padding: 0 15px;
                    >div{
                      width: max-content;
                    }
                    .text1{
                      span{
                        font-family: QuicksandLight;
                        font-size: 1.12vw;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: left;
                        color: #6e7b81;
                      }
                    }
                    .title{
                      span{
                        font-family: QuicksandLight;
                        font-size: 1.12vw;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: left;
                        color: #6e7b81;
                      }
                    }
                  }
                  .texts_container{
                    margin-top: 20px;
                    padding: 0 15px;
                    .text1{
                      height: 70px;
                      span{
                        font-family: SourceSansRoman;
                        font-size: 1.2vw;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.4;
                        letter-spacing: normal;
                        text-align: left;
                        color: #042a45;
                      }
                    }
                  }
                  .btn_link{
                    margin-top: 20px;
                    margin-left: 15px;
                    height: 55px;
                    height: 2.8vw;
                    position: relative;
                    width: 200px;
                    width: 11.2vw;
                    background-image: url(${images.bg_btn_orange_shadow});
                    background-size: 100% 100%;
                    a{
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      line-height: 1;
                      img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: -2px;
                        top: -2px;
                        &.orange{
                          display: block;
                        }
                        &.white{
                          display: none;
                        }
                      }
                      span{
                        font-family: QuicksandLight;
                        font-size: 1vw;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: left;
                        color: #042a45;
                        position: relative;
                        text-align: center;
                        display: block;
                        margin-top: 0.9vw;
                      }
                    }
                  }
                }
              }
            }
          }
          .dots_classes{
            display: flex !important;
            justify-content: center;
            margin-top: 40px;
            li{
              margin: 0 6px;
              width: 15px;
              margin-top: 2.5px;
              height: 15px;
              border-radius: 50%;
              background: #ffa827;
              position: relative;
              display: inline-block;
              &.slick-active{
                margin-top: 0;
                background: #042a45;
                width: 20px;
                height: 20px;
              }
              button{
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .sub_page_8{
    background-image: url(${images.bg_img_p8});
    background-size: 100% 100%;
    padding: 0 6%;
    position: relative;
    padding-top: 60px;
    padding-bottom: 50px;
    .background_right_p8{
      position: absolute;
      right: 0;
      top: 150px;
      width: 22%;
      height: 1021px;
      opacity: 0.75;
      background-image: radial-gradient(circle at 100% 50%,#d33f29 7%,#fff 35%);
    }
    .bg_img_p8_1{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%; 
      display: none;
    }
    .bg_img_p8_2{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%; 
    }
    .sub_page_content{
      padding: 0 3%;
      position: relative;
      .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .title1{
          width: 60%;
          margin: 0 130px 63px 106px;
          font-family: SourceSansRoman;
          font-size: 3.64vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.08;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
      }
      .espaces{
        display: flex;
        justify-content: space-between;
        padding: 0 5%;
        .espace{
          width: 25%;
          padding: 20px;
          .image-container{
            height: 240px;
            max-width: 100%;
            text-align: center;
            img{
              max-height: 240px;
              max-width: 100%;
              margin: auto;
            }
          }
          .btn_link{
            margin: auto;
            margin-top: 20px;
            height: 55px;
            height: 3.1vw;
            position: relative;
            width: 100%;
            width: 13vw;
            a{
              position: absolute;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: -2px;
                &.orange{
                  display: block;
                }
              }
              .text_container{
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 15px;
                padding: 0 15px;
                margin-top: 0.9vw;
                padding: 0 0.64vw;
                position: relative;
                >div{
                  width: max-content;
                  margin: 0 3px;
                }
                .text1{
                  line-height: 1;
                  span{
                    font-family: QuicksandLight;
                    font-size: 1vw;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #042a45
                  }
                }
                .title{
                  display: block;
                  line-height: 1;
                  span{
                    font-family: QuicksandLight;
                    font-size: 1vw;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #042a45;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sub_page_9{
    padding: 0 0px;
    position: relative;
    padding-top: 0px;
    padding-bottom: 50px;
    .background_left_p9{
      position: absolute;
      left: 0;
      top: 0px;
      width: 31%;
      height: 100%;
      opacity: 0.75;
      background-image: radial-gradient(circle at 0% 50%,#55bdb8 7%,#fff 35%);
    }
    .lines_right_p9{
      position: absolute;
      right: -10%;
      top: 100px;
      width: 36%;
      margin: 0;
      padding: 0;
      object-fit: contain;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .bg_9_image{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 41%;
      margin: 0;
      padding: 0;
      object-fit: contain;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .sub_page_content{
      padding: 0 3%;
      position: relative;
      .products{
        padding-top: 60px;
        .product{
          width: 50%;
          padding: 20px;
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-left: 28%;
          border-radius: 40px;
          box-shadow: 0 0 165.7px 33.3px rgb(0 0 0 / 5%);
          margin-top: 5%;
          padding: 20px;
          background: white;
          margin-bottom: 12px;
          height: 15vw;
          .bg_color{
            position: absolute;
            right: 0;
            top: 0;
            width: 22%;
            height: 70%;
            opacity: 0.75;
            background-image: radial-gradient(circle at 100% 0%,#5fc0bc 7%,#fff 35%);
            &.orange{
              background-image: radial-gradient(circle at 100% 0%,#ffa827 7%,#fff 35%);
            }
          }
          .image-container0{
            width: 42%;
            position: relative;
            margin-top: -10%;
            margin-left: -10%;
            .image-container1{
              width: 100%; /* Set the width of the aspect ratio box to fill its container */
              padding-bottom: 100%; /* 4:3 aspect ratio (height is 75% of the width) */
              position: relative;
              .image-container{
                position: absolute; /* Position the content absolutely within the aspect ratio box */
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                img{
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 110%;
                  position: relative;
                }
              }
            }
          }
          .texts{
            position: relative;
            width: 66%;
            object-fit: contain;
            border-radius: 40px;
            height: 80%;
            padding: 20px;
            .title{
              font-family: SourceSansRoman;
              font-size: 2vw;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #042a45;
            }
            .text{
              font-family: QuicksandLight;
              font-size: 1.48vw;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #4c5d65;
            }
            a{
              font-family: QuicksandLight;
              font-size: 1.4vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: right;
              color: #042a45;
              .text_container{
                display: flex;
                justify-content: flex-end;
                margin-top: 15px;
                img{
                  margin-left: 10px;
                  width: 2vw;
                }
              }
            }
          }
          .qrcode_content{
            .qrcode{
              width: 10vw !important;
              height: 10vw !important;
              text-align: center;
              img{
                width: 8vw !important;
                height: 8vw !important;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
  &.tablete{
    height: 100%;
    margin-bottom: 0px !important;
    box-shadow: unset;
    .common{
      width: calc(100% - 14vw);
      height: 33vw;
      margin: auto;
      padding: 0 0 2px;
      object-fit: contain;
      border-radius: 5vw;
      background-image: linear-gradient(to top, #64c2be, #cfe4df);
      background-image: url(${images.background});
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;
      >img{
        width: 52vw;
        height: 31vw;
        margin: 0 56vw 3.8vw 0;
        padding: 0;
        object-fit: contain;
        opacity: 0.5;
        left: 0;
        top: 0;
        position: absolute;
      }
      .left_bottom_icon{
        width: 6.6vw;
        height: 6.6vw;
        margin: 0;
        object-fit: contain;
        border: solid 1.8vw #fff;
        position: absolute;
        left: -7px;
        bottom: -7px;
        background: #64c2be;
        border-radius: 50%;
      }
      >div:not(.left_bottom_icon):not(.slider-bottom){
        padding: 3vw;
        height: 101%;
        padding-bottom: 0;
        padding-right: 0;
        position: relative;
        .react-slideshow-container{
          height: 100%;
          .react-slideshow-fade-wrapper{
            height: 100%;
            .react-slideshow-fade-images-wrap{
              max-width: 100%;
              height: 100%;
              >div{
                .each-fade{
                  height: 100%;
                  padding-bottom: 12vw;
                }
              }
            }
          }
        }
        .image-container{
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .texts_container{
          height: 100%;
          padding: 0vw;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .text1{
            font-family: QuicksandLight;
            font-size: 1vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            margin-top: 0px;
            margin-right: 5px;
          }
          .title{
            margin: 2.5vw 6vw 1vw 0vw;
            width: 57%;
            font-family: SourceSansRoman;
            font-size: 2.8vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            -webkit-letter-spacing: normal;
            -moz-letter-spacing: normal;
            -ms-letter-spacing: normal;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            >img{
              margin-left: 50px;
            }
          }
          .text2{
            margin: 5px 0px 0px 0px;
            font-family: QuicksandLight;
            font-size: 1.4vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            width: 52%;
          }
        }
        .indicators{
          position: absolute;
          bottom: 9.56vw;
          left: 2.8vw;
          z-index: 1;
          display: none;
          >div{
            width: 1.2vw;
            height: 1.2vw;
            border-radius: 50%;
            background-color: white;
            margin: auto;
            margin-right: 1vw;
            &.active{
              width: 1.5vw;
              height: 1.5vw;
              background-color: #042a45;
            }
          }
        }
      }
      .slider-bottom{
        position: absolute;
        bottom: 0;
        z-index: 1;
        .btn_connexion{
          background-image: url(${images.bg_btn_orange_shadow});
          background-size: 100% 100%;
          position: relative;
          width: 232px;
          width: 13vw;
          height: 73px;
          height: 4.1vw;
          margin: 38px 5px 48px 51px;
          margin: 0 5px 2.7vw 2.8vw;
          object-fit: contain;
          border-radius: 3.5vw;
          cursor: pointer;
          img{
            position: absolute;
            left: -3px;
            top: -3px;
            width: 100%;
            height: 100%;
          }
          span{
            position: absolute;
            width: max-content;
            height: unset;
            font-family: QuicksandLight;
            font-size: 1.12vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -60%);
          }
        }
      }
    }
    .sub_page_2{
      padding: 0 8.4vw;
      position: relative;
      padding-top: 50px;
      z-index: 3;
      .background_left_p2{
        position: absolute;
        left: 0;
        top: -90px;
        width: 30%;
        display: none;
        padding: 156px 58px 680px 230px;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#64C2BE 4%,#fff 60%);
        z-index: 1;
      }
      .img_bg{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 120px;
        img{
          width: max-content;
          height: max-content;
          width: 95%;
          opacity: 1;
        }
      }
      .title{
        height: 10vw;
        margin: 5vw 4px 2vw 1vw;
        object-fit: contain;
        font-family: SourceSansRoman;
        font-size: 9.74vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        text-align: left;
        color: #042a45;
        position: relative;
        z-index: 1;
      }
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        .left{
          width: 50%;
          position: relative;
          img{
            position: absolute;
            left: 0;
          }
        }
        .right{
          width: 45%;
          position: relative;
          z-index: 1;
          padding-top: 3vw;
          > span{
            display: block;
          }
          .-propos {
            width: 98px;
            height: unset;
            margin: 3px 1vw 0.5vw 0;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Qui-sommes-nous {
            // width: 618px;
            height: unset;
            font-family: SourceSansRoman;
            margin-bottom: 1vw;
            font-size: 4.2vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .KoolSkools-est-une-plateforme-ducative-destine-aux-coles-o {
            
            font-family: QuicksandLight;
            font-size: 1.68vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
          .-Permettre-une-communication-efficace-avec-les-parents-Gre {
            margin: 1vw 0;
            font-family: QuicksandLight;
            font-size: 1.4vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
            padding-left: 15px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap {
            // width: 572px;
            // height: 95px;
            // margin: 15px 266px 52px 2px;
            font-family: QuicksandLight;
            font-size: 1.4vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
          
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-1 {
            font-weight: bold;
            margin-right: 5px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-2 {
            margin: 0 5px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-3 {
            margin-left: 5px;
          }
        }
      }
    }
    .sub_page_3{
      padding: 0 9%;
      position: relative;
      padding-top: 2.6vw;
      z-index: 2;
      .lines_left_p3{
        position: absolute;
        left: -15%;
        top: 100px;
        width: 36%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        z-index: 1;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .background_left_p3{
        position: absolute;
        left: 0;
        top: -90px;
        width: 30%;
        padding: 156px 58px 680px 230px;
        padding: 15vw 6vw 70vw 30vw;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#64C2BE 4%,#fff 60%);
        z-index: 1;
      }
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        position: relative;
        .left{
          width: 45%;
          position: relative;
          padding-top: 5vw;
          z-index: 1;
          > span{
            display: block;
          }
          .Vocation {
            width: 96px;
            height: 18px;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Notre-mission {
            font-family: SourceSansRoman;
            font-size: 4.76vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Nous-aspirons-encourager-les-coles-et-les-enseignants-ado {
            margin-top: 20px;
            font-family: QuicksandLight;
            font-size: 1.68vw;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
        }
        .right{
          width: 50%;
          position: relative;
          .qrcode_content{
  
          }
          > img{
            position: absolute;
            right: -10vw;
            width: 250%;
          }
        }
      }
      .buttons{
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-top: 70px;
        width: 45%;
        z-index: 2;
        a{
          margin-right: 15px;
          img{
            width: 100%;
            height: 72px;
            object-fit: contain;
          }
        }
      }
    }
    .sub_page_4{
      padding: 0 1vw 0 0vw;
      position: relative;
      padding-top: 3vw;
      z-index: 2;
      .background_right_p4{
        position: absolute;
        right: -11%;
        top: -10%;
        width: 21vw;
        height: 126vw;
        opacity: 0.75;
        background-image: radial-gradient(circle at 100% 50%,#ffa827 3%,#fff 32%);
      }
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        position: relative;
        .left{
          width: 40%;
          .img_bg{
            background-image: url(${images.imqge});
            width: 100%;
            height: 700px;
            margin: 3vw 1.2vw 3vw 3.3px;
            padding: 0 0 1px;
            object-fit: contain;
            border-radius: 5vw;
            background-color: #04041e;
            position: relative;
            background-size: 100% 100%;
            img{
              width: 654px;
              height: 1048px;
              padding: 488px 101px 486px 100px;
              object-fit: contain;
              opacity: 0.5;
              display: none;
            }
            span{
              display: block;
            }
          }
          .Utilit {
            font-family: QuicksandLight;
            font-size: 1.88vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            position: absolute;
            left: 50%;
            top: calc(50% - 25px);
            transform: translate(-50%, -50%);
            width: max-content;
            max-width: 100%;
          }
          .Pour-tous-les-acteurs {
            margin: 13px 0 0;
            font-family: SourceSansRoman;
            font-size: 3vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            position: absolute;
            left: 50%;
            top: calc(50% + 5px);
            transform: translate(-50%, -50%);
            width: max-content;
            max-width: 100%;
          }
        }
        .right{
          width: 55%;
          margin-top: 2.5vw;
          > span{
            display: block;
          }
          .Fonctionnalits {
            width: 165px;
            height: unset;
            margin: 5vw 1vw 0.8vw 2vw;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            display: none;
          }
          .Gestion-360-de-lcole {
            height: unset;
            margin: 0.8vw 1vw 3vw 2vw;
            font-family: SourceSansRoman;
            font-size: 4.2vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Plateforme-ducative-pour-les-coles-les-parents-les-lves {
            font-family: QuicksandLight;
            font-size: 1.68vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #6e7b81;
            margin-left: 23px;
          }
          .cards_fonctionalities{
            padding: 23px;
            display: flex;
            flex-wrap: wrap;
            .card_fonctionality{
              width: 50%;
              padding: 10px;
              display: flex;
              padding-left: 0;
              margin-left: auto;
              .img{
                width: 6vw;
                img{
                  width: 6vw;
                }
              }
              .title_items{
                width: calc(100% - 6vw);
                padding-left: 10px;
                .ttle{
                  font-family: QuicksandLight;
                  font-size: 1.32vw;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  text-align: left;
                  color: #042a45;
                  margin-bottom: 5px;
                }
                .items{
                  font-family: QuicksandLight;
                  font-size: 1.23vw;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.3;
                  letter-spacing: normal;
                  text-align: left;
                  color: #6e7b81;
                }
                .item_content{
                  display: block;
                  text-indent: -0.8vw;
                  padding-left: 1vw;
                  font-family: unset;
                }
              }
            }
          }
        }
      }
    }
    .sub_page_5{
      padding: 0 0%;
      position: relative;
      padding-top: 50px;
      padding-bottom: 60px;
      background-image: url(${images.bg_5});
      background-size: 100% 100%;
      z-index: 2;
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        padding: 0 3vw;
        .card_ressource{
          width: 33.33%;
          padding: 2vw;
          .card_ressource_content{
            background: white;
            height: 100%;
            border-radius: 20px;
            padding: 20px;
            padding-bottom: 40px;
            .img{
              width: 100%;
              img{
                width: 100px;
                margin: 20px 0;
              }
            }
            .title_items{
              width: calc(100%);
              padding-left: 10px;
              .ttle{
                font-family: QuicksandLight;
                font-size: 1.32vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                margin-bottom: 15px;
              }
              .items{
                font-family: QuicksandLight;
                font-size: 1.23vw;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: left;
                color: #6e7b81;
              }
              .item_content{
                display: block;
                text-indent: -0.8vw;
                padding-left: 1vw;
                font-family: unset;
              }
            }
          }
        }
        .common_cards{
          width: 66.66%;
          .bg_div{
            background-image: url(${images.ressources_bg});
            background-size: 100% 100%;
            min-height: 100%;
            height: 28vw;
            margin: 0;
            padding: 0 0 1px;
            object-fit: contain;
            border-radius: 3vw;
            background-color: #04041e;
            position: relative;
            span{
              display: block;
            }
            .Des-ressources-pour-toute-lcole {
              width: 80%;
              margin: auto;
              padding-top: 10%;
              font-family: SourceSansRoman;
              font-size: 4.2vw;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }
            .btn_connexion{
              background-image: url(${images.bg_btn_shadow2});
              background-size: 100% 100%;
              position: relative;
              width: 312px;
              width: 17.4vw;
              height: 81px;
              height: 4.5vw;
              margin: 38px 5px 48px 51px;
              margin: 38px 5px 2.68vw 2.85vw;
              margin-left: 10%;
              object-fit: contain;
              border-radius: 1.96vw;
              cursor: pointer;
              img{
                position: absolute;
                left: -3px;
                top: -3px;
                width: 100%;
                height: 100%;
              }
              span{
                position: absolute;
                font-family: QuicksandLight;
                font-size: 1.12vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                width: max-content;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -60%);
              }
            }
          }
        }
      }
    }
    .sub_page_6{
      padding: 0 9%;
      position: relative;
      padding-top: 8vw;
      padding-bottom: 60px;
      padding-bottom: 190px;
      padding-bottom: 10vw;
      .background_right_p6{
        position: absolute;
        left: -5vw;
        top: -10vw;
        width: 183px;
        height: 1021px;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#ffa827 7%,#fff 36%);
      }
      .sub_page_content{
        padding: 0 8%;
        position: relative;
        .title{
          position: absolute;
          width: 100%;
          padding: 0 15%;
          left: 0;
          text-align: right;
          >div{
            text-align: right;
          }
          .title1{
            font-family: QuicksandLight;
            font-size: 1vw;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #042a45;
          }
          .title2{
            font-family: SourceSansRoman;
            font-size: 3.64vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: right;
            color: #042a45;
          }
          .title3{
            >img{
              margin-left: 50px;
            }
          }
        }
        .chiffres{
          display: flex;
          justify-content: space-between;
          position: relative;
        }
      }
    }
    .sub_page_7{
      padding: 0 6%;
      position: relative;
      padding-top: 0;
      padding-bottom: 5vw;
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title1{
            font-family: SourceSansRoman;
            font-size: 4.64vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .title_btn{
            margin-bottom: 4vw;
            .btn_show_plus{
              background-image: url(${images.bg_btn_orange_shadow});
              position: relative;
              width: 232px;
              height: 73px;
              width: 13vw;
              height: 4.1vw;
              object-fit: contain;
              border-radius: 2vw;
              cursor: pointer;
              img{
                position: absolute;
                left: -3px;
                top: -3px;
                width: 100%;
                height: 100%;
              }
              span{
                position: absolute;
                font-family: QuicksandLight;
                font-size: 1.12vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -60%);
              }
            }
          }
        }
        .cards{
          padding: 0 2%;
          .slick-slider{
            .slick-list{
              .slick-track{
                .slick-slide{
                  max-width: 33.33% !important;
                  margin: 0;
                  background: #fff;
                  border-radius: 3vw;
                  padding: 1.5vw;
                  &.slick-active{
                    &.slick-current{
                      background: #64c2be;
                      .site_date{
                        .text1{
                          span{
                            color: #fff;
                          }
                        }
                        .title{
                          span{
                            color: #fff;
                          }
                        }
                      }
                      .texts_container{
                        .text1{
                          span{
                            color: #fff;
                          }
                        }
                      }
                      .btn_link{
                        a{
                          img{
                            &.orange{
                              display: none;
                            }
                            &.white{
                              display: block;
                            }
                          }
                          span{
                            color: #042a45;
                          }
                        }
                      }
                    }
                  }
                  .each-fade{
                    .image-container{
                      img{
                        width: 100%;
                      }
                    }
                    .site_date{
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      margin-top: 20px;
                      padding: 0 15px;
                      >div{
                        width: max-content;
                      }
                      .text1{
                        span{
                          font-family: QuicksandLight;
                          font-size: 1.12vw;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #6e7b81;
                        }
                      }
                      .title{
                        span{
                          font-family: QuicksandLight;
                          font-size: 1.12vw;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #6e7b81;
                        }
                      }
                    }
                    .texts_container{
                      margin-top: 20px;
                      padding: 0 15px;
                      .text1{
                        height: 70px;
                        span{
                          font-family: SourceSansRoman;
                          font-size: 1.2vw;
                          font-weight: 600;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.4;
                          letter-spacing: normal;
                          text-align: left;
                          color: #042a45;
                        }
                      }
                    }
                    .btn_link{
                      margin-top: 20px;
                      margin-left: 15px;
                      height: 55px;
                      height: 3.8vw;
                      position: relative;
                      width: 200px;
                      width: 13.2vw;
                      background-image: url(${images.bg_btn_orange_shadow});
                      background-size: 100% 100%;
                      a{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        line-height: 1;
                        img{
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: -2px;
                          top: -2px;
                          &.orange{
                            display: block;
                          }
                          &.white{
                            display: none;
                          }
                        }
                        span{
                          font-family: QuicksandLight;
                          font-size: 1.5vw;
                          font-weight: 600;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #042a45;
                          position: relative;
                          text-align: center;
                          display: block;
                          margin-top: 0.9vw;
                        }
                      }
                    }
                  }
                }
              }
            }
            .dots_classes{
              display: flex !important;
              justify-content: center;
              margin-top: 40px;
              li{
                margin: 0 6px;
                width: 15px;
                margin-top: 2.5px;
                height: 15px;
                border-radius: 50%;
                background: #ffa827;
                position: relative;
                display: inline-block;
                &.slick-active{
                  margin-top: 0;
                  background: #042a45;
                  width: 20px;
                  height: 20px;
                }
                button{
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .sub_page_8{
      background-image: url(${images.bg_img_p8});
      background-size: 100% 100%;
      padding: 0 5%;
      position: relative;
      padding-top: 6vw;
      padding-bottom: 50px;
      .background_right_p8{
        position: absolute;
        right: -10vw;
        top: -5vw;
        width: 26%;
        height: 1021px;
        opacity: 0.75;
        background-image: radial-gradient(circle at 100% 50%,#d33f29 6%,#fff0 36%);
      }
      .bg_img_p8_1{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%; 
        display: none;
      }
      .bg_img_p8_2{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%; 
      }
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title1{
            width: 60%;
            margin: 0 10vw 5vw 106px;
            font-family: SourceSansRoman;
            font-size: 3.64vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
        }
        .espaces{
          display: flex;
          justify-content: space-between;
          padding: 0 0;
          .espace{
            width: 25%;
            padding: 2vw;
            .image-container{
              height: 200px;
              max-width: 100%;
              text-align: center;
              img{
                max-height: 200px;
                max-width: 100%;
                height: 100%;
                margin: auto;
              }
            }
            .btn_link{
              margin: auto;
              margin-top: 20px;
              height: 55px;
              height: 4vw;
              position: relative;
              width: 100%;
              width: 15vw;
              a{
                position: absolute;
                width: 100%;
                height: 100%;
                img{
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: -2px;
                  &.orange{
                    display: block;
                  }
                }
                .text_container{
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  margin-top: 15px;
                  padding: 0 15px;
                  margin-top: 0.9vw;
                  padding: 0 0.64vw;
                  position: relative;
                  margin-left: -3px;
                  >div{
                    width: max-content;
                    margin: 0 3px;
                  }
                  .text1{
                    line-height: 1.5vw;
                    span{
                      font-family: QuicksandLight;
                      font-size: 1.2vw;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.5vw;
                      letter-spacing: normal;
                      text-align: left;
                      color: #042a45
                    }
                  }
                  .title{
                    display: block;
                    line-height: 1.5vw;
                    span{
                      font-family: QuicksandLight;
                      font-size: 1.2vw;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.5vw;
                      letter-spacing: normal;
                      text-align: left;
                      color: #042a45;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sub_page_9{
      padding: 0 0px;
      position: relative;
      padding-top: 0px;
      padding-bottom: 50px;
      .background_left_p9{
        position: absolute;
        left: 0;
        top: 0px;
        width: 31%;
        height: 100%;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#55bdb8 7%,#fff 35%);
      }
      .lines_right_p9{
        position: absolute;
        right: -10%;
        top: 100px;
        width: 36%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .bg_9_image{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 41%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .products{
          padding-top: 2vw;
          .product{
            width: 50%;
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-left: 28%;
            border-radius: 40px;
            box-shadow: 0 0 165.7px 33.3px rgb(0 0 0 / 5%);
            margin-top: 5%;
            padding: 20px;
            background: white;
            margin-bottom: 12px;
            height: 15vw;
            .bg_color{
              position: absolute;
              right: 0;
              top: 0;
              width: 22%;
              height: 70%;
              opacity: 0.75;
              background-image: radial-gradient(circle at 100% 0%,#5fc0bc 7%,#fff 35%);
              &.orange{
                background-image: radial-gradient(circle at 100% 0%,#ffa827 7%,#fff 35%);
              }
            }
            .image-container0{
              width: 42%;
              position: relative;
              margin-top: -10%;
              margin-left: -10%;
              .image-container1{
                width: 100%; /* Set the width of the aspect ratio box to fill its container */
                padding-bottom: 100%; /* 4:3 aspect ratio (height is 75% of the width) */
                position: relative;
                .image-container{
                  position: absolute; /* Position the content absolutely within the aspect ratio box */
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-size: 100% 100%;
                  img{
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 110%;
                    position: relative;
                  }
                }
              }
            }
            .texts{
              position: relative;
              width: 66%;
              object-fit: contain;
              border-radius: 40px;
              height: 80%;
              padding: 20px;
              .title{
                font-family: SourceSansRoman;
                font-size: 2vw;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
              }
              .text{
                font-family: QuicksandLight;
                font-size: 1.48vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #4c5d65;
              }
              a{
                font-family: QuicksandLight;
                font-size: 1.4vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: right;
                color: #042a45;
                .text_container{
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 15px;
                  img{
                    margin-left: 10px;
                    width: 2vw;
                  }
                }
              }
            }
            .qrcode_content{
              .qrcode{
                width: 10vw !important;
                height: 10vw !important;
                text-align: center;
                img{
                  width: 8vw !important;
                  height: 8vw !important;
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  &.mobile{
    height: 100%;
    margin-bottom: 0px !important;
    box-shadow: unset;
    padding-top: 0px;
    margin-top: -1px;
    .common{
      width: 92vw;
      height: calc(87vh - 16vw - 95px);
      margin: auto;
      padding: 0 0 2px;
      object-fit: contain;
      border-radius: 50px;
      min-height: 545px;
      background-image: linear-gradient(to top, #64c2be, #cfe4df);
      background-image: url(${images.background});
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;
      margin-left: 3.5vw;
      >img{
        width: 52vw;
        height: 31vw;
        margin: 0 56vw 3.8vw 0;
        padding: 0;
        object-fit: contain;
        opacity: 1;
        left: 0;
        top: 0;
        position: absolute;
      }
      >div{
        &:not(.left_bottom_icon):not(.slider-bottom){
          .image-container{
            z-index: -1;
          }
          .texts_container{
            height: 50%;
            padding: 0vw;
            padding-top: 9vw;
            padding-left: 5vw;
            display: flex;
            flex-direction: column;
            justify-content: start;
            .text1 span{
              font-family: QuicksandLight;
              font-size: 1vw;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #042a45;
              margin-top: 0px;
              margin-right: 5px;
            }
            .title{
              margin: 0;
              width: 60%;
              span{
                margin: 0;
                margin-left: 5px;
                font-family: 'SourceSansRoman' !important;
                font-size: 7vw;
                font-size: 33px;
                font-weight: bolder !important;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                -webkit-letter-spacing: normal;
                -moz-letter-spacing: normal;
                -ms-letter-spacing: normal;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                >img{
                  margin-left: 50px;
                }
              }
            }
            .text2 {
              margin: 5px 0px 0px 0px;
              width: 60%;
              span{
                margin: 5px 0px 0px 0px;
                font-family: 'QuicksandLight' !important;
                font-size: 2.5vw; 
                font-size: 12px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
              }
            }
          }
          .indicators{
            position: absolute;
            bottom: 9.56vw;
            left: 2.8vw;
            z-index: 1;
            display: none;
            >div{
              width: 1.2vw;
              height: 1.2vw;
              border-radius: 50%;
              background-color: white;
              margin: auto;
              margin-right: 1vw;
              &.active{
                width: 1.5vw;
                height: 1.5vw;
                background-color: #042a45;
              }
            }
          }
        }
      }
      .slider-bottom{
        position: absolute;
        top: 52%;
        &.add_6p{
          top: 58%;
        }
        left: 3%;
        z-index: 1;
        .btn_connexion{
          background-image: url(${images.bg_btn_orange_shadow});
          background-size: 100% 100%;
          position: relative;
          width: 150px;
          height: 45px;
          margin: 38px 5px 48px 51px;
          margin: 0 5px 2.7vw 2.8vw;
          object-fit: contain;
          border-radius: 25px;
          cursor: pointer;
          img{
            position: absolute;
            left: -3px;
            top: -3px;
            width: 100%;
            height: 100%;
          }
          span{
            position: absolute;
            width: max-content;
            height: unset;
            font-family: QuicksandLight;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -60%);
          }
        }
      }
      .react-slideshow-container{
        .each-fade{
          padding-bottom: 56vw;
        }
      }
      .left_bottom_icon{
        width: 17.6vw;
        height: 17.6vw;
        border: solid 5.6vw #fff;
        left: -4vw;
        bottom: -4vw;
        margin: 0;
        object-fit: contain;
        position: absolute;
        background: #64c2be;
        border-radius: 50%;
      }
      >div:not(.left_bottom_icon):not(.slider-bottom){
        padding: 0vw;
        height: 101%;
        padding-bottom: 0;
        padding-right: 0;
        position: relative;
        .react-slideshow-container{
          height: 100%;
          .react-slideshow-fade-wrapper{
            height: 100%;
            .react-slideshow-fade-images-wrap{
              max-width: 100%;
              height: 100%;
              >div{
                .each-fade{
                  height: 100%;
                  padding-bottom: 12vw;
                }
              }
            }
          }
        }
        .image-container{
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
        
      }
    }
    .sub_page_2{
      padding: 0 8.4vw;
      position: relative;
      padding-top: 50px;
      z-index: 3;
      .background_left_p2{
        position: absolute;
        left: 0;
        top: 50%;
        width: 30%;
        display: block;
        padding: 156px 58px 64% 230px;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#64C2BE 2%,#fff0 60%);
        z-index: 1;
        .img_bg{
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          top: 120px;
          img{
            width: max-content;
            height: max-content;
            width: 95%;
            opacity: 1;
          }
        }
      }
      .title{
        object-fit: contain;
        font-family: SourceSansRoman;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        text-align: left;
        color: #042a45;
        position: relative;
        z-index: 1;
        height: unset;
        margin: unset;
        text-align: center;
        font-size: 15.74vw;
        z-index: 2;
        margin-bottom: -16px;
        margin-top: 10px;
      }
      .img_bg{
        margin-left: -10vw;
        position: relative;
        top: -20vw;
        z-index: 1;
        left: -6vw;
        img{
          width: 158vw;
          width: 181vw;
        }
      }
      .sub_page_content{
        z-index: 1;
        margin-top: -27vw;
        display: flex;
        justify-content: space-between;
        .left{
          display: none;
        }
        .right{
          width: 100% !important;
          .-propos{
            font-size: 3.29vw;
            text-transform: capitalize;
          }
        }
      }
    }
    .sub_page_2{
      .sub_page_content{
        .left{
          width: 50%;
          position: relative;
          img{
            position: absolute;
            left: 0;
          }
        }
        .right{
          width: 45%;
          position: relative;
          z-index: 1;
          padding-top: 3vw;
          > span{
            display: block;
          }
          .-propos {
            width: 98px;
            height: unset;
            margin: 3px 1vw 0.5vw 0;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Qui-sommes-nous {
            // width: 618px;
            height: unset;
            font-family: 'SourceSansRoman';
            margin-bottom: 10px;
            font-size: 30px !important;
            font-size: 10vw !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .KoolSkools-est-une-plateforme-ducative-destine-aux-coles-o {
            
            font-family: QuicksandLight;
            font-size: 4vw !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
          .-Permettre-une-communication-efficace-avec-les-parents-Gre {
            margin: 2vw 0;
            font-family: QuicksandLight;
            font-size: 3.6vw !important;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
            padding-left: 15px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap {
            // width: 572px;
            // height: 95px;
            // margin: 15px 266px 52px 2px;
            font-family: QuicksandLight;
            font-size: 4vw !important;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
          
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-1 {
            font-weight: bold;
            margin-right: 5px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-2 {
            margin: 0 5px;
          }
          .KoolSkools-propose-des-applications-pour-iOS-et-Android-adap .text-style-3 {
            margin-left: 5px;
          }
        }
      }
    }
    .sub_page_3{
      padding: 0 9%;
      position: relative;
      padding-top: 2.6vw;
      z-index: 2;
      .lines_left_p3{
        position: absolute;
        left: -70%;
        top: 54.5%;
        width: 120%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        z-index: 1;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .background_left_p3{
        position: absolute;
        left: 0;
        top: -90px;
        width: 30%;
        padding: 156px 58px 680px 230px;
        padding: 15vw 6vw 70vw 30vw;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#64C2BE 4%,#fff 60%);
        z-index: 1;
      }
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 35px;
        flex-wrap: wrap;
        .left{
          width: 100%;
          position: relative;
          padding-top: 0;
          z-index: 1;
          > span{
            display: block;
          }
          .Vocation {
            width: 96px;
            height: 18px;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Notre-mission {
            font-family: SourceSansRoman;
            font-size: 10vw !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Nous-aspirons-encourager-les-coles-et-les-enseignants-ado {
            margin-top: 0px;
            font-family: QuicksandLight;
            font-size: 4.5vw !important;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #4c5d65;
          }
        }
        .right{
          height: 57vh;
          width: 100%;
          position: relative;
          .qrcode_content{
  
          }
          > img{
            position: absolute;
            right: -25vw;
            width: 260%;
          }
          > .background_right_p3{
            position: absolute;
            right: -11%;
            top: 0;
            width: 100vw;
            height: 299vw;
            opacity: 0.75;
            background-image: radial-gradient(circle at 100% 30%,#ffa827d6 5%,#fff0 27%);
          }
        }
      }
      .buttons{
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-top: 30px;
        width: 84%;
        z-index: 2;
        a{
          margin-right: 15px;
          img{
            width: 100%;
            height: 72px;
            object-fit: contain;
          }
        }
      }
    }
    .sub_page_4{
      padding: 0 1vw 0 0vw;
      position: relative;
      padding-top: 3vw;
      z-index: 2;
      .background_right_p4{
        display: none;
        position: absolute;
        right: -11%;
        top: -10%;
        width: 21vw;
        height: 126vw;
        opacity: 0.75;
        background-image: radial-gradient(circle at 100% 50%,#ffa827 3%,#fff 32%);
      }
      .sub_page_content{
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        .left{
          width: 77vw;
          margin: auto;
          .img_bg{
            background-image: url(${images.imqge});
            width: 100%;
            height: 65vh;
            margin: 3vw 1.2vw 3vw 3.3px;
            padding: 0 0 1px;
            object-fit: contain;
            border-radius: 14vw;
            background-color: #04041e;
            position: relative;
            background-size: 100% 100%;
            img{
              width: 654px;
              height: 1048px;
              padding: 488px 101px 486px 100px;
              object-fit: contain;
              opacity: 0.5;
              display: none;
            }
            span{
              display: block;
            }
          }
          .Utilit {
            font-family: QuicksandLight;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            position: absolute;
            left: 50%;
            top: calc(50% - 25px);
            transform: translate(-50%, -50%);
            width: max-content;
            max-width: 100%;
          }
          .Pour-tous-les-acteurs {
            margin: 0px 0 0;
            font-family: SourceSansRoman;
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            position: absolute;
            left: 50%;
            top: calc(50% + 5px);
            transform: translate(-50%, -50%);
            width: max-content;
            max-width: 100%;
          }
        }
        .right{
          width: 92%;
          margin: auto;
          margin-top: 2.5vw;
          > span{
            display: block;
          }
          .Fonctionnalits {
            width: 165px;
            height: unset;
            margin: 5vw 1vw 0.8vw 2vw;
            font-family: QuicksandLight;
            font-size: 1.29vw;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            display: none;
          }
          .Gestion-360-de-lcole {
            height: unset;
            margin: 0.8vw 1vw 1vw 2vw;
            font-family: SourceSansRoman;
            font-size: 9vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
          .Plateforme-ducative-pour-les-coles-les-parents-les-lves {
            font-family: QuicksandLight;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #6e7b81;
            margin-left: 10px;
          }
          .cards_fonctionalities{
            padding: 40px;
            display: flex;
            flex-wrap: wrap;
            .card_fonctionality{
              width: 100%;
              padding: 10px;
              display: flex;
              padding-left: 0;
              margin-left: auto;
              margin-top: 0 !important;
              .img{
                width: 60px;
                img{
                  width: 60px;
                }
              }
              .title_items{
                width: calc(100% - 6vw);
                padding-left: 10px;
                .ttle{
                  font-family: QuicksandLight;
                  font-size: 5vw;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  text-align: left;
                  color: #042a45;
                  margin-bottom: 5px;
                  margin-top: 10px;
                }
                .items{
                  font-family: QuicksandLight;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.3;
                  letter-spacing: normal;
                  text-align: left;
                  color: #6e7b81;
                  .item_content{
                    display: block;
                    text-indent: -2.22vw;
                    padding-left: 3.7vw;
                    font-family: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sub_page_5{
      padding: 0 0%;
      position: relative;
      padding-top: 50px;
      padding-bottom: 60px;
      background-image: url(${images.bg_5});
      background-size: 100% 100%;
      z-index: 2;
      .sub_page_content{
        padding: 0 9vw;
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        .card_ressource{
          width: 33.33%;
          padding: 2vw;
          width: 98% !important;
          margin: auto;
          .card_ressource_content{
            background: white;
            height: 100%;
            border-radius: 20px;
            padding: 5.5vw;
            padding-bottom: 40px;
            .img{
              width: 100%;
              img{
                max-width: 100px;
                width: 24vw;
                margin: 3vw 0;
                margin-left: 8px;
              }
            }
            .title_items{
              width: calc(100%);
              padding-left: 10px;
              margin-top: 15px;
              .ttle{
                font-family: QuicksandLight;
                font-size: 7vw;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                margin-bottom: 13px;
              }
              .items{
                font-family: QuicksandLight;
                font-size: 4.3vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.1;
                letter-spacing: normal;
                text-align: left;
                color: #6e7b81;
              }
              .item_content{
                display: block;
                text-indent: -2.22vw;
                padding-left: 3.7vw;
                font-family: unset;
              }
            }
          }
        }
        .card_ressource:not(.common_cards){
          margin-top: 8px;
        }
        .common_cards{
          width: 66.66%;
          .bg_div{
            background-image: url(${images.ressources_bg});
            background-size: 100% 100%;
            min-height: 100%;
            height: 300px;
            margin: 0;
            padding: 0 0 1px;
            object-fit: contain;
            border-radius: 6vw;
            background-color: #04041e;
            position: relative;
            span{
              display: block;
            }
            .Des-ressources-pour-toute-lcole {
              width: 80%;
              margin: auto;
              padding-top: 17%;
              font-family: SourceSansRoman;
              font-size: 8.8vw;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }
            .btn_connexion{
              background-image: url(${images.bg_btn_shadow2});
              background-size: 100% 100%;
              position: relative;
              width: 140px;;
              height: 40px;
              margin: 38px 5px 48px 51px;
              margin: 38px 5px 2.68vw 2.85vw;
              margin-left: 10%;
              object-fit: contain;
              border-radius: 25px;
              cursor: pointer;
              img{
                position: absolute;
                left: -2.5px;
                top: -2.5px;
                width: 100%;
                height: 100%;
                border-radius: 25px;
              }
              span{
                position: absolute;
                font-family: QuicksandLight;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                width: max-content;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -60%);
              }
            }
          }
        }
      }
    }
    .sub_page_6{
      padding: 0 9%;
      position: relative;
      padding-top: 8vw;
      padding-bottom: 60px;
      padding-bottom: 190px;
      padding-bottom: 0vw;
      .background_right_p6{
        position: absolute;
        left: -30vw;
        top: -45vw;
        width: 89vw;
        height: 120vw;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0vw 40%,#55bdb8 28%,#fff0 71%);
      }
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .title{
          position: absolute;
          width: 100%;
          padding: 0 2%;
          padding-right: 7%;
          left: 0;
          text-align: right;
          >div{
            text-align: right;
          }
          .title1{
            font-family: QuicksandLight;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #042a45;
            display: none;
          }
          .title2{
            font-family: SourceSansRoman;
            font-size: 10vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
            margin-top: 4.9vw;
          }
          .title3{
            >img{
              margin-left: 50px;
            }
          }
        }
        .chiffres{
          padding-top: 34vw;
          padding-left: 1vw;
          display: flex;
          justify-content: space-between;
          position: relative;
          flex-wrap: wrap;
          .chiffre_content{
            height: 400px;
            height: 68vw;
            margin-bottom: 45vw;
            padding: 0 !important;
            .chiffre{
              padding-top: 50px;
              .bg_chiffre{
                top: -6vw;
                border-radius: 20px 50px;
                border-radius: 6vw 9vw;
              }
              .bg_img{
                height: 100%;
                img{
                  height: 100%;
                }
              }
              .text_image{
                padding: 0 3px;
                .text{
                  font-weight: 600;
                  font-size: 4vw;
                  padding-top: 20px;
                }
                .img{
                  width: 85px;
                  height: 85px;
                }
              }
              .chiffre_text{
                text-align: center;
                font-size: 18.5vw;
                margin-top: 24.5vw;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .sub_page_7{
      padding: 0 9%;
      position: relative;
      padding-top: 0;
      padding-bottom: 5vw;
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        >.title{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: -1.5vw;
          .title1{
            font-family: 'SourceSansRoman';
            font-size: 9vw;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            text-align: center;
            color: #042a45;
            margin-bottom: 3vw;
          }
          .title_btn{
            margin-bottom: 4vw;
            .btn_show_plus{
              background-image: url(${images.bg_btn_orange_shadow});
              position: relative;
              width: 232px;
              height: 73px;
              width: 13vw;
              height: 4.1vw;
              object-fit: contain;
              border-radius: 2vw;
              cursor: pointer;
              img{
                position: absolute;
                left: -3px;
                top: -3px;
                width: 100%;
                height: 100%;
              }
              span{
                position: absolute;
                font-family: QuicksandLight;
                font-size: 1.12vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -60%);
              }
            }
          }
        }
        .cards{
          padding: 0 2%;
          .slick-slider{
            .slick-list{
              .slick-track{
                .slick-slide{
                  max-width: 33.33% !important;
                  margin: 0;
                  background: #fff;
                  border-radius: 30px;
                  padding: 15px;
                  &.slick-active{
                    &.slick-current{
                      background: #fff;
                      .site_date{
                        .text1{
                          span{
                            color: #6e7b81;
                            font-size: 4vw;
                          }
                        }
                        .title{
                          display: block;
                          span{
                            color: #6e7b81;
                            font-size: 3vw;
                          }
                        }
                      }
                      .texts_container{
                        .text1{
                          span{
                            font-size: 16px;
                            color: #6e7b81;
                          }
                        }
                      }
                      .btn_link{
                        width: 26vw;
                        height: 7vw;
                        a{
                          img{
                            &.orange{
                              display: none;
                            }
                            &.white{
                              display: block;
                            }
                          }
                          span{
                            color: #042a45;
                            font-size: 4vw;
                          }
                        }
                      }
                    }
                  }
                  .each-fade{
                    .image-container{
                      img{
                        width: 100%;
                      }
                    }
                    .site_date{
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      margin-top: 2vw;
                      padding: 0 2vw;
                      >div{
                        width: max-content;
                      }
                      .text1{
                        span{
                          font-family: QuicksandLight;
                          font-size: 4.6vw !important;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #6e7b81;
                        }
                      }
                      .title{
                        display: block;
                        span{
                          font-family: QuicksandLight;
                          font-size: 3vw !important;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #6e7b81;
                        }
                      }
                    }
                    .texts_container{
                      margin-top: 2vw;
                      padding: 0 2vw;
                      .text1{
                        height: 70px;
                        span{
                          font-family: SourceSansRoman;
                          font-size: 3.8vw !important;
                          font-weight: 600;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.1;
                          letter-spacing: normal;
                          text-align: left;
                          color: #042a45 !important;
                        }
                      }
                    }
                    .btn_link{
                      margin-top: 20px;
                      margin-left: 15px;
                      height: 9vw !important;
                      position: relative;
                      width: 30vw !important;
                      width: 13.2vw;
                      background-image: url(${images.bg_btn_orange_shadow});
                      background-size: 100% 100%;
                      a{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        line-height: 1;
                        img{
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: -3px;
                          top: -3px;
                          &.orange{
                            display: block;
                          }
                          &.white{
                            display: none;
                          }
                        }
                        span{
                          font-family: QuicksandLight;
                          font-size: 3.5vw !important;
                          font-weight: 600;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.4;
                          letter-spacing: normal;
                          text-align: left;
                          color: #042a45;
                          position: relative;
                          text-align: center;
                          display: block;
                          margin-top: 0.9vw;
                        }
                      }
                    }
                  }
                }
              }
            }
            .dots_classes{
              display: none !important;
              justify-content: center;
              margin-top: 40px;
              li{
                margin: 0 6px;
                width: 15px;
                margin-top: 2.5px;
                height: 15px;
                border-radius: 50%;
                background: #ffa827;
                position: relative;
                display: inline-block;
                &.slick-active{
                  margin-top: 0;
                  background: #042a45;
                  width: 20px;
                  height: 20px;
                }
                button{
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .sub_page_8{
      background-image: url(${images.bg_img_p8});
      background-size: 100% 100%;
      padding: 0 1%;
      position: relative;
      padding-top: 10vw;
      padding-bottom: 50px;
      .background_right_p8{
        position: absolute;
        right: -10vw;
        top: -5vw;
        width: 26%;
        height: 1021px;
        opacity: 0.75;
        background-image: radial-gradient(circle at 100% 50%,#d33f29 6%,#fff0 36%);
        display: none;
      }
      .bg_img_p8_1{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%; 
        display: none;
      }
      .bg_img_p8_2{
        position: absolute;
        left: -15%;
        top: -2%;
        height: unset;
        width: 60%;
      }
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 5%;
          .title1{
            width: 100%;
            margin: 2% 0;
            font-family: SourceSansRoman;
            font-size: 10vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            text-align: left;
            color: #042a45;
          }
        }
        .espaces{
          display: flex;
          justify-content: space-between;
          padding: 0 0;
          padding-top: 9.5vw;
          flex-wrap: wrap;
          .espace{
            width: 45%;
            margin: 0 2.5%;
            padding: 2vw;
            .image-container{
              height: 38vw;
              max-width: 100%;
              text-align: center;
              img{
                max-height: 38vw;
                max-width: 100%;
                height: 100%;
                margin: auto;
              }
            }
            .btn_link{
              margin: auto;
              margin-top: 4vw;
              height: 10vw;
              position: relative;
              width: 100%;
              a{
                position: absolute;
                width: 100%;
                height: 100%;
                img{
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: -2px;
                  &.orange{
                    display: block;
                  }
                }
                .text_container{
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  margin-top: 15px;
                  padding: 0 15px;
                  margin-top: 2.7vw;
                  padding: 0 0.64vw;
                  position: relative;
                  margin-left: -0;
                  >div{
                    width: max-content;
                    margin: 0 0;
                  }
                  .text1{
                    line-height: 1.8vw;
                    span{
                      font-family: QuicksandLight;
                      font-size: 3vw;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 3vw;
                      letter-spacing: normal;
                      text-align: left;
                      color: #042a45
                    }
                  }
                  .title{
                    display: block;
                    line-height: 1.8vw;
                    span{
                      font-family: QuicksandLight;
                      font-size: 3vw;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 3vw;
                      letter-spacing: normal;
                      text-align: left;
                      color: #042a45;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sub_page_9{
      padding: 0 0px;
      position: relative;
      padding-top: 0px;
      padding-bottom: 50px;
      .background_left_p9{
        position: absolute;
        left: 0;
        top: 0px;
        width: 31%;
        height: 100%;
        opacity: 0.75;
        background-image: radial-gradient(circle at 0% 50%,#55bdb8 7%,#fff 35%);
      }
      .lines_right_p9{
        position: absolute;
        right: -10%;
        top: 100px;
        width: 36%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .bg_9_image{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 41%;
        margin: 0;
        padding: 0;
        object-fit: contain;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .sub_page_content{
        padding: 0 3%;
        position: relative;
        .products{
          padding-top: 2vw;
          .product{
            width: 50%;
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-left: 28%;
            border-radius: 40px;
            box-shadow: 0 0 165.7px 33.3px rgb(0 0 0 / 5%);
            margin-top: 5%;
            padding: 20px;
            background: white;
            margin-bottom: 12px;
            height: 15vw;
            .bg_color{
              position: absolute;
              right: 0;
              top: 0;
              width: 22%;
              height: 70%;
              opacity: 0.75;
              background-image: radial-gradient(circle at 100% 0%,#5fc0bc 7%,#fff 35%);
              &.orange{
                background-image: radial-gradient(circle at 100% 0%,#ffa827 7%,#fff 35%);
              }
            }
            .image-container0{
              width: 42%;
              position: relative;
              margin-top: -10%;
              margin-left: -10%;
              .image-container1{
                width: 100%; /* Set the width of the aspect ratio box to fill its container */
                padding-bottom: 100%; /* 4:3 aspect ratio (height is 75% of the width) */
                position: relative;
                .image-container{
                  position: absolute; /* Position the content absolutely within the aspect ratio box */
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-size: 100% 100%;
                  img{
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 110%;
                    position: relative;
                  }
                }
              }
            }
            .texts{
              position: relative;
              width: 66%;
              object-fit: contain;
              border-radius: 40px;
              height: 80%;
              padding: 20px;
              .title{
                font-family: SourceSansRoman;
                font-size: 2vw;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #042a45;
              }
              .text{
                font-family: QuicksandLight;
                font-size: 1.48vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #4c5d65;
              }
              a{
                font-family: QuicksandLight;
                font-size: 1.4vw;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: right;
                color: #042a45;
                .text_container{
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 15px;
                  img{
                    margin-left: 10px;
                    width: 2vw;
                  }
                }
              }
            }
            .qrcode_content{
              .qrcode{
                width: 10vw !important;
                height: 10vw !important;
                text-align: center;
                img{
                  width: 8vw !important;
                  height: 8vw !important;
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
    .sub_page_3{
      .background_left_p3{
        display: none;
      }
    }
  }
  @media (max-width: 1199px) {
    &:before {
      box-shadow: unset !important;
  }
`;
const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(HomeComponent)));
