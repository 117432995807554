import axios from "axios";
// import { setupCache } from 'axios-cache-interceptor';
import { set, get, remove, logout } from "../storage";
import moment from 'moment'
import Compressor from 'compressorjs';
import {remove_number_in_parentheses} from "utils/utils"


const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const instance_lms = axios.create({ baseURL: process.env.REACT_APP_URL_WS });
const CancelToken = axios.CancelToken;
// const user = get("session_user");
// instance.defaults.headers['Cache-Control'] = "no-cache";

const levels_names = {
  "cp-8a7d26c0cc1c47ad81f3032e82b90a8e": "1AP",
  "ce1-8b6c3f5c48534156bcd420e71b1bd035": "2AP",
  "ce2-8d3d0240a5db45ec9b573861e5f7f6c3": "3AP",
  "cm1-94c01e5dfe134e0aa754fb7bc000e626": "4AP",
  "cm2-fb49ea6b9cf543968b73d83406d934ae": "5AP",
  "6eme-757dc9be360b48a39fa4b8a41edc7084": "6AP",
  "6eme-90b77aa8a0164268b68203a28c717a0f": "6AP",
};

document.studentsAjax = null;
document.professorsAjax = null;
document.professorsMessagingAjax = null;
document.tasksStatisticsAjax = null;
/************** Global ********* */
//Get modules
export const modules_api_handler = (object) => {
  const user = get("session_user");
  return instance
    .get('/modules_api_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const modules_store_api_handler = (data) => {
  const user = get("session_user");
  return instance
    .post('/modules_api_handler', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get profiles
export const profiles_api_handler = (object) => {
  const user = get("session_user");
  return instance
    .get('/profiles_api_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const profiles_store_api_handler = (data) => {
  const user = get("session_user");
  return instance
    .post('/profiles_api_handler', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get categories clients
export const categories_clients_api_handler = (object) => {
  const user = get("session_user");
  return instance
    .get('/categories_clients_api_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const categories_clients_store_api_handler = (data) => {
  const user = get("session_user");
  return instance
    .post('/categories_clients_api_handler', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get signing documents
export const signings_documents_api_handler = (object) => {
  const user = get("session_user");
  return instance
    .get('/signings_documents_api_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const signings_documents_store_api_handler = (data) => {
  const user = get("session_user");
  return instance
    .post('/signings_documents_api_handler', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get libraries
// export const get_library_api = (object) => {
//   const user = get("session_user");
//   return instance
//     .get('/api/libraries', {
//       headers: {Authorization: "Bearer " + user.token},
//       params: object
//     })
//     .then(response => {
//         if("school_id" in object && object.school_id)
//           set("libraries_" + object.school_id, response.data.libraries);
//         else
//           set("libraries", response.data.libraries);

//         var event = document.createEvent('Event');

//         // Nomme l'événement 'build'.
//         event.initEvent('store_libraries', true, true);
//         window.dispatchEvent(event)
//     })
//     .catch(err => {if (err.response) {
//       if(err.response.status == 401)
//           logout();
//       } throw err;});
// }
//Get sms
export const get_sms_api = (object) => {
  const user = get("session_user");
  object.page = object.page || 1;
  object.page_size = object.page_size || 20;
  return instance
    .get('/api/sms_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      if(!object.dashboard){
        var school = get("school");
        school.my_sms_conversations = response.data.my_sms_conversations;
        set("school", school);
        var event = document.createEvent('Event');

        // Nomme l'événement 'build'.
        event.initEvent('store_sms', true, true);
        window.dispatchEvent(event);
      }
      else{
        return response.data;
      }
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//whatsapp msg
export const send_whatsapp_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/whatsapp_api' ,object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const get_whatsapp_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/whatsapp_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data
      // if(!object.dashboard){
      //   var school = get("school");
      //   school.my_sms_conversations = response.data.my_sms_conversations;
      //   set("school", school);
      //   var event = document.createEvent('Event');

      //   // Nomme l'événement 'build'.
      //   event.initEvent('store_sms', true, true);
      //   window.dispatchEvent(event);
      // }
      // else{
      //   return response.data;
      // }
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get controls graded
export const get_controls_graded_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/controls_graded', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get tests diagnostic graded
export const get_tests_diagnostic_graded_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/tests_diagnostic_graded', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get filled notebook
export const get_filled_notebook_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/filled_notebook', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_export_filled_notebook = (object) => {
  const user = get('session_user');
  return instance
    .get(`export/filled_notebook`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filled_notebook' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get get_detailed_reporting_api
export const get_detailed_reporting_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/detailed_reporting', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const export_detailed_reporting_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/detailed_reporting', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'reprting_detailed' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get general_direction
export const get_general_direction_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/general_direction', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_export_general_direction = (object) => {
  const user = get('session_user');
  return instance
    .get(`export/general_direction`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'general_direction' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_export_scheduler = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/update_seance`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'emplois_du_temps_' + (object.name || '') + (object.type_export == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get levels
// const CancelToken = axios.CancelToken;
export const get_koolskools_levels = object => {
  const user = get("session_user");
  const school = get("school");
  const is_sanady_view = school && school.project_id;
  const is_tarl_professor = user.profile.is_tarl_professor;
  return instance
    .get('/api/koolskools_levels', {
      params: object
    })
    .then(response => {
      if(false && (is_sanady_view || is_tarl_professor) && response.data.levels){
        response.data.levels = response.data.levels.map(lvl => {
          lvl.name = levels_names[lvl.level_id] || lvl.name;
          return lvl;
        });
      }
      set("koolskools_levels", response.data.levels);
      return response.data.levels;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel;
export const get_levels_api = (object, updateStorage=true) => {
  if(updateStorage)
    remove('levels');
  const user = get("session_user");
  const school = get("school");
  const is_sanady_view = school && school.project_id;
  const is_tarl_professor = user.profile.is_tarl_professor;
  
  if(user && (user.profile && user.profile.is_program_system || school && school.project_id || user.is_tarl_professor)){
    object.is_program_system = "true";
  }
  cancel && cancel();
  return instance
    .get('/api/levels', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    })
    .then(response => {
      if(false && (is_sanady_view || is_tarl_professor) && response.data.levels){
        response.data.levels = response.data.levels.map(lvl => {
          lvl.name = levels_names[lvl.level_id] || lvl.name;
          return lvl;
        });
      }
      if(!updateStorage)
        return response.data;
      var levels = response.data.levels;
      levels = levels.filter(lvl => !(is_sanady_view || is_tarl_professor) || lvl.group_id > 2)
      var school_levels_for_assistant = (response.data.school_levels_for_assistant || []).filter(lvl => !(is_sanady_view || is_tarl_professor) || lvl.group_id > 2);
      if("school_id" in object){
        levels = levels.map(level =>{
          level.subjects_details = level.subjects_details.filter(subjectD => {
            return subjectD.school == object.school_id
          }).map(subjectD => {
            subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0]
            return subjectD;
          })
          return level
        })
        school_levels_for_assistant = school_levels_for_assistant.map(level =>{
          level.subjects_details = level.subjects_details.filter(subjectD => {
            return subjectD.school == object.school_id
          }).map(subjectD => {
            subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0]
            return subjectD;
          })
          return level
        })
      }
      else{
        //var professors_subjects = get("professor_subjects");
        levels = levels.map(level =>{
          //var sds = [];
          level.subjects_details = level.subjects_details.map(subjectD => {
            subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0];
            //if(professors_subjects && level.subjects.some(sb => (professors_subjects || []).indexOf(sb.subject_id))){
              //sds.push(subjectD);
            //}
            return subjectD;
          });
          //if(professors_subjects){
            //level.subjects_details = sds;
          //}
          return level;
        });
        school_levels_for_assistant = school_levels_for_assistant.map(level =>{
          //var sds = [];
          level.subjects_details = level.subjects_details.map(subjectD => {
            subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0];
            //if(professors_subjects && level.subjects.some(sb => (professors_subjects || []).indexOf(sb.subject_id))){
              //sds.push(subjectD);
            //}
            return subjectD;
          });
          //if(professors_subjects){
            //level.subjects_details = sds;
          //}
          return level;
        });
      }
      school_levels_for_assistant.sort((a,b) => b.order < a.order ? 1 : -1);
      var event = document.createEvent('Event');
      set("school_levels_for_assistant", school_levels_for_assistant);
      set("levels", levels)

    // Nomme l'événement 'build'.
      event.initEvent('store_levels', true, true);
      window.dispatchEvent(event);
      var data = {
        'school_id': object.school_id
      }
      get_chapters_competencies_api(data);
      // levels.forEach(level => {
      //   level.subjects.forEach(subject => {
      //     var data = {
      //       'school_id': object.school_id
      //     }
      //     get_chapters_competencies_api(level.level_id, subject.subject_id, data)
      //   })
      // })
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const check_server_connection = (object) => {
  return instance
    .get('/api/check_server_connection', {
      headers: {},
      params: object || {},
    }).then(response => {
      return response.data;
    }).catch(err => {
      return err.response.data;
    });
};

export const check_group_version = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/check_group_version', {
      headers: {Authorization: "Bearer " + user.token},
      params: object || {},
    }).then(response => {
      return response.data;
    }).catch(err => {
      return err.response.data;
    });
};

export const check_school_sms_sent_status = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/check_school_sms_sent_status', {
      headers: {Authorization: "Bearer " + user.token},
      params: object || {},
    }).then(response => {
      return response.data;
    }).catch(err => {
      return err.response && err.response.data;
    });
};

export const get_school_schlar_yar = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/get_school_schlar_yar', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    }).then(response => {
      return response.data;
    }).catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;
    });
};
export const get_all_levels_api = () => {
  var user = get("session_user");
  instance.get('/api/students_all_levels', {
      headers: {Authorization: "Bearer " + user.token},
      params: {},
    })
    .then(response => {
      var all_levels = response.data.levels;
      var levels_groups = response.data.levels_groups;
      set("all_levels", all_levels);
      set("level_groups", levels_groups);
    })
    .catch(err => {if (err.response) {
      } throw err;});
};

export const get_chapters_list_api = (level, subject, data) => {
  const user = get("session_user");
  return instance
    .get(`/api/chapters_list/${level}/${subject}`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    }).catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;
    });
}

export const get_competencies_list_api = (chapter, data) => {
  const user = get("session_user");
  return instance
    .get(`/api/competencies_list/${chapter}`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    }).catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;
    });
}

export const get_chapters_competencies_api = (data, return_res) => {
  const user = get("session_user");
  return instance
    .get(`/api/chapters_competencies`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      // console.log('response.data.lessons',response.data.lessons)
      if(return_res)
        return response.data;
      var levels_chapters = response.data.chapters,
        competencies = response.data.competencies_lessons,
        levels_lessons = []/*response.data.lessons*/,
        levels = get('levels') || [];
      levels = levels.map(lvl => {
        var l_chapter = false;
        if(levels_chapters.filter(l => l.level_id == lvl.level_id).length > 0)
          l_chapter = levels_chapters.filter(l => l.level_id == lvl.level_id)[0];
        lvl.subjects = lvl.subjects.map(sub => {
          sub.chapters = [];
          sub.competencies = [];
          sub.lessons = [];
          if(l_chapter && l_chapter.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.chapters = l_chapter.subjects.filter(s => s.subject_id == sub.subject_id)[0].chapters;
            // sub.competencies = competencies;
            // sub.lessons =lessons;
          }

          return sub;
        });
        lvl.global_subjects = (lvl.global_subjects || []).map(sub => {
          sub.chapters = [];
          sub.competencies = [];
          sub.lessons = [];
          if(l_chapter && l_chapter.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.chapters = l_chapter.subjects.filter(s => s.subject_id == sub.subject_id)[0].chapters;
          }
          return sub;
        });
        if(lvl.subjects_details){
          lvl.subjects_details = lvl.subjects_details.map(subjectD => {
            if(subjectD.subject)
              subjectD.subject = lvl.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0]
            return subjectD;
          })
        }
        var l_lessons = false;
        if(levels_lessons.filter(l => l.level_id == lvl.level_id).length > 0)
          l_lessons = levels_lessons.filter(l => l.level_id == lvl.level_id)[0];
        lvl.subjects = lvl.subjects.map(sub => {
          if(l_lessons && l_lessons.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.lessons = l_lessons.subjects.filter(s => s.subject_id == sub.subject_id)[0].lessons;
            // sub.competencies = competencies;
            // sub.lessons =lessons;
          }
          return sub;
        });
        lvl.global_subjects = (lvl.global_subjects || []).map(sub => {
          if(l_lessons && l_lessons.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.lessons = l_lessons.subjects.filter(s => s.subject_id == sub.subject_id)[0].lessons;
          }
          return sub;
        });
        if(lvl.subjects_details){
          lvl.subjects_details = lvl.subjects_details.map(subjectD => {
            if(subjectD.subject)
              subjectD.subject = lvl.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0]
            return subjectD;
          })
        }
        return lvl;
      })
      set("levels", levels);
      let event = new CustomEvent("refresh_chapters");
      document.dispatchEvent(event);
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const get_subject_bibliotheque_api = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/subject_bibliotheque`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Google search
export const google_search_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/google_search', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//control_archived_api
export const toggle_control_archived_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/toggle_control_archived_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Youtube search
export const youtube_search_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/youtube_videos', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
/************************ */



/***********profile*******************/
export const save_profile_api = (data) => {
  const user = get("session_user");
  return instance
    .post('/edit_prof', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

/***********send sms*******************/
export const send_sms_api = (data) => {
  const user = get("session_user");
  return instance
    .post('/school_messages_txt',
    data,
    {
        headers: {
            Authorization: "Bearer " + user.token
        }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const save_profile_image_api = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/user/v1/accounts/${user.profile.username}/image`, data, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {

      }
      throw err;
    });
}

export const delete_profile_image_api = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/user/v1/accounts/${user.profile.username}/image`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

/********************************/

/******** xblock api *******/
export const student_view_xblock_api = (location, school_id) => {
  const user = get("session_user");
  var school_url = "";
  if(school_id){
    school_url = "/" + school_id
  }
  return instance
  .get(`/api/xblock/${location}/student_view`, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const studio_view_xblock_api = (location, school_id) => {
  const user = get("session_user");
  var school_url = "";
  if(school_id)
    school_url = "/" + school_id
  return instance
    .get(`/api/xblock/${location}/studio_view${school_url}`, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const professor_seances = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/professor_seances`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_information = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_professor_information`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_information_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_professor_information`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const student_recovery_api_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/student_recovery_api`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const diagnostic_test_comment_api_get = (data) => {
  const user = get("session_user");
  return instance
    .get(`/diagnostic_test_comment_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const diagnostic_test_comment_api_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/diagnostic_test_comment_api`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const student_recovery_api_get = (data) => {
  const user = get("session_user");
  return instance
    .get(`/student_recovery_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_professor_absences = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_professor_absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_absences_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_professor_absences`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const schools_pilotages_visits = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/schools_pilotages_visits`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_pilotages_visits_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/schools_pilotages_visits`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const schools_pilotages_contacts = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/schools_pilotages_contacts`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_pilotages_contacts_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/schools_pilotages_contacts`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_assistant_absences = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_assistant_absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_assistant_absences_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_assistant_absences`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_staff_absences = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_staff_absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_staff_absences_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_staff_absences`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_student_establishments_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/schools_student_handler`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_export_absences = (object) => {
  const user = get('session_user');
  return instance
    .get(`/export/absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'absences-' + moment().format('YYYY-MM-DD') + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const homework_follow_up_api_export = (object) => {
  const user = get('session_user');
  return instance
    .get(`/schools_homework_follow_up_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'homework_follow_up-' + moment().format('YYYY-MM-DD') + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const school_export_absences_recording = (object) => {
  const user = get('session_user');
  return instance
    .get(`/school/absences_recording_by_day`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'absences-recording-' + moment().format('YYYY-MM-DD') + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}

export const school_export_students_absences_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`/export/absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (object.file_name || 'absences') + (object.export_format == "pdf" ? ".pdf" : '.xlsx')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const school_export_students_absences_stats = (object) => {
  const user = get('session_user');
  return instance
    .get(`/export/students_absences_stats_export`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (object.file_name || 'absences') + (object.export_format == "pdf" ? ".pdf" : '.xlsx')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}

export const create_xblock_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/api/xblock/', object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const edit_xblock_api = (location, object, cancel_api) => {
  const user = get("session_user");
  if(cancel_api === true){

    if(document.save_xblock){
      document.save_xblock.cancel();
    }
    document.save_xblock = axios.CancelToken.source();
  }
  var data = {
    headers: {Authorization: "Bearer " + user.token}
  };
  if(cancel_api === true && document.save_xblock){
    data.cancelToken = document.save_xblock.token;
  }
  return instance
    .post(`/api/xblock/${location}`, object , data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const save_passed_sec_slide = (token, location, username, obj) => {
  return instance
    .post(`/api/save_passed_sec_slide/${location}/${username}`, obj, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const edit_xblock_order_api = (object) => {
  const user = get("session_user");
  return instance
    .post(`/api/xblock_order/order_course_items`, object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const answer_xblock_api = (courseId, usage_id, object) => {
  const user = get("session_user");
  return instance_lms
    .post(`/courses/${courseId}/xblock/${usage_id}/handler/done_common`, object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {
      try{
        var btn_validation = document.querySelector(".xblock_viewer [data-usage-id='"+usage_id+"']").closest(".xblock_viewer").querySelector(".answer_exam.disabled");
        if(btn_validation){
          btn_validation.classList.remove('disabled');
        }
      }
      catch{

      }
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const delete_xblock_api = (location, object) => {
  const user = get("session_user");
  object = object || {}
  return instance
    .delete(`/api/xblock/${location}`, {
      headers: {Authorization: "Bearer " + user.token},
      data : object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const share_xblock_api = (type, object) => {
  const user = get('session_user');
  return instance
    .post(`/xblocks_share/${type}`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// export const accept_xblock_api = (type, object) => {
//   const user = get('session_user');
//   return instance
//     .post(`/xblocks_share/${type}`, object, {
//       headers: {Authorization: "Bearer " + user.token}
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };

// export const overwrite_xblock_api = (type, object) => {
//   const user = get('session_user');
//   return instance
//     .post(`/xblocks_share/${type}`, object, {
//       headers: {Authorization: "Bearer " + user.token}
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };
/**************************************/

/************Seances by day***********************/
export const seancesDay = (object) => {
    const user = get('session_user');
    return instance
      .get('/school/schedule_by_day', {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_absences_recording;
/************absences recordings by day***********************/
export const absencesRecordingDay = (object) => {
    const user = get('session_user');
    cancel_absences_recording && cancel_absences_recording();
    return instance
      .get('/school/absences_recording_by_day', {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel_absences_recording = c;
        })
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
//Absences : Calls
// let cancel_absences;
export const absencesApi = (object, cancel_absences) => {
  const user = get('session_user');
  if(cancel_absences === true){
    // cancel && cancel_students && cancel_students();

    if(document.studentsAjax){
      document.studentsAjax.cancel();
    }
    document.studentsAjax = axios.CancelToken.source();
  }
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(cancel_absences === true && document.studentsAjax){
    data.cancelToken = document.studentsAjax.token;
  }
  return instance
    .get('/schools_absence_handler', data)
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        else
          throw err;
      } throw err;
    });
};
export const homework_follow_up_api_get = (object, cancel_api) => {
  const user = get('session_user');
  if(cancel_api === true){

    if(document.studentsAjax){
      document.studentsAjax.cancel();
    }
    document.studentsAjax = axios.CancelToken.source();
  }
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(cancel_api === true && document.studentsAjax){
    data.cancelToken = document.studentsAjax.token;
  }
  return instance
    .get('/schools_homework_follow_up_handler', data)
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        else
          throw err;
      } throw err;
    });
};

export const absences_post_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_absence_handler', object,
      {
          headers: {
              Authorization: "Bearer " + user.token
          }
      }
    )
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const homework_follow_up_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_homework_follow_up_handler', object,
      {
          headers: {
              Authorization: "Bearer " + user.token
          }
      }
    )
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/************************************************/
//ip address access
export const check_ip_address_access = (object) => {
  const user = get('session_user');
  return instance
      .get('/check_ip_address_access', {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Activities
export const activities_api = (object, is_post) => {
  const user = get('session_user');
  if(is_post){


    return instance.post(`/activities_api_handler`,
      object,
      {
          headers: {
              Authorization: "Bearer " + user.token
          }
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  else{
    return instance
      .get('/activities_api_handler', {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
};
/************************************************/

/************************************************/
//subject themes
export const subject_themes_api_store = (object) => {
  const user = get('session_user');
  return instance.post(`/subject_themes_api_handler`,
    object,
    {
        headers: {
            Authorization: "Bearer " + user.token
        }
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};
export const subject_themes_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/subject_themes_api_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};
/************************************************/

/*************** posts *******************/
export const posts_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_posts_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_posts;
export const posts_api_list = (object) => {
  const user = get('session_user');
  cancel_posts && cancel_posts();
  return instance
    .get('/schools_posts_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_posts = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/*************** maintenance monitorings *******************/
export const maintenance_monitorings_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/maintenance_monitorings', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_maintenance_monitorings;
export const maintenance_monitorings_api_list = (object) => {
  const user = get('session_user');
  cancel_maintenance_monitorings && cancel_maintenance_monitorings();
  return instance
    .get('/maintenance_monitorings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_maintenance_monitorings = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/*************** scholar_activities *******************/
export const scholar_activities_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/scholar_activities', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_scholar_activities;
export const scholar_activities_api_list = (object) => {
  const user = get('session_user');
  cancel_scholar_activities && cancel_scholar_activities();
  return instance
    .get('/scholar_activities', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_scholar_activities = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/************************************************/

/******** exercise types *******/
export const exercise_types_api = () => {
  const user = get('session_user');
  return instance
    .get('/exercises_categories_handler', {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************/

/******** exercises api *******/
export const exercises_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** exercises fmps api *******/
export const exercises_fmps_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_fmps_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exercises_fmps_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/exercises_fmps_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** exercise api data *******/
export const exercise_data_api = (exercise_id, data) => {
  const user = get('session_user');
  return instance_lms.get(`/api/exercise/${exercise_id}`,{
      headers: {Authorization: "Bearer " +user.token},
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
};

/********* exercise api done *********/
export const exercise_handle_api = (courseId, usageId, handlerName, data) => {
  const user = get('session_user');
  return instance_lms.post(`/courses/${courseId}/xblock/${usageId}/handler/${handlerName}`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {
      return JSON.parse(response.data);
    })
    .catch(err => { if (err.response) {} throw err;});
};

/******** activities preschools *******/
export const activities_preschools_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/activities_preschools_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const activities_preschools_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/activities_preschools_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};


/******** documents printing *******/
export const document_printing_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/document_printing_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const document_printing_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/document_printing_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};


export const descriptions_annual_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/descriptions_annual_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const descriptions_annual_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/descriptions_annual_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** ares learnings *******/
export const areas_learnings_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/areas_learnings_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const areas_learnings_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/areas_learnings_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** activities kids *******/
export const activities_kids_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get('/activities_kids_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const activities_kids_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/activities_kids_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** kls_fields_export_api *******/
export const fields_export_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/kls_fields_export_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const fields_export_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/kls_fields_export_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};
/******** kls_textbooks_subjects_export_api *******/
export const textbooks_subjects_export_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/kls_textbooks_subjects_export_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};
export const textbooks_subjects_export_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/kls_textbooks_subjects_export_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** extra scolaire activities *******/
export const extra_scolaire_activities_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/extra_scolaire_activities_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

export const extra_scolaire_activities_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/extra_scolaire_activities_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};
export const extra_scolaire_activities_api_export = (object, callback) => {
  const user = get('session_user');
  return instance
    .get(`/extra_scolaire_activities_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      if(callback){
        callback();
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'extra_scolaire_activities' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};

/******** activities themes get api *******/
export const activity_theme_get_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/activity_theme_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** activities themes post api *******/
export const activity_theme_post_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/activity_theme_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};
/******** exercises shared count api *******/
export const exercises_shared_count = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_shared_count',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};



/******** exercises koolskools api *******/
export const exercises_kls_store_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/exercises_kls_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};


export const exercises_kls_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_kls_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** pedagogie_statistics koolskools api *******/
let cancel_synthese_statistics_api;
export const synthese_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_synthese_statistics_api){
    cancel_synthese_statistics_api();
    cancel_synthese_statistics_api = undefined;
  }
  return instance
    .get('/synthese_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_synthese_statistics_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** pedagogie_statistics koolskools api *******/

// let cancel_controls_stats_api;
export const controls_statistics_api = (object) => {
  const user = get('session_user');
  // if(cancel_controls_stats_api){
  //   cancel_controls_stats_api();
  //   cancel_controls_stats_api = undefined;
  // }
  return instance
    .get('/controls_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      // cancelToken: new CancelToken(function executor(c) {
      //   cancel_controls_stats_api = c;
      // })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


let cancel_remediation_competencies_statistics_api;
export const remediation_competencies_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_remediation_competencies_statistics_api){
    cancel_remediation_competencies_statistics_api();
    cancel_remediation_competencies_statistics_api = undefined;
  }
  return instance
    .get('/remediation_competencies_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_remediation_competencies_statistics_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


let cancel_school_remediation_competencies_statistics_api;
export const school_remediation_competencies_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_school_remediation_competencies_statistics_api){
    cancel_school_remediation_competencies_statistics_api();
    cancel_school_remediation_competencies_statistics_api = undefined;
  }
  return instance
    .get('/school_remediation_competencies_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_school_remediation_competencies_statistics_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};



let cancel_remediation_statistics_api;
export const remediation_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_remediation_statistics_api){
    cancel_remediation_statistics_api();
    cancel_remediation_statistics_api = undefined;
  }
  return instance
    .get('/remediation_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_remediation_statistics_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


let cancel_notes_control_stats_api;
export const notes_controls_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_notes_control_stats_api){
    cancel_notes_control_stats_api();
    cancel_notes_control_stats_api = undefined;
  }
  return instance
    .get('/notes_controls_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_notes_control_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_notes_stats_api;
export const notes_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_notes_stats_api){
    cancel_notes_stats_api();
    cancel_notes_stats_api = undefined;
  }
  return instance
    .get('/notes_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_notes_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_stats_api;
export const pedagogie_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_students_tasks_not_done_api_get;
export const students_tasks_not_done_api_get = (object) => {
  const user = get('session_user');
  if(cancel_students_tasks_not_done_api_get){
    cancel_students_tasks_not_done_api_get();
    cancel_students_tasks_not_done_api_get = undefined;
  }
  return instance
    .get('/students_tasks_not_done_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_students_tasks_not_done_api_get = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const students_tasks_not_done_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/students_tasks_not_done_api', object,{
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const students_tasks_not_done_alert_creation_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/students_tasks_not_done_alert_creation_api', object,{
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      console.log(err.response) }});
};
let cancel_zoom_courses_stats_api;
export const zoom_courses_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_zoom_courses_stats_api){
    cancel_zoom_courses_stats_api();
    cancel_zoom_courses_stats_api = undefined;
  }
  return instance
    .get('/zoom_courses_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_zoom_courses_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_controls_graded_api;
export const controls_graded_api = (object) => {
  const user = get('session_user');
  if(cancel_controls_graded_api){
    cancel_controls_graded_api();
    cancel_controls_graded_api = undefined;
  }
  return instance
    .get('/controls_graded_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_controls_graded_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_tests_diagnostic_graded_api;
export const tests_diagnostic_graded_api = (object) => {
  const user = get('session_user');
  if(cancel_tests_diagnostic_graded_api){
    cancel_tests_diagnostic_graded_api();
    cancel_tests_diagnostic_graded_api = undefined;
  }
  return instance
    .get('/tests_diagnostic_graded_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_tests_diagnostic_graded_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_statistics_professor_api;
export const pedagogie_statistics_professor_api = (object) => {
  const user = get('session_user');
  if(cancel_statistics_professor_api){
    cancel_statistics_professor_api();
    cancel_statistics_professor_api = undefined;
  }
  return instance
    .get('/statistics_professor_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_statistics_professor_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_tasks_result_by_user_api;
export const tasks_result_by_user_api = (object) => {
  const user = get('session_user');
  if(cancel_tasks_result_by_user_api){
    cancel_tasks_result_by_user_api();
    cancel_tasks_result_by_user_api = undefined;
  }
  return instance
    .get('/tasks_result_by_user_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_tasks_result_by_user_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** students_discipline_statistics_api koolskools api *******/
export const students_discipline_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  // if(cancel_stats_api){
  //   cancel_stats_api();
  //   cancel_stats_api = undefined;
  // }
  return instance
    .get('/students_discipline_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** vie_scolaire_statistics koolskools api *******/
export const vie_scolaire_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/vie_scolaire_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** students_sanady_statistics_api koolskools api *******/
let students_sanady_stats_api_action = {};
export const students_sanady_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(object.action && students_sanady_stats_api_action[object.action]){
    students_sanady_stats_api_action[object.action]();
    students_sanady_stats_api_action[object.action] = undefined;
  }
  return instance
    .get('/students_sanady_stats_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        students_sanady_stats_api_action[object.action] = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** missing students koolskools api *******/
//let missing_students_api_action = {};
export const missing_students_api_get = (object) => {
  const user = get('session_user');
  //if(object.action && missing_students_api_action[object.action]){
  //  missing_students_api_action[object.action]();
  //  missing_students_api_action[object.action] = undefined;
  //}
  return instance
    .get('/missing_students_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      //cancelToken: new CancelToken(function executor(c) {
      //  missing_students_api_action[object.action] = c;
      //})
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const missing_students_api_post = (object) => {
  const user = get('session_user');
  //if(object.action && missing_students_api_action[object.action]){
  //  missing_students_api_action[object.action]();
  //  missing_students_api_action[object.action] = undefined;
  //}
  return instance
    .post('/missing_students_api' ,object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_students_departures_stats_api;
/******** students_scholar_years_with_departure_api koolskools api *******/
export const students_scholar_years_with_departure_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_students_departures_stats_api){
    cancel_students_departures_stats_api();
    cancel_students_departures_stats_api = undefined;
  }
  return instance
    .get('/students_scholar_years_with_departure_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_students_departures_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** vie_scolaire_statistics koolskools api for students gone *******/
export const vie_scolaire_statistics_students_gone_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/vie_scolaire_statistics_students_gone_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** pedagogie_statistics koolskools api for courses *******/
export const pedagogie_statistics_courses_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/pedagogie_statistics_courses_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** pedagogie_statistics koolskools api for tasks *******/
export const pedagogie_statistics_tasks_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/pedagogie_statistics_tasks_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** pedagogie_statistics koolskools api for tasks *******/
export const pedagogie_statistics_students_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/pedagogie_statistics_students_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** vie_scolaire_transport_cantine_garde_statistics_api *******/
export const vie_scolaire_transport_cantine_garde_statistics_api = (object) => {
  var user = get("session_user");
  return instance
    .get('/vie_scolaire_transport_cantine_garde_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** vie_scolaire_statistics_infirmary_passage koolskools api *******/
export const vie_scolaire_statistics_infirmary_passage_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  return instance
    .get('/vie_scolaire_statistics_infirmary_passage_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** vie_scolaire_statistics koolskools api *******/
export const competencies_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/competencies_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** finance_statistics_api koolskools api *******/
export const finance_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/finance_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


/******** students by classes api *******/
export const students_by_classes = (object) => {
  const user = get('session_user');
  return instance
    .get('/students_by_classes',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      response.data.last_filter_key = object.last_filter_key;
      return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** students by activities classes api *******/
export const students_by_activities_classes = (object) => {
  const user = get('session_user');
  return instance
    .get('/students_by_activities_classes',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      response.data.last_filter_key = object.last_filter_key;
      return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exercises_handler_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/exercises_handler' ,object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const send_sms_message_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/school_messages_txt' ,object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const send_sms_message_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/school_messages_txt' ,{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************/

/************* formations api ***********/
export const formations_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/formations_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const formation_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/formations_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const professor_annual_reviews_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/professor_annual_reviews',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const professor_annual_reviews_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/professor_annual_reviews', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* annual_reviews api ***********/
export const annual_reviews_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/annual_reviews_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const annual_reviews_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/annual_reviews_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* publipostage api ***********/
export const publipostage_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/publipostage_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const publipostage_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/publipostage_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* budget api ***********/
export const budget_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/budget_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const budget_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/budget_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* books api ***********/
export const books_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/books_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const book_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/books_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


/*************** sub chapters api *************/
export const sub_chapters_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/sub_chapters_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};


/*************** purchases api *************/
export const purchases_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/purchases_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

/*************** courses api *************/
export const courses_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/course_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};
// *************** courses shared count***************
export const courses_shared_count = (object) => {
  const user = get('session_user');
  return instance
    .get('/courses_shared_count',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      }  throw err;});
};

export const courses_handler_share_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_course',object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/add_course', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exams_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/api/exams', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_responses_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/api/exam_responses', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/exams', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const classes_subjects_exams_calendar_api = (object) => {
  const user = get('session_user');
  return instance
    .get(`/api/classes_subjects_exams_calendar` ,{
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const sort_subjects_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/sort_subjects', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const sort_chapters_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/sort_chapters', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const convert_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_convert_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const img_from_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_create_image_from_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const slide_from_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_create_slide_from_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_item_api = (course_id) => {
  const user = get('session_user');
  return instance
    .get(`/api/course_item/${course_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_item_stats_api = (course_id, object) => {
  const user = get('session_user');
  return instance
    .get(`/api/course_item_stats/${course_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_item_api = (exam_id, params) => {
  const user = get('session_user');
  return instance
    .get(`/api/exam_item/${exam_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************************************/


export const submit_exercise = (object) => {
  const user = get('session_user');
  return instance
    .post('/submit_exercise', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/****************resource****************/
// list resources or list folders
/*export const resources_list_api = (object) => {
  const user = get('session_user'),
      school = get('school');
  var libraries = get('libraries');

  if(school && !user.profile.is_formator)
    libraries = get('libraries_' + school.id);
  return instance
  .get(`/api/assets/${libraries.mission.course_key}/`,{
    headers: {Authorization: "Bearer " + user.token},
    params: object
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

};*/


export const delete_resources_api = (asset) => {
  const user = get('session_user');
  //     school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);
  return instance
  .delete(`/api/assets`,{
    headers: {Authorization: "Bearer " + user.token}
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};

// delete Folder
export const delete_folder_api = (object) => {
  const user = get("session_user");

  return instance
  .post(`/api/assets`, object, {
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
// create Folder or Add resource
export const create_folder_resource = (object) => {
  const user = get("session_user"),
  school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/assets`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data'
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*************************************** */
/*******  upload to s3************/
export const upload_asset_s3_api = (object) => {
  const user = get("session_user");
  // object.set("file", file_upload, this.user.username + '_' + (new Date()).getTime() + '_' + file_upload.name);
  if(object.get('file').type.indexOf('image/') >= 0){
    return new Promise((resolve, reject) => {
        new Compressor(object.get('file'), {
          quality: 1,
          maxWidth: 1600,
          maxHeight: 1600,
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {

            // The third parameter is required for server
            object.set('file', result, result.name);

            // Send the compressed image file to server with XMLHttpRequest.
            return instance
            .post(`/api/assets_storage`, object, {
              headers: {
                Authorization: "Bearer " + user.token,
                "Content-Type": 'multipart/form-data'
              },
            })
            .then(response => {
              resolve(response.data)
            })
            .catch(err => { if (err.response) {
              if(err.response.status == 401)
                  logout();
              } reject(err);});
          },
          error(err) {
            reject(err);
          },
        });
    });
  }
  else{
    return instance
          .post(`/api/assets_storage`, object, {
            headers: {
              Authorization: "Bearer " + user.token,
              "Content-Type": 'multipart/form-data'
            },
          })
          .then(response => {
            return response.data
          })
          .catch(err => { if (err.response) {
            if(err.response.status == 401)
                logout();
            } throw err;});
  }
}
/*********************************/

/****************RDV Report****************/
// list reports
export const reports_list_api = (object) => {
  const user = get("session_user");

  return instance.get(`/student_rdv_reports`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const reports_list_api_post = (object) => {
  const user = get("session_user");

  return instance.post(`/student_rdv_reports`, object, {
      headers: {
        Authorization: "Bearer " + user.token
      },
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const school_export_reports_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`/student_rdv_reports`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'rendez-vous' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
// list behaviours
export const behaviours_list_api = (object) => {
  const user = get("session_user");

  return instance.get(`/student_behaviours`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const behaviours_list_api_post = (object) => {
  const user = get("session_user");

  return instance.post(`/student_behaviours`, object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const conseils_list_api = (object) => {
  const user = get("session_user");

  return instance.get(`/student_conseils`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// list insurance records
export const insurance_records_list_api = (object) => {
  const user = get("session_user");

  return instance.get(`/student_insurance_records`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_export_insurance_records_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`/student_insurance_records`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'insurance_records' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const school_export_behaviours_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`/student_behaviours`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'incidents' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const behaviors_types_api = (object) => {
  const user = get("session_user");

  return instance.get(`/schools_behavior_type`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/*************************************** */


/****************user documents****************/
// list user documents or list folders
export const user_documents_list_api = (object) => {
  const user = get('session_user'),
      school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);

  return instance
  .get(`/api/user_docs`,{
    headers: {Authorization: "Bearer " + user.token},
    params: object
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};


export const delete_user_document_api = (doc) => {
  const user = get('session_user');
  //     school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);

  return instance
  .delete(`/api/user_docs`,{
    headers: {Authorization: "Bearer " + user.token}
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};
// create doc
export const create_user_document_resource = (object) => {
  const user = get("session_user");
  // school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/user_docs`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data'
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
// create doc
export const edit_sudent_document_resource = (object, id) => {
  const user = get("session_user");
  // school = get('school');
  // var libraries = get('libraries');

  // if(school)
  //   libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/user_docs/`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data'
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*************************************** */


/***********school***************/
//School students
let cancel_students;
export const schoolStudents = (object, cancel_students) => {
  const user = get("session_user");

  // cancel_students && cancel_api && cancel_students();
  if(cancel_students === true){
    // cancel && cancel_students && cancel_students();

    if(document.studentsAjax){
      document.studentsAjax.cancel();
    }
    document.studentsAjax = axios.CancelToken.source();
  }
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(cancel_students === true && document.studentsAjax){
    data.cancelToken = document.studentsAjax.token;
  }
  return instance
    .get('/schools_student_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schoolStudents2 = object => {
  const user = get("session_user");
  let data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/students_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schoolStudentsTutorsMassarExport = (object, file_name, callback, filesErrors) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    // params: object,
  };
  return instance
  .post(`/schools_student_handler`, object, {
    headers: {Authorization: "Bearer " + user.token,Accept: 'application/json',
    'Content-Type': 'application/json'},
    responseType: 'blob',
  })
  .then(response => {
    if(response.headers.files_errors){
      filesErrors(JSON.parse(response.headers.files_errors));
    }
    const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name); //or any other extension
    document.body.appendChild(link);
    link.click();
    if(callback){
      callback();
    }
  });
  return instance
    .get('/schools_student_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/***********school***************/
//School parents
export const get_parents_notes_exclude_api = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_parents_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const get_student_periods_comments_api = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/student_periods_comments_api', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const schools_diagnostic_test_get = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_diagnostic_test', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const post_student_periods_comments_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/student_periods_comments_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const post_parents_notes_exclude_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_parents_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// liste effectif classes
export const schoolEffectif = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_effectif_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// liste students discounts
let cancel_discounts_list_api;
export const schoolDiscountsList = (object, cancel_api) => {
  const user = get("session_user");
  cancel_discounts_list_api && cancel_api && cancel_discounts_list_api();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel_discounts_list_api = c;
    })
  };
  return instance
    .get('/schools_discounts_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// list effectif par level
export const schoolEffectiflevel = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_effectif_level_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// list effectif par cycle
export const schoolEffectifCycle = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_effectif_cycle_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schoolAllUsers = (object) => {
  const user = get("session_user");

  if(document.schoolAllUsersAjax){
    document.schoolAllUsersAjax.cancel();
  }
  document.schoolAllUsersAjax = axios.CancelToken.source();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(document.schoolAllUsersAjax){
    data.cancelToken = document.schoolAllUsersAjax.token;
  }
  return instance
    .get('/schools_all_users_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_students_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_student_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_import_books_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/books_list_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_parents_school_api_get;
//school parents
export const school_parents_list_api = (object, cancel_api) => {
  const user = get("session_user");
  cancel_parents_school_api_get && cancel_api && cancel_parents_school_api_get();
  return instance
    .get('/schools_parent_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_parents_school_api_get = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school connection_tracking
export const school_connection_tracking_list_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_connection_tracking_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school parents
export const school_parents_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_parent_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school professors
export const school_professors_list_api = (object, for_messaging) => {
  const user = get("session_user");
  // if(for_messaging){
  //   if(document.professorsMessagingAjax){
  //     document.professorsMessagingAjax.cancel();
  //   }
  //   document.professorsMessagingAjax = axios.CancelToken.source();
  // }
  // else{
  //   if(document.professorsAjax){
  //     document.professorsAjax.cancel();
  //   }
  //   document.professorsAjax = axios.CancelToken.source();
  // }
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  // data.cancelToken = (for_messaging ? document.professorsMessagingAjax : document.professorsAjax).token;
  return instance
    .get('/schools_professor_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professors_list_api2 = (object, for_messaging) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_professor_handler2', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school surveys
export const school_surveys_list_api = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_survey_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_surveys_post_api = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
  };
  return instance
    .post('/schools_survey_handler', object, data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//professor professors
export const professor_professors_list_api = (object) => {
  const user = get("session_user");
  // if(document.professorsAjax){
  //   document.professorsAjax.cancel();
  // }
  // document.professorsAjax = axios.CancelToken.source();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  // if(document.professorsAjax){
  //   data.cancelToken = document.professorsAjax.token;
  // }
  return instance
    .get('/professor_professors_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professors_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_professor_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// list schools
export const get_list_schools = data => {
  const user = get("session_user");
  return instance
    .get('/api/schools', {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//list post
export const get_list_schools_post = data => {
  const user = get("session_user");
  return instance
    .post('/api/schools', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// list programs
export const api_programs_get = data => {
  const user = get("session_user");
  return instance
    .get('/api/programs', {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const api_programs_post = data => {
  const user = get("session_user");
  return instance
    .post('/api/programs', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// list projects
export const api_projects_get = data => {
  const user = get("session_user");
  return instance
    .get('/api/projects', {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const api_projects_post = data => {
  const user = get("session_user");
  return instance
    .post('/api/projects', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//list sub groups
export const get_list_schools_sub_groups = data => {
  const user = get("session_user");
  return instance
    .get('/api/schools_sub_groups', {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const schools_chap_details_api_list = (data) => {
  const user = get("session_user");
  return instance
    .post('/api/schools_chap_details_api_list', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const get_list_details = (data) => {
  const user = get("session_user");
  return instance
    .post('/api/schools_details', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// Add school
export const add_school = (token, object) => {
  return instance
    .post('/api/add_school', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// schools_critical_subjects_handler
export const schools_critical_subjects_handler = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_critical_subjects_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      } throw err;});
}

export const schools_critical_subjects_handler_store = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_critical_subjects_handler', object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      // if(err.response.status == 401)
          // logout();
      } throw err;});
}

// subjects school group
export const api_school_group_subjects = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/school_group/subjects', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// subjects school group
export const api_all_levels = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/all_levels', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}



// scholar years schools
export const schools_scholar_years = (params) => {
  const user = get("session_user");
  return instance
    .get('/schools_scholar_years_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// professor years schools
export const professor_scholar_years = (token, params) => {
  return instance
    .get('/professor_scholar_years_handler',{
      headers: {Authorization: "Bearer " + token},
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update info
export const update_school_info = (token, object) => {
  return instance
    .post('/school_view/sc_settings/update', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update logo in setting
export const update_school_logo = (token, object) => {
  return instance
    .post('/api/school_file_upload', object, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": 'multipart/form-data'
      },
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// delete school logo
export const delete_school_logo = (token, object) => {
  return instance
    .post('/school_view/sc_settings/delete', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add subject_detail
export const sc_settings_update_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_view/sc_settings/update', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//delete subject
export const sc_settings_delete_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_view/sc_settings/delete', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const buibliotheque_post_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/bibliotheque_api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const buibliotheque_get_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/bibliotheque_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401){
          // logout();
      }
      } throw err;});
}
export const schools_levels_names_api_get = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_levels_names', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401){
          // logout();
      }
      } throw err;});
}

// list of chapter
export const list_chapter = (token, object) => {
  return instance
    .get('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add chapter
export const add_chapter = (token, object) => {
  return instance
    .post('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// delete chapter
export const delete_chapter = (token, object) => {
  return instance
    .post('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list koolskools competency
export const list_koolskools_competencies_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_koolskools_competency_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const store_koolskools_competencies_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_koolskools_competency_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          // logout();
          console.log('error');
      } throw err;});
}

// list competency
export const list_competencies_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_competency_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// import objectifs
export const list_competencies_objectifs_import_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_competency_import_handler', object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const update_competency = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_competency_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          // logout();
          console.log('error');
      } throw err;});
}

export const school_export_competencies = (object) => {
  const user = get('session_user');
  return instance
    .post(`/schools_competency_handler`, object ,{
      headers: {Authorization: "Bearer " + user.token},
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'professors_details_absences' + (object.action == "export_excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}

// list transport
export const transport_api_get = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_transport_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_api_post = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_transport_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_monitoring_api_get = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_transport_monitoring_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_monitoring_api_post = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_transport_monitoring_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//list control
export const list_control = (token, object) => {
  return instance
    .get('/professor_control_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const update_control = (token, object) => {
  return instance
    .post('/professor_control_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const school_update_control = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_control_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_generate_controls = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_control_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          {}
      } throw err;});
}
// add edit delete lesson setting
export const school_update_lesson = (token, object) => {
  return instance
    .post('/school_lesson_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list type of classroom
export const list_type_classroom = (token, object) => {
  return instance
    .get('/schools_classroom_types_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update type of classroom
export const update_type_classroom = (token, object) => {
  return instance
    .post('/schools_classroom_types_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// type Reclamation
export const add_reclamationType =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_type_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
// tye behavior
export const add_behaviorType =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_behavior_type',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const settings_staff_api_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/settings_staff_api',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_absence_monitoring =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_absences_monitoring',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_absence_reason =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_absences_reason',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_delay_reason =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_delays_reason',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
// school cours validation
export const schools_cours_validation =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_cours_validation',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}

// update seance
export const api_update_seance = object => {
  const user = get('session_user');
  return instance
  .post('/api/update_seance',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}


export const list_type_behavior = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_behavior_type', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const settings_staff_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/settings_staff_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const list_absences_monitoring = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_absences_monitoring', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const list_absences_reason = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_absences_reason', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const list_delays_reason = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_delays_reason', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// incidents
export const post_incidents =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_incidents',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_incidents = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_incidents', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// massar
export const massar_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_massar',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const massar_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_massar', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// controls_generation
export const controls_generation_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_controls_generation',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const controls_generation_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_controls_generation', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// inscriptions
export const inscriptions_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_inscriptions_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const inscriptions_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_inscriptions_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// bulletins
export const bulletins_settings_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_bulletins_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const classes_subjects_exams_calendar_settings_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_classes_subjects_exams_calendar_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const bulletins_settings_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_bulletins_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const ref_absences_settings_get =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_ref_absences_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const ref_absences_settings_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_ref_absences_settings', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}


export const schools_accounting_settings_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_accounting_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const schools_accounting_settings_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_accounting_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}

export const classes_subjects_exams_calendar_settings_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_classes_subjects_exams_calendar_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const providers_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/providers_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const providers_api_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/providers_api', object, {
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const purchase_orders_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/purchase_orders_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const purchase_orders_api_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/purchase_orders_api', object, {
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_type_reclamation = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_type_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// reclamation
export const add_reclamation =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const statusreclamation=(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const list_reclamation = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const export_reclamations = (object) => {
  const user = get('session_user');
    return instance
      .get(`/schools_reclamation_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reclamations' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const list_competencies_api_export = (object, callback) => {
  const user = get('session_user');
    return instance
      .get(`/schools_competency_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', (object.default_view == "competencies" ? "Competencies" : 'Objectifs') + (object.export_type == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// reclamation response
export const addreclamresponse=(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_response_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_reclamation_response = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_response_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list classroom
export const list_classroom = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_classroom_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update classroom
export const update_classroom = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_classroom_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list periods
export const list_periods_api = (token, object) => {
  return instance
    .get('/schools_periods_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update period
export const update_period_api = (token, object) => {
  return instance
    .post('/schools_periods_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list semesters
export const list_semesters_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_semesters_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// reregistration_blocking_reasons
export const reregistration_blocking_reasons_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reregistration_blocking_reasons_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      } throw err;});
}
export const reregistration_blocking_reasons_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_reregistration_blocking_reasons_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      } throw err;});
}
// update semester
export const update_semester_api = (token, object) => {
  return instance
    .post('/schools_semesters_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//send push notif
export const send_push_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_push_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// list holidays
export const list_holidays = (token, object) => {
  token = token || get('session_user').token;
  return instance
    .get('/schools_holiday_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update holidays
export const update_holidays = (token, object) => {
  return instance
    .post('/schools_holiday_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add evaluation
export const add_evaluation = (token, object) => {
  return instance
    .post('/school/evaluations', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// predefined_appreciation_block_api
export const predefined_appreciation_block_api = (object) => {
  var user = get("session_user");
  return instance
    .post('/school/predefined_appreciation/block/api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// competencies_levels_api_post
export const competencies_levels_api_post = (object) => {
  var user = get("session_user");
  return instance
    .post('/school/competencies_levels/api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add_appreciation
export const add_appreciation = (token, object) => {
  return instance
    .post('/school/appreciations', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list evaluations
export const list_evaluation = (token, object) => {
  return instance
    .get('/school/evaluations', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list appreciations
export const list_appreciations = (token, object) => {
  return instance
    .get('/school/appreciations', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// get_chapters
export const get_chapters_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/get_chapters', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list of events
export const list_events = (token, object) => {
  return instance
    .get('/api/events', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update events
export const update_events = (token, object) => {
  return instance
    .post('/api/events', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//add event
export const add_events=(token,object)=>{
  return instance
  .post('/api/events', object, {
    headers: {Authorization: "Bearer "+ token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
//delete event
export const delete_events=(token,object)=>{
  return instance
  .post('/api/events',object,{
    headers:{Authorization: "Bearer "+ token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*****************************************/

/*************** Professor *********/

//Professor to school admin
export const becomeAdmin = token => {
  return instance
    .post('/users/become_school_admin', {}, {
      headers: {Authorization: "Bearer " + token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//Classes
export const classes_api_info = classe_id => {
  const user = get("session_user");
  return instance
    .get('/api/classe_info/' + classe_id, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const prof_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/classes', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
      }
      throw err;
    });
};
export const prof_activities_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/activities_classes', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
      }
      throw err;
    });
};

export const prof_classes_store_api = object => {
  const user = get("session_user");
  return instance
    .post('/api/classes/store', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }
      throw err;});
};

//device_token_api

export const device_token_api = object => {
  const user = get("session_user");
  return instance
    .post('/api/device_token', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }
      throw err;});
}

// users menu
export const users_menu_list_api = object => {
  const user = get("session_user");
  return instance
    .get('/api_users_menu_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const users_menu_store_api = data => {
  const user = get("session_user");
  return instance
    .post('/api_users_menu_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// classes
export const school_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/schools_classe_handler', {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_classes_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_classe_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_students_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_student_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_notes_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_notes_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_professors_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_professor_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_staffs_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_assistants_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_infirmaries_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`student_infirmary_passages_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_seances_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_seance_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};

export const school_absences_azur_api = (object, file_name) => {
  const user = get('session_user');
  return instance
    .get(`schools_absence_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};


export const school_classes_store_api = data => {
  const user = get("session_user");
  return instance
    .post('/schools_classe_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// classes textbook
export const tasks_classe_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/tasks_classe_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// classes courses
export const courses_classe_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/courses_classe_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// classes weekly_plan
export const weekly_plan_classe_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/weekly_plan_classe_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// sanady
export const sanady_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/sanady_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// cities
export const cities_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/cities_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
export const sanady_api_post = object => {
  const user = get("session_user");
  return instance
    .post('/sanady_api', object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// project koolskools
export const project_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/project_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
export const project_api_post = object => {
  const user = get("session_user");
  return instance
    .post('/project_api', object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
// sanady unconnected users
export const sanady_unconnected_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/sanady_unconnected_api', {
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
export const sanady_unconnected_api_post = object => {
  const user = get("session_user");
  return instance
    .post('/sanady_unconnected_api', object, {
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
export const classes_observations_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/classes_observations_api', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};
export const classes_observations_api_post = object => {
  const user = get("session_user");
  return instance
    .post('/classes_observations_api', object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

// activities classes
export const school_activities_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/schools_activities_classes_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_activities_classes_store_api = data => {
  const user = get("session_user");
  return instance
    .post('/schools_activities_classes_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// cantine
export const school_cantine_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/schools_cantine_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_cantine_api_post = data => {
  const user = get("session_user");
  return instance
    .post('/schools_cantine_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// garde
export const school_garde_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/schools_garde_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_garde_api_post = data => {
  const user = get("session_user");
  return instance
    .post('/schools_garde_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// garde_soir
export const school_garde_soir_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/schools_garde_soir_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_garde_soir_api_post = data => {
  const user = get("session_user");
  return instance
    .post('/schools_garde_soir_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// panier
export const school_panier_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/schools_panier_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_panier_api_post = data => {
  const user = get("session_user");
  return instance
    .post('/schools_panier_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// garde_repas
export const school_garde_repas_api_get = object => {
  const user = get("session_user");
  return instance
    .get('/schools_garde_repas_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_garde_repas_api_post = data => {
  const user = get("session_user");
  return instance
    .post('/schools_garde_repas_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const stats_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/classes/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const stats_students_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/students/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const stats_courses_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/courses/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//STUDENTS LIST
export const profStudents = (object) => {
  const user = get("session_user");
  object.order_by_name = "true";
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object
  };
  // if(document.studentsAjax){
  //   document.studentsAjax.cancel();
  // }
  // document.studentsAjax = axios.CancelToken.source();
  // if(document.studentsAjax){
  //   data.cancelToken = document.studentsAjax.token;
  // }
  return instance
    .get('/professor_students_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//CRUD STUDENTS
export const crudStudents = (token, object) => {
  return instance
    .post('/api/students/store', object, {
      headers: {Authorization: "Bearer " + token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
 /* **********************************/

/*************share users*********************/
export const users_list_api = (account_type, object) => {
    const user = get('session_user');
    return instance
      .get(`/users_list/${account_type}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
        if(err.response.status == 401)
            logout();
        } throw err;});
};
// export const users_list_api_share = (account_type, object) => {
//     const user = get('session_user');
//     if(document.usersListAjax){
//       document.usersListAjax.cancel();
//     }
//     document.usersListAjax = axios.CancelToken.source();
//     if((!object || !object.school) && get("school")){
//       object = {
//         school: get("school").id
//       };
//     }
//     var data = {
//       headers: {Authorization: "Bearer " + user.token},
//       params: object,
//     };
//     if(document.usersListAjax){
//       data.cancelToken = document.usersListAjax.token;
//     }
//     return instance
//       .get(`/users_list_share/${account_type}`, data)
//       .then(response => {return response.data;})
//       .catch(err => {if (err.response) {
//         if(err.response.status == 401)
//             logout();
//         }});
// };

/************************challenges**************************/
export const challenges_post_api_handler = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/challenges_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const challenges_get_api_handler = (object) => {
  const user = get('session_user');
  return instance
    .get(`/api/challenges_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const challenges_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/challenges_list`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_slip_payment_api;
export const api_increment_slip = (object, cancel_api) => {
  const user = get('session_user');
  cancel_slip_payment_api && cancel_api && cancel_slip_payment_api();
  return instance
    .post('/api/increment_slip', object, {
      headers: {Authorization: "Bearer " + user.token},
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_slip_payment_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const challenge_api_result = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/challenge_result`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/*********** Global list levels **************/
export const levels_api_list = () => {
  return instance
    .get(`api_list_levels`)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/*********** Global list schools **************/

export const schools_api_list = () => {
  return instance
    .get(`api_list_schools`)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/********** Test session api ***********/
export const school_session_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_session_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_session_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_session_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**********************************************/

/********** Document Candidatures ***************/

export const school_documents_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_documents_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_documents_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_documents_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_documents_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_documents_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_documents_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_documents_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_tutor_overall_form_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_tutor_overall_form_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_tutor_overall_form_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_tutor_overall_form_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_inscriptions_follow_up_actions_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_follow_up_actions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_preinscription_parents_professions_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_preinscription_parents_professions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_preinscription_previow_schools_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_preinscription_previow_schools_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_internal_calls_visits_settings_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_internal_calls_visits_settings_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_internal_calls_visits_settings_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_internal_calls_visits_settings_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_follow_up_actions_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_follow_up_actions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_preinscription_parents_professions_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_preinscription_parents_professions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_preinscription_previow_schools_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_preinscription_previow_schools_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_documents_api_upload = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_documents_upload_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/*************************************************/

/********** Application file api ***********/
export const application_file_api_store = (object) => {
  return instance
    .post(`api_store_application_file`, object)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_application_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_application_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_application_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_application_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//get tracking
export const tracking_api_handler = (object) => {
  const user = get('session_user');
  return instance
    .post(`tracking_api_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/*******************************************/

/*********************** payment api *************/

// export const school_session_api_list = (object) => {
//   const user = get('session_user');
//   return instance
//     .get(`schools_session_handler`, {
//       headers: {Authorization: "Bearer " + user.token},
//       params: object
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };

export const student_tracking_sheets_api = (object) => {
  const user = get('session_user');
  return instance
    .get(`student_tracking_sheets_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const student_tracking_sheets_api_export = (object) => {
  const user = get('session_user');
  return instance
    .get(`student_tracking_sheets_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fiche_de_suivi' + (object.type_export == "excel" ? '.xlsx' : '.pdf'));
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
        logout();
    } throw err;});
};
export const student_tracking_sheets_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`student_tracking_sheets_api`, object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const student_notes_api = (object) => {
  const user = get('session_user');
  return instance
    .get(`student_notes_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_calendar_control_api_get = (object) => {
  const user = get('session_user');

  return instance
    .get(`schools_calendar_control_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        } throw err;});
};

export const school_calendar_control_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_calendar_control_handler`, object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const school_bill_sage_api_get = (object) => {
  const user = get('session_user');

  return instance
    .get(`schools_bills_sage_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        } throw err;});
};

let cancel_payments_api;
export const school_payment_api_get = (object, cancel_api) => {
  const user = get('session_user');

  cancel_payments_api && cancel_api && cancel_payments_api();

  return instance
    .get(`schools_payment_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_payments_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        } throw err;});
};

export const school_payment_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_payment_handler`, object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_payments_settings_api;
export const school_settings_payment_api_get = (object, cancel_api) => {
  const user = get('session_user');

  cancel_payments_settings_api && cancel_api && cancel_payments_settings_api();
  return instance
    .get(`schools_settings_payment_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_payments_settings_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        } throw err;});
};

export const school_settings_payment_api_get2 = (object) => {
  const user = get('session_user');

  return instance
    .get(`schools_settings_payment_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {
        if(err.response.status == 401)
          logout();
        } throw err;});
};

export const school_settings_payment_api_export = (object, callback) => {
  const user = get('session_user');
  return instance
    .get(`schools_settings_payment_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      if(callback){
        callback();
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'accountant_payments.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(callback){
        callback();
      }
      if(err.response.status == 401)
        logout();
    } throw err;});
};
// global.save_payments_token = axios.CancelToken.source();
export const school_settings_payment_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_settings_payment_handler`, object, {
      headers: {Authorization: "Bearer " + user.token},
      // cancelToken: new CancelToken(function executor(c) {
      //     if(global.save_payments_token)
      //       global.save_payments_token = c;
      // })
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const phone_call_history_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_phone_call_history_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_billing_handler_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_billing_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_billing_handler_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_billing_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_cv_libraries_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_cv_libraries_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_cv_libraries_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_cv_libraries_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const schools_internal_calls_visits_handler_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_internal_calls_visits_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_internal_calls_visits_handler_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_internal_calls_visits_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_internal_calls_visits_export = (object, trans) => {
  const user = get('session_user');
  return instance
    .get(`schools_internal_calls_visits_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'internal_calls_visits.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
/******************************************** */

/*********************** permissions api *************/
export const school_permissions_api_list = (object, do_not_change_stored_permission) => {
  if(!do_not_change_stored_permission){
    remove('permissions');
  }
  const user = get('session_user');
  return instance
    .get(`schools_permissions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      if(!do_not_change_stored_permission){
        set('permissions', response.data.permissions);
        var event = document.createEvent('Event');

        // Nomme l'événement 'build'.
        event.initEvent('store_permissions', true, true);
        window.dispatchEvent(event)
      }
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/*********************** pilotage permissions api *************/
export const pilotage_permissions_api_list = (object, do_not_change_stored_pilotage_permission) => {
  if(!do_not_change_stored_pilotage_permission){
    remove('pilotage_permissions');
  }
  const user = get('session_user');
  return instance
    .get(`schools_permissions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      if(!do_not_change_stored_pilotage_permission){
        set('pilotage_permissions', response.data.permissions);
        var event = document.createEvent('Event');
        // Nomme l'événement 'build'.
        event.initEvent('store_pilotage_permissions', true, true);
        window.dispatchEvent(event)
      }
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_pilotage_school_api_get;
export const pilotage_school_api_get = (object, cancel_api)=>{
  const user = get('session_user');
  cancel_pilotage_school_api_get && cancel_api && cancel_pilotage_school_api_get();
  return instance
    .get(`/pilotage_school_api`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_pilotage_school_api_get = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const pilotage_school_api_post = (object, cancel_api)=>{
  const user = get('session_user');
  return instance
    .post(`/pilotage_school_api`, object, {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
let cancel_pilotage_responsables_list;
export const pilotage_responsables_list = (object, cancel_api)=>{
  const user = get('session_user');
  cancel_pilotage_responsables_list && cancel_api && cancel_pilotage_responsables_list();
  return instance
    .get(`/pilotage_responsables_list`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_pilotage_responsables_list = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/********************************************************/


/*************** Transmission api *************/

export const school_transmissions_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_transmissions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_transmissions_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_transmissions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* Gallery api *****************/

export const school_galleries_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_galleries_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_galleries_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_galleries_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* LostObject api *****************/

export const school_losts_objects_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_losts_objects_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_losts_objects_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_losts_objects_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* Resources api *****************/

export const school_resources_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_resources_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_resources_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_resources_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* Messaging api *****************/

export const school_mailings_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_mailings_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_mailings_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_mailings_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


/************* Assistants api *****************/

export const school_assistant_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_assistants_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      if(false && (response.data || {}).all_sections){
        response.data.all_sections = response.data.all_sections.filter(s_a => {
          return s_a.section.name != "sms";
        })
      }
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_escort_driver_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_escorts_drivers_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_assistant_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_assistants_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_escort_driver_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_escorts_drivers_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } return err.response.data;throw err;});
};

/******************************************************/


/************* conseils api *****************/

export const school_conseils_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`school_advices_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_conseils_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`school_advices_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******************************************************/

export const school_api_info = (object) => {
  const user = get('session_user');
  return instance
    .post(`api/school_info`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******************* check school ****************/
export const check_school_api = (object) => {
  const user = get('session_user');
  return instance
    .post(`api/check_school`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/****************************************************/
/******************************************************/

/******************* school evaluation validation ****************/
export const school_evaluation_validation = (object) => {
  const user = get('session_user');
  return instance
    .post(`school_evaluation_validation`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/****************************************************/

/******************** tasks classe **************/
let cancel_classes_api;
export const tasks_classe_list = (object, cancel_api) => {
  const user = get('session_user');
  cancel_classes_api && cancel_api && cancel_classes_api();
  return instance
    .get(`tasks_prof`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_classes_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const tasks_stats = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/tasks/stats`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const task_exercises_stats = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/task_exercises/stats`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******************** tasks activity classe **************/
export const tasks_activity_classe_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`tasks_activities_prof`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************************************************/
//***** */ dashboard*********************
export const prof_count_api=(school,object) =>{
const user = get('session_user');
    return instance
      .get(`/dashboard_details/${school}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const prof_users_api=(object) =>{
  const user = get('session_user');
      return instance
        .get(`/dashboard_professors_details`, {
          headers: {Authorization: "Bearer " + user.token},
          params: object
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const students_users_api=(object) =>{
    const user = get('session_user');
        return instance
          .get(`/dashboard_students_details`, {
            headers: {Authorization: "Bearer " + user.token},
            params: object
          })
          .then(response => {return response.data;})
          .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    }
  export const parents_users_api=(object) =>{
      const user = get('session_user');
          return instance
            .get(`/dashboard_parents_details`, {
              headers: {Authorization: "Bearer " + user.token},
              params: object
            })
            .then(response => {return response.data;})
            .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
      }
  export const dashboard_prof_chart=(school,object) =>{
    const user = get('session_user');
      return instance
        .get(`/dashboard_prof_chart/${school}`, {
          headers: {Authorization: "Bearer " + user.token},
          params: object
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const prof_chart_api=(school,object)=>{
    const user = get('session_user');
        return instance
          .get(`/dashboard_chart_data_prof/${school}`, {
            headers: {Authorization: "Bearer " + user.token},
            params: object
          })
          .then(response => {return response.data;})
          .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    }
  export const stu_chart_api=(school,object)=>{
      const user = get('session_user');
          return instance
            .get(`/dashboard_chart_data_stu/${school}`, {
              headers: {Authorization: "Bearer " + user.token},
              params: object
            })
            .then(response => {return response.data;})
            .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
      }
  export const par_chart_api=(school,object)=>{
        const user = get('session_user');
            return instance
              .get(`/dashboard_chart_data_par/${school}`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
        }
  export const pie_chart_api=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/pie_chart_api`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }
  export const users_by_time=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/users_dash_by_time`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const dashboard_courbe_prof=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_prof`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_schools_reportings_api;
  export const dashboard_schools_reporting = (object, cancel_api)=>{
    const user = get('session_user');
    cancel_schools_reportings_api && cancel_api && cancel_schools_reportings_api();
    return instance
      .get(`/dashboard_schools_reporting`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel_schools_reportings_api = c;
        })
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const dashboard_schools_reporting_export = (object, callback)=>{
    const user = get('session_user');
    return instance
      .get(`/dashboard_schools_reporting`, {
        headers: {
          Authorization: "Bearer " + user.token
        },
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'schools_reportings' + (object.export_format == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        if(callback){
          callback();
        }
        link.click();
      })
      .catch(err => { if (err.response) {
        if(callback){
          callback();
        }
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const dashboard_courbe_prof_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_prof_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const dashboard_courbe_par=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_par`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const dashboard_courbe_par_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_par_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const dashboard_courbe_stu=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_stu`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const dashboard_courbe_stu_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_stu_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const assurance_api=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/student_assurance_handler`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const infirmary_passages_api = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/student_infirmary_passages_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const accidents_api = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/student_accidents_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_infirmary_passages = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/student_infirmary_passages_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'infirmary_passages' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
    } throw err;});
  }
  export const school_convert_students = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/convert/students_get`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'converted_madariss' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
    } throw err;});
  }
  export const infirmary_passages_post_api=(object)=>{
    const user = get('session_user');
    return instance
      .post('/student_infirmary_passages_handler', object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const accidents_post_api=(object)=>{
    const user = get('session_user');
    return instance
      .post('/student_accidents_handler', object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const grade_threshold_get = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/grade_threshold_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const semesters_avg_get = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/semesters_avg_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const seance_replacements = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/seance_replacements`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const seance_week_replacements = (object)=>{
    const user = get('session_user');
    return instance
      .post(`/seance_week_replacements`, object,  {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const grade_threshold_post=(object)=>{
    const user = get('session_user');
    return instance
      .post('/grade_threshold_handler', object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const filesassurancevalidate = (data) => {

    const user = get('session_user');
    return instance
        .post('/valid_assur_file',data, {
          headers: {Authorization: "Bearer " + user.token},
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  };
  //journals
  export const export_journals = (object, callback) =>{
    var user = get('session_user');
    instance
    .get(`/journal_handler`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (object.journal_type == "classes" ? 'Cahier de texte classe' : 'Cahier de texte enseignant') + (object.export_type == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      if(callback){
        callback();
      }
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
  }


  /******************** export api *******************/
  let cancel_export_payments;
  export const school_export_payments = (object) => {
    const user = get('session_user');
    cancel_export_payments && cancel_export_payments();
    return instance
      .get(`export/payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob',
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel_export_payments = c;
        })
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payments-' + moment().format('YYYY-MM-DD') + '-.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }
  export const school_export_cantine_garde_students = (url, object, callback) => {
    const user = get('session_user');
    return instance
      .get(url, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', object.file_name + (object.export_type == "excel" ? ".xlsx" : ".pdf")); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }

  /******************** export api *******************/
  export const school_recap_export = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/recap_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'recap-payments-' + moment().format('YYYY-MM-DD') + '-.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }

  /******************** export bills api *******************/
  export const school_export_bills = (object, trans) => {
    const user = get('session_user');
    return instance
      .get(`export/bills`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', (trans ? trans('Invoices') : 'Factures') + '-' + moment().format('YYYY-MM-DD') + '-.xls');
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }

  export const school_export_massar_notes = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/massar_notes`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'notes-' + moment().format('YYYY-MM-DD') + '-.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_global_notes = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/global_notes`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'notes_' + (object.level_name || "") + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        if(callback){
          callback();
        }
      })
      .catch(err => { if (err.response) {
        if(callback){
          callback();
        }
        if(err.response.status == 401){
          // logout();
        }
      } throw err;});
  }
  export const school_global_notes_get = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/global_notes`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const generate_boti_payments = object => {
    const user = get('session_user');
    return instance
      .post(`/api/generate_boti_payments`, object, {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": 'multipart/form-data'
        },
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'result_payments.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })

  }

  export const school_export_massar_notes_post = (object, file_name, uploadProgress, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import_export/massar_notes`, object, {
        headers: {Authorization: "Bearer " + user.token,Accept: 'application/json',
        'Content-Type': 'application/json'},
        responseType: 'blob',
        onUploadProgress: progressEvent => uploadProgress(progressEvent)
      })
      .then(response => {
        // return response.data;
        if(response.headers.files_errors){
          filesErrors(JSON.parse(response.headers.files_errors));
        }
        const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', remove_number_in_parentheses(file_name)); //or any other extension 
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_massar_absences_post = (object, file_name, uploadProgress, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import_export/massar_absences`, object, {
        headers: {Authorization: "Bearer " + user.token,Accept: 'application/json',
        'Content-Type': 'application/json'},
        responseType: 'blob',
        onUploadProgress: progressEvent => uploadProgress(progressEvent)
      })
      .then(response => {
        // return response.data;
        if(response.headers.files_errors){
          filesErrors(JSON.parse(response.headers.files_errors));
        }
        const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_import_massar_notes_post = (object, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import/massar_notes`, object, {
        headers: {Authorization: "Bearer " + user.token}
      })
      .then(response => {
        if(response.data.files_errors){
          filesErrors(response.data.files_errors);
        }
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_import_sanady_controls_competencies_post = (object, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import/sanady_notes_competencies`, object, {
        headers: {Authorization: "Bearer " + user.token}
      })
      .then(response => {
        if(response.data.files_errors){
          filesErrors(response.data.files_errors);
        }
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_students_payment = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/students_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students_payments' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export_tests_results = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/export_inscription_test_result`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tests_results.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_inscriptions_api_viewer_export_tests_results = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/viewer_export_inscription_test_result`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tests_results' + (object.export_type == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_viewer_export_tests_results_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/viewer_export_inscription_test_result`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export_data = (object) => {
    const user = get('session_user');
    return instance
      .get(`schools_inscriptions_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`schools_inscriptions_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'inscriptions' + (object.type == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_discounts_api_export = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`schools_discounts_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'discounts' + (object.type == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_controls_graded = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/controls_graded`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'controls_graded' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_tests_diagnostic_graded = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/tests_diagnostic_graded`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tests_diagnostic_graded' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_tasks_stats = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/tasks_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tasks_stats' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_courses_by_professors = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/courses_by_professors`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tasks_stats' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_absences_by_hours = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences_by_hours`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_list`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_list' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors_details_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`api/professors_absences_details`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_details_absences' + (object.action == "export_excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors_worked_hours = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_worked_hours`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_worked_hours' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_worked_hours_remediation = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_worked_hours_remediation`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_worked_hours' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_students_stats_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/students_absences_stats_export`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_settings_payment_api_export_phone_calls_history = (object) => {
    const user = get('session_user');
    return instance
      .post(`schools_settings_payment_handler`, object, {
        headers: {Authorization: "Bearer " + user.token},
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'historiques_des_appels' + (object.export_format == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_students = (object) => {
    const user = get('session_user');
    return instance
      .get(`/export/students`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'liste_des_élèves' + (object.export_import_format == "pdf" ? '.pdf' : (object.excel_extention ? object.excel_extention : '.xls'))); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_students_trombinoscope = (object) => {
    const user = get('session_user');
    return instance
      .get(`/schools_student_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Trombinoscope' + (object.export_type == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_trombinoscope = (object) => {
    const user = get('session_user');
    return instance
      .get(`/schools_professor_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Trombinoscope' + (object.export_type == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const professor_export_students_trombinoscope = (object) => {
    const user = get('session_user');
    return instance
      .get(`/professor_students_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Trombinoscope' + (object.export_type == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_students_missings_documents = (object) => {
    const user = get('session_user');
    return instance
      .get(`/schools_student_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students_missings_documents' + (object.export_type == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_list_students_by_reregistration = (object) => {
    const user = get('session_user');
    return instance
      .get(`/schools_student_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'list_students_by_reregistration' + (object.export_type == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_bibliotheque_books = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`/books_list_api`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback(object.type_export);
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bibliotheque_books' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        if(callback){
          callback(object.type_export);
        }
        if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_transport_students = (object) => {
    const user = get('session_user');
    return instance
      .get(`/schools_transport_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', (object.action == "get_students_not_boarding" ? "students_not_boarding" : "get_steps_by_circuit" ? "steps_by_circuit" : 'students_by_circuit') + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_assistants_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/assistants_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'assistants_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_staffs_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/staffs_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'staffs_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_students_payments_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/students_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_tasks_stats_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/tasks_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_courses_by_professors_stats_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/courses_by_professors`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_professors_absences_details = (object) => {
    const user = get('session_user');
    return instance
      .get(`api/professors_absences_details`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        // return response.data;
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_professors_absences_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_professors_absences_list_by_hours = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences_by_hours`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_professors_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_list`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const sanady_professors_api = (object) => {
    const user = get('session_user');
    return instance
      .post(`sanady_professors_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_professors_worked_hours_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_worked_hours`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const professors_payments_list_api_get = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_payments_hours`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_professors_worked_hours_remediation_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_worked_hours_remediation`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_professors_replacements = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_replacements`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_professors_stats;
  export const get_professors_stats = (object) => {
    const user = get('session_user');
    cancel_professors_stats && cancel_professors_stats();
    return instance
      .get(`export/professors_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        cancelToken: new CancelToken(function executor(c) {
          cancel_professors_stats = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_professors_effectif;
  let cancel_apis_types = {};
  export const sanady_rapports_lists_api = (object, api_type) => {
    const user = get('session_user');
    if(api_type){
      cancel_apis_types[api_type] && cancel_apis_types[api_type]();
    }
    else{
      cancel_professors_effectif && cancel_professors_effectif();
    }
    return instance
      .post(`sanady_rapports_lists_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
        cancelToken: new CancelToken(function executor(c) {
          if(api_type){
            cancel_apis_types[api_type] = c;
          }
          else{
            cancel_professors_effectif = c;
          }
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_generate_volume_horaires;
  export const volume_horaire_api = (object) => {
    const user = get('session_user');
    cancel_generate_volume_horaires && cancel_generate_volume_horaires();
    return instance
      .post(`volume_horaire_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
        cancelToken: new CancelToken(function executor(c) {
          cancel_generate_volume_horaires = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const cantine_menues_api_post = (object) => {
    const user = get('session_user');
    return instance
      .post(`cantine_menues_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const cantine_menues_api_get = (object) => {
    const user = get('session_user');
    return instance
      .get(`cantine_menues_api`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const students_exemptions_from_subjects_api_post = (object) => {
    const user = get('session_user');
    return instance
      .post(`students_exemptions_from_subjects_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const students_exemptions_from_subjects_api_get = (object) => {
    const user = get('session_user');
    return instance
      .get(`students_exemptions_from_subjects_api`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_generate_volume_professors_absences;
  export const volume_professors_absences_api = (object) => {
    const user = get('session_user');
    cancel_generate_volume_professors_absences && cancel_generate_volume_professors_absences();
    return instance
      .post(`volume_professors_absences_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
        cancelToken: new CancelToken(function executor(c) {
          cancel_generate_volume_professors_absences = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_generate_volume_students_absences;
  export const volume_students_absences_api = (object) => {
    const user = get('session_user');
    cancel_generate_volume_students_absences && cancel_generate_volume_students_absences();
    return instance
      .post(`volume_students_absences_api`, object, {
        headers: {Authorization: "Bearer " + user.token},
        cancelToken: new CancelToken(function executor(c) {
          cancel_generate_volume_students_absences = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_stats = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_stats' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  let cancel_professors_pedagogique_repartition;
  export const get_professors_pedagogique_repartition = (object) => {
    const user = get('session_user');
    cancel_professors_pedagogique_repartition && cancel_professors_pedagogique_repartition();
    return instance
      .get(`export/professors_pedagogique_repartition`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        cancelToken: new CancelToken(function executor(c) {
          cancel_professors_pedagogique_repartition = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_pedagogique_repartition = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_pedagogique_repartition`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_pedagogique_repartition' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_professors_journals = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_journals`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_journals = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_journals`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_journals' + (object.type_export == "pdf" ? '.pdf' : '.xlsx')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professors_replacements = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_replacements`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_replacements' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  let cancel_student_absences_stats_list;
  export const get_students_absences_stats_list = (object) => {
    cancel_student_absences_stats_list && cancel_student_absences_stats_list();
    const user = get('session_user');
    return instance
      .get(`export/students_absences_stats_export`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        cancelToken: new CancelToken(function executor(c) {
          cancel_student_absences_stats_list = c;
        })
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { 
        if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
            return 'Request canceled';
        } else {
          if (err.response) {
            if(err.response.status == 401)
              logout();
          } 
          throw err;
        }
      });
  }
  export const students_absences_stats_post = (object) => {
    const user = get('session_user');
    return instance
      .post(`export/students_absences_stats_export`, object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const students_absences_stats_EXPORT = (object, callback) => {
    const user = get('session_user');
    return instance
      .post(`export/students_absences_stats_export`, object, {
        headers: {Authorization: "Bearer " + user.token},
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students_absences_stats-' + moment().format('YYYY-MM-DD')  + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_assisstants_absences_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/assistants_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_staffs_absences_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/staffs_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_bulletins = (object) => {
    const user = get('session_user');
    var view_url = 'generate_bulletins_v2';
    return instance
      .get(`${view_url}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'bulletins-' + moment().format('YYYY-MM-DD')) + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_test_receipt = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_test_receipt`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'test_inscription_receipt') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_test_invitation = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_test_invitation`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'test_inscription_invitation') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_recapitulatif = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_recapitulatif`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'inscription_recapitulatif') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_professor_file = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_professors_files`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'fiche_professeur') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_export_staff_file = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_staffs_files`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'fiche_personnel') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const school_professor_file_get = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_professors_files`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors = (object) => {
    const user = get('session_user');
    return instance
      .get(`/export/professors`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors-' + moment().format('YYYY-MM-DD') + '.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }


  export const journal_api = (token) => {
    return instance
      .get(`/journals_api_list`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then(response => {
        return response.data;
      })
      // .catch(err => {
      //   if (err.response) {
      //     throw new Error(mapAuthError(err.response.status));
      //   }
      //   throw err;
      // });
  };

  export const log_js_error = (data) => {
    const user = get('session_user');

    return instance
        .post('/log_js_error',data, {
          headers: {Authorization: "Bearer " + user.token},
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  };
  // unconnected_api
  export const unconnected_api_get = (data) => {
    return instance
      .get(`/unconnected_api`, {
        params: data,
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        if (err.response) {
        }
        throw err;
      });
  };
  export const unconnected_api_post = (data) => {
    return instance
        .post('/unconnected_api',data, {
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  };

  export const school_localisations_api_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`student_recovery_api`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
        if(err.response.status == 401)
            logout();
        } throw err;});
  };

  export const school_localisations_api_store = (object) => {
    const user = get('session_user');
    return instance
      .post(`student_recovery_api`, object, {
        headers: {Authorization: "Bearer " + user.token}
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
        if(err.response.status == 401)
            logout();
        } throw err;});
  };

  export const api_link_info = data => {
    // const user = get('session_user');
    return instance.get('/api/link', {
      params: data
    })
    .then(response => {return response.data;});
    // .catch(err => { if (err.response) {} throw err;});
  };



  export const unsubscribe_fcm_api = data => {
    const user = get('session_user');
    return instance.post('/unsubscribe_fcm_api', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  };

  export const get_instanceID_info = () => {
    var token = get('device_token');
    return instance
        .get("https://iid.googleapis.com/iid/info/"+ token,{
          headers: {Authorization: "key=AAAALa0BzME:APA91bH2BlNCtIWiNnZ_BJjUwKqHz1fsxAZsh2yS3YQGPjGBdY5ACc66SK4kYm1gfoAqcWjB6dtXLcfy4t5p2XKjlEfcXB62RmNo1s-mR58NXmnMcdmsfVluD1qRLvIv28f3MPf6dgHL"},
          params:{details:true}
        })

        .then(response => {
          return response.data;
        })
        .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      } throw err;});

  }
