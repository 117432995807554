import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../../assets/variables/colors";
import { images } from "./_resources";
import { get, set } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';
import ModalTermsAndCondition from "../professor/home/components/ModalTermsAndCondition";

class HomeFooter extends Component {
  constructor(props){
    super(props);
    this.device_width = window.innerWidth;
    this.state = {
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
    };
    this.social_links = [
      {
        image: images.linkedin_icon,
        url: "https://www.linkedin.com/company/koolskools",
      },
      {
        image: images.facebook_icon,
        url: "https://www.facebook.com/profile.php?id=100063520930735",
      },
      {
        image: images.instagram_icon,
        url: "https://www.instagram.com/koolskools_/",
      },
      {
        image: images.whatsapp_icon,
        url: "https://wa.me/" + ["212667947883", "212662405854"][Math.round(Math.random())],
      },
    ]
    this.links = [
      {
        text: "KOOLSKOOLS",
        url: "https://www.koolskools.com",
      },
      {
        text: "KOOLSKOOLS Révisions",
        url: "https://www.koolskoolsrevisions.com",
      },
      {
        text: "KOTCHMEE",
        url: "https://www.kotchmee.com",
      },
    ]
    this.contacts = [
      {
        image: images.address_icon,
        values: ["400, bd zerktouni. Casablanca"],
      },
      {
        image: images.phone_icon,
        values: ["+212 522 471 720", "+212 667 947 883", "+212 662 405 854"],
      },
      {
        image: images.email_icon,
        values: ["hello@koolskools.com"],
        is_email: true,
      },
    ]
    
  }

  componentWillReceiveProps(newProps){
  }




  updateDimensions = () => {
    this.device_width = window.innerWidth;
    this.setState({
      is_tablete: this.device_width < 1200,
      is_mobile: this.device_width < 767,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const current_language = get("current_language");
    const {is_mobile, is_tablete} = this.state;
    return (
      <>
        <FooterStyle className={"footer_container " + (is_mobile ? " mobile " : is_tablete ? " tablete " : "")}>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12 logo">
              <div className="img">
                <img src={images.logo_footer} className="" />
              </div>
              <span class="Plateforme-dapprentissage-en-ligne-qui-permet-de-retrouver-to">
                {this.props.t("Plateforme d'apprentissage en ligne qui permet de retrouver tout ce qui concerne l'école en version numérique.")}
              </span>
              <div className="buttons">
                <a className="app_store_button" href="https://apps.apple.com/fr/app/koolskools/id1541912725" target={"_blank"}>
                  <img src={images.app_store_button} />
                </a>
                <a className="google_play_button" href="https://play.google.com/store/apps/details?id=com.koolskools" target={"_blank"}>
                  <img src={images.google_play_button} />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 links">
              <div className="title hidden">{this.props.t("Liens importants")}</div>
              <div className="values links_values hidden">
                {this.links.map((link, idx) => {
                  return <div className="link" key={idx} >
                    <a href={link.url} target="_blank">
                      {link.text}
                    </a>
                  </div>
                })}
              </div>
              <div className="title">{this.props.t("Social media")}</div>
              <div className="values social_media_values">
                {this.social_links.map((social_link, idx) => {
                  return <div className="social_link" key={idx} >
                    <a href={social_link.url} target="_blank">
                      <img src={social_link.image} />
                    </a>
                  </div>
                })}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 contacts">
              <div className="title">{this.props.t("Contact")}</div>
              <div className="values contact_values">
                {this.contacts.map((contact, idx) => {
                  return <div className="contact" key={idx} >
                    <img src={contact.image} />
                    <div className="vals">
                      {contact.values.map((val, idx) => {
                        if(contact.is_email){
                          return <a key={idx} href={`mailto:${val}`}>{val}</a>
                        }
                        return <span key={idx}>{val}</span>
                      })}
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
          <div className="row hidden">
            <div className="col-lg-12 col-md-12 col-12">
              <span class="-All-right-reserved-Koolskools-Made-by-MeteoriAgency">
                {this.props.t("2024 All right reserved Koolskools. Made by MeteoriAgency")}
              </span>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <span class="Termes-et-politique-de-confidentialit" onClick={evt => {
                this.setState({openModalTerms: true})
              }}>
                {this.props.t("Termes et politique de confidentialité")}
              </span>
            </div>
          </div>
        </FooterStyle>
        {this.state.openModalTerms &&
          <ModalTermsAndCondition
            success={this.state.openModalTerms}
            handleClose={() => this.setState({openModalTerms: false})}
          />
        }
      </>
    );
  }
}

const FooterStyle = styled.footer`
  width: 100%;
  padding: 97px 8% 71px 8%;
  object-fit: contain;
  background-image: linear-gradient(to top, #0da39d, #64c2be);
  .logo{
    padding-right: 70px;
    .img{
      margin-bottom: 25px;
      img{
        width: 195px;
        height: 127px;
        object-fit: contain;
      }
    }
    .Plateforme-dapprentissage-en-ligne-qui-permet-de-retrouver-to {
      display: block;
      font-family: QuicksandLight;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .buttons{
      display: flex;
      justify-content: flex-start;
      position: relative;
      margin-top: 50px;
      width: 100%;
      a{
        margin-right: 15px;
        // width: calc(100% - 15px);
        img{
          width: 100%;
          height: 72px;
          max-width: 180px;
          object-fit: contain;
        }
      }
    }
  }

  .row{
    .-All-right-reserved-Koolskools-Made-by-MeteoriAgency {
      display: block;
      margin: 95px 1px 0 1px;
      font-family: QuicksandLight;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .Termes-et-politique-de-confidentialit {
      display: block;
      cursor: pointer;
      margin: 95px 0 0 0;
      font-family: QuicksandLight;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
      color: #fff;
      display: none;
    }
    >div{
      .title{
        font-family: QuicksandLight;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #042a45;
        margin: 25px 0;
      }
      .links_values{
        .link{
          margin-bottom: 10px;
          a{
            font-family: QuicksandLight;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }
        }
      }
      .social_media_values{
        display: flex;
        .social_link{
          a{
            margin-right: 10px;
            img{
              width: 42px;
              height: 42px;
            }
          }
        }
      }
      .contact_values{
        .contact{
          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 20px;
          img{
            width: 60px; 
            margin-right: 10px;
            height: max-content;
            margin-top: auto;
            margin-bottom: auto;
          }
          .vals{
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            >*{
              display: block;
              font-family: QuicksandLight;
              font-size: 20px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.tablete{
    width: 100%;
    padding: 7vw 4% 5vw 4%;
    object-fit: contain;
    background-image: linear-gradient(to top, #0da39d, #64c2be);
    .logo{
      padding-right: 3vw;
      padding-left: 3vw;
      .img{
        margin-bottom: 3vw;
        img{
          width: 20vw;
          height: 12vw;
          object-fit: contain;
          max-width: 180px;
          max-height: 108px;
        }
      }
      .Plateforme-dapprentissage-en-ligne-qui-permet-de-retrouver-to {
        display: block;
        font-family: QuicksandLight;
        font-size: 2vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      .buttons{
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-top: 3vw;
        width: 100%;
        a{
          margin-right: 15px;
          // width: calc(100% - 15px);
          img{
            width: 100%;
            height: 72px;
            max-width: 180px;
            object-fit: contain;
          }
        }
      }
    }

    .row{
      .-All-right-reserved-Koolskools-Made-by-MeteoriAgency {
        display: block;
        margin: 3vw 1px 0 1px;
        font-family: QuicksandLight;
        font-size: 1.5vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      .Termes-et-politique-de-confidentialit {
        display: block;
        cursor: pointer;
        margin: 3vw 0 0 0;
        font-family: QuicksandLight;
        font-size: 1.5vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
        display: none;
      }
      >div{
        .title{
          font-family: QuicksandLight;
          font-size: 2.2vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.24;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
          margin: 25px 0;
        }
        .links_values{
          .link{
            margin-bottom: 10px;
            a{
              font-family: QuicksandLight;
              font-size: 1.8vw;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }
          }
        }
        .social_media_values{
          display: flex;
          .social_link{
            a{
              margin-right: 10px;
              img{
                width: 30px;
                height: 30px;
              }
            }
          }
        }
        .contact_values{
          .contact{
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            img{
              width: 30px; 
              margin-right: 1vw;
            }
            .vals{
              display: flex;
              justify-content: center;
              flex-direction: column;
              flex-wrap: wrap;
              >*{
                display: block;
                font-family: QuicksandLight;
                font-size: 1.5vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  &.mobile{
    width: 100%;
    padding: 10vw 16% 9vw 16%;
    object-fit: contain;
    background-image: linear-gradient(to top, #0da39d, #64c2be);
    border-top: 0;
    .logo{
      padding-right: 3vw;
      padding-left: 3vw;
      .img{
        margin-bottom: 4vw !important;
        img{
          width: 30vw;
          height: 26vw;
          object-fit: contain;
          max-width: 180px;
          max-height: 108px;
        }
      }
      .Plateforme-dapprentissage-en-ligne-qui-permet-de-retrouver-to {
        display: block;
        font-family: QuicksandLight;
        font-size: 3.2vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      .buttons{
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 5vw;
        width: 110%;
        a{
          margin-right: 2vw;
          width: 50%;
          // width: calc(100% - 15px);
          img{
            width: 100%;
            height: 72px;
            max-width: 180px;
            object-fit: contain;
          }
        }
      }
    }
    .links{
      padding: 0 15%;
      .title{
        font-size: 5.2vw;
        margin: 8vw 0 4vw;
      }
      .values{
        .social_link{
          a{
            img{
              width: 8vw;
              height: 8vw;
            }
          }
        }
      }
    }
    .contacts{
      padding: 0 15%;
      .title{
        font-size: 5.2vw;
        margin: 8vw 0 4vw;
      }
      .values{
        .contact{
          img{
            width: 10vw;
            margin-right: 2vw;
          }
          .vals{
            span{
              font-size: 3.5vw;
            }
          }
        }
      }
    }

    .row{
      .-All-right-reserved-Koolskools-Made-by-MeteoriAgency {
        display: block;
        margin: 3vw 1px 0 1px;
        font-family: QuicksandLight;
        font-size: 1.5vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      .Termes-et-politique-de-confidentialit {
        display: block;
        cursor: pointer;
        margin: 3vw 0 0 0;
        font-family: QuicksandLight;
        font-size: 1.5vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
        display: none;
      }
      >div{
        .title{
          font-family: QuicksandLight;
          font-size: 5.2vw;
          margin: 8vw 0 4vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.24;
          letter-spacing: normal;
          text-align: left;
          color: #042a45;
        }
        .links_values{
          .link{
            margin-bottom: 10px;
            a{
              font-family: QuicksandLight;
              font-size: 1.8vw;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }
          }
        }
        .social_media_values{
          display: flex;
          .social_link{
            a{
              margin-right: 10px;
              img{
                width: 8vw;
                height: 8vw;
              }
            }
          }
        }
        .contact_values{
          .contact{
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            img{
              width: 10vw;
              margin-right: 2vw;
            }
            .vals{
              display: flex;
              justify-content: center;
              flex-direction: column;
              flex-wrap: wrap;
              >*{
                display: block;
                font-family: QuicksandLight;
                font-size: 3.5vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                width: 100%;
              }
            }
          }
        }
        .links{
          adding-left: 50px;
        }
        .contacts{
          adding-left: 50px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
  }
  @media (max-width: 767px) {
  }
`;

export default withTranslation('translations')(HomeFooter);