import React, {Component} from "react";
import styles from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import bgFooter from "../../../../assets/img/bg-footer.png";
import { images } from "../_resources";
import { colors } from "../../../../assets/variables/colors";
import { Link } from "react-router-dom";
import { get } from "services/storage";

class Footer extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return (
      <>
        <FooterStyle>
          <div className="footer-logo">
            <a href="/child/dashboard">
              {" "}
              <img src={global.is_koolskools?images.logoFooter:global.logo} />
            </a>
          </div>
          <div className="container">
            <div className="bloc-footer new">
              <h3>{ this.props.t('Contact') }{": "}<a href="mailto:hello@koolskools.com">hello@koolskools.com</a></h3>
              <h3>{ this.props.t('Tél') }{": "}+212 6 62 40 58 54 / +212 6 67 94 78 83</h3>
            </div>
            {/* <div className="bloc-footer">
              <h3>{ this.props.t('About us') }</h3>
              <ul>
                <li>
                  <Link to="/child/dashboard">{ this.props.t('Who are we?') }</Link>{" "}
                </li>
                <li>
                  <a href="">{ this.props.t('Team') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Terms of Use') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Privacy policy') }</a>
                </li>
              </ul>
            </div>

            <div className="bloc-footer">
              <h3>{ this.props.t('Our offer') }</h3>
              <ul>
                <li>
                  <a href="">{ this.props.t('Children') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Parents') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Professors') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Schools') }</a>
                </li>
              </ul>
            </div>

            <div className="bloc-footer">
              <h3>{ this.props.t('Subjects and Level') }</h3>
              <ul>
                <li>
                  <a href="">{ this.props.t('From CP to 6th') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Maths') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('French') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Arab') }</a>
                </li>
              </ul>
            </div>

            <div className="bloc-footer">
              <h3>{ this.props.t('Help') }</h3>
              <ul>
                <li>
                  <a href="">{ this.props.t('Questions') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Forgot your password') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('Contact') }</a>
                </li>
              </ul>
            </div>

            <div className="bloc-footer">
              <h3>{ this.props.t('International') }</h3>
              <ul>
                <li>
                  <a href="">{ this.props.t('Morocco') }</a>
                </li>
                <li>
                  <a href="">{ this.props.t('France (in progress)') }</a>
                </li>
              </ul>
            </div> */}
          </div>
        </FooterStyle>
        <Copyright className="copyright">
          <p>{ this.props.t('2019 KoolSkools. Carefully crafted by 3 Minds Digital') }</p>
        </Copyright>
      </>
    );
  }
};
const Copyright = styles.div`
width:100%;
  float: left;
  background: #46bfb6;
  text-align: center;
  padding: 10px;
  p{
    font-family: 'Montserrat', sans-serif;
    font-size: 10.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }
`;
const FooterStyle = styles.footer`
      width: 100%;
      float: left;
      background: #f7f7f7;
      border-top: 10px solid ${colors.white};
      box-shadow: 0 -2px 10px 0 rgba(0,0,0,0.15);
      padding-bottom: 30px;
    
      .footer-logo{
        text-align: center;
        margin-bottom: 30px;
        a{
          background: url(${bgFooter}) no-repeat scroll;
          width: 294px;
          height: 143px;
          display: block;
          margin: -115px auto 0;
          padding-top: 20px;
          -webkit-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
          -moz-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
          -ms-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
          -o-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
          filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
          img{
            width: 167px;
          }
        }
      }
      .bloc-footer{
        float: left;
        width: 20%;
        text-align: left;
        h3{
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2.4px;
          margin-bottom: 20px;
          color: #292929;
          opacity: 0.63;
        }
        ul{
          margin: 0;
          padding: 0;
          li{
            list-style-type: none;
            a{
              opacity: 0.63;
              font-family: 'Montserrat', sans-serif;
              font-size: 15px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.57;
              letter-spacing: normal;
              color: #6c6c6c;
            }
          }
        }
        &.new{
          display: flex;
          justify-content: space-around;
          float: right;
          text-align: center;
          width: 100%;
          margin-top: -15px;
          h3{
            margin-bottom: -20px;
          }
        }
      }
      @media(max-width: 1199px){
        .bloc-footer{
          width: 33.33%;
          ul{
             li{
               a{
                 font-size:13.5px;
               }
             }
          }
        }
      }  

      @media(max-width: 767px){
        overflow-x: inherit;
        .bloc-footer{
          width: 100%;
        }
        .footer-logo{
          margin-bottom: 10px;
          a{
            background-size: 246px auto;
            width: 246px;
            height: 127px;
            margin: -100px auto 0;
            img{
              width: 140px;
            }
          }
        }
      }
`;

export default withTranslation('translations')(Footer);
