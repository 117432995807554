import 'isomorphic-fetch/fetch-npm-browserify'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import translations_fr from "./translations/fr/translations.json";
import translations_ar from "./translations/ar/translations.json";
import translations_en from "./translations/en/translations.json";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { get, set } from "./services/storage";
import AudioRecorder from 'audio-recorder-polyfill';
import { registerServiceWorker } from "./register_sw";


registerServiceWorker();
// try {

//     const OldNotify = window.Notification;
//     const newNotify = (title, opt) => {
//         debugger
//         var event = document.createEvent('Event');
//         event.title_notification = title;
//         event.opt_notification = opt;
//         event.initEvent('new_notification', true, true);
//         window.dispatchEvent(event);
//         return new OldNotify(title, opt);
//     };
//     newNotify.requestPermission = OldNotify.requestPermission.bind(OldNotify);
//     Object.defineProperty(newNotify, 'permission', {
//         get: () => {
//             return OldNotify.permission;
//         }
//     });

//     window.Notification = newNotify;
// }
// catch(err){

// }

window.MediaRecorder = AudioRecorder
if(!get("current_language")){
    set("current_language", "fr");
    global.language = "fr";
}

let vh = window.innerHeight * 0.01,
    vw = window.innerWidth * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
document.documentElement.style.setProperty('--vw', `${vw}px`);
window.addEventListener("orientationchange", function() {
    setTimeout(()=>{
        let vh = window.innerHeight * 0.01,
            vw = window.innerWidth * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    },500);
});

document.addEventListener("fullscreenchange", function() {
    setTimeout(()=>{
        let vh = window.innerHeight * 0.01,
            vw = window.innerWidth * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    },500);
});

global.language = get("current_language") || "fr";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: get("current_language") || "fr",                              // language to use
    resources: {
        en: {
            translations: translations_en               // 'common' is our custom namespace
        },
        fr: {
            translations: translations_fr
        },
        ar: {
            translations: translations_ar
        },
    },
});
// console.log(process.env.URL_WS)
require('dotenv').config()
ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
