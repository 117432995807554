import React, { Component } from "react";
import styled from "styled-components";

import Header from "../../children/home/components/Header";
import Footer from "../../children/home/components/footer";
import Carousel from "./components/Carousel";
import BlocFeeds from "../../children/home/components/Bloc-feeds";
import SectionGradient from "../../children/home/components/SectionGradient";
import ModalInvalidKey from "./components/ModalInvalidKey"
import queryString from 'query-string';

import {forgotPassword} from "services/api";
class Professor extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    var openConnexion = false;
    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      else if(global.is_pont_logo)
        data_.from = 'pont';
      else if(global.is_reference_logo)
        data_.from = 'reference';
      else if(global.is_anisse_logo)
        data_.from = 'anisse';
      else if(global.is_yakada_logo)
        data_.from = 'yakada';
      else if(global.is_kastalani_logo)
        data_.from = 'kastalani';
      else if(global.is_babylone_logo)
        data_.from = 'babylone';
      else if(global.is_jeannedarc_logo)
        data_.from = 'jeannedarc';
      else if(global.is_montessori_logo)
        data_.from = 'montessori';
      else if(global.is_fmps_logo)
        data_.from = 'FMPS';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
    this.state = {
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      openConnexion:openConnexion,
      openRegister: false
    };
    this.myRef = React.createRef();
    this.RefMobile = React.createRef();
    this.params = queryString.parse(this.props.location.search);
    
  }

  updateDimensions = () => {
    this.setState({
      clientWidth: this.myRef.current.clientWidth,
      clientHeight: this.myRef.current.clientHeight,
      widthMobile: this.RefMobile.current.offsetWidth,
      heightMobile: this.RefMobile.current.offsetHeight
    });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <Professors>
        {this.props.location && this.props.location.state && this.props.location.state.invalid_key &&
          <ModalInvalidKey success={true}
            handleCloseSuccess={()=>{
              const state = { ...this.props.history.location.state };
              delete state.invalid_key;
              this.props.history.replace({ ...this.props.history.location, state });
            }}
          />
        }
        <Header pages="professor" {...this.props}
          username={this.params.username} 
          account_activated={this.params.account_activated && this.params.account_activated === "true"} 
          openConnexion={this.state.openConnexion}
          openRegister={this.state.openRegister}
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleCloseConnexion={() => this.setState({openConnexion:false, openRegister: false, reset_password_response: "", reset_password_error:""})}
          handleOpenConnexion={()  => this.setState({openConnexion:true, openRegister: false})}
          handleCloseRegister={() => this.setState({openRegister:false, openConnexion:false})}
          handleOpenRegister={()  => this.setState({openRegister:true,openConnexion:false})}
          {...this.props}/>
        <section className="one" ref={this.myRef}>
          <SectionGradient
            clientWidth={this.state.clientWidth}
            clientHeight={this.state.clientHeight}
            widthMobile={this.state.widthMobile}
            heightMobile={
              this.state.heightMobile > -60 ? 420 : this.state.heightMobile
            }
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-12 " ref={this.RefMobile}>
                <Carousel 
                  pages="professor"
                  handleOpenModalConnexion={()  => this.setState({openConnexion:true, openRegister: false})}
                  handleOpenModalRegister={()  => this.setState({openRegister:true,openConnexion:false})}
                />
              </div>
              <div className="col-xl-4 col-lg-12 news-feed">
                <BlocFeeds />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Professors>
    );
  }
}

const Professors = styled.div`
  section {
    margin-bottom: 100px;
  }
  @media (max-width: 1199px) {
    section {
      margin-bottom: 150px;
    }
  }
`;

export default Professor;
