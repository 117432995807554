import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import styled from "styled-components";
import ModalConfirm from "pages/school/inscriptions/components/ModalConfirm";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import LdsSpinner from 'components/LdsSpinner';

import {clear} from "services/storage";

import { set, get } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';

import { loginProfessor } from "services/actions";
import { globalData } from "services/cms/actions";

import { sanady_unconnected_api_get } from "services/cms/api";
import ModalPassword from "pages/children/home/components/ModalPassword"
import ModalProfessorSanadiRegister from "pages/professor/home/components/ModalProfessorSanadiRegister"
import ModalCompteSuccess from "pages/children/home/components/ModalCompteSuccess";

import {images} from './_resources'
import ModalPasswordChanged from "../professor/home/components/ModalPasswordChanged";
import {forgotPassword} from "services/api";
import localforage from 'localforage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position_carousel: 0,
      open: false,
      a_propos_next: false,
      open_activity: false,
      open_audio: false,
      open_video: false,
      open_history: false,
      open_register_professor_sanadi: false,
      open_ask_if_professor_sanadi_exists: false,
      openConnexion: false,
      success: false,
      messageSuccess: "",
      section: 0,
      resend_activation_data: "",
      resend_activation_data_message: "",
      programs_projects_schools: {},
      isSubmitting: false,
      reset_password_response: "",
      reset_password_error: "",
      page: 0
    }

    const query = new URLSearchParams(props.location.search);

    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      else if(global.is_pont_logo)
        data_.from = 'pont';
      else if(global.is_reference_logo)
        data_.from = 'reference';
      else if(global.is_anisse_logo)
        data_.from = 'anisse';
      else if(global.is_yakada_logo)
        data_.from = 'yakada';
      else if(global.is_kastalani_logo)
        data_.from = 'kastalani';
      else if(global.is_babylone_logo)
        data_.from = 'babylone';
      else if(global.is_jeannedarc_logo)
        data_.from = 'jeannedarc';
      else if(global.is_montessori_logo)
        data_.from = 'montessori';
      else if(global.is_fmps_logo)
        data_.from = 'FMPS';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
  }

  componentDidMount(){
    if(global.is_sanady_logo){
      this.getProgramsProjectsSchools();
    }
  }

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };
  onOpenModalProfessorSanadiRegister = (professor_already_exists, close_open_ask_if_professor_sanadi_exists) => {
    this.setState({
      professor_already_exists: professor_already_exists,
      open_ask_if_professor_sanadi_exists: close_open_ask_if_professor_sanadi_exists ? false : this.state.open_ask_if_professor_sanadi_exists,
      open_register_professor_sanadi: true
    });
  };
  onOpenModalAskIfProfessorSanadiExists = () => {
    this.setState({
      open_ask_if_professor_sanadi_exists: true
    });
  };
  onCloseModalProfessorSanadiRegister = () => {
    this.setState({
      professor_already_exists: false,
      open_register_professor_sanadi: false
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handlePasswordReseted = (message) => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(this.props.t("Username required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required"))
    });
  };

  handleSubmit = form => {
    const {section} = this.state;
    let loginprof = this.props.addedData || {};
    if(this.props.addedData){
      loginprof.added_action = "add_school";
    }
    loginprof.username = form.username;
    loginprof.password = form.password;
    if(section == 3)
      loginprof.page_front = 'parent';
    
    var lev = get("level_groups");
    var current_language = get("current_language"),
      device_token = get('device_token');
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("device_token", device_token);
    set("level_groups", lev);
    set("current_language", current_language);
    return this.props
      .dispatch(loginProfessor(loginprof))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(section === 2){
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else{
              if(!user.profile.is_school_admin)
                this.props.dispatch(globalData({}));
              this.props.history.push("/");
            }
          }else if (section === 3) {
            if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0 || user.account_type.indexOf('pa') != -1){
              this.props.history.push("/parents/dashboard");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else{
              if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
                this.props.dispatch(globalData({}));
                this.props.history.push("/parents/dashboard");
                // else
                //   this.props.history.push("/parents/candidatures");
              }
              else
                this.props.history.push("/");
            }
          }else if (section === 1) {
            if(['pr', 'pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
              
              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
                this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
              this.props.history.push("/parents/dashboard");
            }
            else{
              this.props.history.push("/");
            }
          } else {
            if(user.profile.is_school_admin && (user.profile.is_program_system || user.profile.is_sanady_assistant) && !get('project')){
              this.props.history.push("/school/program/list");
            }
            else if(user.profile.is_school_admin && (user.profile.is_program_system || user.profile.is_sanady_assistant)){
              this.props.history.push("/school/list");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/school/list");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
              
              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
                this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else
              this.props.history.push("/");
          }
        }
        
      })
      .catch(err => {
        var message = err.message || "";
        this.setState(state => ({
          errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
          username: form.username,
          isSubmitting: false
        }));
      });
  }

  render_connexion = () => {
    const {isSubmitting, section} = this.state;
    return <div className="modalConnexion">
    <div className="modalConnexionTop">
      <Formik
        initialValues={{
          username: this.props.username || this.state.username || "",
          password: ""
        }}
        validationSchema={this.getValidationSchema}
        onSubmit={values => {
          this.setState({
            isSubmitting:true
          })
          this.handleSubmit(values);
        }}
      > 
        {({ errors, touched, values, handleChange }) => {
          return (<Form className="d-flex flex-column align-items-center">
            {this.state.errorGlobal && (                    
              <div style={{color: 'red'}}>{this.state.errorGlobal}</div>
            )}
           
          <div className="">
            <div className={`form-connexion ${global.language == 'ar' && "rtl_mode"}`}>
              <div className="form-item">
                <label>{this.props.t('Identifiant')}</label>
                <input type="text"
                  className={`textInput ${errors.username && "textInputError"}`}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && touched.username ? (
                  <div className="textErrors">{errors.username}</div>
                ) : null}
              </div>
              <div className="form-item">
                <label>{this.props.t('Mot de passe')}</label>
                <input type="password"
                  className={`textInput ${errors.password && "textInputError"}`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password ? (
                  <div className="textErrors">{errors.password}</div>
                ) : null}
              </div>
              <div className="d-flex justify-content-end" style={{width: 500}}>
                <a onClick={() => this.onOpenModalPassword()} className="forgot">
                  { this.props.t('FORGOT PASSWORD?') }
                </a>
              </div>
            </div>
            <div className="form-footer">
              <div className='d-flex'>
                {isSubmitting && <LdsSpinner type='relative'/>}
                <button type="submit" className="signin" 
                  style={{float:'none', backgroundColor: '#03387a'}} 
                  disabled={isSubmitting}>
                { this.props.t('SIGN IN') } 
                </button>
              </div>
            </div>
          </div>
        </Form>);
        }}
      </Formik>
    </div>
  </div>
  }

  getProgramsProjectsSchools = () => {
    var data = {
      action: "get_programs_projects_schools",
    };
    sanady_unconnected_api_get(data).then(res => {
      if(res.success){
        this.setState({programs_projects_schools: res.programs_projects_schools || {}});
      }

    }).catch(err => {
      console.log(err);
    })
  }

  changeLanguage = (new_language) => {
    set("current_language", new_language);
    global.language = new_language;
    this.props.i18n.changeLanguage(new_language);
    this.setState({language: new_language})
  }

  scroll_to_section = (e, section) => {
    e.preventDefault(); // Prevent default jump behavior
    const targetSection = document.querySelector(section);

    if (targetSection) {
        window.scrollTo({
            top: targetSection.offsetTop, // Adjust offset for navbar
            behavior: 'smooth' // Smooth scroll effect
        });
    }
  }

  render_first_page = () => {
    return <div className="first_page" style={{
        backgroundImage: `url(${global.language == 'ar'?images.bg_unconnected_ar:images.bg_unconnected})`, padding: '4vh'
      }}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <div>
          <img src={global.language == 'ar'?images.logo_fmps_ar:images.logo_fmps} style={{width: '5vw'}}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="menu-header" style={{width: '50vw', marginRight: 20}}>
            <div className="menu-item" 
              onClick={e => this.scroll_to_section(e, "#home")}
            >{this.props.t('ACCUEIL')}</div>
            <div className="menu-item" onClick={e => this.scroll_to_section(e, "#a_propos")}>{this.props.t('À PROPOS')}</div>
            <div className="menu-item" onClick={e => this.scroll_to_section(e, "#contact_us")}>{this.props.t('CONTACT')}</div>
            <div className="menu-item">{this.props.t('FAQ')}</div>
          </div>
          <div style={{color: '#FFF', fontSize: 18, fontWeight: 400}}>
            <span onClick={() => this.changeLanguage("fr")}>FR</span> | <span style={{fontSize: 20}} onClick={() => this.changeLanguage("ar")}>عربية</span>
          </div>
        </div>
      </div>
      <div style={{
          display: 'flex', height: '90vh'
        }}>
        <div style={{
          display: 'flex', flexDirection: 'column', width: '29vw'
        }}>
          <img src={images.logo_atfalouna_learning} style={{width: '100%', marginTop: '6vh'}}/>
          <img src={global.language == 'ar'?images.home_form_ar:images.home_form} style={{width: '100%'}}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', 
            alignItems: 'center', justifyContent: 'space-between', margin: '0px 1vw', width: '40vw'}}>
          <img src={global.language == 'ar'?images.all_plateforme_img_ar:images.all_plateforme_img} style={{width: '100%', marginTop: '7vh'}}/>
          <div style={{display: 'flex', flexDirection:'column',
              alignItems: 'center'
          }}>
            <button className="signin" 
              onClick={() => this.setState({page : 1})}
              style={{float:'none', backgroundColor: '#03387a', padding: '1vw 1.5vh', fontSize: '1.2vw', minWidth: '24vw'}} 
              >
              {this.props.t("CONNECTEZ-VOUS")}
            </button>
            <div style={{color: '#043566', marginTop: '1vh', fontSize: '1.1vw', fontWeight: 600}}>
              {this.props.t("Pour commencer votre nouvelle expérience d'apprentissage !")}</div>
          </div>
        </div>
        <div style={{width: '30vw'}}></div>
      </div>
    </div>
  }

  render_login_page = () => {
    return <div className="d-flex" style={{padding: '0px 20px'}}>
    <div className="justify-content-center align-items-center" style={{overflow: 'hidden'}}>
      <img src={images.login_background} style={{height: '100vh'}}/>
    </div>
    <div style={{padding: global.language == 'ar'?'20px 100px 20px 0px':'20px 0px 20px 100px', flex: 1}}>
      <div className="text-right">
        <img src={images.menu_home_connexion} 
          style={{...{cursor: 'pointer', position: 'absolute'}, ...global.language == 'ar'?{left: 40}:{right: 40}}}
          onClick={() => this.setState({page : 0})}/>
      </div>
      <div style={{height: '100vh', width: 500}} className="d-flex flex-column justify-content-center align-items-center">
        
        <h1 style={{color: '#03387a', fontWeight: 'bold'}}>{this.props.t('Connexion')}</h1>
        {this.render_connexion()}
        
      </div>
    </div>
  </div>
  }

  render() {
    const {section, page, position_carousel} = this.state;
    return (
      <Children style={{direction: global.language == 'ar'?'rtl':'ltr'}}>
        <section style={{overflow: 'hidden'}} id="home">
          {page == 0?this.render_first_page():this.render_login_page()}
        </section>
        {page == 0 &&
        <>
          <section id="a_propos" style={{backgroundImage: `url(${images.apropos_bg})`}}>
            <div className='a_propos_left'>
              <img src={global.language == 'ar'?images.apropos_img_ar:images.apropos_img}/>
            </div>
            <div className='a_propos_right' style={{direction: global.language == 'ar'?'rtl':'ltr'}}>
              <div>
                <img src={global.language == 'ar'?images.apropos_title_ar:images.apropos_title}/>
              </div>
              <div className="content" >
                {global.language == 'ar'?
                <>
                 <p>"ATFALOUNA LEARNING" هي منصة تعليمية ذاتُ بعدٍ تفاعلي، طورتها المؤسسة
المغربية للتعليم الأولي (FMPS) بشراكة مع KOOLSKOOLS (ED-ALL) ، تتيحُ المنصة
عالمًا رقميا ممتعًا وجذابًا لأطفال التعليم الأولي بين 4 ـ 6 سنوات بطريقة قوامها اللعب والتعلم معًا،
وبتقنياتٍ وطرائق بيداغوجية متنوعة كما تشيرُ إلى ذلكَ الأبحاثُ التربوية؛
</p>
<p>
وذلكَ، بِفضل مواردها التربوية والتعليمية المتنوعة والغنية في آن، والتي تراعي حاجاتهم النفسية
والتربوية بالإضافة إلى التعلمات الأساسية لنموهم العقلي المعرفي والاجتماعي الوجداني، بناءً على
توجيهات الإطار المنهاجي؛ الوثيقة المرجعية التي أعدتها وزارة التربية الوطنية والتعليم الأولي
والرياضة؛
</p>
<p>
كما أنها أداة فعّالة لتسهيلِ الممارسات التربوية والتعليمية داخل أقسامِ التعليم الأولي، بحيثُ تمَكّن المربين
والمربياتِ من مرافقة الأطفال بطريقة عملية وَتفاعلية، وتسهمُ أيضًا في تتبع تقدمهم في الوقت ذاته.
</p>
{this.state.a_propos_next &&
  <>
<p>تهدف منصة «ATFALOUNA LEARNING» إلى:</p>
<p>تعزيز ممارسة تربوية ممتعة وجذابة: وذلكَ، من خلال وضع الأطفال داخلَ وضعيات تعلمية
وتفاعلية تشدّ انتباههم، وتدعم نموهم وبناء تعلماتهم؛</p>
<p>تعزيز التطور الشامل للطفل: عبر تنمية ذهنية تنظيم التفكير؛ وحل المشكلات داخلَ وضعيات تعلمية
مختلفة، والتي تتطلبُ استخدام مجموعة منَ القدرات الذهنية كالمقارنة والتنظيم...</p>
<p>تعزيز التعلمات الأساسية: بواسطة استخدام أدوات رقمية تسهمُ في اكتساب المهارات الأساسية، وذلكَ
عبر تنويعِ الوسائط (كلمات، صور، أصوات، شرائط) مما يساهمُ في تنمية قدرات التواصل
والاصغاء والحوار..</p>
<p>تحفيز الفضول والإبداع: عبر تطوير الجانب الفني والجمالي للأطفالِ، وتشجيعهم على الاستكشاف
من خلال الألعاب التفاعلية.</p>
</>}
                </>
                :
                <>
                  <p>ATFALOUNA LEARNING est une plateforme éducative interactive développée par la Fondation Marocaine du Préscolaire (FMPS) en partenariat avec KOOLSKOOLS (ED-ALL). 
                  Elle propose un environnement numérique ludique et engageant où les enfants du préscolaire apprennent tout en s'amusant.</p>
                  <p>Grâce à des ressources pédagogiques variées et engageantes, les enfants acquièrent des notions essentielles à leur développement 
                  et bien d'autres compétences conformément au cadre curriculaire du ministère de l'éducation nationale, du préscolaire et des sports.</p>
                  <p>Elle facilite leurs pratiques quotidiennes en classe, leur permettant d'accompagner les enfants de manière interactive et de suivre leur progression en temps réel.</p>
                  {this.state.a_propos_next &&
                  <>
                    <p>La plateforme ATFALOUNA LEARNING a pour objectif de :</p>
                    <p>Valoriser une approche ludique et attrayante en offrant des expériences engageantes qui captivent l'attention des enfants tout en soutenant leur développement et la construction de leurs apprentissages.</p>
                    <p>Renforcer le développement global de l'enfant en encourageant la pensée logique, la résolution de problèmes, ….</p>
                    <p>Renforcer les apprentissages fondamentaux en utilisant des outils numériques pour soutenir l'acquisition des compétences de base à travers des approches multimodales (images, sons, interactions).</p>
                    <p>Stimuler la curiosité et la créativité en encourageant l'exploration à travers des jeux interactifs.</p>
                  </>
                  }
                </>
                }
              </div>
              <div className="w-100 d-flex justify-content-end mt-3">
                <button className="btn" onClick={() => this.setState({a_propos_next: !this.state.a_propos_next})}>{this.props.t(this.state.a_propos_next?"Moins":"Lire la suite")}</button>
              </div>
            </div>
          </section>
          <section id="services" >
            <div className='title'>
              {global.language == 'ar'?'تهدف منصة "LEARNING ATFALOUNA" إلى :':'Ce que vous trouverez sur ATFALOUNA LEARNING'}
            </div>
            <div className={`services-cards ${position_carousel > 0 ?'to-right':''} ${position_carousel < 0?'to-left':''}`} id="services-cards">
              <div className="card-item blue">
                <div className="img">
                  <img src={global.language == 'ar'?images.bg_blue_card_ar:images.bg_blue_card}/>
                  <div className="card-item-title">
                    <div>{this.props.t("Activités")}</div> 
                    <div>{this.props.t("interactives")}</div>
                  </div>
                </div>
                <div className={`content ${global.language == 'ar'?'rtl_mode':''}`}>
                {global.language == 'ar'?
                <>
                <p>
                ترومُ المنصة من خلال أنشطتها التفاعلية تحقيق نوع من الملاءمة معَ إيقاعَات نمو الأطفال بين 4 – 6
سنوات، مما يساعدهم على بناء التعلمات وتطوير مهاراتهم.
تهدف هذه الأنشطة إلى:
</p>
<p className="line">
اكتساب الكفايات التعليمية : بناءً على البرنامج التربوي للتعليم الأولي، وذلك معَ تحديد مواصفات
الأطفالِ عندَ الخروج، المنتظر استيفاؤها في نهاية مرحلة التعليم الأولي.
</p>
<p className="line">
تطوير المهارات العقلية والمعرفية، وذلكَ من خلال :
</p>
<p >
 - تحفيز التفكير المنطقي والنقدي عبر اعتماد وضعيات مشكلة؛
 </p>
 <p>
 - تقوية الذاكرة والانتباه من خلال أنشطة تفاعلية تعزز التركيز.
 </p>
<p className="line">
تمكين الطفل من الثقافة الرقمية : وذلكَ، عبر تعويده على استخدام الوسائل الرقمية، والأدوات
الأساس في التعاطي معَ الأدوات الرقمية؛
</p>
                </>
                :
                <>
                  <p>
                    Des activités interactives et stimulantes adaptées à l'âge des enfants pour leur permettre d'apprendre et de perfectionner leurs compétences
                    <br/>
                    Ces activités ont pour but pédagogique :
                  </p>
                  
                  <p className="line">
                    Renforcer les compétences du programme pédagogique afin d'atteindre le profil de sortie attendu en fin du préscolaire.
                  </p>
                  {/* {this.state.open_activity && */}
                  <>
                    <p>Développer les compétences cognitives :</p>
                    <p className="line">Stimuler la logique et la pensée critique à travers des activités de résolution de problèmes.</p>
                    <p className="line">Renforcer la mémoire et l'attention grâce à des jeux interactifs favorisant la concentration.</p>
                    <p className="line">Initier l'enfant à la culture numérique en le familiarisant avec les interfaces et les outils technologiques de base.</p>
                  </>
                  {/* } */}
                </>
                }
                </div>
                {/* <div className="footer-btn">
                  <span onClick={() => this.setState({open_activity: !this.state.open_activity})}>{this.props.t(this.state.open_activity?'Moins':'Lire la suite')}</span>
                </div> */}
              </div>
              <div className="card-item orange">
                <div className="img">
                  <img src={global.language == 'ar'?images.bg_orange_card_ar:images.bg_orange_card}/>
                  <div className="card-item-title">
                    <div>{this.props.t("Chansons")}<br/>{this.props.t("éducatives")}</div>
                  </div>
                </div>
                <div  className={`content ${global.language == 'ar'?'rtl_mode':''}`}>
                {global.language == 'ar'?
                <>
                <p>
                أناشيد تربوية ممتعة ذات إيقاعات وألحان موسيقية تساعد الأطفال على التعلم بطريقة تربوية؛
تهدف الأناشيد التربوية إلى:
</p>
<p className="line">
إثراء المعجم اللغوي : من خلال تعلم واكتساب مفردات وتعبيرات جديدة، مما يسهل حفظها
واستعمالها في السياقات التواصلية اليومية.
</p>
<p className="line">
تحسين النطق والتلفظ : عبر ترديد الأصوات والمقاطع الصوتية من خلال الغناء؛
</p>
<p className="line">
تنمية الملكة اللغوية : من خلال وضع الطفل أمام جملٍ سليمة نحويًا وتركيبيًا وصرفيًا، مما يُيَسِرَ
فهمها وإعادة استخدامها في سياقات تعلّمية مختلفة.
</p>
<p className="line">
تطوير مهارات الاستماع الفعّال : من خلال أنشطة تركز على النطق السليم للكلمات، والألحان،
والإيقاعات، مما يحسن حسَّ الاصغاءَ والفهم معًا.
</p>
                </>
                :
                <>
                  <p>
                  Des chansons éducatives entraînantes pour apprendre en rythme et avec plaisir !
                  <br/>
                  Ces chansons ont pour but pédagogique :
                  </p>
                  <p className="line">
                    Enrichir le vocabulaire grâce à l'apprentissage de nouveaux mots et expressions, facilitant leur mémorisation et intégration dans le langage quotidien.
                  </p>
                  {/* {!this.state.open_audio? 
                    <p className="line">
                      Améliorer la prononciation et l'articulation.
                    </p>
                  : */}
                    <>
                      <p className="line">
                        Améliorer la prononciation et l'articulation en pratiquant les sons et les phonèmes à travers le chant, favorisant une meilleure intonation et fluidité linguistique.
                      </p>
                      <p className="line">
                        Renforcer la structure linguistique en exposant l'enfant à des phrases grammaticalement correctes, facilitant leur compréhension et reproduction.
                      </p>
                      <p className="line">
                        Développer l'écoute active en travaillant l'attention auditive à travers la répétition des paroles, des mélodies et des rythmes, améliorant ainsi la compréhension orale.
                      </p>
                    </>
                  {/* } */}
                  </>
                  }
                </div>
                {/* <div className="footer-btn">
                  <span onClick={() => this.setState({open_audio: !this.state.open_audio})}>{this.props.t(this.state.open_audio?'Moins':'Lire la suite')}</span>
                </div> */}
              </div>
              <div className="card-item green2">
                <div className="img">
                  <img src={global.language == 'ar'?images.bg_green_card_ar:images.bg_green_card}/>
                  <div className="card-item-title">
                    <div>{this.props.t("Vidéos de")}<br/>{this.props.t("vocabulaire")}</div>
                  </div>
                </div>
                <div className={`content ${global.language == 'ar'?'rtl_mode':''}`}>
                {global.language == 'ar'?
                <>
                <p>
                شرائط ممتعة وتفاعلية تهدف إلى تنمية لغة الأطفال وإثراء مفرداتهم!
تهدف هذه الفيديوهات إلى:
</p>
                  <p className="line">
                  تعزيز التعلم عبرَ الوسائط البصرية والسمعية من خلال التوليف بينَ الصور، الكلمات، والرسوم
                  المتحركة، مما يسهل اكتساب المفردات عبر وسائل ذات بُعد حسي تناسب أطفال التعليم الأولي.
                  </p>
                  <p className="line">
                  تقوية الفهم والاستيعاب : وذلكَ، بِربط الكلمات بالصور الثابتة والمتحركة، ووضعها في وضعيات
                  تعلمية تتأرجحُ بينَ مشاهدَ واقعية وخيالية، مما يؤدي إلى تطوير المهارات اللغوية للطفل، وترسيخ
                  الكلمات والصور في ذاكرتهِ البصرية.
                  </p>
                </>
                :
                <>
                  <p>
                  Des vidéos ludiques et interactives pour enrichir le langage et le vocabulaire des enfants !
                  <br/>Ces vidéos ont pour but pédagogique :
                  </p>
                  {/* {!this.state.open_video?
                  <p className="line">
                    Favoriser un apprentissage visuel et auditif en combinant images, mots et animations, facilitant l'acquisition du vocabulaire grâce à des supports multisensoriels adaptés aux
                  </p>
                  :
                  <> */}
                    <p className="line">
                      Favoriser un apprentissage visuel et auditif en combinant images, mots et animations, facilitant l'acquisition du vocabulaire grâce à des supports multisensoriels adaptés aux enfants d'âge préscolaire.
                    </p>
                    <p className="line">Renforcer la compréhension et la mémorisation en associant les mots à des images ou actions, et en les intégrant dans des scénarios concrets ou imaginaires pour développer les compétences linguistiques de l'enfant.</p>
                  {/* </>
                  } */}
                  </>
                  }
                </div>
                {/* <div className="footer-btn">
                  <span onClick={() => this.setState({open_video: !this.state.open_video})}>{this.props.t(this.state.open_video?'Moins':'Lire la suite')}</span>
                </div> */}
              </div>
              <div className="card-item pink">
                <div className="img">
                  <img src={global.language == 'ar'?images.bg_pink_card_ar:images.bg_pink_card}/>
                  <div className="card-item-title">
                    <div>{this.props.t("Histoires")}<br/>{this.props.t("numériques")}</div>
                  </div>
                </div>
                <div className={`content ${global.language == 'ar'?'rtl_mode':''}`}>
                {global.language == 'ar'?
                <>
                <p>
                هي قصص رقمية متحركة وسمعية، نروم من خلالها شد انتباه الأطفال وتحفيز خيالهم!
تهدف هذه الوسائط المتحركة والسمعية إلى:
</p>
                  <p className="line">
                  تنمية اللغة والتواصل : عبر إثراء معجم الطفل، وسلامة بنية الجمل والكلمات، وتقويم النطق السليم.
                  </p>
                  <p className="line">
                  تحفيز الخيال وروح الإبداع : من خلال تشجيع الأطفال على استكشاف عوالم خيالية وملهمة، وكذا
                  تأليف قصصهم الخاصة.
                  </p>
                  <p className="line">
                  تعزيز التطور العقلي والمعرفي : وذلكَ، عبر تنظيم التفكير، وَتحسين عمل الذاكرة، وتعزيز التفكير
                  المنطقي والربط السببي، من خلال فهم تسلسل الأحداث داخلَ القصص.
                  </p>
                  <p className="line">
                  تحفيز التفاعل الاجتماعي والثقافي : وذلكَ عبر تمرير القيم، والتعريف بالتنوع الثقافي، وتقديم نماذج
                  ملهمة للأطفال.
                  </p>
                  <p className="line">
                  التمهيد للقراءة وذلك، من خلال استئناس الأطفال بتصفح القصص، والانتقال بهم إلى فهم مضامينها
                  وأحداثها، وكذا تنمية حب القراءة.
                  </p>
                </>
                :
                <>
                  <p>
                  Des histoires numériques animées et sonores pour captiver l'attention des enfants et stimuler leur imagination !
                  <br/>Ces vidéos ont pour but pédagogique :
                  </p>
                  <p className="line">
                  Développer le langage et la communication en enrichissant le vocabulaire, en structurant le discours et en améliorant la prononciation.
                  </p>
                  {/* {this.state.open_history && 
                  <> */}
                    <p className="line">Stimuler l'imagination et la créativité en encourageant l'exploration de mondes imaginaires et la création d'histoires personnelles.</p>
                    <p className="line">Renforcer le développement cognitif en structurant la pensée, en améliorant la mémoire, la logique et le raisonnement à travers la compréhension des récits.</p>
                    <p className="line">Favoriser l'apprentissage social et culturel en transmettant des valeurs, en sensibilisant à la diversité et en proposant des modèles inspirants.</p>
                    <p className="line">Préparer à la lecture en facilitant la transition vers la compréhension des livres et le développement du goût pour la lecture.</p>
                  {/* </>
                  } */}
                  </>
                }
                </div>
                {/* <div className="footer-btn">
                  <span onClick={() => this.setState({open_history: !this.state.open_history})}>{this.props.t(this.state.open_history?'Moins':'Lire la suite')}</span>
                </div> */}
              </div>
            </div>
            <div className="services-nav">
              {position_carousel >= 0 && 
              <div className="prev-service" onClick={() => {
                this.setState({
                  position_carousel: position_carousel - 1
                })
              }}>
                <i className="fa fa-angle-left"></i>
              </div>
              }
              {position_carousel <= 0 && 
              <div className="next-service" onClick={() => {
                this.setState({
                  position_carousel: position_carousel + 1
                })
              }}>
                <i className="fa fa-angle-right"></i>
              </div>
              }
            </div>
            {/* <div className="services-footer">
              <span onClick={() => {
                let item = document.getElementById("services-cards");
                item.classList.remove('to-right')
                item.classList.add("to-left")
              }}></span>
              <span onClick={() => {
                let item = document.getElementById("services-cards");
                item.classList.remove('to-right')
                item.classList.remove("to-left")
              }}></span>
              <span onClick={() => {
                let item = document.getElementById("services-cards");
                item.classList.remove('to-right')
                item.classList.remove("to-left")
              }}></span>
              <span onClick={() => {
                let item = document.getElementById("services-cards");
                item.classList.remove('to-left')
                item.classList.add("to-right")
              }}></span>
            </div> */}
          </section>
          <section id="contact_us" style={{backgroundImage: `url(${images.apropos_bg})`}}>
            <div className={`map_form ${global.language == 'ar'?'rtl_mode':''}`}>
              <div className="map">
                <div className="title">
                  {this.props.t("Contactez-nous")}
                </div>
                <div className="map_contact">
                  <div className="d-flex align-items-center">
                    <img src={images.email}/>
                    <div>atfalouna.learning@fmps.ma</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src={images.phone}/>
                    <div style={{direction: 'ltr'}}>05 37 56 35 37</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src={images.localisation}/>
                    <div style={{direction: 'ltr'}}>
                      <a href="https://fmps.ma/index.php/fr/" target="_blank">https://fmps.ma/index.php/fr/</a>
                    </div>
                  </div>
                </div>
                <div className="map_footer">
                  <a href="https://www.facebook.com/share/18QxSJtp2L/?mibextid=wwXIfr" target="_blank"><img src={images.facebook_icon}/></a>
                  <a href="https://www.linkedin.com/company/fmps/" target="_blank"><img src={images.linkedin_icon}/></a>
                  <a href="http://www.youtube.com/@FMPSChannelOFFICIEL" target="_blank"><img src={images.youtube_icon}/></a>
                </div>
              </div>
              <div className="form">
                <div>
                  <label>{this.props.t("Nom complet")}</label>
                  <input type='text' placeholder={this.props.t("Ecrire ici")}/>
                </div>
                <div>
                  <label>{this.props.t("Email")}</label>
                  <input type='text' placeholder={this.props.t("Ecrire ici")}/>
                </div>
                <div>
                  <label>{this.props.t("Téléphone")}</label>
                  <input type='text' placeholder={this.props.t("Ecrire ici")}/>
                </div>
                <div>
                  <label>{this.props.t("Votre message")}</label>
                  <input type='text' placeholder={this.props.t("Ecrire ici")}/>
                </div>
                <div>
                  <button className="btn">
                    {this.props.t("Envoyer")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
        }
        <ModalPassword 
            open={this.state.open} 
            pages={section == 0?"children":(section == 2?"school":"")} 
            onCloseModalPassword={this.onCloseModalPassword}
            handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
        {this.state.open_register_professor_sanadi &&
          <ModalProfessorSanadiRegister 
            open={this.state.open_register_professor_sanadi} 
            onCloseModal={this.onCloseModalProfessorSanadiRegister}
            programs_projects_schools={this.state.programs_projects_schools}
            professor_already_exists={this.state.professor_already_exists}
            handleAccountCreated={(message) => {
              this.setState({
                open_register_professor_sanadi: false, 
                creation_account_message: message,
              })
            }} />
        }
        <ModalConfirm 
            message=""
            title={this.props.t("Were you already a teacher last year?")}
            show={this.state.open_ask_if_professor_sanadi_exists} 
            onClose={evt => {
              // this.onOpenModalProfessorSanadiRegister(false, true);
            }}
            onHide={() => {
              this.onOpenModalProfessorSanadiRegister(false, true)
            }}
            handleValidate={() => {
              this.onOpenModalProfessorSanadiRegister(true, true)
            }} />
        <ModalCompteSuccess
            success={this.state.success}
            message={this.state.messageSuccess}
            handleCloseSuccess={this.handleCloseSuccess}
          />
        <ModalPasswordChanged
          success={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.password_changed
          }
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleClose={() => {
            this.setState({reset_password_response: "", reset_password_error: ""});
            const state = { ...this.props.history.location.state };
            delete state.password_changed;
            this.props.history.replace({
              ...this.props.history.location,
              state
            });
          }}
        />
      </Children>
    );
  }
}
const Children = styled.div`
  *{
    font-family: bahnschrift!important;
  }
  
  section {
    background: radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(245,250,252,1) 83%);
    height: 100vh;
    &#contact_us{
      background-repeat: no-repeat;
      background-size: 80% 80%;
      background-position: center;
      color: #b3b3b3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 12vw 0 12vw;
      .map_form{
        display: flex;
        color: #1f406c;
        background: #FFF;
        &.rtl_mode{
          .map{
            margin-right: 0vw;
            margin-left: 5vw;
            .map_contact{
              img{
                margin-right: 0vw;
                margin-left: 1vw;
              }
            }
          }
        }
        .map{
          display: flex;
          flex-direction:column;
          background-color: #06397b;
          border-radius: 20px;
          padding: 2vw;
          width: 30vw;
          margin-right: 5vw;
          color: #FFF;
          .title{
            font-size: 20px;
            text-align: center;
          }
          .map_contact{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            font-weight: 500;
            margin: 50px 0px;
            flex: 1;
            img{
              width: 2.5vw;
              margin-right: 1vw
            }
          }
          .map_footer{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            img{
              width: 1.5vw;
            }
          }
        }
        .form{
          flex: 1;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .btn{
            background-color: rgb(4, 58, 124);
            width: max-content;
            align-self: flex-end;
            padding: 0.375rem 1.5rem;
          }
          label{
              font-weight: bold;
          }
          >div{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            align-items: flex-start;
            input{
              border: none;
              border-bottom: 1px solid #b3b3b3;
              width: 100%;
            }
          }
        }
      }
    }
    &#services{
      background: #6a90ca;
      color: #FFF;
      display: flex;
      flex-direction: column;
      padding: 10px 0px;
      align-items: center;
      overflow: hidden;
      position: relative;
      .title{
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: 20px;
      }
      .services-nav{
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        >div{
          position: absolute;
          top: 50%;
          background: #2c5eb5BB;
          color: #FFF;
          width: 50px;
          height: 50px;
          font-size: 50px!important;
          border-radius: 50%;
          cursor: pointer;
          >i{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0px;
    
          }
          *{
            font-family: "fontawesome"!important;
          }
          &.prev-service{
            left: 2%;
            >i{
              left: 30%;
            }
          }
          &.next-service{
            right: 2%;
            >i{
              right: 30%;
            }
          }
        }
      }
      .services-footer{
        padding: 30px 0px 20px 0px;
        display: flex;
        justify-content: center;
        gap: 10px;
        span{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #FFF;
          cursor: pointer;
        }
      }
      .services-cards{
        display: flex;
        justify-content: space-between;
        gap: 50px;
        flex: 1;
        width: 120%;
        position: relative;
        left: 0px;
        transition: left 1s;
        max-height: 80%;
        &.to-left{
          left: calc(10% + 50px);
        }
        &.to-right{
          left: calc(-10% - 50px);
        }
        .card-item{
          display: flex;
          flex-direction: column;
          background: #FFF;
          color: #2a2a2a;
          border-radius: 20px;
          padding: 10px;
          box-shadow: 0 8px 16px 0 rgb(0 0 0 / 63%);
          flex: 1;
          max-height: 95%;
          .img{
            width: 100%;
            color: #FFF;
            position: relative;
            img{
              width: 100%;
            }
            .card-item-title{
              position: absolute;
              bottom: 0px;
              padding: 20px;
              font-size: 18px;
              font-weight: 600;
            }

          }
          .content{
            margin-top: 5px;
            font-weight: 500;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &::-webkit-scrollbar-track{
                background-color: #FFF;
            }
            &::-webkit-scrollbar{
                width: 7px;
                height: 3px;
                background-color: #FFF;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #3eb6ad;
            }
            .line{
              &:before{
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                background: black;
                margin-right: 5px;
              }
            }
            &.rtl_mode{
              p{
                text-align: right;
              }
              .line:before{
                  margin-right: 0px;
                  margin-left: 5px;
              }
            }
          }

          .footer-btn{
            font-weight: 600;
            display: flex;
            flex: 1;
            justify-content: flex-end;                
            align-items: flex-end;
            span{
              cursor: pointer
            }
          }

          &.blue{
            .content{
              &::-webkit-scrollbar-thumb{
                  background-color: #2c5eb5;
              }
            }
            .line:before{
              background: #2c5eb5
            }
            .footer-btn{
              color: #2c5eb5
            }
          }
          &.orange{
            .content{
              &::-webkit-scrollbar-thumb{
                  background-color: #f7a823;
              }
            }
            .line:before{
              background: #f7a823
            }
            .footer-btn{
              color: #f7a823
            }
          }
          &.green2{
            .content{
              &::-webkit-scrollbar-thumb{
                  background-color: #8bc53f;
              }
            }
            .line:before{
              background: #8bc53f
            }
            .footer-btn{
              color: #8bc53f
            }
          }
          &.pink{
            .content{
              &::-webkit-scrollbar-thumb{
                  background-color: #ad4582;
              }
            }
            .line:before{
              background: #ad4582
            }
            .footer-btn{
              color: #ad4582
            }
          }
        }
      }
    }
    &#a_propos{
      display: flex;
      background-repeat: no-repeat;
      background-size: 80% 80%;
      background-position: center;
      min-height:100vh;
      height: auto;
      padding-bottom: 20px;
      direction: ltr;
      .a_propos_left{
        flex: 1;
        display: flex;
        align-items: center;
        img{
          width: 100%;
        }
      }
      .a_propos_right{  
        flex: 2;
        padding: 10% 5% 0px 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 40vh;
        }
        .content{
          font-weight: 500;
          column-count: 2;
          column-gap: 30px;
          margin-top: 30px;
          text-align: justify;

        }
      }
    }

    .btn{
      background: #6a90ca;
      color: #FFF;
      border-radius: 17px;
      height: 30px;
      line-height: 15px;
      font-weight: 600;
    }
  }
  .description_home{
    font-size: 2vh;
    @media(max-width: 1199px) {
      font-size: 1.6vh;
    }
    @media(max-width: 767.98px) {
      font-size: 1.2vh;
    }

  }
  .menu-header{
    display: flex;
    justify-content: space-around;
    .menu-item{
      background: #FFF;
      color: #ed910b;
      font-size: 1.3vw;
      font-weight: 500;
      border-radius: 20px;
      padding: 0.5vw; 
      min-width: 8.2vw;
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 63%);
      text-align: center;
      cursor: pointer;
    }

  }
  .first_page{
    background-color: #FFF;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .open-connexion{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 6px 12px 33px 0 rgba(204, 235, 248, 0.19);
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .modal-connexion{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header-menu{
      width: 100%;
      padding: 0 20px;
    }
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      margin-bottom: 20px;
      .btn-close{
        width: 40px;
        height: 40px;
        flex-grow: 0;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 6px 12px 33px 0 rgba(255, 224, 60, 0.16);
        background-color: rgba(218, 218, 218, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        color:  #7d7d7d;
        font-size: 20px;
      }
    }
  }
  .header-menu{
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 400px;
    >button{
      text-transform: capitalize;
      color: #6e7b81;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.active{
        font-weight: bold;
        color: #042a45;
        span{
          background: #64c2be;
          width: 4px;
          height: 4px;
          display: block;
          border-radius: 50%;
        }
      }

    }
  }
  .form-connexion{
    display: flex;
    flex-direction: column;
    align-items: center;
    &.rtl_mode{
      label{
        left: auto!important;
        right: 10px;
      }
    }
    .form-item{
      position: relative;
      label{
        position: absolute;
        top: 10px;
        padding: 0px 5px;
        background-color: #FFF;
        left: 10px;
        font-size: 16px;
      }
      input{
        width: 500px;
        flex-grow: 0;
        margin-top: 20px;
        padding: 20px;
        border-radius: 12px;
        border: solid 0.3px #8b8b8b;
        background-color: #FFF!important;
        color: #000;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
          -webkit-background-clip: text;
          -webkit-text-fill-color: #00000!important;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #23232329;
           -webkit-box-shadow: 0 0 0 30px white inset !important;
        } 
      }
    }
  }
  a.forgot{
    margin: 0px;
    font-size: 14px;
    color: #ee9718;
  }
  .form-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    .forgot{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #ee9718;
    }
    .register{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #7d7e7e;
    }
  }

  .signin{
    // width: 276px;
    // height: 56px;
    padding: 10px 20px;
    flex-grow: 0;
    margin: 22px 42px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5vw;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #fff;
    border: none;
  }
`;
const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(Home)));
