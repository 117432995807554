import React from "react";
import { set, get } from "services/storage";
import md5 from 'crypto-js/md5';
import moment from "moment";
import * as moment_tz from "moment-timezone";
import axios from "axios";
import {get_edulib_catalog, get_catalog_from_koolskools} from "services/api";

import backWave1 from "../assets/img/school/backWave1.svg";
import backWave2 from "../assets/img/school/backWave2.svg";
import backWave3 from "../assets/img/school/backWave3.svg";
import backWave4 from "../assets/img/school/backWave4.svg";
import { images, images_menu } from "../pages/school/classes/_resources";
import heic2any from "heic2any";
import {upload_asset_s3_api} from 'services/cms/api'
import { parseString } from "xml2js";

const START_RAMADA_STR = "2025-02-23";
const END_RAMADA_STR = "2025-04-06";

export const colors = [
  {start: '#86e9e2', end:'#83dad3', icon: backWave1},
  {start: '#f9e887', end:'#f0d26f', icon: backWave2},
  {start: '#bca7f7', end:'#9e82ee', icon: backWave3},
  {start: '#b7d8ff', end:'#87bae6', icon: backWave4}
]

var create_kne_key = () => {
  var ky = "*56Koolskools824R" + moment().format("DDMMYYYY");
  var res = md5(ky);;
  return res.toString();
}
export function assign_value(o){
    var r = {};
    Object.keys(o).forEach(function (k) {
        r[k] = o[k];
    });
    return r;
} 

export const groupBy = (key,array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
);

export const groupBy2 = (array, callback) => {
  return array.reduce(function(store, item) {
    var key = callback(item);
    var value = store[key] || [];
    store[key] = value.concat([item]);
    return store;
  }, {})
}
export const get_sanadi_competencies_periods = (new_format) => {
if(new_format){
    return [
      {name: "DIAGNOSTIQUE", id: 0},
      {name: "E1", id: 1},
    ];
  }
  return [
    {name: "Baseline", id: 0},
    {name: "Midline", id: 1},
    {name: "Endline", id: 2},
  ];
}
export const get_sanady_notes_periods = () => {
  return [
    {name: "E2", id: 2},
    {name: "E3", id: 3},
    {name: "E4", id: 4},
  ];
}
export const remove_number_in_parentheses = (filename) => {
  // Using a regular expression to search for the number within parentheses
  const pattern = /\(\d+\)$/; // Search for a number within parentheses at the end of the filename
  // Get the extension by finding the last occurrence of '.' and extracting the substring from that point
  const extensionIndex = filename.lastIndexOf('.');
  const baseName = filename.substring(0, extensionIndex);
  const extension = filename.substring(extensionIndex);
  const match = baseName.match(pattern);
  let resName = baseName;
  if (match) {
      // If a number within parentheses is found, remove it from the filename
      const originalName = baseName.substring(0, match.index);
      resName = originalName;
  }
  return resName.trim() + extension;
}

export const capitalizeEveryWord = (phrase) => {
  return phrase.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

export const numberToWordsFrench = (number) => {
  const units = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];
  const teens = ['onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'];
  const tens = ['', 'dix', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'];

  function convertBelowHundred(n) {
    if (n < 10) return units[n];
    if (n < 20) return teens[n - 11];
    const digit = n % 10;
    const ten = Math.floor(n / 10);
    return `${tens[ten]}${units[digit] ? '-' + units[digit] : ''}`;
  }

  function convertBelowThousand(n) {
    const hundred = Math.floor(n / 100);
    const belowHundred = convertBelowHundred(n % 100);
    return `${hundred > 1 ? units[hundred] + " cents" : hundred == 1 ? "cent" : ""} ${belowHundred ? ' ' + belowHundred : ''}`;
  }

  if (number === 0) {
    return 'zéro';
  }

  const billion = Math.floor(number / 1000000000);
  const million = Math.floor((number % 1000000000) / 1000000);
  const thousand = Math.floor((number % 1000000) / 1000);
  const remainder = number % 1000;

  let result = '';
  if (billion) {
    result += `${billion > 1 ? convertBelowThousand(billion) + " milliards " : billion == 1 ? " milliard " : ""}`;
  }
  if (million) {
    result += `${million > 1 ? convertBelowThousand(million) + " millions " : billion == 1 ? " million " : ""}`;
  }
  if (thousand) {
    result += `${thousand > 1 ? convertBelowThousand(thousand) + " milles " : thousand == 1 ? " mille " : ""}`;
  }
  if (remainder) {
    result += convertBelowThousand(remainder);
  }

  return result.trim();
}

export const calc_avg_subject = (subject_data) => {
  return [8, 2]
}
export const calc_avg_subject2 = (subject_data, details_by_controls, no_chapters, group_subjects, subjects_groups, get_nbr_chapters_noted, subject_coefficient_) => {
  subject_data = subject_data || {};
  details_by_controls = details_by_controls || false;
  no_chapters = no_chapters || false;
  group_subjects = group_subjects || false;
  subjects_groups = subjects_groups || {};
  get_nbr_chapters_noted = get_nbr_chapters_noted || false;
  subject_coefficient_ = subject_coefficient_ || 0;
  let sum_champers_notes = 0;
  let sum_coefficients = 0;
  let res = 0;
  if(!no_chapters){
    Object.keys(subject_data["chapters"] || {}).map(chapter_id => {
      let chapter_data = subject_data["chapters"][chapter_id];
      if(!chapter_id){
        if(details_by_controls){
            let sum_chapter_note = 0;
            let sum_controls_coefficients = 0;
            Object.keys(chapter_data["controls"] || {}).map(control_id => {
              let control_data = chapter_data["controls"][control_id];
              sum_controls_coefficients += (control_data['note'] || 0) ? (control_data['coefficient'] || 0) : 0;
              sum_chapter_note += parseFloat(control_data['note'] || 0) * parseFloat(control_data['coefficient'] || 0);
            });
            let note_ = (sum_chapter_note / parseFloat(sum_controls_coefficients || 1));
            sum_champers_notes = note_;
            sum_coefficients += note_ ? 1 : 0;
        }
        else{
            sum_champers_notes += chapter_data["note"] || 0;
            sum_coefficients += chapter_data["note"] || 0 ? 1 : 0;
        }
      }
      else if(chapter_data["coefficient"] || 0){
        let chapter_note = 0;
        if(details_by_controls){
          let sum_chapter_note = 0
          let sum_controls_coefficients = 0
          Object.keys(chapter_data["controls"] || {}).map(control_id => {
            let control_data = chapter_data["controls"][control_id];
            sum_controls_coefficients += (control_data['note'] || 0) ? (control_data['coefficient'] || 0) : 0;
            sum_chapter_note += parseFloat(control_data['note'] || 0) * parseFloat(control_data['coefficient'] || 0);
          });
          chapter_note = (sum_chapter_note / parseFloat(sum_controls_coefficients || 1)) * (chapter_data["coefficient"] || 0);
        }
        else{
          chapter_note = (chapter_data["note"] || 0) * (chapter_data["coefficient"] || 0);
        }
        sum_champers_notes += chapter_note;
        sum_coefficients += chapter_note ? (chapter_data["coefficient"] || 0) : 0;
      }
      if(get_nbr_chapters_noted){
        res = [Math.round(sum_champers_notes / (sum_coefficients || 1), 2), sum_coefficients];
      }
      else{
        res = Math.round(sum_champers_notes / (sum_coefficients || 1), 2);
      }
    })
  }
  else{
    let sum_chapter_note = 0
    let sum_coefficients = 0
    Object.keys(subject_data["controls"] || {}).map(control_id => {
      let control_data = subject_data["controls"][control_id];
      sum_coefficients += control_data['coefficient'] || 0;
      sum_chapter_note += (control_data['coefficient'] || 0) * (control_data['note'] || 0);
    });
    if(sum_coefficients == 0){
      if(get_nbr_chapters_noted){
        res = [Math.round(subject_data["avg"] || 0, 2), (sum_coefficients || subject_coefficient_)];
      }
      res = Math.round(subject_data["avg"] || 0, 2);
    }
    else{
      let subject_note = sum_chapter_note / (sum_coefficients || 1);
      if(get_nbr_chapters_noted){
        res = [Math.round(subject_note, 2), sum_coefficients]
      }
      else{
        res = Math.round(subject_note, 2);
      }
    }
  }
  return res;
}

export const render_sanady_competency_note = (note) => {
  if(note && note > 0 || note === 0){
    if(note <= 2){
      return "NA";
    }
    else if(2 < note && note < 8){
      return "EA";
    }
    else if(note <= 10){
      return "A";
    }
  }
  return "";
}
  

export const convert_heic_to_jpeg = (heic_url, callback) => {
  fetch(heic_url)
  .then((res) => res.blob())
  .then((blob) => {
    heic2any({
        blob,
        toType: "image/jpeg",
        quality: 1, // cuts the quality and size by half
    })
    .then((conversionResult) => {
      var filename= heic_url.split('\\').pop().split('/').pop();
      filename = filename.replaceAll(".heic", ".jpeg").replaceAll(".HEIC", ".JPEG")
      var file = new File([conversionResult], filename);
      let formData = new FormData();
      formData.append("file", file, (new Date()).getTime() + '_' + filename);
      upload_asset_s3_api(formData).then(res => {
        callback(res.url);
      }).catch(error => console.log("error", error));
        // conversionResult is a BLOB
        // of the JPEG formatted image
        // with low quality
    })
  })
  .catch((e) => {
    // see error handling section
  });
}

export const animateCSS = (node, animation, time = '0.05s', prefix = '') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    // const node = document.querySelector(element);
    
    node.classList.add(`${prefix}animated`, animationName);
    node.style.setProperty('--animate-duration', time);
    node.addEventListener('animationend', handleAnimationEnd, {once: true});
      

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }
    node.addEventListener('animationend', handleAnimationEnd, {once: true});
    
  });
export const numStr = (a, b)  => {
  // return a;

  return parseFloat(a).toLocaleString('en', {minimumFractionDigits: (b?b:0)}).replaceAll(',', ' ')
  // a = '' + a;
  // b = b || ' ';
  // var c = '',
  //     d = 0;
  // while (a.match(/^0[0-9]/)) {
  //     a = a.substr(1);
  // }
  // for (var i = a.length-1; i >= 0; i--) {
  //     c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
  //     d++;
  // }
  // return c;
}

export const getFlooredFixed = (v, d) => {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}

export const checkIfConflict = (start_abs, end_abs, start_vac, end_vac) => {
  // console.log(start_abs.isSameOrBefore(start_vac, 'day'))
  // console.log(end_abs.isSameOrAfter(start_vac, 'day'))
  // console.log(start_abs.isSameOrBefore(end_vac, 'day'))
  // console.log(end_abs.isSameOrAfter(end_vac, 'day'))

  // console.log(start_vac.isSameOrBefore(start_abs, 'day'))
  // console.log(end_vac.isSameOrAfter(start_abs, 'day'))
  // console.log(start_vac.isSameOrBefore(end_abs, 'day'))
  // console.log(end_vac.isSameOrAfter(end_abs, 'day'))
  return (start_abs.isSameOrBefore(start_vac, 'day') && end_abs.isSameOrAfter(start_vac, 'day')) || (start_abs.isSameOrBefore(end_vac, 'day') && end_abs.isSameOrAfter(end_vac, 'day')) || 
  (start_vac.isSameOrBefore(start_abs, 'day') && end_vac.isSameOrAfter(start_abs, 'day')) || (start_vac.isSameOrBefore(end_abs, 'day') && end_vac.isSameOrAfter(end_abs, 'day'));
}

const cycles_names = {
  "chapter": ["Chapter", "Module"],
  "lesson": ["Lesson", "Unit"],
  "competency": ["Competency", "Learning outcome"],
  "competencies": ["Competencies", "Learning outcomes"],
  "std_txtbook": ["Textbook", "Work to do "],
  "txtbook": ["Textbook", "Work to do"],
  "notebook_journal": ["Notebook journal", "Cahier de texte enseignant"],
}

export const get_white_exams_types = () => {
  return [
    {
      key: "paper_1", label: "Paper 1",
    },
    {
      key: "paper_2", label: "Paper 2",
    },
    {
      key: "paper_3", label: "Paper 3",
    },
  ]
}
export const get_status_sanady = () => {
  return [
    {
      key: "B", label: "Bénéficiaire",
    },
    {
      key: "NB", label: "Non bénéficiaire",
    },
    {
      key: "NE", label: "Non éligible",
    },
    // {
    //   key: "AB", label: "Abandon",
    // },
  ]
}

export const getCycleName = (key, level, is_student, get_boolean) => {
  var school = get("school");
  var levels = get("levels");
  if(!levels || levels.length == 0){
    levels = get("all_levels") || [];
  }
  var levels_groups = level || is_student ? get("level_groups") || [] : (school ? school.levels_groups : get("professor_levels_groups")) || [];
  if(!levels_groups || levels_groups.length == 0){
    levels_groups = get("professor_levels_groups") || [];
  }
  var is_primary = true;
  if(level){
    if(!level.level_id){
      level = levels.filter(l => l.level_id == level)[0];
    }
    else{
      level = levels.filter(l => l.level_id == level.level_id)[0];
    }
    var level_group = levels_groups.filter(l_g => l_g.id == (level && level.group_id))[0];
    is_primary = level_group ? level_group.num < 2 : true;
  }
  else{
    is_primary = levels_groups.filter(l_g => l_g.num > 1).length == 0;
  }
  if(get_boolean){
    return is_primary;
  }
  return (cycles_names[key] || ["", ""])[is_primary ? 0 : 1]
}
export const get_sanady_period = (sanady_period, short_name) => {
  if(sanady_period == 2){
    return short_name ? "E" : "Endline";
  }
  else if(sanady_period == 1){
    return short_name ? "M" : "Midline";
  }
  return short_name ? "B" : "Baseline";
}
export const organize_programs_projects_schools = (programs_projects_schools, as_projects, get_programs_with_students) => {
  if(as_projects){
    let projects = [];
    let programs_ = [];
    let programs_keys = Object.keys(programs_projects_schools);
    programs_keys.filter(key => key != "programs").map(pk => {
      let program = programs_projects_schools[pk];
      programs_.push(program);
      projects = projects.concat(Object.keys(program.projects).map(k_ => program.projects[k_]));
    });
    programs_.sort((p1, p2) => p1.name >= p2.name ? 1 : -1);
    projects.sort((p1, p2) => p1.name >= p2.name ? 1 : -1);
    return get_programs_with_students ? [projects, programs_] : projects;
  }
  if(!programs_projects_schools.programs || programs_projects_schools.programs.length == 0){
    let programs = [];
    let programs_keys = Object.keys(programs_projects_schools);
    programs_keys.filter(key => key != "programs").map(pk => {
      let program = programs_projects_schools[pk];
      programs.push(program);
    });
    programs.sort((p1, p2) => p1.name >= p2.name ? 1 : -1);
    programs_projects_schools.programs = programs;
  }
  return programs_projects_schools;
}
export const get_projects_from_pogram = (program, get_all_projects, programs_projects, programs_ids, regions_ids) => {
  var projects_res = []
  if(programs_ids){
    let projects = [];
    let programs_keys = programs_ids;
    programs_keys.map(pk_ => {
      let program = programs_projects[pk_];
      let projects_keys = Object.keys(program.projects || {});
      projects_keys.map(pk => {
        let project = program.projects[pk];
        project.program = program;
        projects.push(project);
      });
    });
    projects_res = projects;
  }
  else if(get_all_projects || program === true){
    let projects = [];
    let programs_keys = Object.keys(programs_projects || {});
    programs_keys.map(pk_ => {
      let program = programs_projects[pk_];
      let projects_keys = Object.keys(program.projects || {});
      projects_keys.map(pk => {
        let project = program.projects[pk];
        project.program = program;
        projects.push(project);
      });
    });
    projects_res = projects;
  }
  else if(!program){
    projects_res = [];
  }
  else{
    let projects = [];
    let projects_keys = Object.keys(program.projects || {});
    projects_keys.map(pk => {
      let project = program.projects[pk];
      project.program = program;
      projects.push(project);
    });
    projects.sort((p1, p2) => p1.name >= p2.name ? 1 : -1);
    projects_res = projects;
  }
  if(regions_ids){
    projects_res = projects_res.filter(pr => regions_ids.indexOf(pr.project_region_id) != -1);
  }
  return projects_res;
}
export const get_schools_from_projects = (projects) => {

  if(!projects){
    return [];
  }
  let schools = [];
  projects.map(project => {
    schools = schools.concat(project.schools);
  });
  schools.sort((p1, p2) => p1.name >= p2.name ? 1 : -1);
  return schools;
}

export const  areArraysNotEqual = (arr1, arr2) => {
  // Check if both are null or undefined
  var result = false;
  if ((arr1 === null && arr2 === null) || (arr1 === undefined && arr2 === undefined)) {
    result = false;
  }

  // Check if both arrays have the same length
  if (!result && arr1.length !== arr2.length) {
    result = true;
  }

  // Check if all elements are the same
  if(!result){
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          result = true;
        }
    }
  }

  return result;
}

export const get_min_scholar_year_date = (scholar_year) => {
  scholar_year = scholar_year || get("current_year");
  var res = (scholar_year || null) && new Date(scholar_year.start_year, 6, 1);
  return res;
};
export const get_max_scholar_year_date = (scholar_year) => {
  scholar_year = scholar_year || get("current_year");
  var res = (scholar_year || null) && new Date(scholar_year.start_year + 1, 5, 30);
  return res;
};
export const format_str_number = (val, nbr_chars, is_number, add_dot) => {
  var str = String(val);
  if(is_number){
    if(add_dot){
      str = String(val.toFixed(2))
    }
  }
  if(str.length > nbr_chars){
    str = str.substring(str.length - nbr_chars, str.length);
  }
  else if(str.length < nbr_chars){
    if(is_number){
      while(str.length < nbr_chars){
        str = "0" + str
      }
    }
    else{
      while(str.length < nbr_chars){
        str = str + " "
      }
    }
  }
  return str;
}

const levels_appreciations_limitations_massar = {
  "cp-8a7d26c0cc1c47ad81f3032e82b90a8e": 41, 
  "ce1-8b6c3f5c48534156bcd420e71b1bd035": 41, 
  "ce2-8d3d0240a5db45ec9b573861e5f7f6c3": 41, 
  "cm1-94c01e5dfe134e0aa754fb7bc000e626": 41, 
  "cm2-fb49ea6b9cf543968b73d83406d934ae": 41, 
  "6eme-757dc9be360b48a39fa4b8a41edc7084": 16, 
}

export const get_levels_appreciations_limitations_massar = level_id => {
  if(level_id){
    return levels_appreciations_limitations_massar[level_id] || -1;
  }
  return levels_appreciations_limitations_massar;
}

export const serverResponse = (message, handleCloe) => {
  return <div className="server_message" style={{
    position: "fixed",
    width: "100%",
    zIndex: "2200000000",
    top: "0",
    padding: "15px",
    textalign: "center",
    background: "#808080",
    color: "white",
    fontSize: "20px",
  }}>
    <div style={{display: "relative"}}>
      <div className="message" style={{textAlign: "center"}}>
        {message}
      </div>
      <div className="close" onClick={handleCloe} style={{
        position: "absolute",
        top: "50%",
        right: "17px",
        border: "5px solid #fff",
        width: "40px",
        height: "40px",
        textAlign: "center",
        color: "#fff",
        borderRadius: "50%",
        fontSize: "17px",
        lineHeight: "28px",
        background: "#00b3a9",
        boxShadow: "0 0 5px -1px rgba(0, 0, 0, 0.3)",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}>
        &times;
      </div>
    </div>
  </div>
}

export const format_s3_file_name = (file_name, split_by_dash, split_by) => {
  file_name = decodeURI(file_name);
  if(split_by){
    var name = file_name.split(split_by)[1];
    if(name){
      return name;
    }
  }
  if(file_name && split_by_dash){
    file_name = file_name.split(' - ')[1] || file_name.split(' - ')[0] || " "
  }
  else if(!file_name || file_name.indexOf('_audio_') != -1){
    return file_name;
  }
  var filename_tab = file_name.split('_');
  if(filename_tab.length > 2){
    filename_tab.shift();
    filename_tab.shift();
  }
  return filename_tab.join('_');
}

export const add_one_to_time_str = (time_str) => {
  let time_plited = time_str.split(":");
  let new_hour = parseInt(time_plited[0]) + 1;
  time_str = (new_hour < 10 ? "0" : "") + new_hour + ":" + time_plited[1];
  return time_str;
}
export const fix_absences_hours = (absences) => {
  return (absences || []).map(absence => {
    let seance_date = absence.seance_date;
    if(!seance_date.seance_id){
      return absence;
    }
    let date_to_compare_str = seance_date.day;
    seance_date.correct_start = seance_date.correct_start || seance_date.start;
    seance_date.correct_end = seance_date.correct_end || seance_date.end;
    if (START_RAMADA_STR <= date_to_compare_str && END_RAMADA_STR >= date_to_compare_str){
      if(moment(seance_date.correct_start).utc().format("HH:mm") == moment(seance_date.correct_start).format("HH:mm")){
          var new_start = moment(seance_date.correct_start).add(1, "hour");
          var new_end = moment(seance_date.correct_end).add(1, "hour");
          seance_date.start = new_start.toDate();
          seance_date.end = new_end.toDate();
          seance_date.start_time = add_one_to_time_str(seance_date.start_time);
          absence.seance_date = seance_date;
          let seance = absence.seance;
          if(seance){
            seance.start = seance_date.start;
            seance.end = seance_date.end;
          }
          absence.seance = seance;
        }
    }
    else if(moment(seance_date.correct_start).utc().format("HH:mm") == moment(seance_date.correct_start).format("HH:mm")){
      var new_start = moment(seance_date.correct_start).add(1, "hour");
      var new_end = moment(seance_date.correct_end).add(1, "hour");
      seance_date.start = new_start.toDate();
      seance_date.end = new_end.toDate();
      seance_date.start_time = add_one_to_time_str(seance_date.start_time);
      absence.seance_date = seance_date;
      let seance = absence.seance;
      if(seance){
        seance.start = seance_date.start;
        seance.end = seance_date.end;
      }
      absence.seance = seance;
    }
    return absence;
  })
}

export const fix_seances_hours = (seances, selected_date) => {
  var week_start = selected_date && moment(selected_date).startOf("week");
  return (seances || []).map(seance => {
    seance.correct_start = seance.correct_start || seance.start;
    seance.correct_end = seance.correct_end || seance.end;
    if(!week_start){
      week_start = moment(seance.seance_day, "YYYY-MM-DD").startOf("week");
    }
    var date_to_compare = week_start.add(moment(seance.correct_start).day() - week_start.day(), "days");
    var date_to_compare_str = moment(date_to_compare).format("YYYY-MM-DD");
    if ((!seance.seance_from || moment(seance.seance_from).format("YYYY-MM-DD") <= date_to_compare_str) && (!seance.seance_to || moment(seance.seance_to).format("YYYY-MM-DD") >= date_to_compare_str)){
      if (START_RAMADA_STR <= date_to_compare_str && END_RAMADA_STR >= date_to_compare_str){
        if(moment(seance.correct_start).utc().format("HH:mm") == moment(seance.correct_start).format("HH:mm")){
            var new_start = moment(seance.correct_start).add(1, "hour");
            var new_end = moment(seance.correct_end).add(1, "hour");
            seance.start = new_start.toDate();
            seance.end = new_end.toDate();
        }
      }
      else if(moment(seance.correct_start).utc().format("HH:mm") == moment(seance.correct_start).format("HH:mm")){
        var new_start = moment(seance.correct_start).add(1, "hour");
        var new_end = moment(seance.correct_end).add(1, "hour");
        seance.start = new_start.toDate();
        seance.end = new_end.toDate();
      }
    }
    else if(moment(seance.correct_start).utc().format("HH:mm") == moment(seance.correct_start).format("HH:mm")){
      var new_start = moment(seance.correct_start).add(1, "hour");
      var new_end = moment(seance.correct_end).add(1, "hour");
      seance.start = new_start.toDate();
      seance.end = new_end.toDate();
    }
    return seance;
  })
}
export const fix_hour = (time, selected_date) => {
  var selected_date_str = moment(selected_date).format("YYYY-MM-DD");
  if (selected_date_str){
    if (START_RAMADA_STR <= selected_date_str && END_RAMADA_STR >= selected_date_str){
      if(moment(time).utc().format("HH:mm") == moment(time).format("HH:mm")){
        time = moment(time).add(1, "hour");
      }
    }
    else if(moment(time).utc().format("HH:mm") == moment(time).format("HH:mm")){
      time = moment(time).add(1, "hour");
    }
  }
  return time;
}
export const calc_minutes = (start, end) => {
  let diff_minutes = moment(end).diff(moment(start), "minutes");
  return diff_minutes > 0 ? diff_minutes : 0;
}
export const add_subject_to_list = (list_subject, subject) => {
  if(subject && list_subject.indexOf(subject.name) === -1){
    list_subject.push(subject.name)
  }
  return list_subject;
}
export const convert_minutes_to_hours = (minutes, as_str) => {
  let nbr_hours = parseInt(minutes / 60);
  let rest_minutes = minutes % 60;
  if(nbr_hours && rest_minutes){
    return nbr_hours + "H" + rest_minutes;
  }
  else if(nbr_hours){
    return nbr_hours + "H";
  }
  else if(rest_minutes){
    return rest_minutes + "m";
  }
  return "";
}
export const fix_date = (date, selected_date, add) => {
  var date_to_compare_str = moment(selected_date).format("YYYY-MM-DD");
  var date_to_compare2_str = moment().format("YYYY-MM-DD");
  if ((START_RAMADA_STR <= date_to_compare_str && END_RAMADA_STR >= date_to_compare_str)){
    if(moment(date).utc().format("HH:mm") != moment(date).format("HH:mm")){
        var new_start = moment(date).add(add ? 1 : -1, "hour");
        date = new_start.toDate();
    }
    else if((START_RAMADA_STR <= date_to_compare2_str && END_RAMADA_STR >= date_to_compare2_str)){
      if(moment(date).utc().format("HH:mm") == moment(date).format("HH:mm")){
        var new_start = moment(date).add(add ? 1 : -1, "hour");
        date = new_start.toDate();
      }
    }
  }
  return date;
}
export const is_ramadan = (selected_date) => {
  var date_to_compare_str = moment(selected_date).format("YYYY-MM-DD");
  return (START_RAMADA_STR <= date_to_compare_str && END_RAMADA_STR >= date_to_compare_str);
}
export const fix_journals_hours = (journals, selected_date) => {
  var week_start = selected_date && moment(selected_date).startOf("week");
  return (journals || []).map(journal => {
    journal.correct_start = journal.correct_start || journal.journal_start;
    journal.correct_end = journal.correct_end || journal.journal_end;
    if(!week_start){
      week_start = moment().startOf("week");
    }
    var date_to_compare = week_start.add(moment(journal.correct_start).day() - week_start.day(), "days");
    var date_to_compare_str = moment(date_to_compare).format("YYYY-MM-DD");
    if (START_RAMADA_STR <= date_to_compare_str && END_RAMADA_STR >= date_to_compare_str){
      if(moment(journal.correct_start).utc().format("HH:mm") == moment(journal.correct_start).format("HH:mm")){
          var new_start = moment(journal.correct_start).add(1, "hour");
          var new_end = moment(journal.correct_end).add(1, "hour");
          journal.journal_start = new_start.toDate();
          journal.journal_end = new_end.toDate();
        }
    }
    else if(moment(journal.correct_start).utc().format("HH:mm") == moment(journal.correct_start).format("HH:mm")){
      var new_start = moment(journal.correct_start).add(1, "hour");
      var new_end = moment(journal.correct_end).add(1, "hour");
      journal.journal_start = new_start.toDate();
      journal.journal_end = new_end.toDate();
    }
    return journal;
  })
}
export const fix_seance_hours = (time_moment, selected_date, add_hour) => {
  var selected_date_str = moment(selected_date).format("YYYY-MM-DD");
  if(selected_date_str >= START_RAMADA_STR && selected_date_str <= END_RAMADA_STR){
    if(moment(time_moment).utc().format("HH:mm") == moment(time_moment).format("HH:mm")){
      return moment(time_moment).add(add_hour ? 1 : -1, "hour");
    }
  }
  else{
    var h_ = (moment(time_moment).toDate().getTimezoneOffset() / 60) + 1;
    return moment(time_moment).add(-h_, "hour");
  }
  return moment(time_moment);
}

export const return_stats_color = (value, type, ranges) => {
    value = value || undefined;
    var res = "#FF0000FF";
    if(type == "date"){
      if(!value){
        var range = ranges[ranges.length - 1];
        res = range.color;
      }
      else{
        var diff_days = moment().diff(moment(value), "days");
        if(diff_days <= 0){
          var range = ranges[0];
          res = range.color;
        }
        else{
          ranges.map(range => {
            if(range.start <= diff_days && range.end >= diff_days){
              res = range.color;
            }
          })
        }
      }
    }
    if(type == "percent"){
      if(!value){
        var range = ranges[ranges.length - 1];
        res = range.color;
      }
      else{
        ranges.map(range => {
          if(range.start < value && range.end >= value){
            res = range.color;
          }
        })
      }
    }
    return res;
}


export const soapFunction = () => {

  var url = 'https://api.brightsignnetwork.com/2014/12/SOAP/WSDL/';
  var soapMessage = '<?xml version="1.0" encoding="utf-8"?>\
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://www.w3.org/2003/05/soap-envelope">\
      <soap:Header/>\
      <soap:Body>\
      </soap:Body>\
    </soap:Envelope>';
  var objXMLHttpRequest = new XMLHttpRequest();
        objXMLHttpRequest.open("GET", url, true);
        objXMLHttpRequest.onreadystatechange = function () {
          if (objXMLHttpRequest.readyState == 4 && objXMLHttpRequest.status == 200) {
            var result = objXMLHttpRequest.responseXML;
            alert(objXMLHttpRequest.responseText);
            alert(objXMLHttpRequest.responseXML);
          }
        }
        objXMLHttpRequest.setRequestHeader("Content-Type", "text/xml; charset=utf-8");


        objXMLHttpRequest.send(soapMessage);
        var response = objXMLHttpRequest.responseXML;
        alert(objXMLHttpRequest.responseText+ "  "+ response);
}

const getElementsFromXML = (elementsXml) => {
  var result_list = [];
  for (var i=0; i < elementsXml.length; i++){
    result_list.push(elementsXml[i].attributes[0].value);
  }
  return result_list;
}

export const getRessourcesFromXML = (ressourcesXml) => {
  var ressources = [];
  var ressources_items = ressourcesXml.getElementsByTagName('ns1:Ressource');
  for (var i=0; i < ressources_items.length; i++){
    var ressource_item = ressources_items[i];
    ressources.push({
      editor_name: (ressource_item.getElementsByTagName("EditeurRessource")[0] || {}).innerHTML || "",
      image_url: (ressource_item.getElementsByTagName("UrlVisuelRessource")[0] || {}).innerHTML || "",
      title: (ressource_item.getElementsByTagName("TitreRessource")[0] || {}).innerHTML || "",
      access_url: (ressource_item.getElementsByTagName("UrlAccesressource")[0] || {}).innerHTML || "",
      description: (ressource_item.getElementsByTagName("Description")[0] || {}).innerHTML || "",
      is_kne: true,
    });
  }
  return ressources;
}

export const KNEChild = () => {
  var cle = create_kne_key();
  var uai = "Educad20";
  var soap_request = `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="https://www.kiosque-edu.com/webservices">
      <soapenv:Header/>
      <soapenv:Body>
        <web:UserRessourcesCatalog>
            <input>
              <!--You may enter the following 15 items in any order-->
              <Cle>${cle}</Cle>
              <Pf>Koolskools</Pf>
              <ENTPersonStructRattachUAI>${uai}</ENTPersonStructRattachUAI>
              <ENTPersonProfils>National_ELV</ENTPersonProfils>
              <EnfantId></EnfantId>
              <ENTEleveMEF></ENTEleveMEF>
              <ENTEleveCodeEnseignements></ENTEleveCodeEnseignements>
              <ENTEleveClasses></ENTEleveClasses>
              <ENTAuxEnsClassesMatieres></ENTAuxEnsClassesMatieres>
              <user>recette2021</user>
              <ENTAuxEnsGroupes></ENTAuxEnsGroupes>
              <ENTAuxEnsClasses>Classe C|Classe D</ENTAuxEnsClasses>
              <ENTAuxEnsMEF></ENTAuxEnsMEF>
              <ENTStructureTypeStruct></ENTStructureTypeStruct>
            </input>
        </web:UserRessourcesCatalog>
      </soapenv:Body>
    </soapenv:Envelope>`;
              //<ENTEleveMEF>211100</ENTEleveMEF>
  var url = "https://www.kiosque-edu.com/webservices";
    
  // var objXMLHttpRequest = new XMLHttpRequest();
  // objXMLHttpRequest.open("POST", url, true);
  // debugger
  // objXMLHttpRequest.onreadystatechange = function () {
  //   debugger
  //   if (objXMLHttpRequest.readyState == 4 && objXMLHttpRequest.status == 200) {
  //     var resultXml = objXMLHttpRequest.responseXML.documentElement;
  //     var ressources_xml = resultXml.getElementsByTagName("ressources")[0];
  //     var ressources = getRessourcesFromXML(ressources_xml);
  //     console.log(ressources)
  //   }
  // }
  // // objXMLHttpRequest.setRequestHeader("Content-Type", "text/xml; charset=utf-8");
  // // objXMLHttpRequest.setRequestHeader("Accept", "*/*");
  // objXMLHttpRequest.send(soap_request);

  init_user_ressource().then(objXMLHttpRequest => {
    if (objXMLHttpRequest.readyState == 4 && objXMLHttpRequest.status == 200) {
      var resultXml = objXMLHttpRequest.responseXML.documentElement;
      var ressources_xml = resultXml.getElementsByTagName("ressources")[0];
    }
  });
  return axios.post(url, soap_request).then(res=>{
      return res.request;
    }).catch(err=>{console.log(err)});
}

export const init_user_ressource = () => {
  var cle = create_kne_key();
  var uai = "Educad20";
  var soap_request = `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="https://www.kiosque-edu.com/webservices">
      <soapenv:Header/>
      <soapenv:Body>
        <web:InitUserRessourcesCatalog>
            <input>
              <!--You may enter the following 15 items in any order-->
              <Cle>${cle}</Cle>
              <Pf>Koolskools</Pf>
              <ENTPersonStructRattachUAI>${uai}</ENTPersonStructRattachUAI>
            </input>
        </web:InitUserRessourcesCatalog>
      </soapenv:Body>
    </soapenv:Envelope>`;
  var url = "https://www.kiosque-edu.com/webservices";
  return axios.post(url, soap_request).then(res=>{
      return res.request;
    }).catch(err=>{console.log(err)});
}

export const KNEProfessor = () => {
  var cle = create_kne_key();
    var soap_request = `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="https://www.kiosque-edu.com/webservices">
      <soapenv:Header/>
      <soapenv:Body>
        <web:UserRessourcesCatalog>
            <input>
              <!--You may enter the following 15 items in any order-->
              <Cle>${cle}</Cle>
              <Pf>Koolskools</Pf>
              <ENTPersonStructRattachUAI>ecprodV9</ENTPersonStructRattachUAI>
              <ENTPersonProfils>National_ENS</ENTPersonProfils>
              <EnfantId></EnfantId>
              <ENTEleveMEF></ENTEleveMEF>
              <ENTEleveCodeEnseignements></ENTEleveCodeEnseignements>
              <ENTEleveClasses></ENTEleveClasses>
              <ENTAuxEnsClassesMatieres></ENTAuxEnsClassesMatieres>
              <user>recette2021</user>
              <ENTAuxEnsGroupes></ENTAuxEnsGroupes>
              <ENTAuxEnsClasses>Classe C|Classe D</ENTAuxEnsClasses>
              <ENTAuxEnsMEF></ENTAuxEnsMEF>
              <ENTStructureTypeStruct></ENTStructureTypeStruct>
            </input>
        </web:UserRessourcesCatalog>
      </soapenv:Body>
    </soapenv:Envelope>`;
  var url = "https://www.kiosque-edu.com/webservices";
    
  return axios.post(url, soap_request).then(res=>{
    return res.request;
  }).catch(err=>{console.log(err)});
}
export const EdulibCatalogue = () => {
  return getEdulibCatalogFromKoolskools();
  // var edulib_data = get("edulib_data");
  // if(edulib_data && edulib_data.access_token){
  //   var api_time_tecs = moment().diff(moment(edulib_data.api_time), "seconds");
  //   if( api_time_tecs >= (edulib_data.expires_in || 0)){
  //     return getEdulibToken(edulib_data);
  //   }
  //   else{
  //     return getEdulibCatalog(edulib_data);
  //   }
  // }
  // else{
  //   return getEdulibToken();
  // }
}

// export const getEdulibToken = (edulib_data) => {
//   var data = {
//     client_id: "0f738379d8d107e3067bbe17f710d588ef5e5bfc3a2fe69f854f6293c8648845",
//     client_secret: "6ac7a1a2f46841ba9dc3dfc231a657efb2ee1eff08f0e57e5ef814b30b29d90a",
//   }
//   if(edulib_data){
//     data.refresh_token = edulib_data.refresh_token;
//     data.grant_type = "refresh_token";
//   }
//   else{
//     data.username = "nouredineamrani@yahoo.fr";
//     data.password = "Edulib@21!";
//     data.grant_type = "password";
//   }
//   return axios.post("https://staging.edulib.fr/oauth/token", data).then(res=>{
//     if(edulib_data){
//       edulib_data.access_token = res.data.access_token;
//       edulib_data.refresh_token = res.data.refresh_token;
//       edulib_data.expires_in = res.data.expires_in;
//     }
//     else{
//       edulib_data = {
//         access_token: res.data.access_token,
//         refresh_token: res.data.refresh_token,
//         expires_in: res.data.expires_in,
//         token_type: res.data.token_type,
//         scope: res.data.scope,
//         created_at: res.data.created_at,
//       }
//     }
//     edulib_data.api_time = moment().toDate();
//     set("edulib_data", edulib_data);
//     return getEdulibCatalog(edulib_data);
//   }).catch(err=>{console.log(err)});
// }
export const getEdulibCatalog = (edulib_data) => {
  var establishment_account_id = "1000001H";
  var establishment_account_id = "1000368G";
  return get_edulib_catalog(edulib_data.access_token, establishment_account_id).then(res=>{
    var ressources = [];
    res.map(resource => {
      ressources.push(resource);
    })
    return ressources;
  }).catch(err=>{console.log(err)});
}
export const getEdulibCatalogFromKoolskools = () => {
  return get_catalog_from_koolskools("edulib").then(res=>{
    var ressources = [];
    res.catalog.map(resource => {
      ressources.push(resource);
    })
    return ressources;
  }).catch(err=>{console.log(err)});
}

export const soapTest2 = () => {
    var soap_request = '\
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="https://www.kiosque-edu.com/webservices">\
      <soapenv:Header/>\
      <soapenv:Body>\
        <web:UserRessourcesCatalog>\
            <input>\
              <!--You may enter the following 15 items in any order-->\
              <Cle>04b70c9e04d423caa1489ec1c7469ef0</Cle>\
              <Pf>Koolskools</Pf>\
              <ENTPersonStructRattachUAI>ecprodV9</ENTPersonStructRattachUAI>\
              <ENTPersonProfils>National_ENS</ENTPersonProfils>\
              <EnfantId></EnfantId>\
              <ENTEleveMEF></ENTEleveMEF>\
              <ENTEleveCodeEnseignements></ENTEleveCodeEnseignements>\
              <ENTEleveClasses></ENTEleveClasses>\
              <ENTAuxEnsClassesMatieres></ENTAuxEnsClassesMatieres>\
              <user>recette2021</user>\
              <ENTAuxEnsGroupes></ENTAuxEnsGroupes>\
              <ENTAuxEnsClasses>Classe C|Classe D</ENTAuxEnsClasses>\
              <ENTAuxEnsMEF></ENTAuxEnsMEF>\
              <ENTStructureTypeStruct></ENTStructureTypeStruct>\
            </input>\
        </web:UserRessourcesCatalog>\
      </soapenv:Body>\
    </soapenv:Envelope>';
  var url = "https://www.kiosque-edu.com/webservices";
  axios.post(url,
    soap_request,
    {headers:
      {'Content-Type': 'application/xml'}
    }).then(res=>{
      console.log(res);
    }).catch(err=>{console.log(err)});
}

export const banks_list = [
  {value:0, label:'Attijariwafa bank'},
  {value:1, label:'Banque Populaire'},
  {value:2, label:'Bank of Africa(BMCE)'},
  {value:3, label:'Société Générale'},
  {value:4, label:'Crédit du Maroc'},
  {value:5, label:'BMCI'},
  {value:6, label:'Bank Al Yousr'},
  {value:7, label:'Bank Assafaa'},
  {value:8, label:'CFG Bank'},
  {value:9, label:'CDG'},
  {value:10, label:'CIH'},
  {value:11, label:'Crédit Agricole'},
  {value:12, label:'Citibank'},
  {value:13, label:'Arab Bank'},
  {value:14, label:'Bank Al Akhdar'},
  {value:15, label:'Barid Bank'},
  {value:16, label:'Umnia Bank'},
  {value:17, label:'Trésorerie Générale'},
  {value:18, label:'Dar Al Amane'},
  {value:19, label:'Bti Bank'},
  {value:20, label:'Bank Al Maghrib'},
  {value:21, label:'Banques internationales'},
  {value:22, label:'Bank AL KARAM'}
]

export const units = [
  {value:0, label:'Unité 1'},
  {value:1, label:'Unité 2'},
  {value:2, label:'Unité 3'},
  {value:3, label:'Unité 4'},
  {value:4, label:'Unité 5'},
  {value:5, label:'Unité 6'},
  {value:6, label:'Unité 7'},
  {value:7, label:'Unité 8'},
  {value:8, label:'Autre'}
]
export const publication_filter_options = [
  {value:"yes", label:'Yes', label2:'Yes'},
  {value:"no", label:'No', label2:'No'},
]

export const get_stats_inscriptions = () => {
  return [
    {id:0, label: "In progress", className: 'bg-default', color: "#ccc"},
    {id:1, label: "Not accepted", className: 'bg-danger', color: "#dc3545"},
    {id:2, label: "Redo test", className:'bg-warning', color: "#ffc107"},
    {id:4, label: "On hold", className:'bg-info', color: "#17a2b8"},
    {id:6, label: "Inscrits au test et absentés", className: 'bg-success', color: "#28a745"},
    {id:5, label: "Admitted/Commitment", className: 'bg-success', color: "#28a745"},
    {id:3, label: "Admitted", className: 'bg-success', color: "#28a745"},
  ];
}
export const koolskools_tasks_priorities = [
  {value:3, label:'Very important', label2:'Very important'},
  {value:2, label:'Important', label2:'Important'},
  {value:1, label:'Not very important', label2:'Not very important'},
]

export const get_preinscription_step_1_options = (t) => {
  let res = [
    {id: "wrong_number", label: "Wrong number", label2: "Wrong number", className: 'bg-danger0 btn-bg-danger', color: "#dc3545"},
    {id: "not_answering", label: "Not answering", label2: "Not answering", className: 'bg-danger0 btn-bg-danger', color: "#dc3545"},
    {id: "voicemail", label: "Voicemail", label2: "Voicemail", className: 'bg-warning0 btn-bg-warning', color: "#ffc107"},
    {id: "ok", label: "Ok", label2: "Ok", className:'bg-success0 btn-bg-success', color: "#28a745"},
  ];
  if(t){
    return res.map(op => {
      op.label = t(op.label2);
      return op;
    });
  }
  return res;
}
export const get_preinscription_step_2_options = (t) => {
  let res = [
    {id: "not_qualified", label: "Not Qualified", label2: "Not Qualified", className: 'bg-danger0 btn-bg-danger', color: "#dc3545"},
    {id: "to_be_contacted_again", label: "To be Contacted Again", label2: "To be Contacted Again", className: 'bg-warning0 btn-bg-warning', color: "#ffc107"},
    {id: "qualified", label: "Qualified", label2: "Qualified", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "interested_in_activities", label: "Interested in Activities", label2: "Interested in Activities", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "waiting_list", label: "Waiting list", label2: "Waiting list", className:'bg-warning0 btn-bg-success', color: "#ffc107"},
  ];
  if(t){
    return res.map(op => {
      op.label = t(op.label2);
      return op;
    });
  }
  return res;
}
export const get_preinscription_step_3_options = (t, is_interested_in_activities) => {
  let res = [
    {id: "scheduled_visit", label: "Scheduled Visit", label2: "Scheduled Visit", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "scheduled_test", label: "Scheduled Test", label2: "Scheduled Test", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "visit_and_test_scheduled", label: "Visit and test scheduled", label2: "Visit and test scheduled", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "visit_completed", label: "Visit completed", label2: "Visit completed", className:'bg-success0 btn-bg-success', color: "#28a745"},
    {id: "absent_at_the_visit", label: "Absent at the visit", label2: "Absent at the visit", className:'bg-warning0 btn-bg-success', color: "#ffc107"},
  ];
  if(is_interested_in_activities){
    res = [
      {id: "registered", label: "Registered", label2: "Registered", className:'bg-success0 btn-bg-success', color: "#28a745"},
      {id: "deleted", label: "Deleted", label2: "Deleted", className:'bg-success0 btn-bg-danger', color: "#dc3545"},
    ];
  }
  if(t){
    return res.map(op => {
      op.label = t(op.label2);
      return op;
    });
  }
  return res;
}

export const leftPad = (number, targetLength) => {
  var output = number + '';
  while (output.length < targetLength) {
      output = '0' + output;
  }
  return output;
}
export const get_bulletin_british_avg_items = () => {
  return [
    {id:"test", label: "Test", value: 34},
    {id:"effort", label: "Effort", value: 33},
    {id:"quality_of_work", label: "Quality of Work", value: 33},
  ];
}

export const cloneWithoutReference = (source, deep, semester_controls_orders, orders_new_semester_controls) => {
  deep = deep || 1;
  if(!source){
    return source;
  }
  if (Object.prototype.toString.call(source) === '[object Array]') {
    var clone = [];
    for (var i=0; i<source.length; i++) {
      clone[i] = cloneWithoutReference(source[i]);
    }
    return clone;
  } else if (typeof(source)=="object" && !(source instanceof Date)) {
    deep++;
    var clone = {};
    for(var prop in source) {
      if(source.hasOwnProperty(prop)) {
        if(semester_controls_orders && orders_new_semester_controls && deep == 4 && prop != "start_line" && prop != "appreciations" && typeof(source[prop]) != "object" && Object.prototype.toString.call(source[prop]) !== "[object Array]"){
          if(orders_new_semester_controls[semester_controls_orders[prop]]){
            clone[orders_new_semester_controls[semester_controls_orders[prop]]] = cloneWithoutReference(source[prop], deep, semester_controls_orders, orders_new_semester_controls);
          }
        }
        else{
          clone[prop] = cloneWithoutReference(source[prop], deep, semester_controls_orders, orders_new_semester_controls);
        }
      }
    }
    return clone;
  } else {
    return source;
  }
}

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex,
      temporaryArray = array.slice(0);


  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = temporaryArray[currentIndex];
      temporaryArray[currentIndex] = temporaryArray[randomIndex];
      temporaryArray[randomIndex] = temporaryValue;
  }

  return temporaryArray;
}

export function separate_milles_in_integer(integer) {
  if(integer < 1000){
    return integer;
  }
  var integer_str = integer + "";
  let added_str = integer_str.split(".")[1];
  integer_str = integer_str.split(".")[0];
  var list_strs = [];
  for(var i=integer_str.length; i>0;i-=3 ){
    if(i-3 < 0){
      list_strs.unshift(integer_str.substring(0, i));
    }
    else{
      list_strs.unshift(integer_str.substring(i-3, i));
    }
  }
  return list_strs.join(' ') + (added_str ? "." + added_str : "");
}

export const nbr_sms_by_content = (content) => {
  var nbr_chars_by_sms = 160;
  var str_length = content.length;
  var i = 0;
  var chars_type_1 = "@$_!\"#%&x'()*+,-./0123456789:;<=> ?|^{}[~]\\\nABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var chars_type_2 = "éèàüñöäùìòÇØøÅåÆæßÉ¤¡ÄÖÑÜ§¿£¥y";
  var chars_double = "€|^{}[~]\\\n";
  var nbr_double_char = 0;
  while(i<str_length){
      var char = content[i];
      if(chars_double.indexOf(char) > -1){
          nbr_double_char++;
      }
      if(chars_type_2.indexOf(char) > -1){
          if(nbr_chars_by_sms == 160){
              nbr_chars_by_sms = 140; 
          }
      }
      else if(chars_type_1.indexOf(char) > -1){
      }
      else{
          nbr_chars_by_sms = 70;
      }
      i++;
  }
  var nbr_chars = str_length + nbr_double_char;
  var added_chars_for_sequence = 0;
  if(nbr_chars_by_sms == 160 && nbr_chars > 160){
      added_chars_for_sequence += parseInt(nbr_chars / 160) * 3 + (nbr_chars % 160 > 157 ? 3 : 0);
  }
  else if(nbr_chars_by_sms == 140 && nbr_chars > 140){
      added_chars_for_sequence += parseInt(nbr_chars / 140) * 4 + (nbr_chars % 140 > 136 ? 4 : 0);
  }
  else if(nbr_chars_by_sms == 70 && nbr_chars > 70){
      added_chars_for_sequence += parseInt(nbr_chars / 70) * 3 + (nbr_chars % 70 > 67 ? 3 : 0);
  }
  nbr_chars += added_chars_for_sequence;
  return Math.ceil(nbr_chars /nbr_chars_by_sms);
}

export const to_moroccan_time = (date_time) => {
  var cur_time = new Date();
  var diff_mins = cur_time.getTimezoneOffset() + 60;
  if(diff_mins > 0){
    date_time = moment(date_time).add(diff_mins, "minutes");
  }
  else{
    date_time = moment(date_time).subtract(-diff_mins, "minutes");
  }
  
  return date_time.toDate();
}

export const convert_moy_anglo_to_moy = (moy_anglo, note_sur_anglo, cycles_notation_level) => {
  cycles_notation_level = cycles_notation_level || 10;
  if(moy_anglo && note_sur_anglo && cycles_notation_level && note_sur_anglo != cycles_notation_level){
    return parseInt(parseFloat(moy_anglo * cycles_notation_level) * 100 / (note_sur_anglo || cycles_notation_level)) / 100;
  }
  return moy_anglo;
}
export const convert_moy_to_moy_anglo = (moy, note_sur_anglo, cycles_notation_level) => {
  cycles_notation_level = cycles_notation_level || 10;
  if(moy && note_sur_anglo && cycles_notation_level && note_sur_anglo != cycles_notation_level){
    return parseInt(parseFloat(moy * (note_sur_anglo || cycles_notation_level)) * 100 / (cycles_notation_level)) / 100;
  }
  return moy;
}

export const to_local_time = (date_time) => {
  var cur_time = new Date();
  var diff_mins = cur_time.getTimezoneOffset() + 60;
  if(diff_mins < 0){
    date_time = moment(date_time).add(-diff_mins, "minutes");
  }
  else{
    date_time = moment(date_time).subtract(diff_mins, "minutes");
  }
  
  return date_time.toDate();
}

export const get_list_from_number = nbr => {
  var list = [];
  for (var i = 1; i <= nbr; i++) {
    list.push(i);
  }
  return list;
}

export const sections = [
  {title: 'Vie Scolaire', icon: images_menu.iconSectionVieScolaire, start_color: '#fdf0a580', end_color: '#f5de9280'},
  {title: 'Services', icon: images_menu.iconSectionInstallation, start_color: '#aceded80', end_color: '#97eeec80'},
  {title: 'Finances', icon: images_menu.iconSectionFincance, start_color: '#f2e1ca80', end_color: '#e3d1b580'},
  {title: 'Communication', icon: images_menu.iconSectionCommunication, start_color: '#f2cbca80', end_color: '#e3b5b580'},
  {title: 'Pédagogie', icon: images_menu.iconSectionPedagogie, start_color: '#cad3f280', end_color: '#b5c4e380'},
  {title: 'Autres', icon: images_menu.iconSectionOthers, start_color: '#d9caf280', end_color: '#c3b5e380'},
]

export const sections_professor = [
  {title: 'Vie Scolaire', icon: images_menu.iconSectionVieScolaire, start_color: '#fdf0a580', end_color: '#f5de9280'},
  {title: 'Pédagogie', icon: images_menu.iconSectionPedagogie, start_color: '#cad3f280', end_color: '#b5c4e380'},
  {title: 'Services', icon: images_menu.iconSectionInstallation, start_color: '#aceded80', end_color: '#97eeec80'},
  {title: 'Autres', icon: images_menu.iconSectionOthers, start_color: '#d9caf280', end_color: '#c3b5e380'},
]

export const sections_mobile = [
  {slug: 'professor', title: 'Enseignant', icon: images_menu.iconSectionVieScolaire, start_color: '#fdf0a580', end_color: '#f5de9280'},
  {slug: 'parent', title: 'parent', icon: images_menu.iconSectionPedagogie, start_color: '#cad3f280', end_color: '#b5c4e380'},
  {slug: 'student', title: 'élève', icon: images_menu.iconSectionInstallation, start_color: '#aceded80', end_color: '#97eeec80'},
]

export const menuItemsSchool = [
  {link:'/classes', slug: 'classes', title: 'Classes', className: 'iconClasses', icon: images_menu.iconMenuClasses, iconActive: images_menu.iconMenuClassesActive, id: 1, section: 0},
  {link:'/students', slug: 'students', title:'Students', className: 'iconStudents', icon: images_menu.iconMenuStudents, iconActive: images_menu.iconMenuStudentsActive, id: 2, section: 0},
  {link:'/parents', slug: 'parents', title:'Parents', className: 'iconStudents', icon: images_menu.iconMenuParents, iconActive: images_menu.iconMenuParentsActive, id: 3, section: 0},
  {link:'/professors', slug: 'professors', title:'Professors', className: 'iconProfessors', icon: images_menu.iconMenuProfessors, iconActive: images_menu.iconMenuProfessorsActive, id: 4, section: 0},
  {link:'/exercises', slug: 'exercises', title:'Exercices interactifs', className: 'iconExercices', icon: images_menu.iconMenuExercises, iconActive: images_menu.iconMenuExercisesActive, id: 5, section: 4}, 
  {link:'/kls_exercises', slug: 'kls_exercises', title: "Exercices Koolskools", className: 'iconExercices', icon: images_menu.iconMenuTextbook, iconActive: images_menu.iconMenuTextbookActive, id: 6, section: 4},
  {link:'/courses', slug: 'courses', title:'Courses', className: 'iconCourses', icon: images_menu.iconMenuLibraryNumeric, iconActive: images_menu.iconMenuLibraryNumericActive, id: 7, section: 4},
  {link:'/formations', slug: 'formations', title:'Formation', className: 'iconCourses', icon: images_menu.iconMenuFormation, iconActive: images_menu.iconMenuFormationActive, id: 8, section: 5},
  {link:'/zoom_cours', slug: 'zoom_cours', title:'Live Courses', className: 'iconCourses', icon: images_menu.iconMenuCoursesLive, iconActive: images_menu.iconMenuCoursesLiveActive, id: 10, section: 5},
  {link:'/challenges', slug: 'challenges', title:'Challenges', className: 'iconSettings', icon: images_menu.iconMenuChallenge, iconActive: images_menu.iconMenuChallengeActive, id: 11, section: 4},
  {link:'/activities', slug: 'activities', title:"Projets des élèves", className: 'iconCourses', icon: images_menu.iconMenuActivity, iconActive: images_menu.iconMenuActivityActive, id: 12, section: 5},
  {link:'/bibliotheque', slug: 'bibliotheque', title:'Bibliotheque Num', className: 'iconCourses', icon: images_menu.iconMenuLibrarySchool, iconActive: images_menu.iconMenuLibrarySchoolActive, id: 13, section: 1},
  {link:'/bibliotheque_phy', slug: 'bibliotheque_phy', title:'Bibliotheque Phys', className: 'iconCourses', icon: images_menu.iconMenuLibraryNumeric, iconActive: images_menu.iconMenuLibraryNumericActive, id: 14, section: 1},
  {link:'/statistics', slug: 'dashboard', title:'Dashboard', className: 'iconStatistics', icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, id: 15, section: 5},
  {link:'/statistics_sanady', slug: 'dashboard_sanady', title:'Dashboard', className: 'iconStatistics', icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, id: 15, section: 5},
  {link:'/payments', slug: 'payments', title:'Payments', className: 'iconStatistics', icon: images_menu.iconMenuPayment, iconActive: images_menu.iconMenuPaymentActive, id: 16, section: 2},
  {link:'/conseil_classe', slug: 'conseil_classe', title:'Conseils de classe', className: 'iconStatistics', icon: images_menu.iconMenuConseilClasses, iconActive: images_menu.iconMenuConseilClassesActive, id: 17, section: 4},
  {link:'/billing', slug: 'bills', title:'Billing', className: 'iconBills', icon: images_menu.iconMenuInvoice, iconActive: images_menu.iconMenuInvoiceActive, id: 18, section: 2},
  {link:'/journal', slug: 'cahier_journal', title: getCycleName('notebook_journal'), className: 'iconCourses', icon: images_menu.iconMenuTextbook, iconActive: images_menu.iconMenuTextbookActive, id: 19, section: 4},
  {link:'/reclamations', slug: 'reclamations', title:'Reclamations', className: 'iconCourses', icon: images_menu.iconMenuComplaint, iconActive: images_menu.iconMenuComplaintActive, id: 20, section: 0},
  {link:'/classes_observations', slug: 'classes_observations', title: 'Grille d\'observation de classes', className: 'iconClasses', icon: images_menu.iconMenuClasses, iconActive: images_menu.iconMenuClassesActive, id: 54, section: 0},
  {link:'/assistants', slug: 'assistants', title:'Assistants', className: 'iconStudents', icon: images_menu.iconMenuAssistant, iconActive: images_menu.iconMenuAssistantActive, id: 21, section: 5},
  {link:'/inscriptions', slug: 'inscriptions', title:'Inscriptions', className: 'iconCourses', icon: images_menu.iconMenuPreinscription, iconActive: images_menu.iconMenuPreinscriptionActive, id: 22, section: 0},
  {link:'/social_network', slug: 'communication', title:'Communication', className: 'iconCourses', icon: images_menu.iconMenuCommunication, iconActive: images_menu.iconMenuCommunicationActive, id: 23, section: 3},
  {link:'/maintenance_monitorings', slug: 'maintenance_monitorings', title:'Suivi maintenance', className: 'iconCourses', icon: images_menu.iconMenuMaintenanceMonitorings, iconActive: images_menu.iconMenuMaintenanceMonitoringsActive, id: 56, section: 5},
  {link:'/scholar_activities', slug: 'scholar_activities', title:'Activité scolaires', className: 'iconCourses', icon: images_menu.iconMenuScholarActivities, iconActive: images_menu.iconMenuScholarActivitiesActive, id: 57, section: 5},
  {link:'/evaluation', slug: 'evaluations', title:'Grades/Marks', className: 'iconEvaluation', icon: images_menu.iconMenuNote, iconActive: images_menu.iconMenuNoteActive, id: 24, section: 4},
  {link:'/exam', slug: 'exams', title:'Evaluation', className: 'iconExam', icon: images_menu.iconMenuEvaluation, iconActive: images_menu.iconMenuEvaluationActive, id: 25, section: 4},
  {link:'/calendar', slug: 'calendar', title: 'Agenda des évènements', className: 'iconHoliday', icon: images_menu.iconMenuAgenda, iconActive: images_menu.iconMenuAgendaActive, id: 26, section: 3},
  {link:'/schedule', slug: 'schedule', title:'Schedule', className: 'iconSchedule', icon: images_menu.iconMenuSchedule, iconActive: images_menu.iconMenuScheduleActive, id: 27, section: 0},
  {link:'/absences', slug: 'call', title:'Student absences', className: 'iconAbsences', icon: images_menu.iconMenuAbsence, iconActive: images_menu.iconMenuAbsenceActive, id: 28, section: 0},
  {link:'/libraries', slug: 'libraries', title: "Stockage Documents", className: 'iconLibrary', icon: images_menu.iconMenuLibrary, iconActive: images_menu.iconMenuLibraryActive, id: 29, section: 5},
  {link:'/transport', slug: 'transport', title:'Transport', className: 'iconTransport', icon: images_menu.iconMenuTransport, iconActive: images_menu.iconMenuTransportActive, id: 30, section: 1},
  {link:'/internal_calls_visits', slug: 'internal_calls_visits', title:'Internal calls and visits', className: 'iconInternalCallsVisits', icon: images_menu.iconMenuVisit, iconActive: images_menu.iconMenuVisitActive, id: 32, section: 0},
  {link:'/cv_libraries', slug: 'cv_libraries', title:'CV-Thèque', className: 'iconInternalCallsVisits', icon: images_menu.iconMenuCvTheque, iconActive: images_menu.iconMenuCvThequeActive, id: 43, section: 0},
  {link:'/attestations', slug: 'attestations', title:'Attestations', className: 'iconStudents', icon: images_menu.iconMenuAttestation, iconActive: images_menu.iconMenuAttestationActive, id: 33, section: 5},
  {link:'/activities_prescolaire', slug: 'activities_prescolaire', title:'Activités Prescolaires', className: 'iconStudents', icon: images_menu.iconMenuActivityParascolaire, iconActive: images_menu.iconMenuActivityParascolaireActive, id: 34, section: 5},
  {link:'/cantine', slug: 'cantine', title:'Cantine/Garde/Transport', className: 'iconCantine', icon: images_menu.iconMenuCantine, iconActive: images_menu.iconMenuCantineActive, id: 35, section: 1},
  {link:'/cantine_menues', slug: 'cantine_menues', title:'Canteen menus', className: 'iconCantine', icon: images_menu.iconMenuCantineMenu, iconActive: images_menu.iconMenuCantineMenuActive, id: 60, section: 1},
  {link:'/purchases', slug: 'purchases', title:'Purchases', className: 'iconMenuNote', icon: images_menu.iconMenuNote, iconActive: images_menu.iconMenuNoteActive, id: 63, section: 5},
  {link:'/purchase_orders', slug: 'purchase_orders', title:'Purchase orders', className: 'iconPurchaseOrder', icon: images_menu.iconMenuCommand, iconActive: images_menu.iconMenuCommandActive, id: 36, section: 2},
  {link:'/activities_classes', slug: 'extracurricular_activity', title:'Extracurricular activity', className: 'iconClasses', icon: images_menu.iconMenuProjetEducatif, iconActive: images_menu.iconMenuProjetEducatifActive, id: 37, section: 4},
  {link:'/galleries', slug: 'galleries', title:'Gallery', className: 'iconGallery', icon: images_menu.iconMenuGallery, iconActive: images_menu.iconMenuGalleryActive, id: 38, section: 3}, 
  {link:'/transmissions', slug: 'transmissions', title:'Transmission', className: 'iconTransmission', icon: images_menu.iconMenuTransmission, iconActive: images_menu.iconMenuTransmissionActive, id: 39, section: 3},
  {link:'/messagerie', slug: 'messagerie', title:'Messagerie', className: 'iconCourses', icon: images_menu.iconMenuMessages, iconActive: images_menu.iconMenuMessagesActive, id: 40, section: 3},
  {link:'/sms', slug: 'left_menu_sms', title:'SMS', className: 'iconSMS', icon: images_menu.iconMenuSms, iconActive: images_menu.iconMenuSmsActive, id: 42, section: 3},
  {link:'/classes_subjects_exams_calendar', slug: 'classes_exams_calendar', title:'Exams calendar', className: 'iconSchedule', icon: images_menu.iconMenuCalendarControl, iconActive: images_menu.iconMenuCalendarControlActive, id: 41, section: 0},
  {link:'/students_recovery', slug: 'students_recovery', title:'Students recovery', className: 'iconStudents', icon: images_menu.iconMenuStudents, id: 44, section: 5},
  {link:'/activities_kids', slug: 'activities_kids', title: 'Activités Maternelle', className: 'iconStudents', icon: images_menu.iconMenuActivity, iconActive: images_menu.iconMenuActivityActive, id: 45, section: 3},
  {link:'/extra_scolaire_activities', slug: 'extra_scolaire_activities', title: "Extracurricular activities", className: 'iconStudents', icon: images_menu.iconMenuActivity, iconActive: images_menu.iconMenuActivityActive, id: 51, section: 1},
  {link:'/annual_distribution', slug: 'annual_distribution', title:'Weekly plan', className: 'iconStudents', icon: images_menu.iconMenuStudents, id: 46, section: 4},
  {link:'/document_printing', slug: 'document_printing', title:'Impression', className: 'iconStudents', icon: images_menu.iconMenuStudents, id: 47, section: 0},
  {link:'/projects', slug: 'projects', title: 'Projects', className: 'iconClasses', icon: images_menu.iconMenuProjects, iconActive: images_menu.iconMenuProjectsActive, id: 58, section: 5},
  {link:'/areas_learnings', slug: 'areas_learnings', title:"Domaines d'apprentissage", className: 'iconStudents', icon: images_menu.iconMenuStudents, id: 48, section: 5},
  {link:'/connection_tracking', slug: 'connection_tracking', title:'Connection tracking', className: 'iconStatistics', icon: images_menu.iconMenuTracking, iconActive: images_menu.iconMenuTrackingActive, id: 49, section: 5},
  {link:'/homework_follow_up', slug: 'homework_follow_up', title:'Homework follow-up', className: 'iconHomeworkFollowUp', icon: images_menu.iconMenuActivityParascolaireActive, id: 50, section: 4},
  {link:'/reporting', slug: 'reporting', title:'Reports and Lists', className: 'iconStatistics', icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, id: 52, section: 5},
  {link:'/evals_calendar', slug: 'evals_calendar', title:'Calendrier des évaluations', className: 'iconSchedule', icon: images_menu.iconMenuCalendarControl, iconActive: images_menu.iconMenuCalendarControlActive, id: 55, section: 5},
  {link:'/surveys', slug: 'surveys', title:'Surveys', className: 'iconSurveys', icon: images_menu.iconMenuSurvey, iconActive: images_menu.iconMenuSurveyActive, id: 53, section: 3},
  {link:'/losts_objects', slug: 'losts_objects', title:'LostsObjects', className: 'iconLostObject', icon: images_menu.iconMenuLostObject, iconActive: images_menu.iconMenuLostObjectActive, id: 54, section: 0},
  {link:'/resources', slug: 'resources', title: 'Resources', className: 'iconClasses', icon: images_menu.iconMenuProjects, iconActive: images_menu.iconMenuProjectsActive, id: 59, section: 5},
  {link:'/whatsapp', slug: 'whatsapp', title:'Whatsapp', className: 'iconSMS', icon: images_menu.iconMenuSms, iconActive: images_menu.iconMenuSmsActive, id: 60, section: 3},
  {link:'/critical_bulletin', slug: 'critical_bulletin', title:'Bulletin critérié', className: 'iconMenuNote', icon: images_menu.iconMenuNote, iconActive: images_menu.iconMenuNoteActive, id: 61, section: 4},
  {link:'/behaviors', slug: 'behavior', title:'Discipline', className: 'iconMenuNote', icon: images_menu.iconMenuNote, iconActive: images_menu.iconMenuNoteActive, id: 62, section: 0},
  {link:'/settings', slug: 'settings', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/levels', slug: 'settings_levels_subjects', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/competencies', slug: 'settings_evaluations', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/classrooms', slug: 'settings_classroom', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/periods', slug: 'settings_period', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/payments', slug: 'settings_payments', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/reclamations', slug: 'settings_reclamations', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/behavior', slug: 'settings_behavior', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/staff', slug: 'settings_staff', title:'Staffs', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/holidays', slug: 'settings_holidays', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/absences', slug: 'settings_absences', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},
  {link:'/settings/incidents', slug: 'settings_incidents', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuEvaluationActive, id: 31, section: 5},
  {link:'/settings/referentiel', slug: 'settings_referentiel', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},  
  {link:'/settings/coursvalidation', slug: 'settings_schoolvalidation', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5},  
  {link:'/settings/settings_staff', slug: 'settings_staff', title:'Settings', className: 'iconSettings', icon: images_menu.iconMenuParametre, iconActive: images_menu.iconMenuParametreActive, id: 31, section: 5}
]
export const menuItemsSchoolParentSanadi = [
  {link:'/reporting', slug: 'reporting', title:'Reports and Lists', className: 'iconStatistics', icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, id: 52, section: 5},
  // {link:'/statistics_sanady', slug: 'dashboard_sanady', title:'Dashboard', className: 'iconStatistics', icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, id: 15, section: 5},
]

export const menuItemsProfessor = [
  {url: "/professor/classes", slug: 'classes',label: "Classes", icon: images_menu.iconMenuClasses, iconActive: images_menu.iconMenuClassesActive, className: "iconClasses", section: 0, id: 1},
  {url: "/professor/statistics", slug: 'statistics',label: "Dashboard", icon: images_menu.iconMenuDashboard, iconActive: images_menu.iconMenuDashboardActive, className: "iconStatistics", section: 3, id: 2},
  {url: "/professor/students", slug: 'students',label: "Students", icon: images_menu.iconMenuStudents, iconActive: images_menu.iconMenuStudentsActive, className: "iconStudents", section: 0, id: 3},
  {url: "/professor/exercises", slug: 'exercises',label: 'Exercices interactifs', icon: images_menu.iconMenuExercises, iconActive: images_menu.iconMenuExercisesActive, className: "iconExercices", section: 1, id: 4},
  {url: "/professor/courses", slug: 'courses',label: "Courses", icon: images_menu.iconMenuLibraryNumeric, iconActive: images_menu.iconMenuLibraryNumericActive, className: "iconCourses", section: 1, id: 5},
  {url: "/professor/activities_prescolaire", slug: 'activities_prescolaire',label: "Activités Prescolaires", icon: images_menu.iconMenuActivityParascolaire, iconActive: images_menu.iconMenuActivityParascolaireActive, className: "iconStudents", section: 3, id: 6},
  {url: "/professor/formations", slug: 'formations',label: "Formation", icon: images_menu.iconMenuFormation, iconActive: images_menu.iconMenuFormationActive, className: "iconCourses", section: 3, id: 7},
  {url: "/professor/zoom_cours", slug: 'zoom_cours',label: "Live Courses", icon: images_menu.iconMenuCoursesLive, iconActive: images_menu.iconMenuCoursesLiveActive, className: "iconCourses", section: 3, id: 8},
  {url: "/professor/schedule", slug: 'schedule',label: "Emploi du temps", icon: images_menu.iconMenuSchedule, iconActive: images_menu.iconMenuScheduleActive, className: "iconSchedule", section: 0, id: 9},
  {url: "/professor/activities", slug: 'activities',label: "Projets des élèves", icon: images_menu.iconMenuActivity, iconActive: images_menu.iconMenuActivityActive, className: "iconCourses", section: 3, id: 10},
  {url: "/professor/bibliotheque", slug: 'bibliotheque',label: "Bibliotheque", icon: images_menu.iconMenuLibrarySchool, iconActive: images_menu.iconMenuLibrarySchoolActive, className: "iconCourses", section: 3, id: 11},
  {url: "/professor/challenges", slug: 'challenges',label: "Challenges", icon: images_menu.iconMenuChallenge, iconActive: images_menu.iconMenuChallengeActive, className: "iconChallenge", section: 3, id: 12},
  {url: "/professor/evaluation", slug: 'evaluation',label: "Grades/Marks", icon: images_menu.iconMenuNote, iconActive: images_menu.iconMenuNoteActive, className: "iconEvaluation", section: 1, id: 13},
  {url: "/professor/social_network", slug: 'social_network',label: "Communication", icon: images_menu.iconMenuCommunication, iconActive: images_menu.iconMenuCommunicationActive, className: "iconCourses", section: 3, id: 14},
  {url: "/professor/exam", slug: 'exam',label: "Evaluation", icon: images_menu.iconMenuEvaluation, iconActive: images_menu.iconMenuEvaluationActive, className: "iconExam", section: 1, id: 15},
  {url: "/professor/classes_subjects_exams_calendar", slug: 'classes_subjects_exams_calendar',label: "Exams calendar", icon: images_menu.iconMenuCalendarControl, iconActive: images_menu.iconMenuCalendarControlActive, className: "iconSchedule", section: 3, id: 16},
  {url: "/professor/absences" , slug: 'absences', label: "Student absences", icon: images_menu.iconMenuAbsence, iconActive: images_menu.iconMenuAbsenceActive, className: "iconAbsences", section: 0, id: 17},
  {url: "/professor/messagerie",slug: 'messagerie',  label: 'Messagerie', icon: images_menu.iconMenuMessages, iconActive: images_menu.iconMenuMessagesActive, className: "iconCourses", section: 3, id: 18},
  {url: "/professor/libraries", slug: 'libraries', label: 'Drive', icon: images_menu.iconMenuLibrary, iconActive: images_menu.iconMenuLibraryActive, className: "iconLibrary", section: 3, id: 19},
  {url: "/professor/journal", slug: 'journal', label: getCycleName('notebook_journal'), icon: images_menu.iconMenuTextbook, iconActive: images_menu.iconMenuTextbookActive, className: "iconCourses", section: 1, id: 20},
  {url: "/professor/activities_classes", slug: 'activities_classes', label:'Extracurricular activity', icon: images_menu.iconMenuProjetEducatif, iconActive: images_menu.iconMenuProjetEducatifActive, className: "iconClasses", section: 1, id: 21},
  {url: "/professor/galleries", slug: 'galleries', label: "Gallery", icon: images_menu.iconMenuGallery, iconActive: images_menu.iconMenuGalleryActive, className: "iconGalleries", section: 3, id: 22},
  {url: "/professor/transmissions", slug: 'transmissions', label: "Transmission", icon: images_menu.iconMenuTransmission, iconActive: images_menu.iconMenuTransmissionActive, className: "iconTransmission", section: 3, id: 23},
  {url: "/professor/students_recovery", slug: 'students_recovery', label: "Students recovery", icon: images_menu.iconMenuStudents, className: "iconStudents", section: 3, id: 24},
  {url: "/professor/annual_distribution", slug: 'annual_distribution', label: 'Weekly plan', icon: images_menu.iconMenuStudents, className: "iconStudents", section: 3, id: 25},
  {url: "/professor/document_printing", slug: 'document_printing', label: 'Impression', icon: images_menu.iconMenuStudents, className: "iconStudents", section: 3, id: 26},
  {url:'/professor/critical_bulletin', slug: 'critical_bulletin', label:'Bulletin critérié', icon: images_menu.iconMenuNote, className: 'iconMenuNote', id: 61, section: 1},
  {url: "/professor/projects", slug: 'projects',label: "Projects", icon: images_menu.iconMenuProjects, iconActive: images_menu.iconMenuProjectsActive, className: "iconClasses", section: 3, id: 29},
  {url: "/professor/extra_scolaire_activities", slug: 'extra_scolaire_activities', label: 'Extracurricular activities', icon: images_menu.iconMenuActivity, className: "iconStudents", section: 2, id: 27},
  {url: "/professor/student_outing", slug: 'student_outing', label: 'Sortie élève', icon: images_menu.iconMenuActivity, className: "iconStudents", section: 2, id: 28},
  {url: "/professor/resources", slug: 'resources', label: 'Resources', icon: images_menu.iconMenuClasses, iconActive: images_menu.iconMenuClassesActive, className: "iconClasses", section: 3, id: 59},
]

export const menuItemsProfessorMobile = [
  {slug: 'post', label: 'Communication', icon: images_menu.iconMenuCommunication},
  {slug: 'textbook', label: 'Homeworks', icon: images_menu.iconMenuTextbook},
  {slug: 'course', label: 'Cours', icon: images_menu.iconMenuLibraryNumeric},
  {slug: 'notes', label: 'Grades/Marks', icon: images_menu.iconMenuNote},
  {slug: 'seance', label: 'Emploi du temps', icon: images_menu.iconMenuSchedule},
  {slug: 'journal', label: 'Cahier journal', icon: images_menu.iconMenuSchedule},
  {slug: 'absence', label: 'Absences', icon: images_menu.iconMenuAbsence},
  {slug: 'gallery', label: 'Galerie', icon: images_menu.iconMenuGallery},
  {slug: 'inbox', label: 'Messages', icon: images_menu.iconMenuMessages},
  {slug: 'tarl', label: 'TARL', icon: images_menu.iconMenuNote},
  {slug: 'competency', label: 'Skills', icon: images_menu.iconMenuNote},
  {slug: 'resource', label: 'Resources', icon: images_menu.iconMenuLostObject},
  {slug: 'discipline', label: 'Discipline', icon: images_menu.iconMenuLostObject}
]

export const menuItemsParentMobile = [
  {slug: 'post', label: 'Accueil', icon: images_menu.iconMenuCommunication},
  {slug: 'textbook', label: 'Homeworks', icon: images_menu.iconMenuTextbook},
  {slug: 'seance', label: 'Emploi du temps', icon: images_menu.iconMenuSchedule},
  {slug: 'absence', label: 'Absences & Retards', icon: images_menu.iconMenuAbsence},
  {slug: 'notes', label: 'Grades/Marks', icon: images_menu.iconMenuNote},
  {slug: 'course', label: 'Cours', icon: images_menu.iconMenuLibraryNumeric},
  {slug: 'exam_calendar', label: 'Calendrier des contrôles', icon: images_menu.iconMenuCalendarControl},
  {slug: 'event', label: 'Agenda', icon: images_menu.iconMenuAgenda},
  {slug: 'gallery', label: 'Galerie', icon: images_menu.iconMenuGallery},
  {slug: 'transmission', label: 'Transmission', icon: images_menu.iconMenuTransmission},
  {slug: 'payment', label: 'Paiements', icon: images_menu.iconMenuPayment},
  {slug: 'information', label: 'Information école', icon: images_menu.iconMenuParametre},
  {slug: 'inbox', label: 'Messages', icon: images_menu.iconMenuMessages},
  {slug: 'infirmary', label: 'Infirmerie', icon: images_menu.iconMenuComplaint},
  {slug: 'competency', label: 'Skills', icon: images_menu.iconMenuNote},
  {slug: 'survey', label: 'Sondages', icon: images.iconMenuSondageActive},
  {slug: 'lost_object', label: 'LostsObjects', icon: images_menu.iconMenuLostObject},
  {slug: 'resource', label: 'Ressources', icon: images_menu.iconMenuLostObject},
  {slug: 'cantine', label: 'Menus de la cantine', icon: images_menu.iconMenuCantineMenu},
  {slug: 'activity', label: 'Activities', icon: images_menu.iconMenuTextbook},
  {slug: 'discipline', label: 'Discipline', icon: images_menu.iconMenuLostObject}
]

export const menuItemsStudentMobile = [
  {slug: 'home', label: 'Accueil', icon: images_menu.iconMenuDashboard},
  {slug: 'seance', label: 'Emploi du temps', icon: images_menu.iconMenuSchedule},
  {slug: 'textbook', label: 'Homeworks', icon: images_menu.iconMenuTextbook},
  {slug: 'course', label: 'Cours', icon: images_menu.iconMenuLibraryNumeric},
  {slug: 'notes', label: 'Grades/Marks', icon: images_menu.iconMenuNote},
  {slug: 'post', label: 'Communication', icon: images_menu.iconMenuCommunication},
  {slug: 'challenge', label: 'Challenge', icon: images_menu.iconMenuChallenge},
  {slug: 'event', label: 'Agenda', icon: images_menu.iconMenuAgenda},
  {slug: 'exam_calendar', label: 'Calendrier des contrôles', icon: images_menu.iconMenuCalendarControl},
  {slug: 'inbox', label: 'Messages', icon: images_menu.iconMenuMessages},
  {slug: 'survey', label: 'Sondages', icon: images.iconMenuSondageActive},
  {slug: 'lost_object', label: 'LostsObjects', icon: images_menu.iconMenuLostObject},
  {slug: 'resource', label: 'Ressources', icon: images_menu.iconMenuLostObject},
  {slug: 'cantine', label: 'Menus de la cantine', icon: images_menu.iconMenuCantineMenu}
]

export const stylePDF = `
  &.dialogExportViewer2{
    width: 794px;
    padding: 0cm;
    color: #000;
    .export_content{
      &.print_viewer{
        padding-bottom: 0 !important!
        .payments_students{
          padding-bottom: 0 !important!
          padding-top: 0 !important!
        }
      }
      // height: 100%;
      width: 100%;
      // border: 2px solid red;
      .payments_students{
        // height: calc(100% - 180px);
        margin: 0px;
        &::-webkit-scrollbar-track{
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50; /* Position the scrollbar on the right of the element*/
        }
        &::-webkit-scrollbar{
          width: 10px;
          height: 10px;
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50; /* Position the scrollbar on the right of the element*/
        }
        &::-webkit-scrollbar-thumb{
          background-color: #3eb6ad;
          border-radius: 5px;
          position: absolute;
          right: -50; /* Position the scrollbar on the right of the element*/
        }
        .header_slip{
          display: flex;
          justify-content: space-between;
          width: 100%;
          // border: 2px solid green;
          >div{
            // border: 2px solid blue;
              /*border:1px solid #000;*/
              height:100px;
              width:300px;
              padding:5px;
              display:flex;
              justify-content:center;
              align-items:center;
              font-weight: bold;
              &:first-child{
                justify-content:flex-start;
              }
              img{
                max-width:100%;
                max-height:100%
              }
          }
        }
        .header_slip_title{
          border: 2px solid #000;
          padding: 10px;
          margin: 10px 0px;
          text-align: center;
          font-weight: bold;
        }
        &.viewer{
          .header_slip{
            >div{
              height:150px;
            }
          }
          .header_slip_title{
            margin: 10px auto;
            width: max-content;
          }
          .table_payments{
            .header_slip_title{
              margin: 10px auto;
              width: max-content;
            }
            .header_line{
              background-color: #b5c4e3;
              td:not(.spacer_td), th{
                background-image: none;
                background-color: #b5c4e3;
                font-size: 12px;
                font-weight: 600;
              }
            }
            th, td{
              font-size: 12px;
              color: #000;
            }
          }
        }
        &.students_by_new_registration{
          .header_slip_title{
            padding: 10px 20px !important;
            color: black !important;
            border-radius: 6px;
          }
        }
      }
    }

  }
  
  .export_content{
    height: calc(100% - 40px);
    padding-bottom: 40px;
    .payments_students{
      height: calc(100% - 166px);
      &.effectifs_students_by_project, &.effectifs_students_by_school, &.effectifs_students_by_cycle, &.effectifs_students_by_level, &.effectifs_students_by_classe{
        height: calc(100% - 100px) !important;
      }
      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 5px;
        position: absolute;
          right: -50; /* Position the scrollbar on the right of the element*/
      }
      &::-webkit-scrollbar{
        width: 10px;
        height: 10px;
        background-color: #F5F5F5;
        border-radius: 5px;
        position: absolute;
        right: -50; /* Position the scrollbar on the right of the element*/
      }
      &::-webkit-scrollbar-thumb{
        background-color: #3eb6ad;
        border-radius: 5px;
        position: absolute;
          right: -50; /* Position the scrollbar on the right of the element*/
      }
      &.viewer{
        .students_list{
          height: 100%;
          width: 100%;
          background-color: rgb(245, 246, 250);
          margin: 18px 15px;
          border-radius: 8px;
          padding: 10px 5px;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(209, 219, 226);
          border-image: initial;
          &.students_list2{
            flex: 1;
            width: max-content;
            min-width: 100%;
            margin: 0px;
            backdrop-filter: none;
            background-color: #FFF;
            border: 0px;
            border-radius: 0px;
            padding: 0px;
            .parent_body{
              border: 3px solid #000;
            }
            table{
              tr{
                th.checkbox_col, td.checkbox_col{
                  width: 10px;
                }
                &.header{
                  td:not(.spacer_td), th{
                    background-image: none;
                    background-color: #b5c4e3;
                    font-size: 12px;
                    font-weight: 600;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:first-child {
                      border-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &:last-child {
                      border-right-width: 1px!important;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                }
                &.header2{
                  td{
                    border-radius: 6px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.45;
                    color: #6e6e6e;
                    height: 50px;
                    padding: 10px 5px;
                    background-color: white;
                    border-style: solid;
                    border-color: #e8e8e8;
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    width: auto;
                    white-space: nowrap;
                    padding: 2px;
                    &.header_level{
                      background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                      font-size: 10px;
                      font-weight: 600;
                      line-height: 1.64;
                      color: #202726;
                      &:first-child {
                        border-top-left-radius: 14px;
                        border-bottom-left-radius: 14px;
                      }
                      &:last-child {
                        border-right-width: 1px;
                        border-top-right-radius: 14px;
                        border-bottom-right-radius: 14px;
                      }
                    }
                  }
                }
                &.student:not(.spacer){
                  th{
                    background-image: none;
                    background-color: #b5c4e3;
                    font-size: 12px;
                    font-weight: 600;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:first-child {
                      border-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &:last-child {
                      border-right-width: 1px!important;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                  td:not(.spacer_td){
                    border-radius: 0px;
                    font-size: 11px;
                    font-weight: 500;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:last-child {
                      border-right-width: 1px!important;
                    }
                  }
                }
              }
            }
          }
          &.students_list2{
            flex: 1;
            width: max-content;
            min-width: 100%;
            margin: 0px;
            backdrop-filter: none;
            background-color: #FFF;
            border: 0px;
            border-radius: 0px;
            padding: 0px;
            .parent_body{
              border: 3px solid #000;
            }
            table{
              tr{
                th.checkbox_col, td.checkbox_col{
                  width: 10px;
                }
                &.header{
                  td:not(.spacer_td), th{
                    background-image: none;
                    background-color: #b5c4e3;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:first-child {
                      border-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &:last-child {
                      border-right-width: 1px!important;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                }
                &.header2{
                  td{
                    border-radius: 6px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.45;
                    color: #6e6e6e;
                    height: 50px;
                    padding: 10px 5px;
                    background-color: white;
                    border-style: solid;
                    border-color: #e8e8e8;
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    width: auto;
                    white-space: nowrap;
                    padding: 2px;
                    &.header_level{
                      background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 1.64;
                      color: #202726;
                      &:first-child {
                        border-top-left-radius: 14px;
                        border-bottom-left-radius: 14px;
                      }
                      &:last-child {
                        border-right-width: 1px;
                        border-top-right-radius: 14px;
                        border-bottom-right-radius: 14px;
                      }
                    }
                  }
                }
                &.student:not(.spacer){
                  th{
                    background-image: none;
                    background-color: #b5c4e3;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:first-child {
                      border-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &:last-child {
                      border-right-width: 1px!important;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                  td:not(.spacer_td){
                    border-radius: 0px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    padding: 2px;
                    &:last-child {
                      border-right-width: 1px!important;
                    }
                  }
                }
              }
            }
          }
          &.students_tarl_notes{
            width: max-content;
            min-width: 100%;
            margin: 0px;
            backdrop-filter: none;
            background-color: #FFF;
            border: 0px;
            border-radius: 0px;
            padding: 0px;
            table{
              tr{
                &.header{
                  td:not(.spacer_td), th{
                    background-image: none;
                    background-color: #b5c4e3;
                    font-size: 13px;
                    font-weight: 600;
                    color: #000;
                    width: auto;
                    white-space: nowrap;
                    border-color: #000;
                    height: 30px;
                    &:first-child {
                      border-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &:last-child {
                      border-right-width: 0px;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                  }
                }
              }
            }
          }
          &.cantine_garde{
            overflow: overlay !important;
            // min-width: 100%;
            // width: max-content;
          }
          table{
            margin: auto;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0px;
            border: 1px solid #000;
            tr{
              text-align: center;
              td:not(.spacer_td){
                // border-left: 1px solid black;
                // border-top: 1px solid black;
                width: 6%;
                &.large{
                  width: 6.46%
                }
                &.name{
                  width: calc(16% - 20px);
                }
                padding: 3px;
                font-size: 10px;
              }
              &.header{
                font-weight: bold;
                td:not(.spacer_td), th{
                  padding-bottom: 20px;
                }
              }
              &.spacer{
                height: 10px;
              }
              &.student:not(.spacer), &.header{
                td:not(.spacer_td), th{
                  background-color: rgb(255, 255, 255);
                  height: auto;
                  border-style: solid;
                  border-color: rgb(232, 232, 232);
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  &:first-child {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  }
                  &:last-child {
                    border-right-width: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                  }
                  &.gray{
                    background: #F5F5F5;
                  }
                  &.red{
                    background: #FF0000;
                  }
                  &.greeen{
                    background: #00FF00;
                  }
                  &.gray2{
                    background: gray;
                  }
                  &.p_em{
                    max-width: 100px;
                    word-break: break-all;
                  }
                }
              }
              td.spacer_td{
                width: 10px;
                border-left: 1px;
                border-left-style: solid;
                border-left-color: #e8e8e8;
              }
              td.name_ {
                background-color: #f5f6fa !important;
            }
              td.name.header_level {
                background-color: #e8e8e8 !important;
              }
            }
            &.mobile{

              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  width: 25%;
                  &.large{
                    width: 25%
                  }
                  &.name{
                    width: calc(50% - 10px);
                  }
                }
              }
            }
          }
          &.no_scroll{
            overflow: unset;
          }
        }
      }
      .students_list{
        height: 100%;
        width: 100%;
        background-color: rgb(245, 246, 250);
        margin: 18px 15px;
        border-radius: 16px;
        padding: 10px 5px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(209, 219, 226);
        border-image: initial;
        &.students_list2{
          flex: 1;
          width: max-content;
          min-width: 100%;
          margin: 0px;
          backdrop-filter: blur(8px);
          background-color: rgba(222, 230, 234, 0.64);
          table{
            tr{
              th.checkbox_col, td.checkbox_col{
                width: 10px;
              }
              &.header{
                td:not(.spacer_td), th{
                  background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 1.64;
                  color: #202726;
                  width: auto;
                  white-space: nowrap;
                  &:first-child {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px;
                  }
                  &:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px;
                  }
                }
              }
              &.header2{
                td{
                  border-radius: 6px;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 1.45;
                  color: #6e6e6e;
                  height: 50px;
                  padding: 10px 5px;
                  background-color: white;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  width: auto;
                  white-space: nowrap;
                  &.header_level{
                    background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 1.64;
                    color: #202726;
                    &:first-child {
                      border-top-left-radius: 14px;
                      border-bottom-left-radius: 14px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 14px;
                      border-bottom-right-radius: 14px;
                    }
                  }
                }
              }
              &.header_s_t{
                td:not(.spacer_td), th{
                  height: 30px;
                  padding: 5px;
                  &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                  }
                  &:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                  }
                }
              }
              &.student:not(.spacer){
                td:not(.spacer_td){
                  border-radius: 6px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 1.45;
                  color: #6e6e6e;
                  width: auto;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        &.students_tarl_notes{
          table{
            tr{
              &.header{
                td:not(.spacer_td), th{
                  height: 30px;
                  padding: 5px;
                  &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                  }
                  &:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                  }
                }
              }
            }
          }
        }
        &.cantine_garde{
          overflow: overlay !important;
          // min-width: 100%;
          // width: max-content;
        }
        table{
          margin: auto;
          width: 100%;
          border-collapse: separate;
          border-spacing: 1px;
          tr{
            text-align: center;
            td:not(.spacer_td){
              // border-left: 1px solid black;
              // border-top: 1px solid black;
              width: 6%;
              &.large{
                width: 6.46%
              }
              &.name{
                width: calc(16% - 20px);
              }
              padding: 3px;
              font-size: 10px;
            }
            &.header{
              font-weight: bold;
              td:not(.spacer_td), th{
                padding-bottom: 20px;
              }
            }
            &.spacer{
              height: 10px;
            }
            &.student:not(.spacer), &.header{
              td:not(.spacer_td), th{
                background-color: rgb(255, 255, 255);
                height: 50px;
                border-style: solid;
                border-color: rgb(232, 232, 232);
                border-image: initial;
                border-width: 1px 0px 1px 1px;
                padding: 10px;
                &:first-child {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                &:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
                &.gray{
                  background: #F5F5F5;
                }
                &.red{
                  background: #FF0000;
                }
                &.greeen{
                  background: #00FF00;
                }
                &.gray2{
                  background: gray;
                }
                &.p_em{
                  max-width: 100px;
                  word-break: break-all;
                }
              }
            }
            td.spacer_td{
              width: 10px;
              border-left: 1px;
              border-left-style: solid;
              border-left-color: #e8e8e8;
            }
            td.name_ {
              background-color: #f5f6fa !important;
          }
            td.name.header_level {
              background-color: #e8e8e8 !important;
            }
          }
          &.mobile{

            margin: auto;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0px;
            tr{
              text-align: center;
              td:not(.spacer_td){
                // border-left: 1px solid black;
                // border-top: 1px solid black;
                width: 25%;
                &.large{
                  width: 25%
                }
                &.name{
                  width: calc(50% - 10px);
                }
              }
            }
          }
        }
        &.no_scroll{
          overflow: unset;
        }
      }
      .loading{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 15px;
        .loading_img{
          position: relative;
          width: 100%;
          height: 100%;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 50%;
            max-height: 50%;
          }
        }
      }
    }
    .act_export{
      margin: 0 !important;
    }
  }
`



