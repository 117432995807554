import video from "../../assets/video/home.mp4";
import video_fmps from "../../assets/video/home_fmps.mp4";
import logo_laraki from "../../assets/img/logo_laraki.png";
import home_laraki from "../../assets/img/home_laraki.png";
import login_background from "../../assets/img/fmps/login_background.png";
import menu_home_connexion from "../../assets/img/fmps/menu_home_connexion.png";
import bg_unconnected from "../../assets/img/fmps/bg_unconnected.jpg";
import bg_unconnected_ar from "../../assets/img/fmps/bg_unconnected_ar.png";
import all_plateforme_img from "../../assets/img/fmps/all_plateforme_img.png";
import all_plateforme_img_ar from "../../assets/img/fmps/all_plateforme_img_ar.png";
import logo_fmps from "../../assets/img/fmps/logo_fmps.png";
import logo_fmps_ar from "../../assets/img/fmps/logo_fmps_ar.png";
import logo_atfalouna_learning from "../../assets/img/fmps/logo_atfalouna_learning.png";
import home_form from "../../assets/img/fmps/home_form.png";
import home_form_ar from "../../assets/img/fmps/home_form_ar.png";
import apropos_bg from "../../assets/img/fmps/apropos_bg.png";
import apropos_title from "../../assets/img/fmps/apropos_title.png";
import apropos_title_ar from "../../assets/img/fmps/apropos_title_ar.png";
import apropos_img from "../../assets/img/fmps/apropos_img.png";
import apropos_img_ar from "../../assets/img/fmps/apropos_img_ar.png";
import bg_blue_card from '../../assets/img/fmps/blue_card_bg.png';
import bg_green_card from '../../assets/img/fmps/green_card_bg.png';
import bg_pink_card from '../../assets/img/fmps/pink_card_bg.png';
import bg_orange_card from '../../assets/img/fmps/orange_card_bg.png';
import bg_blue_card_ar from '../../assets/img/fmps/blue_card_bg_ar.png';
import bg_green_card_ar from '../../assets/img/fmps/green_card_bg_ar.png';
import bg_pink_card_ar from '../../assets/img/fmps/pink_card_bg_ar.png';
import bg_orange_card_ar from '../../assets/img/fmps/orange_card_bg_ar.png';
import email from '../../assets/img/fmps/email.png';
import localisation from '../../assets/img/fmps/localisation.png';
import phone from '../../assets/img/fmps/phone.png';
import facebook_icon from '../../assets/img/fmps/facebook_icon.png';
import youtube_icon from '../../assets/img/fmps/youtube_icon.png';
import linkedin_icon from '../../assets/img/fmps/linkedin_icon.png';

export const videos = {
  video, video_fmps, logo_laraki, home_laraki
};

export const images = {
  facebook_icon,
  youtube_icon,
  linkedin_icon,
  email,
  localisation,
  phone,
  bg_blue_card,
  bg_green_card,
  bg_pink_card,
  bg_orange_card,
  bg_blue_card_ar,
  bg_green_card_ar,
  bg_pink_card_ar,
  bg_orange_card_ar,
  login_background,
  menu_home_connexion,
  bg_unconnected,
  all_plateforme_img,
  logo_fmps,
  logo_atfalouna_learning,
  home_form,
  bg_unconnected_ar,
  all_plateforme_img_ar,
  logo_fmps_ar,
  home_form_ar,
  apropos_bg,
  apropos_title,
  apropos_title_ar,
  apropos_img,
  apropos_img_ar
}